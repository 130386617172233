export const items = [
  {
    id: 'remove',
    icon: 'delete',
    name: 'Remove',
    permission: {
      collection: 'deals',
      action: 'delete',
    },
  },
];

export const itemAllowDelete = {
  id: 'remove',
  icon: 'delete',
  name: 'Delete',
  permission: {
    collection: 'deals',
    action: 'delete',
  },
};

export const itemDisableDelete = {
  id: 'remove',
  icon: 'delete',
  name: 'Delete',
  className: 'disabled',
  disabled: 'You don’t have delete permission.',
};

export const WON = 'won';

export const LOST = 'lost';

export const IN_PROGRESS = 'inProgress';

export const PENDING = 'pending';

export const CLOSED_WON = 'Closed Won';

export const CLOSED_LOST = 'Closed Lost';

export const OPENED = 'opened';

export function isWonDeal(deal) {
  return deal?.tenant_deal_stage?.name === CLOSED_WON;
}

export function isLostDeal(deal) {
  return deal?.tenant_deal_stage?.name === CLOSED_LOST;
}
