import React, { useEffect, useState } from 'react';
import { FILE_SIZE_LIMIT, getFileSize } from '../../../utils/Utils';
import stringConstants from '../../../utils/stringConstants.json';
import {
  checkAndAllowFileUpload,
  VALID_FILE_FORMATS,
} from '../../../utils/constants';
import MaterialIcon from '../../commons/MaterialIcon';
import FunnyLoaderText from '../../commons/FunnyLoaderText';
import DotDot from '../../commons/DotDot';
import { Spinner } from 'reactstrap';

const constants = stringConstants.modals.uploadFileModal;

const FilePreview = ({ file }) => {
  const [fileInfo, setFileInfo] = useState({
    name: '',
    size: '',
  });

  useEffect(() => {
    setFileInfo((prev) => ({
      ...prev,
      name: file.name,
      size: getFileSize(file.size),
    }));
  }, [file]);

  return (
    <div className="js-dropzone dropzone-custom dz-clickable dz-started">
      <div className="col h-100 px-1 mb-2 dz-processing dz-success dz-complete">
        <div className="dz-preview dz-file-preview border shadow">
          <div className="d-flex justify-content-between align-items-center">
            <div className="dz-details media">
              <span className="dz-file-initials text-capitalize">
                {fileInfo.name[0]}
              </span>
              <div className="media-body dz-file-wrapper">
                <h6 className="dz-filename">
                  <span className="dz-title">{fileInfo.name}</span>
                </h6>
                <div className="dz-size">
                  <strong>{fileInfo.size}</strong>
                </div>
              </div>
            </div>
            <Spinner className="spinner-grow-sm2 text-primary" />
          </div>
          <div className="dz-progress progress mb-1">
            <div className="dz-upload progress-bar bg-success w-100"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const IdfUploadFiles = ({
  fileInput,
  setErrorMessage,
  setFileInput,
  handleSubmit,
  loading,
  setIsLoading,
}) => {
  const [dragActive, setDragActive] = useState(false);

  const onFileChange = (event) => {
    const file = event?.target?.files[0];

    const fileSize = file.size;
    const errors = [];

    // if file exceeds limit
    if (fileSize > FILE_SIZE_LIMIT) {
      errors.push(
        `File size exceeds ${getFileSize(FILE_SIZE_LIMIT, true)} limit.`
      );
    }
    // if file type not allowed
    if (!checkAndAllowFileUpload(file)) {
      errors.push(
        `Invalid file format. Upload file in these ${VALID_FILE_FORMATS.join(
          ','
        )} format.`
      );
    }

    if (errors.length) {
      return setErrorMessage(errors.join('\n'));
    }

    if (!file?.type) {
      const extensionIndex = file.name.indexOf('.');
      const extension = file.name.slice(extensionIndex + 1);

      const newFile = new Blob([file], {
        type: extension,
      });

      newFile.name = file.name.slice(0, extensionIndex);
      newFile.lastModifiedDate = file.lastModifiedDate;
      onSubmit(newFile);
      return setFileInput(newFile);
    }

    if (checkAndAllowFileUpload(file)) {
      onSubmit(file);
      return setFileInput(file);
    }
  };

  const onSubmit = (file) => {
    setIsLoading(true);
    handleSubmit(file || fileInput);
  };

  return (
    <div className="w-100">
      <div className="position-relative">
        <div className="d-flex align-items-center justify-content-center gap-2 rounded mb-3 p-2_1 bg-soft-yellow border border-yellow">
          <MaterialIcon icon="info" clazz="text-black" rounded filled />{' '}
          <p className="mb-0 text-center text-black font-size-sm2">
            All files will be automatically deleted in 14 days.
          </p>
        </div>

        {fileInput ? (
          <>
            <FilePreview file={fileInput} />
          </>
        ) : (
          <>
            <div
              id="file"
              className="js-dropzone border-dashed-gray bg-gray-200 dropzone-custom rounded p-2 my-2"
            >
              <div
                className="media m-auto d-flex align-items-center justify-content-center"
                style={{ height: dragActive ? 120 : 40 }}
              >
                <h5 className="mb-0 d-flex font-weight-normal fs-7 align-items-center justify-content-cente">
                  <MaterialIcon
                    icon={`upload_file`}
                    clazz="font-size-2xl font-weight-normal"
                  />
                  {constants.dragAndDrop}
                  {' or'}&nbsp;
                  <a className="font-weight-semi-bold decoration-underline">
                    {constants.browseFiles}
                  </a>
                  &nbsp;
                  {'for a file to upload'}
                </h5>
              </div>
            </div>

            <input
              onDragEnter={() => setDragActive(true)}
              onDragLeave={() => setDragActive(false)}
              onDrop={() => setDragActive(false)}
              className="file-input-drag"
              type="file"
              name="file"
              onChange={onFileChange}
              accept={VALID_FILE_FORMATS.join(',')}
              value={fileInput}
              id="file"
            />
          </>
        )}
      </div>

      {fileInput && loading && (
        <p className="text-left mb-1 pl-2 fs-7">
          <FunnyLoaderText
            autoPlay={6000}
            startingText="File process in progress"
            isFileProcessing={true}
          />
          <DotDot />
        </p>
      )}
    </div>
  );
};

export default IdfUploadFiles;
