import React, { Fragment, useState } from 'react';
import { Collapse } from 'react-bootstrap';

import InputSearch from './InputSearch';
import InputDefault from './InputDefault';
import {
  revenueListNew,
  employeeCountListNew,
  companyGrowthList,
} from '../constants';
import CategoryMenuCompany from '../CategoryMenuCompany';
import InputRange from './InputRange';
import TechnologiesSearch from './TechnologiesSearch';
import {
  INDUSTRIES_STORAGE_KEY,
  NAICS_STORAGE_KEY,
  SIC_STORAGE_KEY,
  TECHNOLOGIES_STORAGE_KEY,
} from '../../../../utils/Utils';
import LocationSearch from './LocationSearch';

const stepItems = {
  General: [
    {
      components: [
        {
          component: (
            <InputDefault
              name="Company Name"
              keyType="global"
              keyFilter="name"
              placeholder="Enter company name..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputDefault
              name="Domain"
              keyType="domain"
              keyFilter="domain"
              placeholder="Enter domain..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <LocationSearch
              name="Location"
              keyType="location"
              keyFilter="location"
              placeholder="Enter a location..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputDefault
              type="number"
              name="Zip Code"
              keyType="location"
              keyFilter="geo"
              placeholder="Enter zip code..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: <InputRange keyType="location" keyFilter="radius" />,
        },
        {
          component: (
            <InputSearch
              name="Revenue"
              limit={7}
              list={revenueListNew}
              customKey="key"
              keyType="revenue"
              keyFilter="revenue"
              placeholder="Enter revenue search..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <TechnologiesSearch
              name="Technologies"
              keyType="technology"
              keyFilter="techstack"
              placeholder="Enter a technology..."
              showLabelColon={false}
              callKey={TECHNOLOGIES_STORAGE_KEY}
              callType="getTechnologies"
              className="mt-3"
            />
          ),
        },
      ],
      id: 1,
      icon: 'filter_alt',
      keyFilter: [
        'name',
        'domain',
        'location',
        'geo',
        'radius',
        'revenue',
        'techstack',
      ],
      titles: [
        'Company Name',
        'Domain',
        'Location',
        'Zip Code',
        'Revenue',
        'Technologies',
      ],
    },
  ],
  Industry: [
    {
      components: [
        {
          component: (
            <TechnologiesSearch
              name="Industry"
              keyType="industry"
              keyFilter="industry"
              placeholder="Enter a industry search..."
              showLabelColon={false}
              callKey={INDUSTRIES_STORAGE_KEY}
              callType="getIndustries"
            />
          ),
        },
        {
          component: (
            <TechnologiesSearch
              name="NAICS Code"
              keyType="industry"
              keyFilter="naics_code"
              placeholder="Enter a NAICS code search..."
              showLabelColon={false}
              callKey={NAICS_STORAGE_KEY}
              callType="getNaicsCodes"
            />
          ),
        },
        {
          component: (
            <TechnologiesSearch
              name="SIC Code"
              keyType="industry"
              keyFilter="sic_code"
              placeholder="Enter a SIC code search..."
              showLabelColon={false}
              callKey={SIC_STORAGE_KEY}
              callType="getSicCodes"
            />
          ),
        },
      ],
      id: 2,
      icon: 'apartment',
      keyFilter: ['industry', 'naics_code', 'sic_code'],
      titles: ['Industry', 'NAICS Code', 'SIC Code'],
    },
  ],
  Metrics: [
    {
      components: [
        {
          component: (
            <InputSearch
              name="Employee Count"
              limit={9}
              keyType="employer"
              keyFilter="employees"
              customKey="key"
              list={employeeCountListNew}
              placeholder="Enter employee count search..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputSearch
              name="Employee Growth"
              limit={9}
              keyType="growth"
              keyFilter="growth"
              customKey="key"
              list={companyGrowthList}
              placeholder="Enter employee growth search..."
              showLabelColon={false}
            />
          ),
        },
      ],
      id: 3,
      icon: 'finance',
      keyFilter: ['employees', 'growth'],
      titles: ['Employee Count', 'Employee Growth'],
    },
  ],
};

const FilterItemMenuCompany = ({
  title,
  data,
  setData,
  onEnter,
  active,
  setActive,
  selctedFilter,
}) => {
  const [activeTap] = useState(true);

  return (
    <div className="w-100">
      <Collapse in={activeTap}>
        <Fragment>
          {Array.isArray(stepItems[title]) ? (
            <CategoryMenuCompany
              stepItems={stepItems}
              title={title}
              data={data}
              setData={setData}
              onEnter={onEnter}
              active={active}
              setActive={setActive}
              selctedFilter={selctedFilter}
            />
          ) : (
            <></>
          )}
        </Fragment>
      </Collapse>
    </div>
  );
};

export default FilterItemMenuCompany;
