import React, { useEffect, useState } from 'react';
import { Card, FormCheck } from 'react-bootstrap';

import Table from '../GenericTable';
import {
  paginationDefault,
  SEARCH_LESSONS,
  SERVER_ERROR,
} from '../../utils/constants';
import { tableLessonColumns } from './ManageLessonsConstants';
import { DataFilters } from '../DataFilters';
import lessonService from '../../services/lesson.service';
import {
  formatText,
  setDateFormat,
  TrainingFilterOptions,
} from '../../utils/Utils';
import LayoutHead from '../commons/LayoutHead';
import { sortingTable } from '../../utils/sortingTable';
import TableSkeleton from '../commons/TableSkeleton';
import StatusLabel from '../commons/StatusLabel';
import DeleteConfirmationModal from '../modal/DeleteConfirmationModal';
import TableActions from '../commons/TableActions';
import ButtonFilterDropdown from '../commons/ButtonFilterDropdown';
import { useProfileContext } from '../../contexts/profileContext';
import AlertWrapper from '../Alert/AlertWrapper';
import Alert from '../Alert/Alert';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../views/Deals/contacts/utils';

export default function LessonsTable({ setId, setCreate, setModified }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { profileInfo } = useProfileContext();
  const roleInfo = profileInfo?.role;
  const isAdmin = roleInfo?.admin_access;
  const [allLessons, setAllLessons] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);
  const [filterSelected, setFilterSelected] = useState({});
  const [showLoading, setShowLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [dataInDB, setDataInDB] = useState(false);
  const [order, setOrder] = useState([['updated_at', 'DESC']]);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [itemsConfirmation, setItemsConfirmation] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [orgCheck, setOrgCheck] = useState(true);

  const [openModalSingleDelete, setOpenModalSingleDelete] = useState(false);
  const [selectedSingleLesson, setSelectedSingleLesson] = useState([]);

  const draft = [];
  const publised = [];
  for (let i = 0; i < allLessons?.length; i++) {
    if (allLessons[i]?.status === 'draft') {
      draft.push(allLessons[i]);
    }
    if (allLessons[i]?.status === 'published') {
      publised.push(allLessons[i]);
    }
  }

  const [filterOptionSelected, setFilterOptionSelected] = useState({
    id: 1,
    key: 'updated_at',
    name: 'Last Modified',
  });
  const restrictByCondition =
    !isAdmin && orgCheck ? 'private' : isAdmin ? 'externalPublic' : '';

  const getLessons = async (count) => {
    setLoading(true);
    try {
      const response = await lessonService.getLessons({
        page: paginationPage.page,
        limit: paginationPage.limit,
        order,
        ...(restrictByCondition && { restrictBy: restrictByCondition }),
        status: "ne 'deleted'",
        ...filterSelected,
      });

      const { data, pagination } = response || {};

      setPagination(pagination);
      setAllLessons(data);
      if (count) setDataInDB(Boolean(pagination?.totalPages));
      setShowLoading(false);
      setLoading(false);
    } catch (err) {
      console.log('err', err);
      setErrorMessage(SERVER_ERROR);
    }
  };

  useEffect(() => {
    if (profileInfo?.id) {
      getLessons(true);
    }
  }, [filterSelected, paginationPage, order, orgCheck, profileInfo]);

  useEffect(() => {
    if (selectedLessons.length > 0) {
      const selectedLessonsData = selectedLessons.map((id) => {
        return allLessons.find((lesson) => lesson.id === parseInt(id));
      });
      setItemsConfirmation(selectedLessonsData);
    }
  }, [selectedLessons]);

  const redirectTo = (row) => {
    if (isAdmin || (!isAdmin && !row.isPublic)) {
      setId(row.id);
      setCreate(true);
    }
  };

  const handleDelete = (row) => {
    setId(row.id);
    setSelectedSingleLesson([row]);
    setOpenModalSingleDelete(true);
  };

  const lockedClick = (row) => {
    // TODO locked action
  };

  const permissions = {
    collection: 'lessons',
    action: 'create',
  };

  const tableActions = [
    {
      id: 1,
      title: 'Edit',
      icon: 'edit',
      onClick: redirectTo,
      permission: {
        collection: 'lessons',
        action: 'edit',
      },
    },
    {
      id: 2,
      title: 'Delete',
      icon: 'delete',
      onClick: handleDelete,
      style: 'ml-3 text-danger',
      permission: {
        collection: 'lessons',
        action: 'delete',
      },
    },
  ];

  const tableActionsLock = [
    {
      id: 1,
      title: 'Locked',
      icon: 'lock',
      onClick: lockedClick,
      permission: {
        collection: 'lessons',
        action: 'delete',
      },
    },
  ];

  const data = allLessons?.map((lesson) => ({
    ...lesson,
    dataRow: [
      {
        key: 'title',
        component: (
          <span className="d-inline-block text-wrap fw-bold">
            {lesson.title}
          </span>
        ),
      },
      {
        key: 'category',
        component: (
          <span className="title-overflow">{lesson?.category?.title}</span>
        ),
      },
      {
        key: 'updated_at',
        component: <span>{setDateFormat(lesson.updated_at)}</span>,
      },
      {
        key: 'status',
        component: <StatusLabel status={lesson.status} />,
      },
      {
        key: '',
        component: (
          <TableActions
            item={lesson}
            actions={
              isAdmin
                ? tableActions
                : lesson.isPublic
                ? tableActionsLock
                : tableActions
            }
          />
        ),
      },
    ],
  }));

  const onCreateLesson = () => {
    setId(null);
    setCreate(true);
  };

  const loader = () => {
    if (showLoading) return <TableSkeleton cols={4} rows={12} />;
  };

  const sortTable = ({ name }) => {
    if (name === 'category') name = 'category_id';

    sortingTable({ name, order, setOrder: (val) => setOrder([val]) }, true);
  };
  const onHandleDeleteSingle = async () => {
    const lessonId = selectedSingleLesson[0].id;
    try {
      await lessonService.deleteLesson(lessonId);
      getLessons(true);
      setSuccessMessage('Lesson Deleted');
    } catch (error) {
      if (error.response.status === 409) {
        setErrorMessage(formatText(error.response.data.errors[0].message));
      }
    } finally {
      setSelectAll(false);
      setSelectedSingleLesson([]);
      setItemsConfirmation([]);
      setOpenModalSingleDelete(false);
      setModified((prevState) => prevState + 1);
    }
  };
  const onHandleDelete = async () => {
    try {
      await Promise.all(
        selectedLessons.map((lessonId) => lessonService.deleteLesson(lessonId))
      );
      getLessons(true);
    } catch (e) {
      console.log(e);
    } finally {
      setSelectAll(false);
      setSelectedLessons([]);
      setItemsConfirmation([]);
      setOpenModal(false);
      setModified((prevState) => prevState + 1);
    }
  };

  const clearSelection = () => {
    setSelectAll(false);
    setSelectedLessons([]);
  };

  const handleFilterSelect = (e, option) => {
    e.preventDefault();
    setFilterOptionSelected(option);
    setPaginationPage((prev) => ({ ...prev, page: 1 }));
    if (option.key === 'updated_at') {
      setFilterSelected({
        order: [['updated_at', 'DESC']],
      });
    } else {
      setFilterSelected({ status: option.key });
    }
  };

  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      <DeleteConfirmationModal
        showModal={openModal}
        clearSelection={clearSelection}
        setShowModal={setOpenModal}
        setSelectedCategories={setSelectedLessons}
        itemsConfirmation={itemsConfirmation}
        event={onHandleDelete}
        heading="Delete Lessons"
        description="Are you sure you want to delete all the selected lessons?"
      />

      <DeleteConfirmationModal
        showModal={openModalSingleDelete}
        clearSelection={clearSelection}
        setShowModal={setOpenModalSingleDelete}
        setSelectedCategories={setSelectedSingleLesson}
        itemsConfirmation={selectedSingleLesson}
        event={onHandleDeleteSingle}
        heading="Delete Lesson"
        description="Are you sure you want to delete this lesson?"
      />

      <Card className="mb-0 card-0 rounded-0">
        <Card.Header className="flex-column align-items-unset px-0">
          <div className="d-flex align-items-center px-3">
            <DataFilters
              filterSelected={filterSelected}
              setFilterSelected={setFilterSelected}
              searchPlaceholder={SEARCH_LESSONS}
              paginationPage={paginationPage}
              setPaginationPage={setPaginationPage}
            />
            <div className="d-flex align-items-center gap-2">
              <>
                {!isAdmin && (
                  <FormCheck
                    id={'orgCheck'}
                    name={'orgCheck'}
                    type="switch"
                    custom={true}
                    label="My Organization"
                    checked={orgCheck}
                    onChange={() => setOrgCheck(!orgCheck)}
                  />
                )}
              </>

              <ButtonFilterDropdown
                options={TrainingFilterOptions}
                filterOptionSelected={filterOptionSelected}
                handleFilterSelect={handleFilterSelect}
              />

              <LayoutHead
                onHandleCreate={onCreateLesson}
                buttonLabel={'Add Lesson'}
                selectedData={selectedLessons}
                onClear={clearSelection}
                onDelete={() => setOpenModal(true)}
                dataInDB={dataInDB}
                permission={permissions}
                alignTop="mb-0"
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <div className="table-responsive-md">
            <div
              id="datatable_wrapper"
              className="dataTables_wrapper no-footer"
            >
              {showLoading ? (
                loader()
              ) : (
                <Table
                  stickyFooter
                  actionPadding="p-0"
                  tableLoading={loading}
                  checkbox={isAdmin}
                  columns={tableLessonColumns}
                  data={data}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  selectedData={selectedLessons}
                  setSelectedData={setSelectedLessons}
                  usePagination={true}
                  showPerPage={true}
                  paginationInfo={pagination}
                  onPageChange={(newPage) => {
                    changePaginationPage(newPage, setPaginationPage);
                    setSelectedLessons([]);
                  }}
                  onLimitChange={(perPage) => {
                    changePaginationLimit(perPage, setPaginationPage);
                    setSelectedLessons([]);
                  }}
                  emptyDataText="No lesson available yet."
                  title="lesson"
                  dataInDB={dataInDB}
                  onClick={redirectTo}
                  toggle={onCreateLesson}
                  permission={permissions}
                  sortingTable={sortTable}
                  sortingOrder={order}
                  stickyClass="learning-table-container"
                />
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
