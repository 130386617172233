import React, { useCallback, useContext, useMemo, useState } from 'react';
import { AlertMessageContext } from '../../../../contexts/AlertMessageContext';
import { overflowing } from '../../../../utils/Utils';
import DeleteConfirmationModal from '../../../modal/DeleteConfirmationModal';

const DeleteModal = ({ show, setShow, modalConfig, data }) => {
  const { setSuccessMessage } = useContext(AlertMessageContext);

  const handleConfirmDeleteReport = async () => {
    try {
      await modalConfig.deleteCallback(data);
      overflowing();
      setSuccessMessage(modalConfig.successMsg);
      setShow(false);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <DeleteConfirmationModal
      showModal={show}
      setShowModal={setShow}
      event={handleConfirmDeleteReport}
      itemsConfirmation={[data]}
      heading={modalConfig.heading}
      description={modalConfig.description}
    />
  );
};

const useDeleteModal = (modalConfig) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState('');
  const DeleteModalCallback = useCallback(() => {
    return (
      <DeleteModal
        show={showModal}
        setShow={setShowModal}
        data={data}
        modalConfig={modalConfig}
      />
    );
  }, [showModal, setShowModal, data, setData]);

  return useMemo(
    () => ({
      setShowModal,
      setData,
      DeleteModal: DeleteModalCallback,
    }),
    [setShowModal, DeleteModalCallback]
  );
};

export default useDeleteModal;
