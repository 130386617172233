import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FieldSkeletonLoader from '../../fields/FieldSkeletonLoader';
import UserService from '../../../services/user.service';
import NoDataFoundTitle from '../../fields/NoDataFoundTitle';
import NoDataFound from '../../commons/NoDataFound';
import Avatar from '../../Avatar';
import LoadMoreButton from '../../lesson/LoadMoreButton';
import { FormCheck } from 'react-bootstrap';
import MoreActions from '../../MoreActions';
import groupService from '../../../services/groups.service';
import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import RightSlidingDrawer from '../../modal/RightSlidingDrawer';
import ButtonIcon from '../../commons/ButtonIcon';
import MaterialIcon from '../../commons/MaterialIcon';
import TooltipComponent from '../../lesson/Tooltip';

const TitleWithActions = ({ setShow, modalConfig, data, pagination }) => {
  return (
    <div className="d-flex align-items-center w-100 justify-content-between">
      <div className="d-flex align-items-center gap-2">
        <h3 className="mb-0">Users In {data?.name} Role</h3>
        <TooltipComponent
          title={
            data?.description
              ? data.description
              : 'Users with this role have access to the data owned by all other users.'
          }
        >
          <MaterialIcon icon="info" />
        </TooltipComponent>
      </div>
      <div className="d-flex align-items-center gap-2">
        <a className={`icon-hover-bg text-icon cursor-pointer`}>
          <MoreActions
            icon="more_vert"
            items={[
              {
                id: 'edit',
                name: 'Edit',
                className: 'bg-hover-primary',
                icon: 'edit',
              },
              {
                id: 'remove',
                name: 'Delete',
                className: 'bg-hover-danger',
                icon: 'delete',
              },
            ]}
            onHandleEdit={() => {
              setShow(false);
              modalConfig.edit(data);
            }}
            onHandleRemove={() => {
              setShow(false);
              modalConfig.delete(data);
            }}
          />
        </a>
        <a
          className="icon-hover-bg cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            setShow(false);
          }}
        >
          <MaterialIcon icon="close" size="fs-5" />
        </a>
      </div>
    </div>
  );
};
const RoleUsersModal = ({ show, setShow, data, modalConfig }) => {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [role, setRole] = useState({ ...data });
  const loadUsersByRoleGroup = async () => {
    try {
      setLoader(!loadMore);
      const response = await UserService.getUsers(
        { groupId: data.id },
        { ...pagination, page }
      );
      const previousList = !loadMore ? [] : [...users];
      const newList = [...previousList, ...response.data.users];
      setUsers(newList);
      setPagination(response.data.pagination);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
      setLoadMore(false);
    }
  };

  const onLoadMore = () => {
    setLoadMore(true);
    setPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (show && data?.id) {
      loadUsersByRoleGroup();
    }
  }, [show, data]);

  useEffect(() => {
    if (show && data?.id && page > 1) {
      setLoadMore(true);
      loadUsersByRoleGroup();
    }
  }, [page]);

  const handlePeerDataVisibility = async () => {
    try {
      setRole({ ...role, has_sibling_access: !role.has_sibling_access });
      await groupService.updateGroup({
        id: data.id,
        name: data.name,
        description: data.description,
        parent_id: data.parent_id,
        has_sibling_access: !data.has_sibling_access,
      });
      setSuccessMessage('Role Updated.');
      modalConfig.refresh();
    } catch (e) {
      console.log(e);
      setErrorMessage('Error updating role.');
    }
  };
  const containerRef = useRef();
  return (
    <>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <RightSlidingDrawer
        open={show}
        containerWidth={670}
        modalBodyClass="p-0"
        withCard={true}
        header={
          <TitleWithActions
            setShow={setShow}
            data={data}
            modalConfig={modalConfig}
            pagination={pagination}
          />
        }
        toggleDrawer={() => {
          setShow(false);
        }}
        body={
          <>
            {loader ? (
              <div className="my-1 px-4">
                <FieldSkeletonLoader rows={5} />
              </div>
            ) : (
              <>
                {users?.length > 0 ? (
                  <>
                    <div className="d-flex align-items-center py-3 px-4 gap-3">
                      <div className="d-flex align-items-center gap-1">
                        <span className="font-size-sm2">
                          Peer Data Visibility:
                        </span>
                        <FormCheck
                          id={'has_sibling_access_modal'}
                          name={'has_sibling_access_modal'}
                          type="switch"
                          custom={true}
                          label=""
                          value={role?.has_sibling_access}
                          checked={role?.has_sibling_access}
                          onChange={handlePeerDataVisibility}
                        />
                      </div>
                    </div>
                    <h4 className="border-bottom mb-0 px-4 pb-1">Users</h4>
                    <div
                      ref={containerRef}
                      className="d-flex flex-column overflow-y-auto gap-1"
                      style={{ maxHeight: 'calc(100% - 150px' }}
                    >
                      {users.map((acnt) => (
                        <div
                          key={acnt.id}
                          className="d-flex border-bottom-light px-4 py-2 align-items-center gap-2"
                        >
                          <Avatar
                            user={{
                              ...acnt,
                              firstName: acnt?.name?.split(' ')[0],
                              lastName: acnt?.name?.includes(' ')
                                ? acnt?.name?.split(' ')[1]
                                : '',
                            }}
                          />
                          <div>
                            <h5 className="mb-0 font-weight-medium">
                              {acnt.name}
                            </h5>
                            <p className="mb-0 fs-7">{acnt.email}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <LoadMoreButton
                      list={users}
                      pagination={pagination}
                      btnStyle="btn-sm"
                      loading={loadMore}
                      onClick={onLoadMore}
                      containerRef={containerRef}
                    />
                  </>
                ) : (
                  <NoDataFound
                    icon="people"
                    containerStyle="text-gray-search my-6 py-6"
                    title={<NoDataFoundTitle str="No users found" />}
                  />
                )}
              </>
            )}
          </>
        }
        footer={
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="text-black-50 font-weight-medium mb-0">
              {`${pagination?.count || 0 || 0} Total Users`}
            </h5>
            <ButtonIcon
              color="white"
              onclick={() => setShow(false)}
              classnames="font-weight-normal btn-sm"
              label="Cancel"
            />
          </div>
        }
      />
    </>
  );
};

const useRoleUsersModal = (modalConfig) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const RoleUsersModalCallback = useCallback(() => {
    return (
      <RoleUsersModal
        show={showModal}
        setShow={setShowModal}
        data={data}
        modalConfig={modalConfig}
      />
    );
  }, [showModal, setShowModal, data, setData]);

  return useMemo(
    () => ({
      setShowModal,
      setData,
      RoleUsersModal: showModal ? RoleUsersModalCallback : () => {},
    }),
    [setShowModal, RoleUsersModalCallback]
  );
};

export default useRoleUsersModal;
