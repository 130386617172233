import React from 'react';
import { AFPSource, OldAFPSource } from './constants/widgetsConstants';

const WidgetSourceBlock = ({ text }) => {
  const newText = text === OldAFPSource ? AFPSource : text;
  return (
    <p className="fs-10 text-muted source-block mb-0 text-left">
      Source:&nbsp;
      <span dangerouslySetInnerHTML={{ __html: newText }}></span>
    </p>
  );
};

export default WidgetSourceBlock;
