import React from 'react';
import { Card } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import MaterialIcon from '../../../../components/commons/MaterialIcon';
import { statsStyles, statusText } from '../../../../utils/checklist.constants';

const StatusCard = ({
  part,
  icon,
  index,
  count,
  isLast,
  status,
  isFirst,
  customTitle,
  customFooter,
  customDescription,
}) => (
  <div
    className={`col-md-3 
    pl-2 pr-2 ${isFirst ? 'pl-3' : isLast ? 'pr-3' : ''}
  `}
    key={index}
  >
    <Card
      className="position-relative shadow-none"
      style={{ borderRadius: '0.75rem !important' }}
    >
      <Card.Body style={{ borderRadius: '0.75rem' }}>
        <div
          className={`h-100 ${statsStyles?.[status]?.color || ''}`}
          style={{
            position: 'absolute',
            left: '0px',
            width: '5px',
            top: 0,
            borderTopLeftRadius: '0.75rem',
            borderBottomLeftRadius: '0.75rem',
          }}
        ></div>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="text-gray-800" style={{ flexGrow: 1 }}>
            {customTitle || statusText[status]}
          </h5>
          {icon}
        </div>
        <h2 className="font-size-2em">
          {customDescription || `${count} (${part}%)`}
        </h2>
        <span className="text-muted font-weight-normal font-size-sm2">
          {customFooter || 'Current'}
        </span>
      </Card.Body>
    </Card>
  </div>
);

const Stats = ({
  completed,
  inProgress,
  pending,
  overdue,
  totalCount,
  className,
  isLoading,
}) => {
  const stats = { completed, inProgress, pending, overdue };
  const last = Object.entries(stats).length - 1;

  if (isLoading) {
    return (
      <div className={`row mx-0 mb-3 ${className}`}>
        {[0, 1, 2, 3].map((index) => (
          <StatusCard
            key={index}
            icon={<></>}
            index={index}
            isFirst={index === 0}
            isLast={index === last}
            customTitle={<Skeleton />}
            customFooter={<Skeleton />}
            customDescription={<Skeleton />}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={`row mx-0 mb-3 ${className}`}>
      {Object.entries(stats).map(([status, count], index) => {
        let part = totalCount > 0 ? (count * 100) / totalCount : 0;
        part = part > 0 && part < 1 ? part.toFixed(2) : Math.round(part);

        if (!status) return <></>;

        return (
          <StatusCard
            key={index}
            index={index}
            isFirst={index === 0}
            isLast={index === last}
            count={count}
            part={part}
            status={status}
            icon={
              <MaterialIcon
                icon={statsStyles?.[status]?.icon}
                clazz={`${
                  statsStyles?.[status]?.iconColor || ''
                } font-size-2em`}
              />
            }
          />
        );
      })}
    </div>
  );
};
export default Stats;
