import React, { createContext, useContext, useEffect, useState } from 'react';
import qs from 'qs';
import authService from '../services/auth.service';
import organizationService from '../services/organization.service';
import {
  getIdfPublicToken,
  getClientPortalOrganization,
  getClientPortalToken,
} from '../layouts/constants';
import contactService from '../services/contact.service';

const ClientPortalContext = createContext({
  organization: null,
  setOrganization: () => null,
  organizationId: null,
  setOrganizationId: () => null,
  owner: null,
  setOwner: () => null,
  contactId: null,
  setContactId: () => null,
  contact: {},
  setContact: () => {},
  selectedMenu: {},
  setSelectedMenu: () => {},
});

export const ClientPortalProvider = ({ children }) => {
  const [organization, setOrganization] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [contact, setContact] = useState({});
  const [contactId, setContactId] = useState(null);
  const [owner, setOwner] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState({});

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const hasUrlToken = urlParams.get('token');
      let contact_id = null;
      let shared_by = null;
      let resource_access = null;

      const tokenPayloadObj = getClientPortalToken();
      const organizationObj = getClientPortalOrganization();
      const idfPublicTokenObj = getIdfPublicToken();

      sessionStorage.setItem(
        'idftoken-public',
        JSON.stringify({ access_token: hasUrlToken })
      );
      if (tokenPayloadObj) {
        resource_access = tokenPayloadObj.resource_access?.organization[0]?.id;
        shared_by = tokenPayloadObj.shared_by;
        contact_id = tokenPayloadObj.contact_id;
      } else if (hasUrlToken) {
        const query = qs.parse(location.search, { ignoreQueryPrefix: true });
        const storageToken = idfPublicTokenObj?.access_token || query.token;
        try {
          const tokenPayload = await authService.introspect(storageToken);
          contact_id = tokenPayload.contact_id;
          shared_by = tokenPayload.shared_by;
          tokenPayload.exp = tokenPayload.exp * 1000;
          resource_access = tokenPayload.resource_access.organization[0].id;
          sessionStorage.setItem('tokenPayload', JSON.stringify(tokenPayload));
        } catch (error) {
          console.error('Error introspecting token:', error);
        }
      }

      // Set states
      setContactId(contact_id);
      setOwner(shared_by);
      setOrganizationId(resource_access);

      // Load organization data if not already in sessionStorage
      if (resource_access && !organizationObj) {
        getOrganization(resource_access);
      } else if (organizationObj) {
        setOrganization(organizationObj);
      }
    })();
  }, []);

  const getOrganization = async (organizationId) => {
    try {
      const organizationObj = await organizationService.getOrganizationById(
        organizationId
      );
      setOrganization(organizationObj);
      sessionStorage.setItem(
        'organizationObj',
        JSON.stringify(organizationObj)
      );
    } catch (error) {
      console.error('Error fetching organization:', error);
    }
  };

  const getContactInfo = async () => {
    const data = await contactService.getContactById(contactId);
    setContact(data);
  };

  useEffect(() => {
    if (contactId) {
      getContactInfo();
    }
  }, [contactId]);

  return (
    <ClientPortalContext.Provider
      value={{
        organization,
        contactId,
        organizationId,
        owner,
        selectedMenu,
        setSelectedMenu,
        contact,
        setContact,
      }}
    >
      {children}
    </ClientPortalContext.Provider>
  );
};

export const useClientPortalContext = () => {
  return useContext(ClientPortalContext);
};
