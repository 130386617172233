import Skeleton from 'react-loading-skeleton';
import { numbersWithComma } from '../utils/Utils';

const TableFooterStats = ({ stats, loading = false }) => {
  if (!stats) {
    return null;
  }
  return (
    <>
      {loading ? (
        <div className="d-flex table-stats-loading">
          <Skeleton width={120} height={10} />
        </div>
      ) : (
        <div className="stats">
          <ul>
            {stats
              ?.sort((a, b) => a.order - b.order)
              ?.map((item) => (
                <li
                  key={item.id}
                  className="d-flex fs-7 align-items-center justify-content-center gap-1"
                >
                  <label className="mb-0 fs-7 font-weight-normal cursor-pointer">
                    {item.label}
                  </label>
                  <span className="text-gray-400">•</span>
                  <label className="mb-0 cursor-pointer text-black">
                    <span>{numbersWithComma(item.count)}</span>
                  </label>
                </li>
              ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default TableFooterStats;
