import React, { useState } from 'react';

import ProspectLookup from './ProspectLookup';
import { isModuleAllowed, isPermissionAllowed } from '../../../utils/Utils';
import WriteAI from './WriteAI';
import PreCallPlanPanel from './PreCallPlanPanel';
import RightBarLoader from '../../loaders/RightBarLoader';
import { useTenantContext } from '../../../contexts/TenantContext';
import DemoBankInsights from '../../../views/Deals/Organization/DemoBankInsights';
import useIsTenant from '../../../hooks/useIsTenant';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import TopicIcon from '../../commons/TopicIcon';

const RightBar = ({ profileInfo, isPeople, isLoading, moduleName }) => {
  const rightBarOptions = [
    {
      id: 'precallPlan',
      component: <PreCallPlanPanel />,
      module: moduleName,
    },
    {
      id: 'prospectLookup',
      component: <ProspectLookup />,
      module: 'prospecting_prospect_lookup_sidebar',
      permission: {
        collection: 'prospects',
        action: 'view',
      },
    },
    {
      id: 'write',
      component: <WriteAI />,
      module: 'ai_assist_write',
    },
  ];

  const { tenant } = useTenantContext();
  const { isDemo } = useIsTenant();
  const [demoBankInsights, setDemoBankInsights] = useState(false);

  return (
    <div
      className="right-sidebar insights-bar border-left"
      style={{ zIndex: demoBankInsights ? 999 : 95 }}
    >
      <div className="splitted-content-mini splitted-content-mini-right splitted-content-bordered pb-3 pt-4 sticky-top z-index-2 w-auto">
        <ul className="nav nav-compact-icon list-unstyled-py-3 nav-compact-icon-circle justify-content-center">
          {!isLoading ? (
            <li className="d-flex flex-column gap-2 pt-2">
              {!isPeople && isDemo && (
                <DemoBankInsights
                  showModal={demoBankInsights}
                  setShowModal={setDemoBankInsights}
                  detailPage={true}
                >
                  <OverlayTrigger
                    key={'insights'}
                    placement="bottom"
                    overlay={
                      <Tooltip
                        id={`tooltip-insights}`}
                        className={`tooltip-profile font-weight-bold`}
                      >
                        <p>Insights</p>
                      </Tooltip>
                    }
                  >
                    <div
                      className="nav-item scroll-item mb-2"
                      onClick={() => setDemoBankInsights(!demoBankInsights)}
                    >
                      <div className={`nav-icon insights cursor-pointer`}>
                        <TopicIcon
                          icon="search_insights"
                          iconBg="insights"
                          filled={true}
                          iconClasses="fs-5"
                        />
                      </div>
                    </div>
                  </OverlayTrigger>
                </DemoBankInsights>
              )}

              {rightBarOptions.map((option) => {
                const { id, component } = option;
                if (isPeople && id === 'industryinsights') {
                  return [];
                }

                return (
                  <>
                    {option.permission
                      ? isPermissionAllowed(
                          option.permission.collection,
                          option.permission.action
                        ) &&
                        isModuleAllowed(tenant?.modules, option.module) && (
                          <div key={id}>
                            {React.cloneElement(component, {
                              profileInfo,
                            })}
                          </div>
                        )
                      : isModuleAllowed(tenant?.modules, option.module) && (
                          <div key={id}>
                            {React.cloneElement(component, {
                              profileInfo,
                            })}
                          </div>
                        )}
                  </>
                );
              })}
            </li>
          ) : (
            <li>
              <RightBarLoader count={3} />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default RightBar;
