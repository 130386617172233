import { useState, Fragment, useEffect } from 'react';

import FileIcon from '../../fileIcon/FileIcon';
import { setDateFormat, getFileSize } from '../../../utils/Utils';
import { removeFile } from '../../../utils/removeFile';
import DeleteFile from '../../../components/peopleProfile/contentFeed/DeleteFile';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import Alert from '../../../components/Alert/Alert';
import MoreActions from '../../MoreActions';
import { items } from '../../../views/Deals/pipelines/Pipeline.constants';
import assetsService from '../../../services/assets.service';
import filesService from '../../../services/files.service';
import { FILE_DOESNT_EXIST } from '../../../utils/constants';
import RenameModal from '../../modal/RenameModal';
import IdfTooltip from '../../idfComponents/idfTooltip';
import MaterialIcon from '../../commons/MaterialIcon';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import Avatar from '../../Avatar';
import TooltipComponent from '../../lesson/Tooltip';
import DeleteConfirmationModal from '../../modal/DeleteConfirmationModal';

const ActivityFile = ({ file, getFiles, publicPage, isOwner, contactId }) => {
  const FullName = file?.file.filename_download?.split('.') || [];
  const extension = FullName ? FullName[FullName.length - 1] : '';
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [removeFeedFile, setRemoveFeedFile] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [name, setName] = useState(
    FullName?.slice(0, FullName?.length - 1).join('.')
  );
  const [loading, setLoading] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  const [fileUploadedDate, setFileUploadedDate] = useState({});
  const [hoursDifference, setHoursDifference] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const isFourteenDaysOld = (fileDate) => {
    const currentDate = new Date();
    const timeDifferenceInMilliseconds = currentDate - fileDate;
    const days = Math.floor(
      timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24)
    );
    return days < 15;
  };
  useEffect(() => {
    const fileUploadedOn = new Date(file.file.uploaded_on);
    setFileUploadedDate(fileUploadedOn);
    setHoursDifference(isFourteenDaysOld(fileUploadedOn));
  }, []);

  useEffect(() => {
    setHoursDifference(isFourteenDaysOld(fileUploadedDate));
  }, [fileUploadedDate]);

  const toggleModal = () => {
    setRemoveFeedFile((prev) => !prev);
  };

  const toggleRename = () => {
    setRenameModal((prev) => !prev);
  };

  const onDownload = () => {
    assetsService
      .downloadFile(file.file.id, file?.file?.filename_download)
      .catch((_) => {
        setErrorMessage(FILE_DOESNT_EXIST);
      });
  };

  const onOpen = async () => {
    if (!openLoading) {
      setOpenLoading(true);
      await assetsService.openFile(file.file.id).catch((_) => {
        setErrorMessage(FILE_DOESNT_EXIST);
      });
      setOpenLoading(false);
    }
  };

  const rename = async () => {
    setLoading(true);
    const id = await filesService.renameFile(
      file.file.id,
      name + '.' + extension
    );
    id
      ? setSuccessMessage('File is renamed Successfully')
      : setErrorMessage('File is not renames successfully');
    setLoading(false);
    setRenameModal(false);
  };

  const onDelete = async () => {
    try {
      await filesService.removeFile(file.file.id);
      getFiles();
      setSuccessMessage('File Successfully Deleted');
    } catch (err) {
      console.error(err);
    } finally {
      setOpenModal(false);
    }
  };

  const permission = {
    collection: 'activities',
    action: 'edit',
  };

  const fileCreatedBy = file?.createdByContact || file?.file?.uploadedBy;

  return (
    <>
      <DeleteConfirmationModal
        showModal={openModal}
        setShowModal={setOpenModal}
        event={onDelete}
        heading="Delete File"
        description="Are you sure you want to delete this file?"
      />

      <td className="align-middle pl-0">
        <h5
          className="d-flex align-items-center mb-1 text-block"
          onClick={hoursDifference && onOpen}
        >
          <FileIcon info={file.file} size="sm" colAuto="pl-1" />
          <div className="font-size-sm2 font-weight-semi-bold">
            {file?.file?.filename_download}
            {openLoading && (
              <Spinner color="primary" size="sm" className="ml-1" />
            )}
            <p className="mb-0 fs-8 font-weight-normal text-muted">
              {getFileSize(file.file.filesize)}
            </p>
          </div>
        </h5>
      </td>
      <td className="align-middle font-size-sm2">
        {moment(setDateFormat(file.file.uploaded_on)).format('MMMM DD, YYYY')}
      </td>
      <td className="align-middle">
        <TooltipComponent title={fileCreatedBy?.name}>
          <span>
            <Avatar defaultSize="sm" user={fileCreatedBy} />
          </span>
        </TooltipComponent>
      </td>
      <td className="align-middle">
        <div className="action-btns d-flex gap-2 align-items-center justify-content-center">
          {!publicPage && isOwner && (
            <>
              <DeleteFile
                confirmOpen={removeFeedFile}
                setConfirmOpen={setRemoveFeedFile}
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                id={file.file_id}
                getFiles={getFiles}
                removeFile={removeFile}
              >
                <MoreActions
                  items={items}
                  permission={permission}
                  toggleClassName="dropdown-search btn-icon border-0 shadow-none no-shadow"
                  variant="outline-link"
                  onHandleRemove={toggleModal}
                  onHandleDownload={onDownload}
                  onHandleEdit={toggleRename}
                  onHandleOpen={onOpen}
                />
              </DeleteFile>
              <RenameModal
                open={renameModal}
                onHandleConfirm={rename}
                onHandleClose={() => {
                  setRenameModal(false);
                }}
                name={name}
                setName={setName}
                loading={loading}
                extension={extension}
              />
            </>
          )}
          {publicPage && hoursDifference ? (
            <Fragment>
              {contactId === file.contact_id ? (
                <span
                  className="btn btn-light font-size-sm2"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenModal(true);
                  }}
                >
                  Delete
                </span>
              ) : (
                <span className="btn btn-light font-size-sm2 hide pointer-event">
                  Delete
                </span>
              )}
              <span
                className="rounded bg-primary-soft-forced border cursor-pointer position-relative icon-hover-bg"
                style={{ width: 35, height: 35 }}
                onClick={(e) => {
                  e.preventDefault();
                  onDownload();
                }}
              >
                <IdfTooltip text="Download">
                  <MaterialIcon
                    icon="download"
                    rounded
                    style={{ top: 2 }}
                    clazz="text-primary position-relative font-size-xl"
                  />
                </IdfTooltip>
              </span>
            </Fragment>
          ) : null}
        </div>
      </td>
      <AlertWrapper>
        <Alert message={successMessage} setMessage={setSuccessMessage} />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
    </>
  );
};

export default ActivityFile;
