import React, { useEffect, useReducer, useState } from 'react';
import { Card } from 'react-bootstrap';

import Filters from '../components/Filters';
import Search from '../components/manageUsers/Search';
import {
  reducer,
  removeCustomFieldsFromActivityForm,
} from '../views/Deals/contacts/utils';
import ProductTable from '../components/Products/ProductTable';
import productService from '../services/product.service';
import ValidateAdminAccess from '../components/validateAdminAccess/ValidateAdminAccess';
import tenantService from '../services/tenant.service';
import ProductCreateModal from '../components/Products/ProductCreateModal';
import Alert from '../components/Alert/Alert';
import AlertWrapper from '../components/Alert/AlertWrapper';
import stringConstants from '../utils/stringConstants.json';
import LayoutHead from '../components/commons/LayoutHead';
import fieldService from '../services/field.service';
import { groupBy } from 'lodash';
import TableSkeleton from '../components/commons/TableSkeleton';
import DeleteConfirmationModal from '../components/modal/DeleteConfirmationModal';
import { useModuleContext } from '../contexts/moduleContext';

const constants = stringConstants.settings.products;

const initialFiltersItems = [
  {
    id: 1,
    label: 'Tenants',
    name: 'tenant',
    options: [],
    type: 'search',
    key: true,
  },
];

const productInitial = {
  name: '',
  code: '',
  price: '',
  description: '',
  category: '',
  unit: '0',
  tax: '0',
};

const initialConf = { items: [], done: false };

const Products = () => {
  const { moduleMap } = useModuleContext();
  const [filters, dispatch] = useReducer(reducer, {});
  const [filtersItems, setFiltersItems] = useState(initialFiltersItems);
  const [pagination, setPagination] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(productInitial);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productsToRemove, setProductsToRemove] = useState(initialConf);
  const [loading, setLoading] = useState(false);
  const [dataInDB, setDataInDB] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [isFieldsData, setIsFieldsData] = useState();
  const [productType, setProductType] = useState('');
  const [customFields, setCustomFields] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const currentView = 'product';
  const groupBySection = (fieldsList) => {
    const data = groupBy(fieldsList, 'section');
    setIsFieldsData(data);
  };
  const getFields = async () => {
    setLoading(true);
    const { data } = await fieldService.getFields(currentView, {
      preferred: true,
    });
    if (data.length > 0) {
      groupBySection(data);
    } else {
      const { data } = await fieldService.createDefaultFields(currentView);
      groupBySection(data);
    }
    setLoading(false);
  };
  const EditFields = async (item) => {
    setLoading(true);
    const { data } = await fieldService.getFields(currentView, {
      usedField: true,
    });
    const {
      data: { data: customFields },
    } = await productService.getCustomField(item?.id, {
      page: 1,
      limit: 100,
    });
    let customValues = {};
    data.forEach((field) => {
      if (field.isCustom) {
        customFields.forEach((item) => {
          if (field.key === item.field.key && field.field_type !== 'DATE') {
            customValues = {
              ...customValues,
              [field.key?.toLowerCase().replace(/\s+/g, '')]:
                field.field_type === 'CURRENCY'
                  ? item.value.substring(1)
                  : item.value,
            };
          } else if (
            field.key === item.field.key &&
            field.field_type === 'DATE'
          ) {
            customValues = {
              ...customValues,
              [field.key?.toLowerCase().replace(/\s+/g, '')]: new Date(
                item.value
              ),
            };
          }
        });
      }
    });
    customValues = { ...item, ...customValues };
    setCurrentProduct(customValues);
    if (data.length > 0) {
      groupBySection(data);
    } else {
      const { data } = await fieldService.createDefaultFields(currentView);
      groupBySection(data);
    }
    setLoading(false);
  };
  const getProducts = async (count, search = '', page = 1, limit = 10) => {
    try {
      setLoading(true);
      const params = { tenant_id: filters?.tenant?.id };

      if (search) {
        params.search = search;
      }
      const { data } = await productService.getProducts(params, {
        page,
        limit,
      });

      setPagination(data.pagination);
      setProducts(data.products);

      setDataInDB(count ? Boolean(data?.pagination?.totalPages) : false);
    } catch (e) {
      return setErrorMessage(`An error has occurred`);
    } finally {
      setLoading(false);
    }
  };

  const onChangeSearch = (e) => {
    const { value } = e.target;
    getProducts(true, value);
  };

  const onHandleFilter = () => {
    getProducts(true);
  };

  useEffect(() => {
    setFiltersItems(initialFiltersItems);
    getProducts(true);
  }, []);

  const onSubmit = async () => {
    setButtonSpinner(true);
    const { price } = currentProduct;
    const newProduct = {
      ...currentProduct,
      price: Number(price),
    };
    const updateFields = removeCustomFieldsFromActivityForm(
      newProduct,
      customFields
    );
    try {
      const data = await productService.saveProduct(updateFields);
      await Promise.all(
        customFields?.map(async (item) => {
          await new Promise((resolve) => {
            productService
              .updateCustomField(data?.data?.id, item)
              .then(resolve);
          });
        })
      );
      setCurrentProduct({
        name: '',
        code: '',
        price: '',
        description: '',
        category: '',
        unit: '0',
        tax: '0',
      });
      setSuccessMessage(constants.createMessage);
      setShowModal(false);
      await getProducts(true);
    } catch (e) {
      setErrorMessage(constants.AlreadyExistMessage);
    }
    setButtonSpinner(false);
  };

  const onUpdate = async () => {
    setIsLoading(true);
    setButtonSpinner(true);

    const { name, description, code, id, price } = currentProduct;
    const newProduct = {
      ...currentProduct,
      name,
      description,
      code,
      price: Number(price),
    };
    const updateFields = removeCustomFieldsFromActivityForm(
      newProduct,
      customFields
    );
    delete updateFields?.fields;
    try {
      const { data } = await productService.updateProduct(id, updateFields);
      await Promise.all(
        customFields?.map(async (item) => {
          if (item.value === '$' || item.value === '') {
            await productService.deleteCustomField(data.id, item.field_id);
          } else {
            await new Promise((resolve) => {
              productService.updateCustomField(data.id, item).then(resolve);
            });
          }
        })
      );
      setSuccessMessage('Product Saved');
      setShowModal(false);
      await getProducts(true);
    } catch (e) {
      setErrorMessage(constants.notUpdateMessage);
    }
    setIsLoading(false);
    setButtonSpinner(false);
    setCurrentProduct({
      name: '',
      code: '',
      price: '',
      description: '',
      category: '',
      unit: 0,
      tax: 0,
    });
  };

  const onHandleEdit = (item) => {
    setProductType('edit');
    setCurrentProduct(item);
    EditFields(item);
    setShowModal(true);
  };

  const onHandleClone = (item) => {
    setProductType('clone');
    const propertiesToRemove = [
      'id',
      'modified_user_id',
      'tenant_id',
      'assigned_user_id',
      'created_by',
      'external_id',
    ];

    if (Object.keys(item)?.length > 0) {
      const cleanedProductData = Object.keys(item)
        .filter((key) => !propertiesToRemove.includes(key))
        .reduce((acc, key) => {
          acc[key] = item[key];
          return acc;
        }, {});
      setCurrentProduct({
        ...cleanedProductData,
        name: `Clone ${cleanedProductData?.name}`,
        title: `Clone ${cleanedProductData?.name}`,
      });
    }

    getFields();
    setShowModal(true);
  };
  const onHandleChangePage = (page) => {
    getProducts(true, '', page);
  };

  const onHandleDelete = async () => {
    setLoading(true);
    const msg = `${moduleMap.product.singular} Deleted.`;
    try {
      const data = selectedProducts?.map(async (product) => {
        return await productService.deleteProduct(product);
      });

      const updateData = await Promise.all(data);
      if (updateData?.some((item) => item?.status === 200)) {
        getProducts(true);
      }
      setSuccessMessage(msg);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setShowModalDelete(false);
      setSelectAll(false);
      setSelectedProducts([]);
      setProductsToRemove(initialConf);
    }

    // setShowModalDelete(false);
  };

  const toogleModalDelete = () => {
    const productClone = selectedProducts.map((id) => ({
      id,
      name: products.find((product) => product.id === id)?.name || '',
      isDeleted: false,
    }));

    setProductsToRemove((prev) => ({ ...prev, items: productClone }));
    setShowModalDelete(true);
  };

  const onCreate = () => {
    setCurrentProduct(productInitial);
    setProductType('Add');
    getFields();
    setShowModal(true);
  };
  const permissions = {
    collection: 'products',
    action: 'create',
  };
  const onClose = () => {
    setCurrentProduct({
      name: '',
      code: '',
      price: '',
      description: '',
      category: '',
      unit: 0,
      tax: 0,
    });
    setShowModal(false);
    setIsLoading(false);
    setSelectAll(false);
  };
  const handleCloseModal = () => {
    if (productsToRemove.done) {
      setSelectedProducts([]);
    }
    setSelectAll(false);
    setSelectedProducts([]);
    setProductsToRemove(initialConf);
  };

  const loader = () => {
    if (loading) return <TableSkeleton cols={5} rows={10} />;
  };

  const isMultiple = selectedProducts?.length > 1;
  const heading = `Delete ${
    moduleMap.product[isMultiple ? 'plural' : 'singular']
  }`;
  const description = `Are you sure you want to delete ${
    isMultiple ? 'all these' : 'this'
  } ${moduleMap.product[isMultiple ? 'plural' : 'singular']}?`;

  return (
    <>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>

      <ProductCreateModal
        fields={isFieldsData}
        show={showModal}
        onHandleClose={onClose}
        product={currentProduct}
        setProduct={setCurrentProduct}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        customDataFields={customFields}
        setCustomDataFields={setCustomFields}
        onHandleSubmit={onSubmit}
        buttonSpinner={buttonSpinner}
        productType={productType}
        onHandleUpdate={onUpdate}
        loading={loading}
        setLoading={setLoading}
      />

      <DeleteConfirmationModal
        showModal={showModalDelete}
        setShowModal={setShowModalDelete}
        handleCloseModal={handleCloseModal}
        event={onHandleDelete}
        heading={heading}
        description={description}
      />

      <Card className="shadow-none rounded-0 border-0 card-borderinline">
        <Card.Header className="border-0 pb-2 justify-content-end">
          <LayoutHead
            onHandleCreate={onCreate}
            buttonLabel={'Add Product'}
            selectedData={selectedProducts}
            onDelete={toogleModalDelete}
            dataInDB={dataInDB}
            permission={permissions}
          >
            <Search
              classnames="p-0"
              searchPlaceholder={'Search'}
              onHandleChange={onChangeSearch}
            />
            <ValidateAdminAccess onlyAdmin>
              <div className="d-none">
                <Filters
                  onHandleFilterContact={onHandleFilter}
                  dispatch={dispatch}
                  filtersItems={filtersItems}
                  customTitle={'name'}
                  callbackService={tenantService}
                  callbackRequest={'getTenants'}
                  callbackResponseData={''}
                  searchPlaceholder={'Tenants'}
                  customKey
                />
              </div>
            </ValidateAdminAccess>
          </LayoutHead>
        </Card.Header>
        <Card.Body className="p-0">
          {loading ? (
            loader()
          ) : (
            <ProductTable
              data={products}
              permission={permissions}
              paginationInfo={pagination}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              handleEdit={onHandleEdit}
              onHandleClone={onHandleClone}
              onPageChange={onHandleChangePage}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              dataInDB={dataInDB}
              onCreate={onCreate}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Products;
