import React, { Fragment } from 'react';
import withOrganizationData from '../withOrganizationData';
import { useTenantContext } from '../../../contexts/TenantContext';
import PageTitle from '../../commons/PageTitle';
import { ReportTileStyles } from '../../reports/reports.constants';
import MaterialIcon from '../../commons/MaterialIcon';

const HomeContent = ({ organization, selectedMenu = {} }) => {
  const { tenant } = useTenantContext();
  const reportStyle = ReportTileStyles[selectedMenu?.key];

  return (
    <>
      <PageTitle page={selectedMenu?.name} pageModule="" />
      <div id="clientPortalContent">
        {Object.entries(selectedMenu).length > 0 ? (
          <>
            <div
              style={{
                maxWidth: 825,
              }}
              id="clientPortalHeading"
              className="m-auto page-title px-3 pt-3 d-flex justify-content-between align-items-center"
            >
              <h3 className="mb-0">
                <span className="font-weight-bold">
                  {selectedMenu?.type === 'Checklist' ? (
                    selectedMenu?.name
                  ) : (
                    <div className="d-flex align-items-center gap-2">
                      <div
                        className={`${reportStyle.styleClass} report-tile-icon client-report-title-icon`}
                      >
                        <MaterialIcon icon={reportStyle.icon} size="fs-5" />
                      </div>
                      {selectedMenu?.name}
                    </div>
                  )}
                </span>
              </h3>
            </div>
            <div className="dasboard px-0 py-4 m-auto position-relative">
              {selectedMenu?.type === 'Checklist' ? (
                selectedMenu?.component
              ) : (
                <div className="m-auto" style={{ maxWidth: 825 }}>
                  {selectedMenu?.component(organization, tenant, true)}
                </div>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default withOrganizationData(HomeContent);
