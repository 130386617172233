import FunnyLoaderText from './FunnyLoaderText';
import DotDot from './DotDot';
import React from 'react';

const FunnyLoaderBlinker = ({ containerClasses, autoPlay }) => {
  return (
    <p className={containerClasses}>
      <span className="blinker"></span>
      <FunnyLoaderText autoPlay={autoPlay} />
      <DotDot />
    </p>
  );
};

export default FunnyLoaderBlinker;
