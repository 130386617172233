import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { Dropdown, Form, FormLabel } from 'react-bootstrap';
import ButtonIcon from '../commons/ButtonIcon';
import useEngagementReportTabs from '../reports/EngagementReportTabs';
import ButtonFilterDropdown from '../commons/ButtonFilterDropdown';
import OrganizationService from '../../services/organization.service';
import {
  EngagementReportConfig,
  generatePDF,
  generatePNG,
  generateSlides,
  getCanvasElements,
  getPageDimensions,
  getVideoUrlWithTenant,
  ReportFileType,
  ReportFileTypes,
  ReportTileStyles,
  ReportTypes,
  TreasuryReportSections,
  WorkingCapitalReportSections,
} from '../reports/reports.constants';
import {
  APOData,
  AROData,
  DisclaimerData,
  DPOFTIData,
  DPOTPData,
  DSOCashFlowData,
  DSOFTIData,
  DSOTPData,
  ElectronicPaymentsSavingsData,
  ElectronicPaymentsToGrowthData,
  FBCWData,
  FraudMitigationData,
  FraudPreventData,
  HoursSpentWeeklyData,
  ImproveVendorRelationshipData,
  PFData,
  PFIBData,
  RefundAndChargebacksAmountProcessedData,
  SimplifyingFraudData,
  TODDData,
  UWCData,
  WidgetTypes,
} from '../reportbuilder/constants/widgetsConstants';
import { useTenantContext } from '../../contexts/TenantContext';
import {
  DATE_FORMAT_DASHED_TZ,
  DATE_FORMAT_EJS,
  getDifferenceWidgetVisibility,
  getNaicsWithTitle,
  MultipleSources,
} from '../../utils/Utils';
import ReactDatepicker from '../inputs/ReactDatpicker';
import moment from 'moment/moment';
import { AlertMessageContext } from '../../contexts/AlertMessageContext';
import ReportPDFWrapper from '../reportbuilder/ReportPDFWrapper';
import ReportCover from '../reports/ReportCover';
import ReportPages from '../reportbuilder/ReportPages';
import _ from 'lodash';
import {
  calculateOpportunity,
  getCycleDate,
  getReportPDFName,
  getReportPDFNameMerchant,
  MerchantReportAggregateData,
  updateJsonObject,
  workingCapitalMapping,
} from '../reports/reports.helper.functions';
import {
  defaultGlossary,
  defaultGlossaryWorkingCapital,
} from '../../utils/constants';
import ReportPagesAccountStructure from '../reportbuilder/ReportPagesAccountStructure';
import MaterialCheckbox from '../commons/MaterialCheckbox';
import MaterialIcon from '../commons/MaterialIcon';
import PptxGenJS from 'pptxgenjs';
import JSZip from 'jszip';
import ReportBlockWrapper from '../reports/ReportBlockWrapper';
import { ProcessingSummary } from '../reports/merchant-services/ProcessSummary';
import { MerchantFees } from '../reports/merchant-services/MerchantFees';
import DisclaimerWidget from '../reportbuilder/widgets/horizontal/DisclaimerWidget';
import ReportCoverThankYou from '../reports/ReportCoverThankYou';

const PageConfigMerchantReport =
  EngagementReportConfig[ReportTypes.MerchantV2].PageConfig;
const MerchantReportV2SectionKeys =
  EngagementReportConfig[ReportTypes.MerchantV2].SectionKeys;
const ReportFilterType = {
  Latest: 'Latest',
  SelectDates: 'SelectDates',
};

const ReportFilterTypes = [
  {
    key: ReportFilterType.Latest,
    name: 'Most Recent',
  },
  {
    key: ReportFilterType.SelectDates,
    name: 'Select date range',
  },
];

const ReportsSelection = ({
  organization,
  handleReportsSelectionChange,
  selectedReports,
  setSelectedReports,
}) => {
  const { enabledReports } = useEngagementReportTabs({
    organizationId: organization?.id,
  });

  const renderDropdownTitle = () => {
    if (selectedReports.length === 0) {
      return 'Select reports';
    } else if (selectedReports.length === 1) {
      return '1 report selected';
    } else {
      return `${selectedReports.length} reports selected`;
    }
  };

  const [selectAll, setSelectAll] = useState(false);
  const [show, setShow] = useState(false);

  return (
    <FormGroup>
      <FormLabel>Select Reports</FormLabel>
      <Dropdown show={show} onToggle={setShow}>
        <Dropdown.Toggle
          variant="white"
          as="div"
          className="w-100 btn btn-white dropdown-toggle cursor-pointer custom-toggle-caret"
          id="dropdown-basic"
        >
          {renderDropdownTitle()}
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100 py-0">
          <div
            onClick={() => {
              const checked = !selectAll;
              setSelectAll(checked);
              if (checked) {
                setSelectedReports(enabledReports.map((rpt) => rpt.key));
              } else {
                setSelectedReports([]);
              }
            }}
            className={`${
              selectAll ? 'bg-gray-5' : ''
            } pt-3 pl-3 pr-3 pb-2 text-black bg-hover-soft-gray cursor-pointer d-flex justify-content-between`}
          >
            <div className="d-flex align-items-center gap-1">
              <span>&nbsp;&nbsp;</span>
              <span className="font-size-sm2 font-weight-medium">
                Select All
              </span>
            </div>
            <MaterialCheckbox checked={selectAll} />
          </div>
          <Form className="p-0">
            {enabledReports.map((rpt) => (
              <div
                key={rpt.key}
                onClick={() => {
                  setSelectAll(false);
                  handleReportsSelectionChange({
                    target: { value: rpt.key },
                  });
                }}
                className={`${
                  selectedReports.includes(rpt.key) ? 'bg-gray-5' : ''
                } py-2 d-flex cursor-pointer text-black bg-hover-soft-gray align-items-center justify-content-between px-3`}
              >
                <div className="d-flex align-items-center gap-1">
                  <div
                    className={`${
                      ReportTileStyles[rpt.key].styleClass
                    } p-2 rounded-circle`}
                  >
                    <MaterialIcon
                      icon={ReportTileStyles[rpt.key].icon}
                      size="fs-5 d-flex"
                    />
                  </div>
                  <span className="fs-7 font-weight-medium">{rpt.name}</span>
                </div>
                <MaterialCheckbox checked={selectedReports.includes(rpt.key)} />
              </div>
            ))}
          </Form>
          <div className="p-3 border-top">
            <ButtonIcon
              label="Done"
              color="primary"
              classnames="btn-block btn-sm"
              onclick={() => setShow(false)}
            />
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </FormGroup>
  );
};
const DatesSelection = ({
  list,
  reportFilter,
  handleSelected,
  selectedDates,
  setSelectedDates,
}) => {
  const handleDateChange = (value, key) => {
    const StartEndOfDates = {
      startDt: moment(value).startOf('day').format(DATE_FORMAT_DASHED_TZ),
      endDt: moment(value).endOf('day').format(DATE_FORMAT_DASHED_TZ),
    };
    const newDatesSelected = {
      ...selectedDates,
      [key]: value,
      [`${key}_Formatted`]: StartEndOfDates[key],
    };
    setSelectedDates(newDatesSelected);
  };
  return (
    <>
      <FormGroup>
        <FormLabel>Select Dates</FormLabel>
        <ButtonFilterDropdown
          buttonText="Opportunities"
          options={list}
          filterOptionSelected={reportFilter}
          handleFilterSelect={handleSelected}
          menuClass="drop-menu-card"
          ignoreChildHover="w-100 ignore-child gray"
          btnToggleStyle="w-100"
          optionIconClass="filled"
        />
      </FormGroup>
      {reportFilter?.key === ReportFilterType.SelectDates ? (
        <Row>
          <Col>
            <FormGroup>
              <FormLabel>Start Date</FormLabel>
              <ReactDatepicker
                id={'startDt'}
                name={'startDt'}
                format={DATE_FORMAT_EJS}
                autoComplete="off"
                todayButton="Today"
                value={selectedDates.startDt}
                validationConfig={{}}
                placeholder={'Select Start Date'}
                className="form-control bg-transparent"
                onChange={(value) => handleDateChange(value, 'startDt')}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <FormLabel>End Date</FormLabel>
              <ReactDatepicker
                id={'endDt'}
                name={'endDt'}
                format={DATE_FORMAT_EJS}
                autoComplete="off"
                todayButton="Today"
                value={selectedDates.endDt}
                validationConfig={{}}
                placeholder={'Select End Date'}
                className="form-control bg-transparent"
                onChange={(value) => handleDateChange(value, 'endDt')}
              />
            </FormGroup>
          </Col>
        </Row>
      ) : null}
    </>
  );
};
const FileTypeSelection = ({ list, reportFileType, setReportFileType }) => {
  const handleSelected = (e, item) => {
    setReportFileType(item);
  };

  return (
    <>
      <FormGroup>
        <FormLabel>Select File Type</FormLabel>
        <ButtonFilterDropdown
          buttonText="File Type"
          options={list}
          filterOptionSelected={reportFileType}
          handleFilterSelect={handleSelected}
          ignoreChildHover="w-100 ignore-child gray"
          btnToggleStyle="w-100"
          optionIconClass="filled"
        />
      </FormGroup>
    </>
  );
};

const getQRCodeLinkConfigs = (tenant, type, reportPages) => {
  const LinkConfigs = {
    [ReportTypes.Treasury]: {
      area: {
        areaX: 520,
        areaY: 400,
        areaWidth: 110,
        areaHeight: 30,
      },
      page: 5,
      url: getVideoUrlWithTenant(tenant, FraudMitigationData.videoId),
    },
    [ReportTypes.WorkingCapital]: {
      area: {
        areaX: 520,
        areaY: 730,
        areaWidth: 110,
        areaHeight: 30,
      },
      page: reportPages[WorkingCapitalReportSections.LoanOverview]?.enabled
        ? 3
        : 2,
      url: getVideoUrlWithTenant(tenant, UWCData.videoId),
    },
    [ReportTypes.Fraud]: {
      area: {
        areaX: 520,
        areaY: 650,
        areaWidth: 110,
        areaHeight: 30,
      },
      page: 3,
      url: SimplifyingFraudData.channel,
    },
    [ReportTypes.CommercialCard]: {},
  };
  return LinkConfigs[type];
};
const DownloadReportForm = ({ organization }) => {
  const { setErrorMessage } = useContext(AlertMessageContext);
  const [startDownload, setStartDownload] = useState(false);
  const [reportFilter, setReportFilter] = useState(ReportFilterTypes[0]);
  const [reportFileType, setReportFileType] = useState(ReportFileTypes[0]);
  const [selectedReports, setSelectedReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const now = new Date();
  const { tenant } = useTenantContext();
  const [reports, setReports] = useState([]);
  const [, setCurrentReport] = useState({});
  const [progress, setProgress] = useState({ current: 0, total: 0 });
  const [selectedDates, setSelectedDates] = useState({
    startDt: now,
    endDt: now,
    [`startDt_Formatted`]: moment(now)
      .startOf('day')
      .format(DATE_FORMAT_DASHED_TZ),
    [`endDt_Formatted`]: moment(now).endOf('day').format(DATE_FORMAT_DASHED_TZ),
  });
  const [insightsData, setInsightsData] = useState({});

  const handleReportsSelectionChange = (event) => {
    const { value } = event.target;
    if (!selectedReports.includes(value)) {
      setSelectedReports((prevState) => [...prevState, value]);
    } else {
      setSelectedReports((prevState) =>
        prevState.filter((item) => item !== value)
      );
    }
  };

  const areReportsAvailable = (responses) => {
    return responses.some((rpt) => {
      return rpt.data?.data.length > 0;
    });
  };

  const getUpdatedWidgetConfigFraud = (wg) => {
    const newDescriptions = {
      [WidgetTypes.PF]: PFData.description,
      [WidgetTypes.EPG]: ElectronicPaymentsToGrowthData.description,
      [WidgetTypes.BEC]: FraudPreventData.description,
      [`${WidgetTypes.QRCode}-${SimplifyingFraudData.oldHeading}`]:
        SimplifyingFraudData.description,
    };
    const newHeadings = {
      [WidgetTypes.PF]: PFData.heading,
    };
    const newItems = {
      [WidgetTypes.PFIB]: PFIBData.items,
    };
    let updatedWidgetConfig = {
      ...wg.widgetConfig,
      heading: newHeadings[wg.type] || wg.widgetConfig.heading,
      videoDuration: wg.widgetConfig.videoDuration,
      items: newItems[wg.type] || wg.widgetConfig?.items,
    };
    if (
      wg.type === WidgetTypes.QRCode &&
      wg.widgetConfig.heading === SimplifyingFraudData.oldHeading
    ) {
      return {
        ...updatedWidgetConfig,
        videoDuration: SimplifyingFraudData.videoDuration,
        description:
          newDescriptions[`${wg.type}-${SimplifyingFraudData.oldHeading}`] ||
          wg.widgetConfig.description,
      };
    }
    updatedWidgetConfig = {
      ...updatedWidgetConfig,
      description: newDescriptions[wg.type] || wg.widgetConfig.description,
    };

    return updatedWidgetConfig;
  };

  const updateDefaultWidgetsWithMappingFraud = (
    defaultOrSavedWidgets,
    reportObjectWithMapping
  ) => {
    const updatedWidgets = {};
    delete reportObjectWithMapping.widgets;
    delete reportObjectWithMapping.reportPages;
    for (const key in defaultOrSavedWidgets) {
      updatedWidgets[key] = defaultOrSavedWidgets[key]?.map((wg) => {
        const updatedConfig = getUpdatedWidgetConfigFraud(wg);
        return {
          ...wg,
          widgetConfig: {
            ...wg.widgetConfig,
            data:
              wg.type || (wg.type === WidgetTypes.DISC) === WidgetTypes.FBAR
                ? reportObjectWithMapping
                : null,
            heading: updatedConfig.heading,
            description: updatedConfig.description,
            videoDuration: updatedConfig.videoDuration,
            items: updatedConfig.items,
          },
        };
      });
    }
    return {
      ...updatedWidgets,
    };
  };

  const coreDataExists = (rpt) => {
    return Object.keys(rpt?.coreData || {}).length || rpt?.manualInput?.isAuto;
  };
  const getUpdatedWidgetConfigCommercialCard = (wg) => {
    const updatedWidgetConfig = {
      ...wg.widgetConfig,
      items: wg.widgetConfig?.items,
    };
    return updatedWidgetConfig;
  };

  // eslint-disable-next-line no-unused-vars
  const updateDefaultWidgetsWithMappingCommercialCard = (
    defaultOrSavedWidgets,
    reportObjectWithMapping
  ) => {
    const updatedWidgets = {};
    delete reportObjectWithMapping.widgets;
    delete reportObjectWithMapping.reportPages;
    for (const key in defaultOrSavedWidgets) {
      updatedWidgets[key] = defaultOrSavedWidgets[key]?.map((wg) => {
        const updatedConfig = getUpdatedWidgetConfigCommercialCard(wg);
        return {
          ...wg,
          widgetConfig: Array.isArray(wg.widgetConfig)
            ? wg.widgetConfig
            : {
                ...wg.widgetConfig,
                data: reportObjectWithMapping,
                items: updatedConfig.items,
              },
        };
      });
    }
    return {
      ...updatedWidgets,
    };
  };

  const defaultGlossaryWidgetTreasury = {
    [TreasuryReportSections.Glossary]: [
      {
        id: 1,
        widgetConfig: { glossary: defaultGlossary, data: { id: 'gloss' } },
        type: WidgetTypes.GLOSS,
      },
    ],
  };

  const getUpdatedWidgetConfigTreasury = (
    wg,
    reportData,
    section,
    insights
  ) => {
    const newDescriptions = {
      [WidgetTypes.BEC]: FraudPreventData.description,
      [WidgetTypes.EPS]: ElectronicPaymentsSavingsData.description,
    };
    const newHeadings = {
      [WidgetTypes.BEC]: FraudPreventData.heading,
      [WidgetTypes.EPS]: ElectronicPaymentsSavingsData.heading,
    };
    const newItems = {
      [WidgetTypes.PFIB]: PFIBData.items,
    };
    const newConfigs = {
      [WidgetTypes.EPG]: ElectronicPaymentsToGrowthData,
      [WidgetTypes.IVR]: ImproveVendorRelationshipData,
      [WidgetTypes.BEC]: FraudPreventData,
      [WidgetTypes.HSW]: HoursSpentWeeklyData,
      [WidgetTypes.IconSet]: DSOCashFlowData,
    };

    let newReport = reportData;
    if (wg.type === WidgetTypes.PEO) {
      newReport = {
        ...reportData,
        enableDifferenceWidget: getDifferenceWidgetVisibility(wg.widgetConfig),
      };
    }
    const defaultWidget = {
      ...wg,
      widgetConfig: {
        ...wg.widgetConfig,
        data: newReport,
        insightsData: insights,
        heading: newHeadings[wg.type] || wg.widgetConfig.heading,
        description: newDescriptions[wg.type] || wg.widgetConfig.description,
        items: newItems[wg.type] || wg.widgetConfig.items,
      },
    };
    switch (section) {
      case TreasuryReportSections.Fraud:
        if (wg.type === WidgetTypes.QRCode) {
          return { ...wg, widgetConfig: FraudMitigationData };
        }
        return defaultWidget;
      case TreasuryReportSections.Payables:
      case TreasuryReportSections.Receivables:
        return newConfigs[wg.type]
          ? { ...wg, widgetConfig: newConfigs[wg.type] }
          : defaultWidget;
      default:
        return defaultWidget;
    }
  };

  const getDefaultReportPages = (reportObject) => {
    return _.cloneDeep(EngagementReportConfig[reportObject.type].PageConfig);
  };

  const getDefaultWidgetsTreasury = (reportObject, newInsights) => {
    return {
      ...reportObject.widgets,
      [TreasuryReportSections.Overview]: [
        {
          id: 1,
          widgetConfig: {
            data: reportObject,
          },
          type: WidgetTypes.ESTPR,
        },
        {
          id: 2,
          widgetConfig: {
            data: reportObject,
          },
          type: WidgetTypes.PEO,
        },
        {
          id: 3,
          widgetConfig: ElectronicPaymentsSavingsData,
          type: WidgetTypes.EPS,
        },
      ],
      [TreasuryReportSections.Payables]: [
        {
          id: 1,
          widgetConfig: ElectronicPaymentsToGrowthData,
          type: WidgetTypes.EPG,
        },
        {
          id: 2,
          widgetConfig: {
            data: reportObject,
            insightsData: newInsights,
            source: MultipleSources,
          },
          type: WidgetTypes.DPO,
        },
        {
          id: 3,
          widgetConfig: ImproveVendorRelationshipData,
          type: WidgetTypes.IVR,
        },
      ],
      [TreasuryReportSections.Receivables]: [
        {
          id: 1,
          widgetConfig: HoursSpentWeeklyData,
          type: WidgetTypes.HSW,
        },
        {
          id: 2,
          widgetConfig: {
            data: reportObject,
            insightsData: newInsights,
            source: MultipleSources,
          },
          type: WidgetTypes.DSO,
        },
      ],
      [TreasuryReportSections.Fraud]: [
        {
          id: 1,
          widgetConfig: {
            data: reportObject,
          },
          type: WidgetTypes.BAR,
        },
        {
          id: 2,
          widgetConfig: FraudMitigationData,
          type: WidgetTypes.QRCode,
        },
        {
          id: 3,
          widgetConfig: FraudPreventData,
          type: WidgetTypes.BEC,
        },
      ],
      [TreasuryReportSections.FeeSummary]: [
        {
          id: 1,
          widgetConfig: {
            data: reportObject,
          },
          type: WidgetTypes.BAEC,
        },
      ],
      ...defaultGlossaryWidgetTreasury,
    };
  };

  const updateExpenseIcons = (rpt) => {
    const expenses = rpt?.loanOverview?.expenses || [];
    const icons = {
      peakUtilization: 'arrow_circle_up',
      lowUtilization: 'arrow_circle_down',
      totalInterestExpense: 'monetization_on',
    };
    return expenses.map((exp) => ({
      ...exp,
      icon: icons[exp.key] || exp.icon,
    }));
  };

  const displayValuesInViewWorkingCapital = (
    data,
    manualInput,
    insightsData
  ) => {
    const mapping = workingCapitalMapping(manualInput, insightsData);
    const newExpenses = updateExpenseIcons(manualInput);
    const updatedReport = {
      ...manualInput,
      loanOverview: { ...manualInput.loanOverview, expenses: newExpenses },
    };
    return {
      reportId: data.reportId,
      createdById: data.createdById,
      companyName: data.name,
      valueNaicsSic: manualInput.valueNaicsSic,
      ...updatedReport,
      ...mapping,
      reportDate: getCycleDate(data.date),
      valueN: getNaicsWithTitle(manualInput, organization),
      type: ReportTypes.WorkingCapital,
      nameKey: 'companyName',
      dateKey: 'reportDate',
    };
  };

  const getUpdatedWidgetConfigMerchantReport = (wg) => {
    return {
      ...wg.widgetConfig,
      items: wg.widgetConfig?.items,
    };
  };
  const updateDefaultWidgetsWithMappingMerchantReport = (
    defaultOrSavedWidgets,
    reportObjectWithMapping,
    aggregate
  ) => {
    const HeaderTitle = {
      [WidgetTypes.FBCW]: FBCWData.header,
      [WidgetTypes.APW]: RefundAndChargebacksAmountProcessedData.header,
    };
    const updatedWidgets = {};
    delete reportObjectWithMapping.widgets;
    delete reportObjectWithMapping.reportPages;
    for (const key in defaultOrSavedWidgets) {
      updatedWidgets[key] = defaultOrSavedWidgets[key]?.map((wg) => {
        const updatedConfig = getUpdatedWidgetConfigMerchantReport(wg);
        return {
          ...wg,
          widgetConfig: Array.isArray(wg.widgetConfig)
            ? wg.widgetConfig
            : {
                ...wg.widgetConfig,
                header: wg.widgetConfig.header || HeaderTitle[wg.type],
                data: aggregate,
                items: updatedConfig.items,
                aggregate, // default true
              },
        };
      });
    }
    return {
      ...updatedWidgets,
    };
  };

  const displayValuesInViewMerchantReport = (data, manualInput) => {
    return {
      key: data.reportId,
      createdById: data.createdById,
      ...manualInput,
      name: data?.name,
      type: ReportTypes.Merchant,
      reportDate: getCycleDate(data.date),
    };
  };

  const getInsights = async () => {
    try {
      return await OrganizationService.getInsightsByOrganization(
        organization.id
      );
    } catch (e) {
      console.log(e);
    }
  };

  const APOHeadings = {
    [APOData.extraType]: APOData.heading,
    [AROData.extraType]: AROData.heading,
  };

  const FTIData = {
    DPO: DPOFTIData,
    DSO: DSOFTIData,
  };

  const PowerPotentialData = {
    DPO: DPOTPData,
    DSO: DSOTPData,
  };

  const getHeading = (wg, config) => {
    return wg.type === WidgetTypes.APO
      ? APOHeadings[config.extraType]
      : config.heading;
  };

  const getDescription = (wg, config) => {
    const description = config.description;
    if (wg.type === WidgetTypes.QRCode) {
      return UWCData.description;
    }
    if (wg.type === WidgetTypes.TODD) {
      return TODDData.description;
    }
    return description;
  };

  const getValueText = (wg, config) => {
    return config.extraType === 'DPO' ? APOData.valueText : AROData.valueText;
  };

  const getFactors = (wg, config) => {
    const defaultFactors = FTIData[config.extraType]?.factors;
    return config.factors.map((f) => {
      const oldFactor = defaultFactors.find(
        (of) => of.heading.toLowerCase() === f.heading.toLowerCase()
      );
      return {
        ...f,
        heading: oldFactor.heading,
        icon: oldFactor?.icon || f.icon,
        width: oldFactor?.width,
      };
    });
  };

  const defaultGlossaryWidgetWorkingCapital = {
    [WorkingCapitalReportSections.Glossary]: [
      {
        id: 1,
        widgetConfig: {
          glossary: defaultGlossaryWorkingCapital,
          data: { id: 'gloss' },
        },
        type: WidgetTypes.GLOSS,
      },
    ],
  };

  const getPowerAndPotential = (wg, config) => {
    return PowerPotentialData[config.extraType]?.powerAndPotential;
  };

  const updateDefaultWidgetsWithMappingWorkingCapital = (
    defaultOrSavedWidgets,
    reportObjectWithMapping
  ) => {
    const updatedWidgets = {};
    for (const key in defaultOrSavedWidgets) {
      updatedWidgets[key] = defaultOrSavedWidgets[key]?.map((wg) => ({
        ...wg,
        widgetConfig: {
          ...wg.widgetConfig,
          heading: getHeading(wg, wg.widgetConfig),
          description: getDescription(wg, wg.widgetConfig),
          data: wg.type === WidgetTypes.QRCode ? null : reportObjectWithMapping,
          extraPadding: 'py-0', // QRCode widget reads so...
          factors:
            wg.type === WidgetTypes.FTI
              ? getFactors(wg, wg.widgetConfig)
              : null,
          powerAndPotential:
            wg.type === WidgetTypes.OTP
              ? getPowerAndPotential(wg, wg.widgetConfig)
              : null,
          valueText:
            wg.type === WidgetTypes.APO
              ? getValueText(wg, wg.widgetConfig)
              : null,
          videoDuration:
            wg.type === WidgetTypes.QRCode ? UWCData.videoDuration : null,
          videoId:
            wg.type === WidgetTypes.QRCode
              ? UWCData.videoId
              : wg.widgetConfig.videoId,
        },
      }));
    }

    return {
      ...updatedWidgets,
      ...defaultGlossaryWidgetWorkingCapital,
    };
  };

  const processReportByType = (report, insights) => {
    if (report.type === ReportTypes.Treasury) {
      const reportResponse = report;
      const reportObject = report.manualInput;
      const checks = _.cloneDeep(reportObject.paymentMethodsUsed);
      checks.Checks = checks.Checks || checks.Check;
      checks.Checks.key = 'Checks';
      delete reportObject.paymentMethodsUsed.Check;
      reportObject.paymentMethodsUsed.Checks = checks.Checks;
      reportObject.paymentMethodsUsed = updateJsonObject(
        reportObject.paymentMethodsUsed
      );
      reportObject.typesOfReceivables = updateJsonObject(
        reportObject.typesOfReceivables
      );
      // remove for comerica as per Emily
      delete reportObject.paymentMethodsUsed['Automated Payable Solution'];
      delete reportObject.typesOfReceivables['Automated Receivables Solution'];
      reportObject.paymentRisks.fraudPreventionProducts = updateJsonObject(
        reportObject.paymentRisks.fraudPreventionProducts
      );

      const opportunity = calculateOpportunity(
        reportObject?.paymentMethodsUsed?.Checks?.itemValue
      );

      for (const key in reportObject.widgets) {
        reportObject.widgets[key] = reportObject.widgets[key].map((wg) => {
          return getUpdatedWidgetConfigTreasury(
            wg,
            {
              ...reportObject,
              opportunity,
              insightsData: insights || insightsData,
            },
            key,
            insights || insightsData
          );
        });
      }

      const newWidgets = getDefaultWidgetsTreasury(reportObject, insights);
      const newTMReports = {
        ...reportObject,
        value2: getCycleDate(reportObject.value2),
        glossary: reportObject?.glossary || defaultGlossary,
        valueN: getNaicsWithTitle(reportObject, organization),
        reportId: reportResponse.reportId,
        widgets: Object.entries(reportObject.widgets).length
          ? { ...reportObject.widgets, ...defaultGlossaryWidgetTreasury }
          : { ...newWidgets },
        reportPages:
          reportObject.reportPages || getDefaultReportPages(reportObject),
        type: report.type,
        nameKey: 'value1',
        dateKey: 'value2',
      };
      return newTMReports;
    } else if (report.type === ReportTypes.Fraud) {
      const { manualInput } = report;
      const rptObject = manualInput;
      const fraudPreventionProducts =
        rptObject?.fraudPreventionProducts ||
        updateJsonObject({
          'Positive Pay': rptObject.positivePay ? 1 : 0,
          'ACH Positive Pay': rptObject.achPositivePay ? 1 : 0,
        });

      const savedWidgets = updateDefaultWidgetsWithMappingFraud(
        rptObject.widgets,
        {
          ...rptObject,
          fraudPreventionProducts,
        }
      );
      return {
        ...rptObject,
        fraudPreventionProducts,
        id: report.reportId,
        reportId: report.reportId,
        valueN: getNaicsWithTitle(rptObject, organization),
        reportDate: getCycleDate(rptObject.reportDate),
        widgets: savedWidgets,
        type: report.type,
        nameKey: 'companyName',
        dateKey: 'reportDate',
      };
    } else if (report.type === ReportTypes.WorkingCapital) {
      const { manualInput } = report;
      const rptObject = displayValuesInViewWorkingCapital(
        report,
        manualInput,
        insights
      );
      rptObject.widgets = updateDefaultWidgetsWithMappingWorkingCapital(
        manualInput.widgets,
        rptObject,
        insights
      );
      return rptObject;
    } else if (report.type === ReportTypes.AccountStructure) {
      const reportInfo = report.manualInput.ReportInfo;
      return {
        ...report.manualInput,
        id: report.reportId,
        reportId: report.reportId,
        valueN: getNaicsWithTitle(reportInfo, organization),
        reportDate: getCycleDate(reportInfo.reportDate),
        type: report.type,
        nameKey: 'companyName',
        dateKey: 'reportDate',
      };
    } else if (
      [ReportTypes.MerchantV2, ReportTypes.Merchant].includes(report.type)
    ) {
      const isCoreData = coreDataExists(report);
      if (report.type === ReportTypes.Merchant && !isCoreData) {
        const newReport = displayValuesInViewMerchantReport(
          report,
          report.manualInput
        );
        return { ...newReport, reportPages: 3 };
      }
      const rptObject = report;
      const aggregate = MerchantReportAggregateData(
        rptObject?.manualInput?.coreData
      );
      const start = moment(aggregate?.monthRange?.start?.split('T')[0]).format(
        'MMMM YYYY'
      );
      const end = moment(aggregate?.monthRange?.end?.split('T')[0]).format(
        'MMMM YYYY'
      );
      const prettyDate = `${start} - ${end}`;
      return {
        ...rptObject,
        ...report.manualInput,
        aggregate,
        widgets: updateDefaultWidgetsWithMappingMerchantReport(
          report.manualInput.widgets,
          rptObject.manualInput,
          aggregate
        ),
        prettyDate,
      };
    } else if (report.type === ReportTypes.CommercialCard) {
      const reportInfo = report.manualInput;
      return {
        ...report.manualInput,
        id: report.reportId,
        reportId: report.reportId,
        valueN: getNaicsWithTitle(reportInfo, organization),
        reportDate: getCycleDate(reportInfo.reportDate),
        widgets: reportInfo.widgets,
        reportPages: reportInfo.reportPages,
        type: report.type,
        nameKey: 'companyName',
        dateKey: 'reportDate',
      };
    }
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      let filter = {
        limit: 1,
        page: 1,
        order: [['updatedAt', 'DESC']],
      };
      const dateRange = {
        startDt: selectedDates.startDt_Formatted,
        endDt: selectedDates.endDt_Formatted,
      };
      if (reportFilter.key === ReportFilterType.SelectDates) {
        filter = { ...filter, ...dateRange, limit: 10 };
      }
      // this only gives reports
      const requests = selectedReports.map((rptType) =>
        OrganizationService.getReports(organization.id, {
          ...filter,
          type: rptType,
        })
      );
      const responses = await Promise.all(requests);
      if (areReportsAvailable(responses)) {
        // if we got any reports then we have to get the report details by id
        const allReports = responses.map((resp) => resp.data.data).flat();
        const reportDetailResponses = allReports.filter(
          (rpt) => !!rpt.manualInput
        );
        let orgInsights = {};
        if (reportDetailResponses.length) {
          const hasTreasuryOrWorkingCapitalReport = allReports.find(
            (rpt) =>
              rpt.type === ReportTypes.Treasury ||
              rpt.type === ReportTypes.WorkingCapital
          );
          if (hasTreasuryOrWorkingCapitalReport) {
            try {
              const data = await getInsights();
              orgInsights = data;
              setInsightsData(orgInsights);
            } catch (e) {
              setInsightsData({});
            }
          }
          const processedReports = reportDetailResponses.map((r) =>
            processReportByType(r, orgInsights)
          );
          setLoading(false);
          setStartDownload(true);
          setProgress({ current: 1, total: processedReports.length });
          setReports(processedReports);
          setCurrentReport(processedReports[0]);
          do {
            const newReport = processedReports[0];
            setCurrentReport(newReport);
            await downloadReport(newReport);
            setProgress((prevState) => ({
              ...prevState,
              current: prevState.current + 1,
            }));
            processedReports.shift();
            setReports(processedReports);
            if (processedReports.length === 0) {
              setProgress({ ...progress, current: 0 });
              setStartDownload(false);
            }
          } while (processedReports.length !== 0);
        } else {
          setLoading(false);
          setErrorMessage(
            "We couldn't find any reports in the selected date range. Please try another date range."
          );
        }
      } else {
        setErrorMessage(
          "We couldn't find any reports in the selected date range. Please try another date range."
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const downloadReport = async (report) => {
    const reportType = report.type;
    const linkConfig = [
      ReportTypes.Merchant,
      ReportTypes.MerchantV2,
      ReportTypes.AccountStructure,
    ].includes(reportType)
      ? {}
      : getQRCodeLinkConfigs(tenant, reportType, report.reportPages);

    const fileName = coreDataExists(report)
      ? `${getReportPDFNameMerchant(
          reportType,
          { name: organization?.name || report.name, date: report.prettyDate },
          true
        )}`
      : `${getReportPDFName(
          reportType,
          reportType === ReportTypes.AccountStructure
            ? report.ReportInfo
            : report
        )}`;

    await new Promise((resolve, reject) => {
      setTimeout(async () => {
        const $canvas = document.getElementsByClassName(
          `rptPdf_${report.id || report.reportId}`
        )[0];
        const dimensions = getPageDimensions($canvas);
        const element = `.rptPdf_${report.id || report.reportId} > div.px-0`;
        const canvases = await Promise.all(getCanvasElements(element));

        if (reportFileType.key === ReportFileType.PPT) {
          const pptx = new PptxGenJS();
          generateSlides(pptx, canvases, dimensions, linkConfig);
          pptx.writeFile({ fileName: `${fileName}.pptx` });
        } else if (reportFileType.key === ReportFileType.PNG) {
          const zip = new JSZip();
          await generatePNG(zip, canvases, fileName);
        } else {
          // eslint-disable-next-line new-cap
          const pdf = new window.jspdf.jsPDF('p', 'pt', [
            dimensions.HTML_Width,
            dimensions.pageHeight,
          ]);
          generatePDF(pdf, canvases, dimensions, linkConfig);
          pdf.save(`${fileName}.pdf`);
        }
        resolve();
      }, 100);
    });
  };

  return (
    <div className="p-3 border-top">
      <ReportsSelection
        organization={organization}
        selectedReports={selectedReports}
        setSelectedReports={setSelectedReports}
        handleReportsSelectionChange={handleReportsSelectionChange}
      />
      <DatesSelection
        list={ReportFilterTypes}
        reportFilter={reportFilter}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        handleSelected={(e, item) => {
          setReportFilter(item);
        }}
      />
      <FileTypeSelection
        list={ReportFileTypes}
        reportFileType={reportFileType}
        setReportFileType={setReportFileType}
      />
      <div className="mt-3">
        <ButtonIcon
          label={
            progress.current === 0
              ? 'Download'
              : `Downloading... (${progress}/${progress.total})`
          }
          loadingText={
            !startDownload && (progress.current === 0 || reports.length === 0)
              ? 'Download'
              : `Downloading... (${progress.current}/${progress.total})`
          }
          icon=""
          classnames="btn-sm btn-block"
          color="primary"
          loading={loading || startDownload}
          disabled={selectedReports.length === 0}
          onclick={handleDownload}
        />
      </div>

      {startDownload && (
        <>
          {reports?.map((report) => (
            <div key={report.id || report.reportId}>
              <ReportPDFWrapper
                classes={
                  report.type === ReportTypes.Merchant ? 'merchant-card' : ''
                }
                selector={`rptPdf_${report.id || report.reportId}`}
              >
                {report.type === ReportTypes.AccountStructure ? (
                  <>
                    <ReportCover
                      name={report?.ReportInfo[report.nameKey]}
                      date={report?.ReportInfo[report.dateKey]}
                      report={report?.ReportInfo}
                      type={report.type}
                      organization={organization}
                    />
                    <ReportPagesAccountStructure
                      report={report}
                      reportType={report.type}
                    />
                  </>
                ) : [ReportTypes.MerchantV2, ReportTypes.Merchant].includes(
                    report.type
                  ) ? (
                  <>
                    {report.type === ReportTypes.Merchant &&
                    !coreDataExists(report) ? (
                      <>
                        <ReportCover
                          name={report.name}
                          date={report.reportDate}
                          type={ReportTypes.Merchant}
                          report={report}
                        />
                        <ReportBlockWrapper
                          showLogo={startDownload}
                          current={2}
                          total={report.reportPages}
                          report={report}
                          excelBankMode={false}
                          reportType={ReportTypes.Merchant}
                        >
                          <ProcessingSummary
                            startDownload={startDownload}
                            report={report}
                          />
                        </ReportBlockWrapper>
                        <ReportBlockWrapper
                          showLogo={startDownload}
                          current={3}
                          total={report.reportPages}
                          report={report}
                          excelBankMode={false}
                          reportType={ReportTypes.Merchant}
                        >
                          <MerchantFees
                            startDownload={startDownload}
                            report={report}
                          />
                        </ReportBlockWrapper>
                        <ReportBlockWrapper
                          showLogo={startDownload}
                          current={4}
                          total={report.reportPages}
                          report={report}
                          excelBankMode={false}
                          reportType={ReportTypes.Merchant}
                        >
                          <div className="px-5">
                            {startDownload && (
                              <div className="px-0 pt-3 mt-2">
                                <div className="text-left mt-2">
                                  <h4 className={`fw-bold mb-2`}>Disclaimer</h4>
                                </div>
                              </div>
                            )}
                            <DisclaimerWidget
                              whenPrinting={startDownload}
                              widgetConfig={{ data: DisclaimerData }}
                            />
                          </div>
                        </ReportBlockWrapper>
                      </>
                    ) : (
                      <>
                        <ReportCover
                          name={organization?.name}
                          date={report?.prettyDate}
                          excelBankMode={false}
                          report={report}
                          type={ReportTypes.MerchantV2}
                          organization={organization}
                          isAggregate={true}
                        />
                        <ReportPages
                          pages={report.widgets}
                          report={report}
                          reportPages={report.reportPages}
                          sectionKeys={MerchantReportV2SectionKeys}
                          pageConfig={PageConfigMerchantReport}
                          showIcon={false}
                          reportType={ReportTypes.MerchantV2}
                        />
                        <ReportCoverThankYou
                          name={organization.name}
                          date={getCycleDate(new Date().toISOString())}
                          excelBankMode={false}
                          report={report}
                          type={ReportTypes.MerchantV2}
                          organization={organization}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <ReportCover
                      name={report[report.nameKey]}
                      date={report[report.dateKey]}
                      report={report}
                      type={report.type}
                      organization={organization}
                    />
                    <ReportPages
                      pages={report.widgets}
                      report={report}
                      reportPages={report.reportPages}
                      sectionKeys={
                        EngagementReportConfig[report.type].SectionKeys
                      }
                      pageConfig={
                        EngagementReportConfig[report.type].PageConfig
                      }
                      showIcon={false}
                      reportType={report.type}
                    />
                  </>
                )}
              </ReportPDFWrapper>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
const useDownloadReport = (organization) => {
  const [show, setShow] = useState(true);
  const DownloadReportFormCallback = useCallback(() => {
    return (
      <DownloadReportForm
        show={show}
        setShow={setShow}
        organization={organization}
      />
    );
  }, [show, setShow]);

  return useMemo(
    () => ({
      show,
      setShow,
      DownloadReportForm: show ? DownloadReportFormCallback : () => {},
    }),
    [setShow, DownloadReportFormCallback]
  );
};

export default useDownloadReport;
