import React from 'react';
import { Card } from 'react-bootstrap';

import DealTable from '../../../components/deals/DealTable';

const DealList = ({
  allDeals,
  dataInDB,
  pagination,
  onPageChange,
  onLimitChange,
  showLoading,
  tableLoading,
  service,
  onAddDeal,
  sortingTable,
  sortingOrder,
  selectedPipeline,
  viewType,
  DealStats,
}) => {
  return (
    <Card className="border-0 shadow-none" style={{ zIndex: 99 }}>
      <DealTable
        data={allDeals}
        selectedPipeline={selectedPipeline}
        paginationInfo={pagination}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        service={service}
        viewType={viewType}
        showLoading={showLoading}
        tableLoading={tableLoading}
        onAddDeal={onAddDeal}
        dataInDB={dataInDB}
        sortingTable={sortingTable}
        sortingOrder={sortingOrder}
        DealStats={DealStats}
      />
    </Card>
  );
};

export default DealList;
