import React, { useState, useEffect } from 'react';
import AssignmentsTable from './AssignmentsTable';
import { Card } from 'react-bootstrap';
import ModalCreateAssignment from './ModalCreateAssignment';
import SimpleModalCreation from '../modal/SimpleModalCreation';
import LayoutHead from '../commons/LayoutHead';
import { DataFilters } from '../DataFilters';
import {
  ADD_ASSIGNMENTS,
  SEARCH_ASSIGNMENTS,
  SERVER_ERROR,
  paginationDefault,
} from '../../utils/constants';
import ButtonFilterDropdown from '../commons/ButtonFilterDropdown';
import assignmentService from '../../services/assignment.service';
import TableSkeleton from '../commons/TableSkeleton';
import DeleteConfirmationModal from '../../components/modal/DeleteConfirmationModal';
import { sortingTable } from '../../utils/sortingTable';
import AlertWrapper from '../Alert/AlertWrapper';
import Alert from '../Alert/Alert';
import { formatText, isModuleAllowed } from '../../utils/Utils';
import { useProfileContext } from '../../contexts/profileContext';
import { useTenantContext } from '../../contexts/TenantContext';
import NoDataFound from '../commons/NoDataFound';
import PillFilters from '../commons/PillFilters';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../views/Deals/contacts/utils';

const FilterStatuses = [
  {
    id: 'all',
    label: 'All',
    status: 'all',
  },
  {
    id: 'active',
    label: 'Active',
    status: 'active',
  },
  {
    id: 'inactive',
    label: 'Inactive',
    status: 'inactive',
  },
];

const Assignments = ({ setModified }) => {
  const { profileInfo } = useProfileContext();
  const { tenant } = useTenantContext();
  if (
    !isModuleAllowed(tenant.modules, 'learns_lesson_catalog') &&
    !isModuleAllowed(tenant.modules, 'learns_assignments') &&
    isModuleAllowed(tenant.modules, 'settings_learn') &&
    !profileInfo.role.admin_access
  ) {
    return (
      <>
        <NoDataFound
          icon="manage_search"
          containerStyle="text-gray-search my-6 py-6"
          title={'No learn lesson catalog & assignments permissions'}
        />
      </>
    );
  }
  const [filterOptionSelected, setFilterOptionSelected] = useState({
    id: 1,
    key: '',
    name: 'All',
  });
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [assignmentToDelete, setAssignmentToDelete] = useState([]);
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [assignmentToEdit, setAssignmentToEdit] = useState([]);
  const [allAssignments, setAllAssignments] = useState([]);
  const [filterSelected, setFilterSelected] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [order, setOrder] = useState([['createdAt', 'DESC']]);
  const [errorMessage, setErrorMessage] = useState('');
  const [dataInDB, setDataInDB] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [assignFilter, setAssignFilter] = useState(FilterStatuses[0]);

  const onClose = () => {
    setOpenAssignModal(false);
    setIsEdit(false);
  };

  const AssignFilterOptions = [
    { id: 1, key: '', name: 'All' },
    { id: 2, key: 'active', name: 'Active' },
    { id: 3, key: 'inactive', name: 'Inactive' },
  ];

  const onHandleFilterAssignment = (e, option) => {
    e.preventDefault();
    setFilterOptionSelected(option);
    setPaginationPage((prev) => ({ ...prev, page: 1 }));
    if (option.key === 'createdAt') {
      setFilterSelected({
        order: [['createdAt', 'DESC']],
      });
    } else {
      if (option.key !== '') {
        setFilterSelected({
          status: option.key,
        });
      } else {
        setFilterSelected({});
      }
    }
  };

  const getAssignments = async (count) => {
    setLoading(true);
    try {
      const response = await assignmentService.getAssignments({
        page: paginationPage.page,
        limit: paginationPage.limit,
        order,
        ...filterSelected,
        retrieveAssigned: false,
      });

      setAllAssignments(response?.data);
      setPagination(response?.pagination);
      setDataInDB(Boolean(response?.pagination?.totalPages));
      setShowLoading(false);
      setLoading(false);
    } catch (err) {
      console.log('err', err);
      setErrorMessage(SERVER_ERROR);
    }
  };

  const handleDelete = (assignment) => {
    setAssignmentToDelete([assignment]);
    setShowDeleteModal(true);
  };

  const handleEditFullAssignment = async (assignment) => {
    setAssignmentToEdit(assignment);
    setIsEdit(true);
    setOpenAssignModal(true);
  };

  const handleUpdate = async (modAssign) => {
    setSuccessMessage(modAssign);
    getAssignments(true);
  };

  const handleDeleteAssignment = async () => {
    const singleAssignment = assignmentToDelete[0];
    try {
      await assignmentService.deleteAssignment([
        singleAssignment?.assignmentId,
      ]);
      setSuccessMessage('Assignment Deleted');
    } catch (e) {
      if (e.response.status === 409) {
        setErrorMessage(formatText(e.response.data.errors[0].message));
      } else {
        setErrorMessage(formatText(e.response.data.errors[0].message));
      }
    } finally {
      setAssignmentToDelete([]);
      setShowDeleteModal(false);
      getAssignments(true);
      setModified((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    getAssignments(true);
  }, [filterSelected, paginationPage, order, assignFilter]);

  const showAssignmentModalEdit = () => {
    setOpenAssignModal(true);
  };

  const handleUpdateStatus = async (assignment) => {
    setAssignmentToEdit(assignment);
    setShowUpdateStatusModal(true);
  };

  const confirmUpdateStatus = async () => {
    const statueToSave =
      assignmentToEdit.status === 'active' ? 'inactive' : 'active';
    const dataToEdit = {
      title: assignmentToEdit.title,
      status: statueToSave,
      dueAt: assignmentToEdit.dueAt,
    };
    try {
      await assignmentService.updateAssignmentById(
        assignmentToEdit.assignmentId,
        dataToEdit
      );
      setSuccessMessage(
        `Status successfully changed for ${assignmentToEdit.title}`
      );
    } catch (error) {
      console.error('Error updating assignment:', error);
    } finally {
      setShowUpdateStatusModal(false);
      getAssignments(true);
    }
  };

  const sortTable = ({ name }) => {
    sortingTable({ name, order, setOrder: (val) => setOrder([val]) }, true);
  };

  return (
    <div>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>

      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        setSelectedCategories={setAssignmentToDelete}
        event={handleDeleteAssignment}
        itemsConfirmation={assignmentToDelete}
        heading="Delete Assignment"
        description="Are you sure you want to delete this assignment?"
      />

      <DeleteConfirmationModal
        showModal={showUpdateStatusModal}
        setShowModal={setShowUpdateStatusModal}
        event={confirmUpdateStatus}
        heading="Update Assignment"
        description={`Do you want to update the status for assignment ${assignmentToEdit.title}?`}
        positiveBtnText="Update"
      />

      <SimpleModalCreation
        open={openAssignModal}
        setOpenAssignModal={setOpenAssignModal}
        size={'xxl'}
        modalTitle={isEdit ? 'Edit Assignment' : ADD_ASSIGNMENTS}
        onHandleCloseModal={onClose}
        customModal="modal-assigncontent"
        bankTeam={true}
        noFooter={true}
        bodyClassName="pt-0 pb-0"
      >
        <ModalCreateAssignment
          onUpdate={handleUpdate}
          setOpenAssignModal={setOpenAssignModal}
          dataToEdit={assignmentToEdit}
          isEdit={isEdit}
          setModified={setModified}
        />
      </SimpleModalCreation>

      <Card className="mb-0 card-0 rounded-0">
        <Card.Header className="flex-column align-items-unset px-0">
          <div className="d-flex align-items-center px-3 justify-content-between">
            <div className="d-none">
              <PillFilters
                items={FilterStatuses}
                currentFilter={assignFilter}
                onFilterChange={(newFilter) => {
                  if (newFilter.status === 'all') {
                    setFilterSelected({});
                  } else {
                    setFilterSelected({
                      status: newFilter.status,
                    });
                  }
                  setAssignFilter(newFilter);
                }}
              />
            </div>

            <DataFilters
              searchPlaceholder={SEARCH_ASSIGNMENTS}
              filterSelected={filterSelected}
              setFilterSelected={setFilterSelected}
              paginationPage={paginationPage}
              setPaginationPage={setPaginationPage}
            />

            <div className="d-flex align-items-center gap-2">
              <ButtonFilterDropdown
                options={AssignFilterOptions}
                filterOptionSelected={filterOptionSelected}
                handleFilterSelect={onHandleFilterAssignment}
              />
              <LayoutHead
                onHandleCreate={showAssignmentModalEdit}
                buttonLabel={ADD_ASSIGNMENTS}
                dataInDB={dataInDB}
                alignTop="mb-0"
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {showLoading && <TableSkeleton cols={5} rows={12} />}

          {!showLoading && (
            <AssignmentsTable
              data={allAssignments}
              tableLoading={loading}
              paginationInfo={pagination}
              onPageChange={(newPage) => {
                changePaginationPage(newPage, setPaginationPage);
              }}
              onLimitChange={(perPage) => {
                changePaginationLimit(perPage, setPaginationPage);
              }}
              dataInDB={dataInDB}
              sortingTable={sortTable}
              sortingOrder={order}
              handleDelete={handleDelete}
              handleUpdateStatus={handleUpdateStatus}
              handleEdit={showAssignmentModalEdit}
              handleEditFullAssignment={handleEditFullAssignment}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Assignments;
