import React, { useEffect, useState } from 'react';
import TooltipComponent from '../../../lesson/Tooltip';
import MaterialIcon from '../../../commons/MaterialIcon';
import ListItems from '../../filters/ListItems';

const InputRange = ({
  data,
  setData,
  onEnter,
  label = 'Radius',
  keyType,
  keyFilter,
  showSlider = true,
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [isCustomInput] = useState(false);

  const radiusValue =
    data[keyType][keyFilter]?.match(/::~([\d.]+)mi/)?.[1] || 0;

  const updateData = (newData) => {
    const payload = {
      ...data,
      [keyType]: {
        ...data[keyType],
        [keyFilter]: `::~${newData}mi`,
      },
    };
    setData({ type: 'set', payload });
    if (data?.location?.location?.length || data?.location?.radius?.length) {
      setRefresh((prevState) => prevState + 1);
    }
  };

  const handleInputChange = (e) => {
    const miles = e.currentTarget.value;
    setCurrentStepIndex(miles);
  };

  const handleInputChangeSlider = (e) => {
    const miles = e.currentTarget.value;
    setCurrentStepIndex(miles);
    updateData(miles);
  };

  const handleKeyPress = (event) => {
    if (
      event.key === 'Enter' &&
      radiusValue !== undefined &&
      radiusValue !== null &&
      radiusValue !== '' &&
      currentStepIndex &&
      currentStepIndex !== radiusValue
    ) {
      updateData(currentStepIndex || 0);
      setCurrentStepIndex(0);
    }
  };

  const onHandleClear = () => {
    updateData(0);
    setCurrentStepIndex(0);
  };

  useEffect(() => {
    onEnter();
  }, [refresh]);

  return (
    <div className="mb-2 mt-3">
      <div className="mt-2 mb-0 text-capitalize font-weight-semi-bold fs-7 d-flex align-items-center">
        <span>
          {label} <span className="text-lowercase">(mi)</span>
        </span>
        <TooltipComponent title="Optionally set radius (mi) from the city. Note: Leaving at '0' will disable this feature.">
          <a
            href=""
            className="text-muted ml-1 position-relative"
            style={{ top: 3 }}
          >
            <MaterialIcon icon="help" filled />
          </a>
        </TooltipComponent>
      </div>

      {showSlider && (
        <div className="position-relative">
          <input
            onInput={handleInputChangeSlider}
            type="range"
            min="0"
            value={currentStepIndex}
            max="100"
            step="25"
            list="tick-list"
            className="w-100 form-range text-secondary"
          />
          <datalist id="tick-list">
            <option>0</option>
            <option>25</option>
            <option>50</option>
            <option>75</option>
            <option>100</option>
          </datalist>
          <div className="ticks">
            <span className="tick-text">0</span>
            <span className="tick-text">25</span>
            <span className="tick-text">50</span>
            <span className="tick-text">75</span>
            <span className="tick-text">100+</span>
          </div>
          <div
            className="bg-primary position-absolute"
            style={{
              width: currentStepIndex + '%',
              height: 7,
              borderRadius: 8,
              top: 8,
            }}
          />
        </div>
      )}
      {isCustomInput && (
        <input
          type="number"
          min="0"
          value={currentStepIndex}
          onChange={handleInputChange}
          className="form-control mt-2"
          placeholder="Enter radius in miles"
          onKeyPress={handleKeyPress}
        />
      )}

      {data &&
        data[keyType][keyFilter] &&
        radiusValue &&
        Number(radiusValue) > 0 && (
          <ListItems
            items={[data[keyType][keyFilter]?.match(/~([\d.]+)mi/)?.[0]]}
            deleteItem={onHandleClear}
            onClear={onHandleClear}
          />
        )}
    </div>
  );
};

export default InputRange;
