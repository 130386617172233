import qs from 'qs';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import envService from '../services/env.service';
import useTenantTheme from '../hooks/useTenantTheme';
import { usePagesContext } from '../contexts/pagesContext';
import { getIdfPublicToken } from '../layouts/constants';

import { useTenantContext } from '../contexts/TenantContext';
import PortalModuleNavigation from '../components/ClientPortal/PortalModuleNavigation';
import HomeContent from '../components/ClientPortal/home/HomeContent';

const ClientDashboard = () => {
  const history = useHistory();

  const { pageContext } = usePagesContext();
  const { setTenant } = useTenantContext();

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const hasUrlToken = urlParams.get('token');

      if (hasUrlToken) {
        if (!pageContext?.ClientPortalData) {
          try {
            const query = qs.parse(location.search, {
              ignoreQueryPrefix: true,
            });
            const hasQueryKeys = Object.keys(query).length > 0;
            const storageToken =
              getIdfPublicToken()?.access_token || query.token;

            // no query keys, redirect to login
            if (!hasQueryKeys || !query.token) {
              history.push('/clientportal/login');
            }

            const token = {
              access_token: storageToken,
            };
            sessionStorage.setItem('idftoken-public', JSON.stringify(token));
            const data = await envService.getEnv();
            setTenant(data);
          } catch (error) {
            history.push('/clientportal/login');
          }
        }
      }
    })();
  }, []);

  useTenantTheme();

  return (
    <div className="custom-layout">
      <PortalModuleNavigation />

      <div className="custom-layout-content bg-white pt-5-xl">
        <HomeContent />
      </div>
    </div>
  );
};

export default ClientDashboard;
