import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import RightSlidingDrawer from '../../modal/RightSlidingDrawer';
import { Actions } from '../fields.constants';
import ModalTitleWithSection from '../ModalTitleWithSection';
import { Form } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import Asterick from '../../commons/Asterick';
import InputValidation from '../../commons/InputValidation';
import ButtonIcon from '../../commons/ButtonIcon';
import { CHOOSE_IMAGE_FILE } from '../../../utils/constants';
import DragDropUploadFile from '../../commons/DragDropUploadFile';
import DropdownSelect from '../../DropdownSelect';
import {
  ActionIcons,
  CHECKLIST_ACTIONS,
} from '../../../utils/checklist.constants';
import checklistService from '../../../services/checklist.service';
import { createBlobObject, youTubeWistiaRegex } from '../../../utils/Utils';
import filesService from '../../../services/files.service';

const DrawerHeader = ({ mode, section }) => (
  <h3 className="mb-0">
    <ModalTitleWithSection
      title={
        mode === Actions.Edit ? 'Edit Checklist Item' : 'Add Checklist Item'
      }
      section={section?.toLowerCase() === 'deals' ? 'Pipelines' : section}
    />
  </h3>
);

const DrawerBody = ({
  openModal,
  editedField,
  setEditedField,
  handleValueChange,
  errors,
  register,
  handleSubmit,
  handleSave,
}) => {
  const onRemoveFile = () => {
    setEditedField({ ...editedField, attachment: null, fileId: null });
  };

  const onLoadFile = (e) => {
    const loadedFile = e.target.files[0];
    setEditedField({
      ...editedField,
      attachment: loadedFile
        ? { name: loadedFile?.name, file: loadedFile, isLoaded: true }
        : null,
    });
  };
  return (
    <div className="py-3">
      <Form onSubmit={handleSubmit(handleSave)}>
        <Row className="align-items-center mt-2">
          <Col md={3}>
            <h5>
              Action Name <Asterick />{' '}
            </h5>
          </Col>
          <Col md={9}>
            <InputValidation
              name="title"
              type="input"
              autoFocus={true}
              placeholder="Action Name"
              value={editedField?.title || ''}
              validationConfig={{
                validateSpaces: true,
                required: true,
                inline: true,
                onChange: handleValueChange,
              }}
              errors={errors}
              register={register}
            />
          </Col>
        </Row>{' '}
        <Row className={`align-items-center mt-3`}>
          <Col md={3}>
            <h5 className="mb-0 text-nowrap">Action Type</h5>
          </Col>
          <Col md={9}>
            <DropdownSelect
              data={CHECKLIST_ACTIONS}
              onHandleSelect={(item) => {
                setEditedField({
                  ...editedField,
                  action: item,
                });
              }}
              select={editedField?.action?.title || CHECKLIST_ACTIONS[0]?.title}
              selectIcon={
                editedField?.action?.icon || CHECKLIST_ACTIONS[0]?.icon
              }
              typeMenu="custom"
              placeholder="Action Type"
              customClasses={'w-100 overflow-y-auto max-h-300'}
            />
          </Col>
        </Row>
        {editedField?.action?.name === 'watch' ? (
          <Row className="align-items-start mt-3">
            <Col md={3} className="align-items-start">
              <h5 className="mb-0 mt-3">
                Video URL <Asterick />{' '}
              </h5>
            </Col>
            <Col md={9}>
              <InputValidation
                name="videoURL"
                fieldType="url"
                type="input"
                autoFocus={true}
                placeholder="Video URL"
                value={editedField?.videoURL || ''}
                validationConfig={{
                  required: true,
                  inline: false,
                  validateSpaces: true,
                  onChange: handleValueChange,
                  pattern: youTubeWistiaRegex,
                }}
                errorMessage={'Invalid URL'}
                errors={errors}
                register={register}
              />
              <p className="font-size-sm2 pt-1">
                Only Wistia or YouTube URL Allowed
              </p>
            </Col>
          </Row>
        ) : (
          <Row className="align-items-start mt-3">
            <Col md={3} className="align-items-start">
              <h5 className="mb-0">Attachment</h5>
            </Col>
            <Col md={9}>
              <DragDropUploadFile
                file={editedField?.attachment}
                displayFormats={[]}
                setFile={(loadedFile) => {
                  setEditedField({
                    ...editedField,
                    attachment: loadedFile
                      ? { name: loadedFile?.name, file: loadedFile }
                      : null,
                  });
                }}
                name="checklistFile"
                onLoadFile={onLoadFile}
                chooseFileText={CHOOSE_IMAGE_FILE}
                onRemoveFile={onRemoveFile}
                isLoading={false}
              />
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

const DrawerFooter = ({
  loader,
  handleSubmit,
  handleSave,
  handleClose,
  mode,
}) => {
  return (
    <div className="d-flex align-items-center justify-content-end gap-2">
      <ButtonIcon color="white" onclick={handleClose} label="Cancel" />
      <ButtonIcon
        color="primary"
        loading={loader}
        onclick={handleSubmit(handleSave)}
        label={mode === 'ADD' ? 'Save' : 'Update'}
      />
    </div>
  );
};

const AddEditChecklistItem = ({
  field,
  openModal,
  setOpenModal,
  setSuccessMessage,
  setErrorMessage,
  getChecklistItems,
  items = [],
  mode = Actions.Add,
  section,
  options,
}) => {
  const defaultFieldObject = {
    title: '',
    document: null,
    mandatory: true,
    action: CHECKLIST_ACTIONS[0],
    attachment: null,
    videoURL: null,
    position: null,
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFieldObject,
  });
  const [editedField, setEditedField] = useState(defaultFieldObject);
  const [loading, setLoading] = useState(false);
  const [isItemLoading, setIsItemLoading] = useState(false);

  const getChecklistFile = async () => {
    // if we have file id then get its info and load it
    if (field.fileId) {
      const { data: fileData } = await filesService.getFile(field?.fileId);
      return fileData?.filename_download;
    }
    return null;
  };
  const getChecklistItemData = async () => {
    setIsItemLoading(true);
    try {
      if (field.checklistMode === Actions.Edit) {
        const fileName = await getChecklistFile();
        const data = field;
        const itemsEdited = {
          ...data,
          action: {
            title: data?.action === 'watch' ? 'Video' : data?.action,
            name: data?.action,
            value: data.action,
            icon: ActionIcons[data?.action],
          },
          videoURL: data?.videoURL,
          attachment: field.fileId ? { name: fileName, isLoaded: true } : null,
        };
        setEditedField(itemsEdited);
        setValue('title', itemsEdited.title);
        setValue('videoURL', itemsEdited?.videoURL);
      } else {
        const fileName = await getChecklistFile();
        const addFieldObject = {
          ...defaultFieldObject,
          ...field,
          attachment: field.fileId ? { name: fileName, isLoaded: true } : null,
        };
        setEditedField(addFieldObject);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsItemLoading(false);
    }
  };

  useEffect(() => {
    if (openModal) {
      getChecklistItemData();
    }
  }, [openModal]);
  const handleValueChange = (e) => {
    const { name, value } = e.target;
    setEditedField({ ...editedField, [name]: value });
    setValue(name, value);
  };

  const handleSave = async () => {
    setLoading(true);
    let lastIndex = items ? items?.length - 1 : -1;
    lastIndex = lastIndex + 1;
    try {
      const newField = { ...editedField };

      let fileData = {};
      if (!newField?.fileId && newField?.attachment?.name) {
        const formBlob = await createBlobObject(newField?.attachment?.file);
        const form = new FormData();
        form.append('file', formBlob, newField?.attachment?.name);
        fileData = await filesService.uploadFile(form);
      }

      const fieldsData = {
        title: newField.title,
        checklistId: editedField?.checklistId,
        fileId: fileData?.data?.id || newField.fileId || null,
        mandatory: newField.mandatory,
        type: field?.type || editedField?.type,
        position: mode === Actions.Add ? lastIndex + 1 : editedField?.position,
        action:
          typeof newField.action === 'string'
            ? newField.action.toLowerCase()
            : newField.action?.name?.toLowerCase(),
        feId: newField.feId || crypto.randomUUID(),
        videoURL: newField?.videoURL,
      };
      // scenarios:
      // Checklist already created: edit checklist/add checklist item we should save it
      // Checklist not created: edit/add we should just maintain an array
      let newFieldItem = null;
      if (editedField.checklistId) {
        if (newField.checklistItemId) {
          const { data } = await checklistService.updateCheckListItem(
            newField.checklistItemId,
            fieldsData
          );
          newFieldItem = data;
        } else {
          const { data } = await checklistService.createCheckListItem(
            fieldsData
          );
          newFieldItem = data;
        }
        getChecklistItems(newFieldItem?.checklistItemId, newFieldItem, mode);
        setSuccessMessage(
          `Checklist item ${
            mode === Actions.Add ? 'created' : 'updated'
          } successfully.`
        );
      } else {
        getChecklistItems(null, fieldsData, mode);
        setSuccessMessage(
          `Checklist item ${
            mode === Actions.Add ? 'added' : 'updated'
          } successfully.`
        );
      }
    } catch (error) {
      setErrorMessage(error);
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  const handleClose = () => {
    setEditedField(defaultFieldObject);
    reset(defaultFieldObject);
    setOpenModal(false);
  };

  return (
    <RightSlidingDrawer
      open={openModal}
      withCard={true}
      isLoading={isItemLoading}
      toggleDrawer={() => {
        setOpenModal(false);
      }}
      header={<DrawerHeader mode={mode} section={section} />}
      body={
        <DrawerBody
          editedField={editedField}
          setEditedField={setEditedField}
          mode={mode}
          openModal={openModal}
          handleSubmit={handleSubmit}
          options={options}
          errors={errors}
          register={register}
          handleSave={handleSave}
          handleValueChange={handleValueChange}
        />
      }
      footer={
        <DrawerFooter
          editedField={editedField}
          setOpenModal={setOpenModal}
          loader={loading}
          mode={mode}
          handleSubmit={handleSubmit}
          handleSave={handleSave}
          handleClose={handleClose}
        />
      }
      containerWidth={550}
    />
  );
};

export default AddEditChecklistItem;
