import React, { useState, useEffect } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Col } from 'react-bootstrap';

import Card from './Cards';
import Skeleton from 'react-loading-skeleton';

const Columns = ({
  id,
  title,
  color,
  onGetDeals,
  setNotification,
  loading,
  onClick,
  value,
  onAddDeal,
  pickedItem,
}) => {
  const { items } = value;
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (load) setLoad(false);
  }, [value]);

  const handleAddDeal = (e) => {
    e?.preventDefault();
    onAddDeal();
  };

  const isPickedItemInColumn = items?.some(
    (item) => `id-${item.id}-name-${item.name}` === pickedItem?.draggableId
  );

  const BoardLoader = ({ count }) => {
    const [loaderCount] = useState(Array(count).fill(0));
    const ColumnLoader = () => {
      return (
        <div className="px-2 mb-4">
          <Skeleton count={3} height={7} className="my-2_1 d-block w-100" />
        </div>
      );
    };
    return (
      <div>
        {loaderCount.map((_, index) => (
          <ColumnLoader key={index} />
        ))}
      </div>
    );
  };

  return (
    <Col
      className="p-0 w-100 rounded deal-col pipelines-board-col"
      style={{ paddingBottom: 100 }}
    >
      <div className="h-100">
        {value.loading ? (
          <BoardLoader count={1} />
        ) : (
          <Droppable droppableId={title}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={`column-deal position-relative overflow-x-hidden h-100 ${
                  snapshot.isDraggingOver
                    ? 'rounded bg-primary-soft overflow-x-hidden border-dashed-gray'
                    : ''
                }`}
              >
                {snapshot.isDraggingOver &&
                (!isPickedItemInColumn || items?.length === 1) ? (
                  <div className="position-absolute w-100 h-100 overflow-x-hidden d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column align-items-center text-center">
                      <p className="text-muted fs-7 mb-0">
                        Drag and Drop here to move to
                      </p>
                      <p className="mb-0 font-weight-bold">{title}</p>
                    </div>
                  </div>
                ) : null}
                {items?.length === 0 ? (
                  <div
                    className={`d-flex align-items-center justify-content-center py-6 ${
                      snapshot.isDraggingOver && !isPickedItemInColumn
                        ? 'opacity-0'
                        : ''
                    }`}
                  >
                    <h5 className="text-muted py-6 font-weight-medium">
                      This stage is empty
                    </h5>
                  </div>
                ) : (
                  items?.map((deal, index) => (
                    <Card
                      key={deal.id}
                      tenant_deal_stage_id={deal.tenant_deal_stage_id}
                      deal={deal}
                      index={index}
                      onGetDeals={onGetDeals}
                      setNotification={setNotification}
                      loading={loading}
                      onAddDeal={handleAddDeal}
                      containerClass={
                        snapshot.isDraggingOver && !isPickedItemInColumn
                          ? 'opacity-0'
                          : ''
                      }
                    />
                  ))
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </div>
    </Col>
  );
};

export default Columns;
