import AlertWrapper from '../../Alert/AlertWrapper';
import Alert from '../../Alert/Alert';
import React, { useEffect, useState } from 'react';
import {
  EngagementReportConfig,
  MerchantReportV2Sections,
  ReportTileStyles,
  ReportTypes,
} from '../../reports/reports.constants';
import useWidgetsLibrary from '../../reportbuilder/widgets/useWidgetsLibrary';
import {
  APWData,
  ARRWData,
  FBCOWData,
  FBCWData,
  OVWData,
  RecommendationsDidYouKnowData,
  RefundAndChargebacksAmountProcessedData,
  RefundAndChargebacksOverviewData,
  WidgetTypes,
} from '../../reportbuilder/constants/widgetsConstants';
import { ListGroup } from 'reactstrap';
import WidgetWithActionButtons from '../../reportbuilder/widgets/WidgetWithActionButtons';
import NoDataFound from '../../commons/NoDataFound';
import { NO_REPORTS_AVAILABLE } from '../../../utils/constants';
import useIsTenant from '../../../hooks/useIsTenant';
import {
  getCycleDate,
  getReportName,
  MerchantReportAggregateData,
  MerchantReportAggregateSingleData,
} from '../../reports/reports.helper.functions';
import { useProfileContext } from '../../../contexts/profileContext';
import useHash from '../../../hooks/useHash';
import ReportAction from '../../reports/ReportAction';
import ReportPDFWrapper from '../../reportbuilder/ReportPDFWrapper';
import ReportCover from '../../reports/ReportCover';
import ReportPages from '../../reportbuilder/ReportPages';
import _ from 'lodash';
import ReportCoverThankYou from '../../reports/ReportCoverThankYou';
import reportService from '../../../services/report.service';
import {
  extractReportId,
  getNAICSWithIndustry,
  getNaicsWithTitle,
} from '../../../utils/Utils';
import moment from 'moment';
import OrganizationService from '../../../services/organization.service';
import ReportDropdownItem from '../../reports/ReportDropdownItem';
import ReportBlocksSkeleton from '../../loaders/ReportBlocksSkeleton';
import useGenerateMerchantServicesReportModal from '../../../hooks/reports/modals/useGenerateMerchantServicesReportModal';
import useFetchSubOrganizations from '../../../hooks/reports/useFetchSubOrganizations';
import { TAX_ID_KEY } from '../../prospecting/v2/constants';

const DOWNLOAD_OPTIONS = [
  {
    id: 1,
    icon: 'picture_as_pdf',
    key: 'downloadAsPdf',
    name: 'PDF Download',
  },
];

const PageConfig = EngagementReportConfig[ReportTypes.MerchantV2].PageConfig;
const MerchantReportV2SectionKeys =
  EngagementReportConfig[ReportTypes.MerchantV2].SectionKeys;

const MerchantReportV2 = ({
  organization,
  readOnly,
  selectedTenant,
  currentTab,
  getProfileInfo,
}) => {
  const { hash, updateHash } = useHash();
  const { profileInfo } = useProfileContext();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [report, setReport] = useState({});
  const [selectedRpt, setSelectedRpt] = useState({});
  const [selectedIndividualRpt, setSelectedIndividualRpt] = useState({});
  const [rptGenerated, setRptGenerated] = useState(false);
  const [startDownload, setStartDownload] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const getDefaultReportPages = () => {
    return _.cloneDeep(PageConfig);
  };
  const { isExcelBank, isComericaBank } = useIsTenant();
  const excelBankMode = isExcelBank;
  const [loadingPastReports, setLoadingPastReports] = useState(false);
  const [pastReports, setPastReports] = useState([]);
  const [selectedSubOrganization, setSelectedSubOrganization] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const MapReportDropdownItem = (rpt) => {
    const rptObject = rpt.manualInput;
    const start = moment(
      rptObject?.aggregate?.monthRange?.start?.split('T')[0]
    ).format('MMMM YYYY');
    const end = moment(
      rptObject?.aggregate?.monthRange?.end?.split('T')[0]
    ).format('MMMM YYYY');
    const prettyDate = `${start} - ${end}`;
    return {
      key: rpt.reportId,
      reportId: rpt.reportId,
      customElement: (
        <ReportDropdownItem item={rptObject} prettyDate={prettyDate} />
      ),
      name: getReportName(rptObject),
      isManual: !!rpt.manualInput,
      createdById: rpt.createdById,
      createdAt: rpt.createdAt,
      updatedAt: rpt.updatedAt,
      reportDate: rptObject?.aggregate?.monthRange?.end,
      prettyDate,
      isAuto: rptObject?.isAuto,
      aggregate: MerchantReportAggregateData(rptObject?.coreData),
      coreDataList:
        rptObject?.coreData?.map((mp) => ({
          ...MerchantReportAggregateSingleData(mp),
          key: mp.feId,
          reportId: mp.feId,
          prettyDate: moment(mp.Month?.split('T')[0]).format('MMMM YYYY'),
        })) || [],
    };
  };
  const getDefaultWidgets = (reportObject = {}) => {
    return {
      [MerchantReportV2Sections.CardPaymentAcceptanceOverview]: [
        {
          id: 1,
          widgetConfig: {
            ...APWData,
            data: reportObject,
          },
          type: WidgetTypes.APW,
        },
        {
          id: 2,
          widgetConfig: {
            ...OVWData,
            data: reportObject,
          },
          type: WidgetTypes.OVW,
        },
      ],
      [MerchantReportV2Sections.FeeBreakdown]: [
        {
          id: 1,
          widgetConfig: { ...FBCWData, reportObject },
          type: WidgetTypes.FBCW,
        },
        {
          id: 2,
          widgetConfig: FBCOWData,
          type: WidgetTypes.OVW,
        },
      ],
      [MerchantReportV2Sections.RefundsAndChargebacks]: [
        {
          id: 1,
          widgetConfig: {
            ...RefundAndChargebacksAmountProcessedData,
            data: reportObject,
          },
          type: WidgetTypes.APW,
        },
        {
          id: 2,
          widgetConfig: RefundAndChargebacksOverviewData,
          type: WidgetTypes.OVW,
        },
      ],
      [MerchantReportV2Sections.Recommendations]: [
        {
          id: 1,
          widgetConfig: RecommendationsDidYouKnowData,
          type: WidgetTypes.OVW,
        },
      ],
      [MerchantReportV2Sections.YourDedicatedTeam]: [
        {
          id: 1,
          widgetConfig: { ...reportObject },
          type: WidgetTypes.TCW,
        },
        {
          id: 2,
          widgetConfig: ARRWData,
          type: WidgetTypes.ARRW,
        },
      ],
    };
  };

  const [widgets, setWidgets] = useState(getDefaultWidgets());
  const [reportPages, setReportPages] = useState(getDefaultReportPages());
  const {
    setShowModal: setShowWidgetsLibrary,
    WidgetsLibraryModal,
    setSelectedWidget,
  } = useWidgetsLibrary();
  const onAddWidget = async (e, newWidget, oldWidget) => {};
  const onDeleteWidget = async (oldWidget) => {};
  const {
    subOrganizations,
    loading: subOrganizationsLoading,
    search: searchSubOrganizations,
    setSearch: setSearchSubOrganizations,
  } = useFetchSubOrganizations(organization);
  const getUpdatedWidgetConfig = (wg) => {
    const updatedWidgetConfig = {
      ...wg.widgetConfig,
      items: wg.widgetConfig?.items,
    };
    return updatedWidgetConfig;
  };

  const HeaderTitle = {
    [WidgetTypes.FBCW]: FBCWData.header,
    [WidgetTypes.APW]: RefundAndChargebacksAmountProcessedData.header,
  };
  const updateDefaultWidgetsWithMapping = (
    defaultOrSavedWidgets,
    reportObjectWithMapping,
    aggregate
  ) => {
    const updatedWidgets = {};
    delete reportObjectWithMapping.widgets;
    delete reportObjectWithMapping.reportPages;
    for (const key in defaultOrSavedWidgets) {
      updatedWidgets[key] = defaultOrSavedWidgets[key]?.map((wg) => {
        const updatedConfig = getUpdatedWidgetConfig(wg);
        return {
          ...wg,
          widgetConfig: Array.isArray(wg.widgetConfig)
            ? wg.widgetConfig
            : {
                ...wg.widgetConfig,
                header: wg.widgetConfig.header || HeaderTitle[wg.type],
                data: reportObjectWithMapping,
                items: updatedConfig.items,
                aggregate, // default true
              },
        };
      });
    }
    return {
      ...updatedWidgets,
    };
  };

  const hasCoreDataReportCreated = (coreDataReport, oldReports) => {
    // since we are currently looking by month/year so...
    const analysisDate = coreDataReport.Month;
    const foundReport = oldReports.find((rpt) => {
      const rptDate = rpt.reportDate;
      return analysisDate === rptDate;
    });
    return foundReport !== undefined;
  };

  const createManualReportFromCoreData = (coreDataObject, coreDataReports) => {
    const aggregate = MerchantReportAggregateData(coreDataReports);

    const reportMapped = {
      companyName: aggregate.Customer_Name || organization.name, // 0
      reportDate: aggregate.monthRange.end, // 4
      aggregate,
      coreData: coreDataReports.map((dt) => ({
        ...dt,
        feId: crypto.randomUUID(),
      })),
    };

    const defaultWidgets = getDefaultWidgets(reportMapped);
    const defaultPages = getDefaultReportPages();

    return {
      name: reportMapped.companyName,
      date: reportMapped.reportDate,
      type: ReportTypes.Merchant,
      manualInput: {
        ...reportMapped,
        widgets: defaultWidgets,
        reportPages: defaultPages,
        ...getNAICSWithIndustry(organization),
        isAuto: true, // track whether this was generated by core
      },
    };
  };

  // comerica case:
  // load past reports
  // check if org has tax id from fields api then call
  // core data by taxid => then normal create report etc and select first one.
  // normal case:
  // just load reports from identifee and load first one
  const comericaFlowOrNormalFlow = async () => {
    if (isComericaBank) {
      setLoadingPastReports(true);
      let oldReports = [];
      let reports = [];
      try {
        const reportParams = {
          limit: 100,
          page: 1,
          type: ReportTypes.Merchant,
        };

        const serviceMethod = selectedSubOrganization?.subOrganizationId
          ? OrganizationService.getSubOrganizationReports(
              selectedSubOrganization.subOrganizationId,
              reportParams
            )
          : OrganizationService.getReports(organization.id, reportParams);

        const { data } = await serviceMethod;
        oldReports = data.data;

        reports = oldReports
          .filter((rpt) => !!rpt.input || !!rpt.manualInput)
          .map(MapReportDropdownItem)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        const fieldsServiceMethod = selectedSubOrganization?.subOrganizationId
          ? OrganizationService.getFieldBySubOrganization(
              selectedSubOrganization.subOrganizationId,
              {}
            )
          : OrganizationService.getFieldByOrganization(organization.id, {});

        const { data: fieldsData } = await fieldsServiceMethod;
        const taxIdField = fieldsData.find(
          (tx) => tx?.field?.key?.toLowerCase() === TAX_ID_KEY
        );
        if (taxIdField?.value) {
          const coreDataReports = await reportService.getReportsAggregateData({
            type: ReportTypes.Merchant,
            taxId: taxIdField.value,
          });
          if (coreDataReports?.length) {
            const latestReport = coreDataReports[0]; // pick the first one as its latest
            if (!hasCoreDataReportCreated(latestReport, reports)) {
              // assuming the data is coming sorted.
              const mappedData = createManualReportFromCoreData(
                latestReport,
                coreDataReports
              );
              const mappedReport = mappedData;

              // create report in identifee platform
              const orgIdOrSubOrgId = selectedSubOrganization?.subOrganizationId
                ? selectedSubOrganization?.subOrganizationId
                : organization.id;
              const createReportMethod =
                selectedSubOrganization?.subOrganizationId
                  ? OrganizationService.createManualReportSubOrg(
                      orgIdOrSubOrgId,
                      mappedData
                    )
                  : OrganizationService.createManualReport(
                      orgIdOrSubOrgId,
                      mappedData
                    );
              const reportCreated = await createReportMethod;

              const reportDropdownItem = MapReportDropdownItem(reportCreated);
              reports = [...pastReports, reportDropdownItem];
              setLoadingPastReports(false);
              setSelectedRpt(reportDropdownItem);
              setReport(mappedReport.manualInput);
              setReportPages(mappedReport.manualInput.reportPages);
              setWidgets(mappedReport.manualInput.widgets);
              setRptGenerated(false);

              setPastReports(
                reports.sort(
                  (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                )
              );
            } else {
              setLoadingPastReports(false);
              setPastReports(reports);
            }
            const firstReport = reports.length
              ? hash?.includes('/new')
                ? {}
                : reports[0]
              : {};
            // if we have the reportId then get it
            if (firstReport?.key || hash?.includes('/id')) {
              // we got the id from url now we want to find that in our reports array and get full object
              const urlHashId = extractReportId(hash); // get the last index which has report id
              const reportFound = reports.find(
                (rp) => rp.reportId === urlHashId
              );
              if (reportFound) {
                setSelectedRpt(reportFound);
              } else {
                setSelectedRpt(firstReport);
              }
            } else {
              setSelectedRpt({});
              setRptGenerated(false);
            }
          } else {
            setLoadingPastReports(false);
            setPastReports(reports);
            selectFirstAvailableReport(reports);
          }
        } else {
          setLoadingPastReports(false);
          setPastReports(reports);
          selectFirstAvailableReport(reports);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingPastReports(false);
      }
    } else {
      loadReportsByOrgOrSubOrg();
    }
  };

  const selectFirstAvailableReport = (loadedReports = []) => {
    const firstReport = loadedReports.length
      ? hash?.includes('/new')
        ? {}
        : loadedReports[0]
      : {};
    // if we have the reportId then get it
    if (firstReport?.key || hash?.includes('/id')) {
      // we got the id from url now we want to find that in our reports array and get full object
      const urlHashId = extractReportId(hash); // get the last index which has report id
      const reportFound = loadedReports.find((rp) => rp.reportId === urlHashId);
      if (reportFound) {
        setSelectedRpt(reportFound);
      } else {
        setSelectedRpt(firstReport);
      }
    } else {
      setSelectedRpt({});
      setRptGenerated(false);
    }
  };
  // this will get reports by org and sub orgs from identifee
  const loadReportsByOrgOrSubOrg = async (dontSelect) => {
    setLoadingPastReports(true);
    let oldReports = [];
    try {
      const reportParams = {
        limit: 100,
        page: 1,
        type: ReportTypes.Merchant,
      };

      const serviceMethod = selectedSubOrganization
        ? OrganizationService.getSubOrganizationReports(
            selectedSubOrganization.subOrganizationId,
            reportParams
          )
        : OrganizationService.getReports(organization.id, reportParams);

      const { data } = await serviceMethod;
      oldReports = data.data;

      const reports = oldReports
        .filter((rpt) => !!rpt.input || !!rpt.manualInput)
        .map(MapReportDropdownItem)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setPastReports(reports);
      if (!dontSelect) {
        selectFirstAvailableReport(reports);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingPastReports(false);
    }
  };

  const getReportById = async (selectedReport) => {
    if (!selectedReport?.key) {
      return;
    }
    setLoadingReport(true);
    try {
      const defaultReportPages = getDefaultReportPages();
      const data = await reportService.getReport(selectedReport.key);
      setRptGenerated(true);
      const { manualInput } = data;
      const rptObject = manualInput;
      if (Object.hasOwn(rptObject, 'widgets')) {
        const savedWidgets = updateDefaultWidgetsWithMapping(
          rptObject.widgets,
          { ...rptObject },
          true
        );
        setWidgets(savedWidgets);
      } else {
        const defWidgets = getDefaultWidgets(selectedReport);
        setWidgets(defWidgets);
      }
      // if we have .reportPages saved in report then load those otherwise load default ones to cater old reports too
      if (Object.hasOwn(rptObject, 'reportPages')) {
        setReportPages(rptObject.reportPages);
      } else {
        setReportPages(defaultReportPages);
      }

      const newReport = {
        ...rptObject,
        id: selectedReport.key,
        reportId: selectedReport.key,
        valueN: getNaicsWithTitle(rptObject, organization),
        reportDate: getCycleDate(rptObject.reportDate),
      };
      setSelectedRpt(MapReportDropdownItem(data));
      setReport(newReport);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingReport(false);
    }
  };

  useEffect(() => {
    if (organization?.id || selectedSubOrganization?.id) {
      comericaFlowOrNormalFlow();
    }
  }, [organization?.id, selectedSubOrganization, selectedOrganization]);

  useEffect(() => {
    if (selectedRpt?.key || hash?.includes('/id')) {
      setSelectedIndividualRpt({});
      getReportById(selectedRpt);
    }
  }, [selectedRpt?.key]);

  useEffect(() => {
    if (selectedIndividualRpt?.key) {
      const savedWidgets = updateDefaultWidgetsWithMapping(
        report.widgets,
        {
          ...selectedIndividualRpt,
        },
        false
      );
      setWidgets(savedWidgets);
    }
  }, [selectedIndividualRpt?.key]);

  const {
    setShowModal,
    setSelectedReport,
    setModalReportPages,
    setModalWidgets,
    GenerateMerchantServicesReportModal,
  } = useGenerateMerchantServicesReportModal(
    report,
    organization,
    reportPages,
    widgets,
    (reportObject, widgets, reportPages, contacts, updatedIndividualReport) => {
      setSuccessMessage('Report Updated.');
      setReportPages(reportPages);
      const savedWidgets = updateDefaultWidgetsWithMapping(
        widgets,
        {
          ...updatedIndividualReport,
        },
        false
      );
      setWidgets(savedWidgets);
      setSelectedIndividualRpt(updatedIndividualReport);
      setSelectedRpt({
        ...selectedRpt,
        aggregate: reportObject.manualInput.aggregate,
        coreDataList:
          reportObject.manualInput.coreData?.map((mp) => ({
            ...MerchantReportAggregateSingleData(mp),
            key: mp.feId,
            reportId: mp.feId,
            prettyDate: moment(mp.Month?.split('T')[0]).format('MMMM YYYY'),
          })) || [],
      });
    },
    getProfileInfo
  );

  useEffect(() => {
    if (startDownload) {
      document.querySelectorAll(
        '.companydetail-tab-engagement'
      )[0].style.overflowY = 'hidden';
    } else {
      document.querySelectorAll(
        '.companydetail-tab-engagement'
      )[0].style.overflowY = 'auto';
    }
  }, [startDownload]);
  return (
    <>
      <WidgetsLibraryModal />
      <GenerateMerchantServicesReportModal />
      <AlertWrapper className="alert-position">
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
          time={8000}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
          time={8000}
        />
      </AlertWrapper>
      {pastReports.length === 0 &&
        !loadingReport &&
        !loadingPastReports &&
        !subOrganizationsLoading && (
          <NoDataFound
            icon={ReportTileStyles[ReportTypes.Merchant].icon}
            iconRounded={true}
            containerStyle="my-6 py-6 text-gray-search"
            title={NO_REPORTS_AVAILABLE}
            titleStyle="text-gray-search font-normal font-size-sm2"
          />
        )}
      <ReportAction
        readOnly={readOnly}
        pastReports={pastReports}
        handleManualReport={() => {}}
        rptGenerated={rptGenerated}
        handleEditReport={() => {}}
        organization={organization}
        setSuccessMessage={setSuccessMessage}
        subOrganizationConfig={{
          loading: subOrganizationsLoading,
          list: subOrganizations,
          search: searchSubOrganizations,
          setSearch: setSearchSubOrganizations,
          selectedParent: selectedOrganization,
          selected: selectedSubOrganization,
          refreshReports: comericaFlowOrNormalFlow,
          refetchReports: loadReportsByOrgOrSubOrg,
          onChangeParent: () => {
            setSelectedSubOrganization(null);
            setSelectedOrganization(organization);
          },
          onChange: (sub) => {
            setSelectedOrganization(null);
            setSelectedSubOrganization(sub);
          },
        }}
        loadingReport={loadingReport}
        loadingPastReports={loadingPastReports}
        selectedRpt={selectedRpt}
        setSelectedRpt={(newReport) => {
          updateHash('');
          setSelectedRpt(newReport);
        }}
        selectedIndividualRpt={selectedIndividualRpt}
        setSelectedIndividualRpt={(newReport) => {
          updateHash('');
          setSelectedIndividualRpt(newReport);
        }}
        handleClearIndividualReport={() => {
          setSelectedIndividualRpt({});
          const savedWidgets = updateDefaultWidgetsWithMapping(
            report.widgets,
            { ...report, aggregate: selectedRpt?.aggregate },
            true
          );
          setWidgets(savedWidgets);
        }}
        handleEditIndividualReport={(newReport) => {
          const savedWidgets = updateDefaultWidgetsWithMapping(
            report.widgets,
            { ...newReport },
            false
          );
          setWidgets(savedWidgets);
          setModalReportPages(report.reportPages);
          setModalWidgets(savedWidgets);
          setSelectedReport({
            main: report,
            individual: newReport,
          });
          setShowModal(true);
        }}
        profileInfo={profileInfo}
        report={report}
        startDownload={startDownload}
        setStartDownload={setStartDownload}
        downloadOptions={DOWNLOAD_OPTIONS}
        reportType={ReportTypes.MerchantV2}
        reportPdfTitle={{
          name: selectedSubOrganization?.name || organization?.name,
          date: selectedIndividualRpt?.reportDate || selectedRpt?.prettyDate,
        }}
        isAggregate={Object.keys(selectedIndividualRpt || {}).length === 0}
      />
      <div className="text-center">
        {startDownload && (
          <ReportPDFWrapper>
            <ReportCover
              name={selectedSubOrganization?.name || organization?.name}
              date={
                selectedIndividualRpt?.reportDate || selectedRpt?.prettyDate
              }
              excelBankMode={excelBankMode}
              selectedTenant={selectedTenant}
              report={report}
              type={ReportTypes.MerchantV2}
              organization={organization}
              isAggregate={
                Object.keys(selectedIndividualRpt || {}).length === 0
              }
            />
            <ReportPages
              pages={widgets}
              report={report}
              reportPages={reportPages}
              sectionKeys={MerchantReportV2SectionKeys}
              pageConfig={PageConfig}
              showIcon={false}
              selectedTenant={selectedTenant}
              reportType={ReportTypes.MerchantV2}
            />
            <ReportCoverThankYou
              name={organization.name}
              date={getCycleDate(new Date().toISOString())}
              excelBankMode={excelBankMode}
              selectedTenant={selectedTenant}
              report={report}
              type={ReportTypes.MerchantV2}
              organization={organization}
            />
          </ReportPDFWrapper>
        )}

        {loadingReport || loadingPastReports ? (
          <ReportBlocksSkeleton />
        ) : (
          <>
            {rptGenerated && (
              <div className={`pb-3 ${readOnly ? 'client-dashboard-rpt' : ''}`}>
                <ListGroup className="list-group-no-gutters mt-0 list-group-flush">
                  {MerchantReportV2SectionKeys.flatMap((key) => {
                    const widgetsForCurrentKey = widgets[key] || [];
                    const pageEnabled = reportPages[key]?.enabled;
                    const widgetsWithoutBlank = widgetsForCurrentKey
                      ?.filter((widget) => widget.type !== WidgetTypes.BLANK)
                      .map((wg) => ({
                        ...wg,
                        id: crypto.randomUUID(),
                        section: key,
                      }));

                    if (widgetsWithoutBlank.length && pageEnabled) {
                      return widgetsWithoutBlank?.map((widget) => (
                        <WidgetWithActionButtons
                          key={widget.id}
                          widget={widget}
                          section={key}
                          onAddWidget={onAddWidget}
                          onDeleteWidget={onDeleteWidget}
                          selectedTenant={selectedTenant}
                          setSelectedWidget={setSelectedWidget}
                          setShowWidgetsLibrary={setShowWidgetsLibrary}
                          actionButtons={{}}
                        />
                      ));
                    }
                    return null;
                  })}
                </ListGroup>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MerchantReportV2;
