import React from 'react';
import PageTitle from '../components/commons/PageTitle';
import PortalNavigationMenu from '../components/ClientPortal/LeftSidebar';
import { Toaster } from 'react-hot-toast';
import useSessionExpiryModal from '../components/modal/SessionExpiryModal';
import useCheckTokenValidityClientPortal from '../hooks/useCheckTokenValidityClientPortal';
import { useClientPortalContext } from '../contexts/clientPortalContext';
import Loading from '../components/Loading';

const ClientDashboardLayout = ({ children }) => {
  const { SessionExpiryModal, setShowModal } = useSessionExpiryModal(true);
  const { organization, contactId } = useClientPortalContext();

  useCheckTokenValidityClientPortal(setShowModal);

  if (!contactId || !organization) {
    return <Loading />;
  }

  return (
    <>
      <PageTitle page={organization?.name} pageModule="" />

      <SessionExpiryModal />

      <div
        className={`client-portal-layout d-flex AppBackground overflow-x-hidden w-100 client-dashboard vh-100 has-navbar-vertical-aside navbar-vertical-aside-show-xl`}
      >
        <PortalNavigationMenu />

        <main className="main">
          <div className="content container-fluid p-0">{children}</div>
        </main>

        <Toaster position="bottom-right" reverseOrder={true} />
      </div>
    </>
  );
};

export default ClientDashboardLayout;
