import React from 'react';
import useTenantTheme from '../../hooks/useTenantTheme';
import { useClientPortalContext } from '../../contexts/clientPortalContext';

const withOrganizationData = (WrappedComponent) => {
  return function WithOrganizationData() {
    const {
      organization,
      organizationId,
      contactId,
      owner,
      selectedMenu,
      setSelectedMenu,
    } = useClientPortalContext();

    useTenantTheme();

    return (
      <WrappedComponent
        organization={organization}
        contactId={contactId}
        organizationId={organizationId}
        owner={owner}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
      />
    );
  };
};

export default withOrganizationData;
