import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import { Card, CardBody } from 'reactstrap';
import MaterialIcon from '../../../commons/MaterialIcon';
import { AFPSource } from '../../constants/widgetsConstants';

const PAYMENT_COMPARISON = [
  {
    header: null,
    items: ['Average Cost', 'Funding', 'Risk of Fraud', 'Reconciliation'],
  },
  {
    header: { icon: 'credit_card', title: 'Virtual Card' },
    items: ['$0.30', 'Next Day', 'Lowest', 'Fastest'],
    bg: 'bg-primary-soft',
  },
  {
    header: { icon: 'account_balance', title: 'ACH' },
    items: ['$0.25', 'Next Day', 'Low', 'Faster'],
  },
  {
    header: { icon: 'price_change', title: 'Instant Payments' },
    items: ['$2.00', 'Instant', 'Low', 'Fastest'],
    bg: 'bg-primary-soft',
  },
  {
    header: { icon: 'checkbook', title: 'Check' },
    items: ['$2.98', '4 Days', 'Highest', 'Manual'],
  },
];

const PaymentComparisonItem = ({ data, whenPrinting }) => {
  return (
    <>
      {data?.header ? (
        <div className="d-flex flex-column flex-fill align-items-center">
          <div className="d-flex align-items-center bg-primary-soft rounded-circle justify-content-center p-2">
            <MaterialIcon
              icon={data.header.icon}
              symbols
              rounded={false}
              clazz="font-size-xl text-primary"
            />
          </div>
          <div
            className={`my-1 text-primary text-center w-100 font-weight-semi-bold ${
              whenPrinting ? 'fs-10' : 'fs-8'
            }`}
          >
            {data.header.title}
          </div>
          <div className="rounded-xl w-100">
            {data.items.map((item) => (
              <p
                key={item}
                className={`${
                  whenPrinting ? 'fs-10 p-1' : 'fs-8 p-2'
                } mb-0 text-center w-100 font-weight-normal ${data.bg}`}
              >
                {item}
              </p>
            ))}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex align-items-center justify-content-center p-1">
            &nbsp;
          </div>
          <p className="my-2 text-primary font-weight-semi-bold fs-8">&nbsp;</p>
          {data.items.map((item) => (
            <p
              key={item}
              className={`${
                whenPrinting ? 'fs-10 py-1' : 'fs-8 py-2'
              } w-100 text-left mb-0 pr-4 font-weight-normal`}
            >
              {item}
            </p>
          ))}
        </div>
      )}
    </>
  );
};

const PaymentComparisonWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <Card className="report-widget">
      <CardBody className="align-items-center d-flex">
        <div className="flex-fill">
          <h5 className="text-left d-flex justify-content-between mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <div className="px-2">
            <div className="d-flex w-100 align-items-center">
              {PAYMENT_COMPARISON.map((mp) => (
                <PaymentComparisonItem
                  data={mp}
                  key={mp.label}
                  whenPrinting={whenPrinting}
                />
              ))}
            </div>
          </div>
          {widgetConfig.source && (
            <div className="mt-2">
              <WidgetSourceBlock text={AFPSource} />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default PaymentComparisonWidget;
