import React, { useState } from 'react';
import { Badge } from 'reactstrap';
import ProfileCardItem from '../ProfileCardItem';
import MaterialIcon from '../../commons/MaterialIcon';
import DeleteConfirmationModal from '../../modal/DeleteConfirmationModal';

const ContactOwnerList = ({
  item,
  handleRemove,
  isPrincipalOwner,
  mainOwner,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const removeItem = () => {
    setOpenModal(true);
  };

  const handleConfirm = async () => {
    try {
      await handleRemove();
      setOpenModal(false);
    } catch (error) {
      console.log('error while delete owner', error);
    }
  };

  const ownerInfo = item?.user;
  const ownerType =
    ownerInfo?.type === 'owner'
      ? 'Secondary'
      : ownerInfo?.type === 'primaryOwner'
      ? 'Primary'
      : 'Follower';

  return (
    <div className="list-group list-group-lg list-group-flush list-group-no-gutters">
      <DeleteConfirmationModal
        showModal={openModal}
        setShowModal={setOpenModal}
        event={handleConfirm}
        heading="Delete Owner"
        description="Are you sure you want to delete this owner?"
      />

      <div
        data-testid={`item-${item?.user_id}`}
        key={`${item?.user_id}-${item?.contact_id}`}
        className="list-group-item py-0"
      >
        <div className="media align-items-center justify-content-between">
          <div className="mr-auto">
            <ProfileCardItem user={ownerInfo} mainOwner={mainOwner} size="xs" />
          </div>
          <div className="ml-auto">
            {ownerType && (
              <Badge
                id={ownerInfo?.id}
                style={{
                  fontSize: '12px',
                  borderRadius: 40,
                  color:
                    ownerType === 'Primary' || item?.user?.type === 'owner'
                      ? 'white'
                      : 'black',
                  backgroundColor: `${
                    ownerType === 'Primary'
                      ? 'green'
                      : item?.user?.type === 'owner'
                      ? 'blue'
                      : '#e6e6e6'
                  }`,
                }}
                className="text-uppercase"
              >
                {ownerType}
              </Badge>
            )}
          </div>

          {handleRemove &&
            isPrincipalOwner &&
            item?.user_id !== mainOwner?.id &&
            ownerInfo?.type === 'owner' && (
              <div
                style={{ width: '10%' }}
                className={'d-flex justify-content-center'}
              >
                <button
                  className="btn btn-icon btn-sm btn-ghost-danger rounded-circle"
                  onClick={removeItem}
                >
                  <MaterialIcon icon="delete" />
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ContactOwnerList;
