import React, { useContext, useEffect, useState } from 'react';
import { Card, FormGroup, ListGroup } from 'react-bootstrap';
import { Badge, Label } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import DropdownLesson from '../../../components/lesson/DropdownLesson';
import courseService from '../../../services/course.service';
import { courseInit } from './courses.constants';
import stringConstants from '../../../utils/stringConstants.json';
import HeaderCardCourses from '../../../components/courses/HeaderCardCourse';
import FormCourses from '../../../components/courses/FormCourses';
import { DRAFT, PUBLISHED, SERVER_ERROR } from '../../../utils/constants';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import Alert from '../../../components/Alert/Alert';
import { useForm } from 'react-hook-form';
import Asterick from '../../../components/commons/Asterick';
import NoDataFound from '../../../components/commons/NoDataFound';
import { CategoriesContext } from '../../../contexts/categoriesContext';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import TooltipComponent from '../../../components/lesson/Tooltip';
import lessonService from '../../../services/lesson.service';

const constants = stringConstants.settings.resources.courses;
const ItemLesson = ({ lesson, index, deleteItem }) => {
  return (
    <Draggable key={lesson.id} draggableId={`id-${lesson.id}`} index={index}>
      {(provided) => (
        <ListGroup.Item
          className="border-0 p-0"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="d-flex bg-hover-gray-dark rounded align-items-center py-2 pr-1 justify-content-between">
            <div
              id={lesson.id}
              className="item-user d-flex align-items-center w-100"
            >
              <div {...provided.dragHandleProps}>
                <MaterialIcon
                  icon="drag_indicator"
                  clazz="icon-list-size text-gray-600 d-flex align-items-center"
                />
              </div>
              <div className="d-flex align-items-center gap-3 w-50 justify-content-between">
                <h5 className="mb-0 d-flex align-items-center ml-1">
                  <span className="mr-1">{index + 1}.</span>
                  <span>{lesson.title}</span>
                </h5>
                <Badge
                  color="bg-primary-soft"
                  className="h-100 fs-8 p-2 bg-primary-soft"
                >
                  {lesson?.category?.title || lesson?.category}
                </Badge>
              </div>
            </div>
            <div>
              <TooltipComponent title="Delete">
                <a
                  className="cursor-pointer btn btn-icon-sm btn-icon icon-hover-bg"
                  onClick={() => {
                    deleteItem(index);
                  }}
                >
                  <MaterialIcon icon="delete" />
                </a>
              </TooltipComponent>
            </div>
          </div>
        </ListGroup.Item>
      )}
    </Draggable>
  );
};

const ListLesson = ({ lessons, deleteItem, onDragEnd }) => {
  const Title = () => {
    return <div className="text-muted">No lessons</div>;
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <ListGroup
              {...provided.droppableProps}
              ref={provided.innerRef}
              className=""
              variant="flush"
            >
              {lessons.map((lesson, index) => (
                <ItemLesson
                  key={index}
                  lesson={lesson}
                  index={index}
                  deleteItem={deleteItem}
                />
              ))}
              {provided.placeholder}
            </ListGroup>
          )}
        </Droppable>
      </DragDropContext>
      {lessons.length === 0 && (
        <NoDataFound
          title={<Title />}
          icon="menu_book"
          containerStyle="w-100 border bg-gray-200 rounded border-dashed-gray min-h-120 text-muted"
        />
      )}
    </>
  );
};

const ManagementCourses = ({ currentCourseId, setCreate, setModified }) => {
  const [currentCourse, setCurrentCourse] = useState(courseInit);
  const [allLessons, setAllLessons] = useState([]);
  const [selectLessons, setSelectLessons] = useState([]);
  const [removedLessons, setRemovedLessons] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [scrollLimit, setLimit] = useState(10);
  const { setRefresh } = useContext(CategoriesContext);
  const {
    register,
    handleSubmit,
    reset,
    setValue,

    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (allLessons?.length > 0) getLessons(1);
  }, []);
  const onHandleChangeForm = (e) => {
    const { name } = e.target;
    let value = e.target.value;

    if (name === 'name') {
      setValue('name', value);
    }

    if (name === 'description') {
      setValue('description', value);
    }

    if (name === 'is_learning_path') {
      value = !currentCourse.is_learning_path;
    }

    setCurrentCourse({
      ...currentCourse,
      [name]: value,
    });
  };

  useEffect(() => {}, [selectLessons]);
  const getLessons = async (page = 1, query) => {
    try {
      const data = await lessonService.getLessons({
        page: page || 1,
        limit: scrollLimit,
        status: 'published',
        ...query,
      });
      if (scrollLimit <= data?.pagination?.count) {
        setHasMore(data?.data?.length > 0);
        setLimit((prevLimit) => prevLimit + 10);
      } else {
        setHasMore(false);
      }
      setAllLessons(data?.data);
    } catch (e) {
      setErrorMessage(SERVER_ERROR);
    }
  };

  const onHandleChangePage = async (paginationData) => {
    const limitPage = 10;
    setPaginationLoading(true);
    try {
      await getLessons(paginationData.page, paginationData.limit + limitPage);
    } catch (e) {
      console.log(e);
    } finally {
      setPaginationLoading(false);
    }
  };

  const onHandleDeleteLesson = (index) => {
    const lessons = selectLessons?.slice();
    const [removed] = lessons.splice(index, 1);
    setSelectLessons(lessons);
    if (currentCourse.id) {
      const removedId = [...removedLessons];
      if (removed.CourseLessonModel)
        removedId.push(removed.CourseLessonModel.id);

      setRemovedLessons(removedId);
    }
  };
  const updateCourse = async () => {
    const lessons = selectLessons.map((item) => item.id);
    const { id, name, is_learning_path, category } = currentCourse;
    const uniqueCategoryIds = new Set();

    if (selectLessons?.length > 0) {
      // Extracting unique category_id values from each item in the array
      selectLessons?.forEach((item) => {
        const categoryId = item?.category_id || 0;
        uniqueCategoryIds.add(categoryId);
      });
    }
    const uniqueCategoryArray = Array.from(uniqueCategoryIds);
    const courseUpdate = {
      name,
      description: currentCourse.description || '',
      is_learning_path,
      lessons,
      removedLessons,
      category_id: category?.id,
      categoryIds: uniqueCategoryArray,
      status: DRAFT,
    };

    setLoading(true);

    try {
      await courseService.updateCourseLessons(id, courseUpdate);
      setSuccessMessage(constants.courseUpdateSuccessMessage);
      reset({});
      // this is updating a categories context so that when any category is updated
      // we also trigger an update to refresh call from api for sidemenu navigation
      setRefresh((prevState) => prevState + 1);
      setCreate(false);
    } catch (e) {
      setErrorMessage(SERVER_ERROR);
    } finally {
      setModified((prevState) => prevState + 1);
      setLoading(false);
    }
  };

  const onHandleSaveCourse = async () => {
    const lessons = selectLessons;

    if (!lessons || lessons.length < 2) {
      setErrorMessage('Minimum 2 lessons are required');
      return;
    }

    if (currentCourse.id) {
      updateCourse();
    }
  };

  const onHandlePublished = async () => {
    const lessons = selectLessons;

    if (!lessons || lessons.length < 2) {
      setErrorMessage('Minimum 2 lessons are required');
      return;
    }

    const uniqueCategoryIds = new Set();

    if (selectLessons?.length > 0) {
      // Extracting unique category_id values from each item in the array
      selectLessons?.forEach((item) => {
        const categoryId = item?.category_id || 0;
        uniqueCategoryIds.add(categoryId);
      });
    }
    const uniqueCategoryArray = Array.from(uniqueCategoryIds);
    setLoadingPublish(true);
    const lessonIds = selectLessons.map((item) => item.id);
    const status = PUBLISHED;
    const { id, name, is_learning_path, category } = currentCourse;
    const courseUpdate = {
      name,
      description: currentCourse.description || '',
      is_learning_path,
      lessons: lessonIds,
      removedLessons,
      category_id: category?.id,
      status,
      categoryIds: uniqueCategoryArray,
    };

    await courseService.updateCourseLessons(id, courseUpdate);
    setSuccessMessage('Course Saved');
    courseService
      .updateCourse(currentCourse.id, { status })
      .then(() => {
        currentCourse.status = PUBLISHED;
        reset({});
        setCreate(false);
      })
      .catch((e) => {
        setErrorMessage(SERVER_ERROR);
      })
      .finally(() => {
        setLoadingPublish(false);
        setModified((prevState) => prevState + 1);
      });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onHandleDragEndLessons = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      selectLessons,
      result.source.index,
      result.destination.index
    );

    setSelectLessons(items);
  };

  const clearLessons = () => {
    if (currentCourse.id) {
      const removedId = [];
      selectLessons.forEach((lesson) => {
        if (lesson.CourseLessonModel) {
          removedId.push(lesson.CourseLessonModel.id);
        }
      });

      setRemovedLessons([...removedLessons, ...removedId]);
    }
  };
  useEffect(() => {
    clearLessons();
    setSelectLessons([]);
    setCurrentCourse({
      ...currentCourse,
      category: {},
    });
  }, [currentCourse.is_learning_path]);

  useEffect(() => {
    const getData = async () => {
      await getLessons();
    };
    getData();
  }, []);

  const processCourseResponse = (course) => {
    if (course) {
      const {
        id,
        name,
        description,
        is_learning_path,
        badge,
        quiz,
        status,
        lessons,
        category,
      } = course;

      setCurrentCourse({
        id,
        name,
        description,
        is_learning_path,
        status,
        badge,
        quiz,
        category,
      });

      setValue('name', name);
      setValue('categoryId', [category?.id]);

      const lessonsOrder = lessons.sort((next, prox) => {
        if (next.CourseLessonModel.position > prox.CourseLessonModel.position) {
          return 1;
        }
        return -1;
      });

      setSelectLessons(lessonsOrder);
    }
  };

  useEffect(() => {
    const getDataCourse = async () => {
      if (currentCourseId && currentCourseId !== 'new') {
        try {
          const responses = await Promise.all([
            courseService.getCourseLessonsById(currentCourseId),
          ]);
          processCourseResponse(responses[0]);
        } catch (e) {}
      }
    };

    getDataCourse();
  }, [currentCourseId]);

  return (
    <div>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <Card className="rounded-0 card-borderinline shadow-none border-0">
        <form onSubmit={handleSubmit(onHandleSaveCourse)}>
          <HeaderCardCourses
            published={currentCourse.status?.toLowerCase() !== 'published'}
            loading={loading}
            loadingPublish={loadingPublish}
            onHandleSave={onHandleSaveCourse}
            onHandlePublished={handleSubmit(onHandlePublished)}
            flagNew={currentCourse.id}
            goBack={() => {
              reset({});
              setCreate(false);
            }}
            register={register}
            errors={errors}
            courseForm={currentCourse}
            onHandleChange={onHandleChangeForm}
            courseId={currentCourseId}
          />
          <Card.Body className="px-3">
            <div className="d-flex">
              <div className="w-100">
                <div className="pt-0">
                  <FormGroup>
                    <Label
                      htmlFor="selectLessonDropdown"
                      className="form-label"
                    >
                      {constants.searchLessonPlaceholder} <Asterick />{' '}
                      <span className="fs-8 font-weight-normal">
                        (minimum 2 are required)
                      </span>
                    </Label>
                    <DropdownLesson
                      id={`selectLessonDropdown`}
                      title={constants.searchLessonPlaceholder}
                      placeholder={constants.searchLessonPlaceholder}
                      value={''}
                      results={allLessons}
                      error={'nada'}
                      getLessons={getLessons}
                      hasMore={hasMore}
                      paginationLoading={paginationLoading}
                      onHandleChangePage={onHandleChangePage}
                      selection={selectLessons}
                      setSelection={setSelectLessons}
                      onDeleteLesson={onHandleDeleteLesson}
                      onChange={(e) => {
                        if (e) {
                          const { value } = e.target;
                          getLessons(1, 10, { search: value });
                        }
                      }}
                    />
                  </FormGroup>
                  <ListLesson
                    lessons={selectLessons}
                    deleteItem={onHandleDeleteLesson}
                    onDragEnd={onHandleDragEndLessons}
                  />
                </div>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="px-3">
            <FormCourses
              course={currentCourse}
              errors={errors}
              register={register}
              setValue={setValue}
              onHandleChange={onHandleChangeForm}
            />
          </Card.Footer>
        </form>
      </Card>
    </div>
  );
};

export default ManagementCourses;
