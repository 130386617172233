import AlertWrapper from '../../Alert/AlertWrapper';
import Alert from '../../Alert/Alert';
import DeleteConfirmationModal from '../../modal/DeleteConfirmationModal';

const DeleteFile = ({
  id,
  children,
  confirmOpen,
  setConfirmOpen,
  successMessage,
  setSuccessMessage,
  errorMessage,
  setErrorMessage,
  setRefreshRecentFiles,
  activityPerantId,
  getNotes,
  getFiles,
  removeFile,
}) => {
  const onRemoveFile = async () => {
    try {
      await removeFile({
        id,
        setOpen: setConfirmOpen,
        setErrorMessage,
        setSuccessMessage,
        setRefreshRecentFiles,
        getFiles,
      });

      if (activityPerantId) {
        await getNotes();
      }
    } catch (err) {
      console.error('Error removing file:', err);
    }
  };

  return (
    <>
      <AlertWrapper>
        <Alert message={successMessage} setMessage={setSuccessMessage} />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>

      <DeleteConfirmationModal
        showModal={confirmOpen}
        setShowModal={setConfirmOpen}
        event={onRemoveFile}
        heading="Delete File"
        description="Are you sure you want to delete this file?"
      />

      {children}
    </>
  );
};

export default DeleteFile;
