import React, { useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import MaterialIcon from './MaterialIcon';
import IdfTooltip from '../idfComponents/idfTooltip';
import TooltipComponent from '../lesson/Tooltip';
import useOutsideClickDropDown from '../../hooks/useOutsideClickDropDown';
import { Badge } from 'reactstrap';

// generic component to filter out table UI data, for ref check MyLessons.js and Training->My Favorites-> Filters top right button
const ButtonFilterDropdown = ({
  buttonText = 'Filters',
  options,
  handleFilterSelect,
  filterOptionSelected,
  btnToggleStyle = 'btn-sm',
  btnAddConfig,
  showOnlyIcon = false,
  menuClass,
  ignoreChildHover,
  openFilter,
  setOpenFilter,
  customKeys = ['key', 'name', 'description'],
  icon,
  validationConfig,
  fieldState,
  children,
  withTooltip,
  hideIcon = false,
  dropmenuWidth = 'min-w-170',
  optionIconClass,
}) => {
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState('');
  const [submenuConfig, setSubmenuConfig] = useState({});
  const menuRef = useRef();
  useOutsideClickDropDown(menuRef, open, setOpen);

  return (
    <Dropdown show={openFilter || open} onToggle={setOpenFilter || setOpen}>
      {withTooltip ? (
        <TooltipComponent title={withTooltip}>
          <Dropdown.Toggle
            variant="white"
            className={`btn btn-white dropdown-toggle ${btnToggleStyle} ${
              validationConfig?.required
                ? 'border-left-4 border-left-danger rounded'
                : ''
            } ${
              fieldState?.invalid && !fieldState?.error?.ref?.value
                ? 'border-danger'
                : ''
            }`}
            id="dropdown-basic"
          >
            <div className="d-flex align-items-center text-left w-100">
              {!hideIcon && (
                <MaterialIcon
                  icon={filterOptionSelected?.icon || icon || 'filter_list'}
                  clazz={showOnlyIcon ? '' : 'mr-1'}
                />
              )}

              {!showOnlyIcon && (
                <p className="d-inline-block text-truncate text-capitalize mb-0 w-100">
                  {(filterOptionSelected &&
                    filterOptionSelected[customKeys[1]]) ||
                    buttonText}{' '}
                </p>
              )}
            </div>
          </Dropdown.Toggle>
        </TooltipComponent>
      ) : (
        <Dropdown.Toggle
          variant="white"
          className={`btn btn-white dropdown-toggle ${btnToggleStyle} ${
            validationConfig?.required
              ? 'border-left-4 border-left-danger rounded'
              : ''
          } ${
            fieldState?.invalid && !fieldState?.error?.ref?.value
              ? 'border-danger'
              : ''
          }`}
          id="dropdown-basic"
        >
          <div className="d-flex align-items-center text-left w-100">
            {!hideIcon && (
              <MaterialIcon
                rounded
                icon={filterOptionSelected?.icon || icon || 'filter_list'}
                clazz={`fs-5 ${showOnlyIcon ? '' : 'mr-1'}`}
              />
            )}
            {!showOnlyIcon && (
              <p
                className={`${
                  filterOptionSelected?.isDefault
                    ? 'd-inline-flex align-items-center gap-1'
                    : 'd-inline-block'
                } text-truncate text-capitalize mb-0 w-100`}
              >
                {(filterOptionSelected &&
                  filterOptionSelected[customKeys[1]]) ||
                  buttonText}
                {filterOptionSelected?.isDefault && (
                  <Badge
                    color="primary"
                    style={{ height: 16, width: 45, fontSize: '70%' }}
                    pill
                    className="position-unset d-flex justify-content-center align-items-center top-0"
                  >
                    Default
                  </Badge>
                )}
              </p>
            )}
          </div>
        </Dropdown.Toggle>
      )}

      <>
        {children || (
          <Dropdown.Menu
            style={{ zIndex: 250 }}
            ref={menuRef}
            className={`p-0 z-index-250 py-1 ${dropmenuWidth} idf-dropdown-item-list ${ignoreChildHover}`}
          >
            <div className={`overflow-y-auto ${menuClass}`}>
              {options.map((option) => (
                <>
                  {option.submenu ? (
                    <>
                      <Dropdown.Item
                        key={option.key}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        onMouseEnter={(e) => {
                          setOpenMenu(option.key);
                          setSubmenuConfig({
                            top: e.target.offsetTop,
                            left: 200,
                          });
                        }}
                        onMouseLeave={() => setOpenMenu('')}
                        className={`px-3 ${
                          filterOptionSelected &&
                          filterOptionSelected[customKeys[0]] ===
                            option[customKeys[0]]
                            ? 'text-primary'
                            : ''
                        } ${option.showHide}`}
                      >
                        <div className="d-flex align-items-center justify-content-between py-1">
                          <div className="d-flex align-items-center">
                            {option.icon && (
                              <MaterialIcon icon={option.icon} clazz="mr-2" />
                            )}
                            {option?.customElement || (
                              <>
                                <span
                                  className={
                                    filterOptionSelected &&
                                    filterOptionSelected[customKeys[0]] ===
                                      option[customKeys[0]]
                                      ? 'fw-bold'
                                      : ''
                                  }
                                >
                                  {option[customKeys[1]]}
                                </span>
                              </>
                            )}
                          </div>
                          <MaterialIcon icon="arrow_right" />
                          {openMenu && openMenu === option.key && (
                            <div
                              className="position-absolute rounded bg-white border shadow-lg"
                              style={{
                                top: submenuConfig.top,
                                left: submenuConfig.left,
                              }}
                            >
                              {option.submenu}
                            </div>
                          )}
                        </div>
                      </Dropdown.Item>
                    </>
                  ) : (
                    <Dropdown.Item
                      key={option.id}
                      href="#"
                      onClick={(e) => {
                        if (option.key === -1) {
                          setOpen(true);
                          return;
                        }
                        handleFilterSelect(e, option);
                      }}
                      className={`px-3 ${
                        filterOptionSelected &&
                        filterOptionSelected[customKeys[0]] ===
                          option[customKeys[0]]
                          ? 'text-primary all-child'
                          : ''
                      } ${option.showHide} ${
                        option.key === -1
                          ? 'inactive font-weight-normal bg-white cursor-default'
                          : ''
                      } ${
                        optionIconClass &&
                        filterOptionSelected &&
                        filterOptionSelected[customKeys[0]] ===
                          option[customKeys[0]]
                          ? 'bg-gray-5'
                          : ''
                      }`}
                    >
                      <div className="d-flex px-0 align-items-center justify-content-between py-1">
                        <div className="d-flex align-items-center">
                          {option.icon && !hideIcon && (
                            <>
                              {optionIconClass ? (
                                <div className="mr-2 rounded-circle p-1 d-flex bg-gray-5">
                                  <MaterialIcon
                                    icon={option.icon}
                                    rounded
                                    clazz="fs-5"
                                  />
                                </div>
                              ) : (
                                <MaterialIcon
                                  icon={option.icon}
                                  rounded
                                  clazz="mr-2 fs-5"
                                />
                              )}
                            </>
                          )}
                          {option?.customElement || (
                            <>
                              <span
                                className={`d-flex flex-column ${
                                  filterOptionSelected &&
                                  filterOptionSelected[customKeys[0]] ===
                                    option[customKeys[0]]
                                    ? 'fw-bold'
                                    : ''
                                }`}
                              >
                                <>
                                  {withTooltip ? (
                                    <IdfTooltip text={option.tip}>
                                      {option[customKeys[1]]}
                                    </IdfTooltip>
                                  ) : (
                                    <span className="d-flex text-capitalize align-items-center gap-1">
                                      {option[customKeys[1]]}
                                      {option?.isDefault && (
                                        <Badge color="primary" pill>
                                          Default
                                        </Badge>
                                      )}
                                    </span>
                                  )}

                                  {option[customKeys[2]] ? (
                                    <span className="text-muted fs-9">
                                      {option[customKeys[2]]}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </>
                              </span>
                            </>
                          )}
                        </div>
                        {filterOptionSelected &&
                          filterOptionSelected[customKeys[0]] ===
                            option[customKeys[0]] && (
                            <MaterialIcon icon="check" clazz="fw-bold" />
                          )}
                      </div>
                    </Dropdown.Item>
                  )}
                </>
              ))}
            </div>
            {btnAddConfig && (
              <div className="idf-dropdown-item-list">
                <a
                  className="btn-soft-light w-100 dropdown-item btn-block cursor-pointer py-2 px-3 mt-2 border-top"
                  onClick={btnAddConfig.onClick}
                >
                  <div className="d-flex align-items-center">
                    <MaterialIcon icon={btnAddConfig.icon} clazz="mr-1" />
                    <span>{btnAddConfig.text}</span>
                  </div>
                </a>
              </div>
            )}
          </Dropdown.Menu>
        )}
      </>
    </Dropdown>
  );
};

export default ButtonFilterDropdown;
