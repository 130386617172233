import React, { useContext, useEffect, useRef, useState } from 'react';

import { CardForm, CardSection, CardButton, List } from '../layouts/CardLayout';
import roleService from '../../services/role.service';
import userService from '../../services/user.service';
import stringConstants from '../../utils/stringConstants.json';
import permissions from '../../utils/permissions';
import { isModuleAllowed, RIGHT_PANEL_WIDTH_Large } from '../../utils/Utils';
import Avatar from '../../components/Avatar';
import { PermissionsContext } from '../../contexts/permissionContext';
import { CardBody, CardFooter, TabContent, TabPane } from 'reactstrap';
import {
  CANCEL_LABEL,
  NAME_INVITED_USER,
  STATUS_ACTIVE,
} from '../../utils/constants';
import MaterialIcon from '../../components/commons/MaterialIcon';
import _, { capitalize } from 'lodash';
import RightPanelModal from '../../components/modal/RightPanelModal';
import AnimatedTabs from '../commons/AnimatedTabs';
import Loading from '../Loading';
import LoadMoreButton from '../lesson/LoadMoreButton';
import TooltipComponent from '../lesson/Tooltip';
import useRoleNameDescriptionEditModal from './modals/useRoleNameDescriptionEditModal';
import NewKindOfSearchDropdown from '../commons/NewKindOfSearchDropdown';
import { PermissionToggles } from './PermissionToggles';
const constants = stringConstants.settings.roles.edit;

const permissionList = permissions.permissionList;

const role = {
  name: '',
  description: '',
  id: '',
  isAdmin: false,
};

const buttons = {
  save: {
    title: constants.saveRole,
    variant: 'primary',
  },
  remove: {
    title: constants.remove,
    variant: 'outline-danger',
  },
  add: {
    title: constants.add,
    variant: 'outline-primary',
  },
};

const EditRoleModal = ({
  showModal,
  setShowModal,
  showViewUsers,
  selectedRoleData,
  toggleLoadRoles,
  setSuccessMessage,
  setErrorMessage,
}) => {
  const [roleData, setRoleData] = useState(selectedRoleData || role);
  const [searchUser, setSearchUser] = useState();
  const [usersData, setUsersData] = useState([]);
  const [userSelection, setUserSelection] = useState([]);
  const [initialRoleUsers, setInitialRoleUsers] = useState([{}]);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [ownerAccessSwitch, setOwnerAccessSwitch] = useState(false);
  const [appAccessSwitch, setAppAccessSwitch] = useState(false);

  const [tenantAccessSwitch, seTenantAccessSwitch] = useState(false);
  const [permissionLevel, setPermissionLevel] = useState({});
  const [permissionSwitches, setpermissionSwitches] = useState([]);
  const { permissionChanges, setPermissionChanges } =
    useContext(PermissionsContext);
  const [permissions, setPermissions] = useState([...permissionList]);
  const [permissionsDropdown, setPermissionsDropdown] = useState('');
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const onInputSearch = (e) => {
    const { value } = e.target || {};
    setSearchUser({
      ...searchUser,
      search: value,
    });
  };

  // Update role service
  const updateRole = () => {
    return new Promise((resolve, reject) => {
      roleData.id = selectedRoleData?.id;
      roleData.isOwner = ownerAccessSwitch || false;

      userSelection.length > 0 &&
        userSelection.forEach(async (user) => {
          const checkIfExist = initialRoleUsers.some(
            (item) => item.id === user.id
          );
          if (!checkIfExist) {
            const userUpdate = {
              roleId: selectedRoleData?.id,
              first_name: user?.first_name,
              last_name: user?.last_name,
              avatar: user?.avatar,
              status: user?.status,
            };

            userService
              .updateUserInfoById(user.id, userUpdate)
              .catch((err) => console.log(err));
          }
        });
      initialRoleUsers.length > 0 &&
        initialRoleUsers.forEach(async (user) => {
          const checkIfExist = userSelection.some(
            (item) => item.id === user.id
          );
          if (!checkIfExist) {
            const userUpdate = {
              roleId: null,
              first_name: null,
              last_name: null,
              avatar: null,
            };

            userService
              .updateUserInfoById(user.id, userUpdate)
              .catch((err) => console.log(err));
          }
        });

      setInitialRoleUsers(userSelection);

      return roleService.updateRole(roleData).then(resolve).catch(reject);
    });
  };

  const getRoleById = async () => {
    const roleData = await roleService.getRoleById(selectedRoleData?.id);
    if (roleData?.id) {
      setRoleData({
        name: roleData.name || '',
        description: roleData.description || '',
        id: roleData.id || '',
      });
      setOwnerAccessSwitch(roleData.owner_access || false);
      setAppAccessSwitch(
        roleData.owner_access === false ? roleData.app_access : false
      );
    }
  };

  const getRoleUsers = async () => {
    const response = await userService
      .getUsers({ roleId: selectedRoleData?.id }, { ...pagination, page })
      .catch((err) => console.log(err));

    setPagination(response.data?.pagination);
    const previousList = !loadMore ? [] : [...userSelection];
    const newList = [...previousList, ...response.data?.users];

    const roleUsersList = newList?.map((user) => {
      const roleUsersItem = {
        name: `${
          user.first_name !== null ? user.first_name : NAME_INVITED_USER
        } ${user.last_name !== null ? user.last_name : ''}`,
        email: user.email,
        avatar: user.avatar,
        id: user.id,
        status: user?.status,
        roleId: user.roleId,
        first_name: user.first_name,
        last_name: user.last_name,
      };
      return roleUsersItem;
    });
    setUserSelection(roleUsersList);
    setInitialRoleUsers(roleUsersList);
    setLoading(false);
    setLoadMore(false);
  };

  const onLoadMore = () => {
    setLoadMore(true);
    setPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    // select tab2 when selects view users from dropdown
    if (showViewUsers) {
      setActiveTab('2');
    }
  }, [showViewUsers, showModal]);

  useEffect(() => {
    if (showModal && selectedRoleData?.id && page > 1) {
      setLoadMore(true);
      getRoleUsers();
    }
  }, [page]);

  const getRolePermissions = async () => {
    const rolePermissions = await roleService
      .getPermissionsByRole(selectedRoleData?.id)
      .catch((err) => console.log(err));

    const permissionsCollection = rolePermissions.filter((item) => {
      return item.collection;
    });

    if (permissionsCollection && permissionsCollection[0]?.level === 'tenant') {
      seTenantAccessSwitch(true);
    }
    setPermissionLevel(permissionsCollection[0]);
    const groupedPermission = _.groupBy(permissionsCollection, 'collection');

    const loadedPermissions = Object.keys(groupedPermission).map((key) => {
      const inner = groupedPermission[key]; // array
      const innerPermission = {};
      innerPermission.name = key;
      innerPermission.label = capitalize(key);
      innerPermission.group = inner.map((perm) => {
        return {
          name: perm.action,
          label: capitalize(perm.action),
          isChecked: true,
          permissions: [
            {
              label: capitalize(perm.action),
              collection: perm.collection,
              action: perm.action,
            },
          ],
        };
      });
      return innerPermission;
    });

    const permissionJson = [...permissions];
    permissionJson.forEach((permission) => {
      permission.inner_permissions.forEach((permCat) => {
        const permFound = loadedPermissions.find(
          (f) => f.name === permCat.name
        );
        if (permFound) {
          const groupItems = permFound.group.map((m) => m.name);
          permCat.group.forEach((ch) => {
            ch.isChecked = groupItems.includes(ch.name);
          });
          permCat.isChecked = permCat.group.some((s) => s.isChecked);
        } else {
          permCat.group.forEach((ch) => {
            ch.isChecked = false;
          });
          permCat.isChecked = false;
        }
      });
    });
    setPermissions(permissionJson);
    setpermissionSwitches(permissionsCollection);
  };

  useEffect(() => {
    getRoleById();
    getRoleUsers();
    getRolePermissions();
  }, []);

  const getUsers = async () => {
    const searchResults = await userService
      .getUsers(
        { ...{ search: searchUser?.search, status: STATUS_ACTIVE } }, // only get active users
        {
          page: 1,
          limit: 10,
        }
      )
      .catch((err) => console.log(err));
    const { data } = searchResults || {};

    const filteredUsersData = data?.users?.filter((user) => {
      return !userSelection?.some((item) => item.id === user.id);
    });
    setUsersData(filteredUsersData);
  };

  useEffect(() => {
    getUsers();
  }, [searchUser?.search]);

  useEffect(() => {
    if (ownerAccessSwitch) setOwnerAccessSwitch(true);
  }, [ownerAccessSwitch]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const updatedData = permissionSwitches?.map((item) => {
        return {
          ...item,
          level: permissionSwitches[0]?.level === 'tenant' ? 'tenant' : 'user',
        };
      });
      await updateRole();
      await roleService.updatePermissions(selectedRoleData?.id, [
        ...updatedData,
      ]);
      await getRolePermissions();
      setSuccessMessage('Profile updated.');
      setShowModal(false);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
      setPermissionChanges(!permissionChanges);
      handleDropdownPermission('');
      toggleLoadRoles();
    }
  };

  const handleSwitchEvent = async (e, permissionGroup, permissionsCategory) => {
    const isChecked = e.target.checked;
    const newPermissionGroup = {
      collection: permissionGroup.permissions[0].collection,
      action: permissionGroup.name,
    };

    // update permissions category group
    const updatedGroup = permissionsCategory.group.map((group) => ({
      ...group,
      isChecked:
        group.name === permissionGroup.name ? isChecked : group.isChecked,
    }));
    const updatedPermissions = [...permissions].map((perm) => ({
      ...perm,
      inner_permissions: [...perm.inner_permissions].map((permCat) => ({
        ...permCat,
        group:
          permCat.name === permissionsCategory.name
            ? updatedGroup
            : permCat.group,
      })),
    }));
    setPermissions(updatedPermissions);
    if (isChecked) {
      let newPermissionsData = [...permissionSwitches, newPermissionGroup];
      if (newPermissionGroup?.collection === 'lessons') {
        const course = {
          collection: 'courses',
          action: newPermissionGroup?.action,
        };
        const categories = {
          collection: 'categories',
          action: newPermissionGroup?.action,
        };
        newPermissionsData = [
          ...permissionSwitches,
          newPermissionGroup,
          course,
          categories,
        ];
      }
      setpermissionSwitches(newPermissionsData);
    } else {
      const index = permissionSwitches?.findIndex((permissionCheck) => {
        return (
          permissionCheck.collection === permissionsCategory?.name &&
          permissionCheck.action === permissionGroup.name
        );
      });
      if (index !== -1) {
        permissionSwitches.splice(index, 1);
      }
      const updatedCheckedPermissions = permissionSwitches;
      const updatedPermissions = [...permissions].map((perm) => ({
        ...perm,
        inner_permissions: [...perm.inner_permissions].map((permCat) => ({
          ...permCat,
          group:
            permCat.name === permissionsCategory.name
              ? updatedGroup
              : permCat.group,
        })),
      }));
      setPermissions(updatedPermissions);
      setpermissionSwitches(updatedCheckedPermissions);
      if (newPermissionGroup.collection === 'lessons') {
        permissionSwitches.forEach((switchItem) => {
          if (
            switchItem.collection === 'courses' ||
            switchItem.collection === 'categories'
          ) {
            const updatedSwitches = permissionSwitches.filter(
              (item) =>
                (item.collection !== 'courses' ||
                  item.action !== newPermissionGroup.action) &&
                (item.collection !== 'lessons' ||
                  item.action !== newPermissionGroup.action) &&
                (item.collection !== 'categories' ||
                  item.action !== newPermissionGroup.action)
            );
            setpermissionSwitches(updatedSwitches);
          }
        });
      }
    }

    // if all are unchecked remove/uncheck main toggle
    if (updatedGroup.every((item) => !item.isChecked)) {
      handleDropdownPermission('');
      setPermissionsDropdown('');
      const newUpdatedCheckedPermissions = permissionSwitches?.filter(
        (permissionCheck) => {
          return permissionCheck.collection !== permissionsCategory?.name;
        }
      );
      const newUpdatedGroup = permissionsCategory.group.map((group) => ({
        ...group,
        isChecked: false,
      }));
      const updatedPermissions = [...permissions].map((perm) => ({
        ...perm,
        inner_permissions: [...perm.inner_permissions].map((permCat) => ({
          ...permCat,
          isChecked: permCat.name === permissionsCategory.name,
          group:
            permCat.name === permissionsCategory.name
              ? newUpdatedGroup
              : permCat.group,
        })),
      }));
      setPermissions(updatedPermissions);
      setpermissionSwitches(newUpdatedCheckedPermissions);
    }
  };
  const handlePermissionCategoryChange = async (
    permission,
    permissionCategory
  ) => {
    // now each permission object has inner_permissions array which is being rendered in UI, so we have to update that
    // in order to reflect in state/UI
    // instead of using .isChecked, you've started using permissionSwitches in value of toggleTab
    const updatedGroup = permissionCategory?.group.map((group) => ({
      ...group,
      isChecked: !permissionCategory.isChecked,
    }));
    const updatedInnerPermissions = [...permission.inner_permissions].map(
      (permCat) => ({
        ...permCat,
        isChecked:
          permissionCategory.name === permCat.name
            ? !permissionCategory.isChecked
            : permCat.isChecked,
        group:
          permissionCategory.name === permCat.name
            ? updatedGroup
            : permCat.group,
      })
    );

    // once we have inner_permissions updated we need to updated its parent too.
    const updatedPermissions = [...permissions].map((perm) => ({
      ...perm,
      inner_permissions:
        perm.name === permission.name
          ? updatedInnerPermissions
          : perm.inner_permissions,
    }));
    setPermissions(updatedPermissions);
    const collection = permission.inner_permissions.find((item) => {
      return item.name === permissionCategory.name;
    });
    const action = collection?.group?.filter((item) => {
      return item.name;
    });
    const permissionCheckUnCheck = permissionSwitches?.find((item) => {
      return item.collection === permissionCategory.name;
    });
    let updatedCheckedPermissions = [];
    if (permissionCategory.isChecked) {
      updatedCheckedPermissions = permissionSwitches?.filter(
        (permissionCheck) => {
          return (
            permissionCheck.collection !== permissionCheckUnCheck.collection
          );
        }
      );
      setpermissionSwitches(updatedCheckedPermissions);
      handleTraining(updatedCheckedPermissions);
      handleDropdownPermission('');
    } else {
      const arr = [];
      const permissionArray = action
        .map((group) => ({
          collection: group.permissions[0]?.collection,
          action: group.permissions[0]?.action,
        }))
        .filter((permissionChecked) => {
          return !(
            (permissionLevel?.level === 'user' ||
              (!ownerAccessSwitch && !appAccessSwitch)) &&
            (permissionChecked?.action === 'delete' ||
              isInvalidPermission(permissionChecked))
          );
        });
      const getContactCollection = permission.inner_permissions.find(
        (item) => item.name === 'contacts'
      );
      const getCompaniesCollection = permission.inner_permissions.find(
        (item) => item.name === 'organizations'
      );
      const getDealCollection = permission.inner_permissions.find(
        (item) => item.name === 'deals'
      );
      const getNoteCollection = permission.inner_permissions.find(
        (item) => item.name === 'notes'
      );
      const getProductCollection = permission.inner_permissions.find(
        (item) => item.name === 'products'
      );
      if (permissionCategory.name === 'deals') {
        if (
          !permissionArray.some(
            (item) =>
              item.collection === getCompaniesCollection?.name &&
              item.action === getCompaniesCollection?.group[0].name
          ) &&
          !permissionArray.some(
            (item) =>
              item.collection === getContactCollection?.name &&
              item.action === getContactCollection?.group[0].name
          ) &&
          !permissionArray.some(
            (item) =>
              item.collection === getProductCollection?.name &&
              item.action === getProductCollection?.group[0].name
          ) &&
          !permissionArray.some(
            (item) =>
              item.collection === getNoteCollection?.name &&
              item.action === getNoteCollection?.group[0].name
          )
        ) {
          arr.push(
            {
              collection: getCompaniesCollection?.name,
              action: getCompaniesCollection?.group[0].name,
            },
            {
              collection: getContactCollection?.name,
              action: getContactCollection?.group[0].name,
            },
            {
              collection: getProductCollection?.name,
              action: getProductCollection?.group[0].name,
            },
            {
              collection: getNoteCollection?.name,
              action: getNoteCollection?.group[0].name,
            }
          );
        }
      } else if (permissionCategory.name === 'activities') {
        // Check if the objects are not already in the array before pushing
        if (
          !permissionArray.some(
            (item) =>
              item.collection === getCompaniesCollection?.name &&
              item.action === getCompaniesCollection?.group[0].name
          ) &&
          !permissionArray.some(
            (item) =>
              item.collection === getContactCollection?.name &&
              item.action === getContactCollection?.group[0].name
          ) &&
          !permissionArray.some(
            (item) =>
              item.collection === getDealCollection?.name &&
              item.action === getDealCollection?.group[0].name
          ) &&
          !permissionArray.some(
            (item) =>
              item.collection === getNoteCollection?.name &&
              item.action === getNoteCollection?.group[0].name
          )
        ) {
          arr.push(
            {
              collection: getCompaniesCollection?.name,
              action: getCompaniesCollection?.group[0].name,
            },
            {
              collection: getContactCollection?.name,
              action: getContactCollection?.group[0].name,
            },
            {
              collection: getDealCollection?.name,
              action: getDealCollection?.group[0].name,
            },
            {
              collection: getNoteCollection?.name,
              action: getNoteCollection?.group[0].name,
            }
          );
        }
      }
      const allPermissionsData = [
        ...permissionSwitches,
        ...permissionArray,
        ...arr,
      ];
      if (permissionCategory?.name === 'lessons') {
        handleTraining(allPermissionsData);
      }
      setpermissionSwitches(allPermissionsData);
    }
  };

  const isInvalidPermission = (permission) => {
    const { collection, action } = permission;
    return (
      (collection === 'dashboard' && action === 'manage') ||
      (collection === 'lessons' && action === 'create') ||
      (collection === 'lessons' && action === 'edit')
    );
  };
  const handleTraining = (allPermissionsData) => {
    if (
      allPermissionsData.every((item) => item.collection !== 'courses') &&
      allPermissionsData.every((item) => item.collection !== 'categories')
    ) {
      const allowedActions = ['edit', 'delete', 'view', 'create'];

      const isAllowedActionExists = allPermissionsData.some(
        (permission) =>
          permission.collection === 'lessons' &&
          allowedActions.includes(permission.action)
      );

      if (!isAllowedActionExists) {
        allPermissionsData = allPermissionsData.filter(
          (permission) =>
            permission.collection !== 'courses' &&
            permission.collection !== 'categories'
        );
      }
      const newCourse = allowedActions
        .map((action) => ({
          collection: 'courses',
          action: isAllowedActionExists ? action : '',
        }))
        .filter((obj) => obj.collection !== '' && obj.action !== '');

      const newCategory = allowedActions
        .map((action) => ({
          collection: 'categories',
          action: isAllowedActionExists ? action : '',
        }))
        .filter((obj) => obj.collection !== '' && obj.action !== '');

      setpermissionSwitches([
        ...allPermissionsData,
        ...newCategory,
        ...newCourse,
      ]);
    }
  };
  const handleDropdownPermission = (item) => {
    if (permissionsDropdown) {
      setPermissionsDropdown('');
    } else if (!permissionsDropdown) {
      setPermissionsDropdown(item);
    }
  };

  const handleSelected = (item) => {
    // Check if item with the same id already exists in userSelection
    const isItemAlreadyAdded = userSelection?.some(
      (existingItem) => existingItem.id === item.id
    );

    if (!isItemAlreadyAdded) {
      // If not present, add the item to userSelection
      const updatedUserSelection = [...userSelection, item];
      setUserSelection(updatedUserSelection);
      setSearchUser({ search: '' });
    } else {
      // Log or handle the case where the item is already in userSelection
      console.log(
        'Item with the same id already exists in userSelection:',
        item
      );
    }
  };

  useEffect(() => {
    if (!tenantAccessSwitch) {
      const filteredPermissions = permissionSwitches?.filter(
        (switchPermission) => {
          return !permissionList[1]?.inner_permissions?.some((listGroup) =>
            listGroup?.group?.some(
              (listPermission) =>
                listGroup.name === switchPermission.collection &&
                listPermission.name === switchPermission.action
            )
          );
        }
      );
      setpermissionSwitches(filteredPermissions);
    }
  }, [tenantAccessSwitch]);

  const moduleNoteCheck = (modules, moduleName) => {
    const isCRMModule = moduleName === 'crm';

    if (isCRMModule) {
      const notesModule = modules?.includes('pipelines_notes')
        ? 'pipelines_notes'
        : '' || modules?.includes('contacts_notes')
        ? 'contacts_notes'
        : '' || modules?.includes('companies_notes')
        ? 'companies_notes'
        : '';
      return isModuleAllowed(modules, notesModule);
    } else {
      return isModuleAllowed(modules, moduleName);
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);
    }
  };

  const tabsData = [
    {
      title: 'Permissions',
      tabId: '1',
    },
    {
      title: 'Users',
      tabId: '2',
      length: selectedRoleData?.totalUsers,
    },
  ];

  const {
    RoleNameDescriptionEditModal,
    setShowModal: showProfileEditModal,
    setData,
  } = useRoleNameDescriptionEditModal({
    refresh: (newData) => {
      setRoleData({ ...roleData, ...newData });
    },
    setSuccessMessage,
    setErrorMessage,
    refreshTable: toggleLoadRoles,
  });
  const containerRef = useRef();

  return (
    <>
      <RoleNameDescriptionEditModal />
      <RightPanelModal
        showModal={showModal}
        setShowModal={setShowModal}
        showOverlay={true}
        containerBgColor={'pb-0'}
        containerWidth={RIGHT_PANEL_WIDTH_Large}
        containerPosition={'position-fixed'}
        headerBgColor={loading ? '' : 'bg-gray-5'}
        cardHeaderClass="width-full"
        showCloseIcon={false}
        Title={
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex gap-2 py-2 align-items-center">
              <h3 className="mb-0">{roleData.name}</h3>
              {roleData.description && (
                <TooltipComponent
                  tooltipTextFont
                  titleLeftAlign
                  title={roleData.description}
                >
                  <MaterialIcon icon="info" clazz="text-icon cursor-default" />
                </TooltipComponent>
              )}
              <a
                className="icon-hover-bg cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setData(roleData);
                  showProfileEditModal(true);
                }}
              >
                <TooltipComponent title="Edit">
                  <MaterialIcon icon="edit" />
                </TooltipComponent>
              </a>
            </div>
            <div className="d-flex align-items-center gap-2">
              <a
                className="icon-hover-bg cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(false);
                }}
              >
                <MaterialIcon icon="close" size="fs-5" />
              </a>
            </div>
          </div>
        }
      >
        {loading ? (
          <CardBody className="scrollable-body">
            <Loading bars={true} />
          </CardBody>
        ) : (
          <>
            <CardBody className="scrollable-body" innerRef={containerRef}>
              <CardForm wrapInContainer={false}>
                <CardSection className="px-0 mx-0 flex-column">
                  <div>
                    <AnimatedTabs
                      tabsData={tabsData}
                      activeTab={activeTab}
                      tabClasses="px-21 pt-1"
                      borderClasses="border-bottom"
                      toggle={toggleTab}
                    />
                  </div>
                  <TabContent activeTab={activeTab}>
                    <TabPane className="position-relative" tabId={'1'}>
                      <PermissionToggles
                        permissionSwitches={permissionSwitches}
                        permissions={permissions}
                        moduleNoteCheck={moduleNoteCheck}
                        ownerAccessSwitch={ownerAccessSwitch}
                        tenantAccessSwitch={tenantAccessSwitch}
                        permissionLevel={permissionLevel}
                        handlePermissionCategoryChange={
                          handlePermissionCategoryChange
                        }
                        handleSwitchEvent={handleSwitchEvent}
                        appAccessSwitch={appAccessSwitch}
                        permissionsDropdown={permissionsDropdown}
                        handleDropdownPermission={handleDropdownPermission}
                      />
                    </TabPane>
                    <TabPane className="position-relative" tabId={'2'}>
                      <div className="border-bottom px-20 py-3">
                        <NewKindOfSearchDropdown
                          readOnly={true}
                          preData={usersData}
                          placeholder="Add users"
                          onItemSelect={handleSelected}
                          onInputSearch={onInputSearch}
                        />
                      </div>
                      <List className="mt-0">
                        {userSelection
                          .filter((value) => Object.keys(value).length !== 0)
                          .map((acnt) => (
                            <div
                              key={acnt.id}
                              className="d-flex border-bottom-light px-4 py-2 align-items-center gap-2"
                            >
                              <Avatar
                                user={{
                                  ...acnt,
                                  firstName: acnt?.name?.split(' ')[0],
                                  lastName: acnt?.name?.includes(' ')
                                    ? acnt?.name?.split(' ')[1]
                                    : '',
                                }}
                              />
                              <div>
                                <h5 className="mb-0 font-weight-medium">
                                  {acnt.name}
                                </h5>
                                <p className="mb-0 fs-7">{acnt.email}</p>
                              </div>
                            </div>
                          ))}
                      </List>
                      <LoadMoreButton
                        list={userSelection}
                        pagination={pagination}
                        btnStyle="btn-sm"
                        loading={loadMore}
                        onClick={onLoadMore}
                        btnContainerStyle="my-4"
                        type="button"
                        containerRef={containerRef}
                      />
                      {userSelection.filter(
                        (value) => Object.keys(value).length !== 0
                      ).length < 1 && (
                        <p className="text-muted text-center mt-3">{`No users`}</p>
                      )}
                    </TabPane>
                  </TabContent>
                </CardSection>
              </CardForm>
            </CardBody>
          </>
        )}
        <CardFooter className="px-3 bg-gray-5">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="text-black-50 font-weight-medium mb-0">
              {`${selectedRoleData?.totalUsers || 0} Total Users`}
            </h5>
            <div>
              <CardButton
                title={CANCEL_LABEL}
                variant={'light'}
                onClick={() => setShowModal(false)}
                className="btn-sm mr-2"
              />
              <CardButton
                title={buttons.save.title}
                variant={buttons.save.variant}
                onClick={handleSubmit}
                isLoading={isLoading}
                disabled={loading}
                className="btn-sm ms-2"
              />
            </div>
          </div>
        </CardFooter>
      </RightPanelModal>
    </>
  );
};

export default EditRoleModal;
