import { useState, useEffect } from 'react';
import ProspectSaveSearch from './ProspectSaveSearch';
import { numbersWithComma } from '../../../utils/Utils';
import Skeleton from 'react-loading-skeleton';

const Counter = ({ max }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 500) {
      setCount(max); // skip the increment and set to max directly if max is greater than 500
      return;
    }
    const interval = setInterval(() => {
      if (count < max) {
        setCount(count + 1);
      }
    }, 2);

    return () => clearInterval(interval);
  }, [count, max]);

  return <span>{numbersWithComma(count)}</span>;
};

const ProspectResults = ({
  filter,
  pagination,
  type,
  setErrorMessage,
  setSuccessMessage,
  resultsOnly = false,
  saveSearchOnly = false,
  loading = false,
}) => {
  return (
    <div className="d-flex align-items-center">
      {resultsOnly ? (
        <>
          {loading ? (
            <div className="d-flex p-2 justify-content-center align-items-center ">
              <Skeleton width={80} height={10} />
            </div>
          ) : (
            <div className="stats">
              <ul className="px-3">
                <li className="d-flex fs-7 align-items-center justify-content-center gap-1">
                  <label className="mb-0 fs-7 font-weight-normal cursor-pointer">
                    Results
                  </label>
                  <span className="text-gray-400">•</span>
                  <label className="mb-0 cursor-pointer text-black">
                    <Counter max={pagination?.total || 0} />
                  </label>
                </li>
              </ul>
            </div>
          )}
        </>
      ) : (
        ''
      )}
      {saveSearchOnly ? (
        <ProspectSaveSearch
          filter={filter}
          type={type}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default ProspectResults;
