import React, { Fragment, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import ButtonIcon from '../../commons/ButtonIcon';
import { Form } from 'react-bootstrap';
import MaterialIcon from '../../commons/MaterialIcon';
import activityService from '../../../services/activity.service';
import feedService from '../../../services/feed.service';
import { ShortDescription } from '../../ShortDescription';
import { useClientPortalContext } from '../../../contexts/clientPortalContext';
import { formatUSPhoneNumber } from '../../../utils/Utils';
import Avatar from '../../Avatar';

const DAYS = [
  { id: 7, text: 'sunday', isSelected: false },
  { id: 1, text: 'monday', isSelected: false },
  { id: 2, text: 'tuesday', isSelected: false },
  { id: 3, text: 'wednesday', isSelected: false },
  { id: 4, text: 'thursday', isSelected: false },
  { id: 5, text: 'friday', isSelected: false },
  { id: 6, text: 'saturday', isSelected: false },
];
const scheduleCallExtraOptions = [
  {
    id: 'schedule-option-1',
    icon: 'location_on',
    description: 'Details provided upon confirmation',
  },
];

const ModalScheduleCallForm = (props) => {
  const [days, setDays] = useState(DAYS);
  const [value, setValue] = useState([]);
  const [notes, setNotes] = useState('');
  const [requesting, setRequesting] = useState(false);
  const [scheduleCallOptions, setScheduleCallOptions] = useState(
    scheduleCallExtraOptions
  );
  const { organizationId, owner } = useClientPortalContext();

  useEffect(() => {
    if (!props.data?.phone) {
      const newScheduleOptions = [
        ...scheduleCallOptions,
        {
          id: 'schedule-option-3',
          icon: 'call',
          description: props.data?.phone,
        },
      ];

      setScheduleCallOptions(newScheduleOptions);
    }
  }, [props.data]);

  const initForm = () => {
    setDays(
      [...days].map((d) => ({ ...d, isSelected: d.id === new Date().getDay() }))
    );
    setValue(value);
    setNotes('');
  };

  useEffect(() => {
    // get current day from date and select in UI
    setDays(
      [...days].map((d) => ({ ...d, isSelected: d.id === new Date().getDay() }))
    );
  }, []);

  const handleRequestCall = async () => {
    setRequesting(true);
    try {
      const requestPayload = {
        availability: {
          days: ['monday'],
          timePeriods: ['morning'],
        },
        notes,
      };
      await activityService.createActivityRequest(requestPayload);
      await feedService.createNote(
        notes,
        {
          blocks: [
            {
              key: crypto.randomUUID(),
              text: notes,
              type: 'unstyled',
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
          ],
          entityMap: {},
        },
        null,
        organizationId,
        null,
        null,
        null,
        owner?.tenant_id,
        owner?.id,
        owner?.id
      );
      initForm();
      props.onModalSuccessMsg();
    } catch (e) {
      console.log(e);
    } finally {
      setRequesting(false);
    }
  };

  return (
    <Fragment>
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="request_call"
        centered
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton className="px-3 pt-3 pb-2">
          <Modal.Title
            id="request_call"
            className="d-inline-flex justify-content-center w-100 text-center"
          >
            Contact Your Representative
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 pb-3 pt-0">
          <div className="modal-request-call">
            <div className="row mb-3">
              <div className="col">
                <div className="d-flex gap-2 align-items-center">
                  <Avatar user={props.data} />
                  <div>
                    <div className="d-flex align-items-center">
                      {props.data?.name && (
                        <h4 className="mb-0 text-capitalize">
                          {props.data?.name}{' '}
                        </h4>
                      )}
                      {props.data?.title && (
                        <p className="text-muted fs-7 mb-0 pl-2">
                          ({props.data?.title})
                        </p>
                      )}
                    </div>
                    {props.data?.phone || props.data?.email ? (
                      <div className="d-flex align-items-center pt-1 gap-2">
                        <div className="d-flex align-items-center gap-1">
                          <div className="d-flex gap-2 align-items-center">
                            {props.data?.phone && (
                              <p className="d-flex align-items-center gap-1 mb-0">
                                <MaterialIcon
                                  clazz="text-primary"
                                  size="fs-6"
                                  icon="phone"
                                  rounded
                                  filled
                                />
                                <span className="font-size-sm2">
                                  {formatUSPhoneNumber(props.data?.phone)}
                                </span>
                              </p>
                            )}
                            {props.data?.email && (
                              <p className="d-flex align-items-center gap-1 mb-0">
                                <MaterialIcon
                                  clazz="text-primary"
                                  size="fs-6"
                                  icon="mail"
                                  rounded
                                  filled
                                />
                                <span className="font-size-sm2">
                                  <ShortDescription
                                    content={props.data?.email}
                                    limit={50}
                                  />
                                </span>
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row gy-2">
              <div className="col">
                <div className="d-flex flex-column gap-2 w-100 h-100">
                  <Form.Control
                    as="textarea"
                    className="rounded flex-fill"
                    style={{ height: 300 }}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Question or Feedback"
                  />
                  <ButtonIcon
                    icon=""
                    color="primary"
                    classnames="btn-md btn-block"
                    label="Contact"
                    loading={requesting}
                    disabled={!notes?.length}
                    onClick={handleRequestCall}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalScheduleCallForm;
