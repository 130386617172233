import React, { useEffect, useRef, useState } from 'react';
import MaterialIcon from '../components/commons/MaterialIcon';
import stageService from '../services/stage.service';
import pipelineService from '../services/pipeline.services';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import ButtonIcon from '../components/commons/ButtonIcon';
import MoreActions from '../components/MoreActions';
import { Form, FormGroup, InputGroup } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import TooltipComponent from '../components/lesson/Tooltip';
import SimpleModalCreation from '../components/modal/SimpleModalCreation';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import InputValidation from '../components/commons/InputValidation';
import Asterick from '../components/commons/Asterick';
import InlineInput from '../components/commons/InlineInput';
import DeleteConfirmationModal from '../components/modal/DeleteConfirmationModal';
import TeamsService from '../services/teams.service';
import Alert from '../components/Alert/Alert';
import AlertWrapper from '../components/Alert/AlertWrapper';
import DropdownValidation from '../components/commons/DropdownValidation';
import CheckBoxInput from '../components/inputs/CheckBoxInput';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import { useModuleContext } from '../contexts/moduleContext';
import { isPermissionAllowed } from '../utils/Utils';
import TextOverflowTooltip from '../components/commons/TextOverflowTooltip';
import { icons } from '../components/manageLessons/ManageLessonsConstants';
import IconDropdownSearch from '../components/commons/IconDropdownSearch';
import SearchOptionDropdown from '../components/commons/SearchOptionDropdown';
import {
  CLOSED_LOST,
  CLOSED_WON,
  LOST,
  WON,
} from '../views/Deals/pipelines/Pipeline.constants';
import { LexoRank } from 'lexorank';

const generateRankAtEnd = (lastItemRank) => {
  const lastRank = lastItemRank ? LexoRank.parse(lastItemRank) : LexoRank.min();
  return lastRank.between(LexoRank.max()).toString();
};
const Messages = {
  Team: 'Team is assigned.',
  Teams: 'All teams are assigned.',
  TeamRemoved: 'Team is removed.',
  TeamUpdated: 'Teams updated',
  TeamError:
    'Error assigning team to pipeline. Please check console for details.',
  Stage: {
    Added: 'Stage is created.',
    Updated: 'Stage is updated.',
    Deleted: 'Stage is deleted.',
    Position: 'Stage rearranged successfully.',
    DeletedError: 'Error deleting stage. Please check console for details.',
  },
  Pipeline: {
    Created: 'Opportunity is created.',
    Updated: 'Opportunity name is updated.',
    IconUpdated: 'Icon Updated',
    Deleted: 'Opportunity is deleted.',
    DeletedError:
      'Error deleting opportunity. Please check console for details.',
    Default: 'Opportunity set as default.',
    DefaultError:
      'Error setting opportunity as default. Please check console for details.',
  },
  TeamGlobal: 'Opportunity marked as global.',
  TeamGlobalError:
    'Error setting opportunity global, Please check console for details.',
};

const Actions = {
  Add: 'ADD',
  Edit: 'EDIT',
  Update: 'UPDATE',
  Save: 'SAVE',
  Remove: 'REMOVE',
  Delete: 'DELETE',
  Clone: 'CLONE',
  Default: 'DEFAULT',
};

const PIPELINE_NEW_KEY = 'PipelineNew';

const DeleteStageModal = ({
  stages,
  stage,
  openModal,
  setOpenModal,
  handleConfirmModal,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const { moduleMap } = useModuleContext();
  const {
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { stageId: '' },
  });
  const [moveStageId, setMoveStageId] = useState(-1);
  const stagesExceptMe = stages.filter((p) => p.id !== stage.id);

  useEffect(() => {
    setMoveStageId(-1);
  }, [openModal]);

  const handleStageChange = (e) => {
    setMoveStageId(e.target.value);
  };

  const handleDeleteStage = async () => {
    if (stagesExceptMe.length > 0 && moveStageId === -1) {
      throw new Error(
        JSON.stringify({
          type: 'no_selection',
          message: 'Select a stage to transfer',
        })
      );
    }
    try {
      await stageService.deleteStage(
        stage.id,
        moveStageId === -1 ? null : moveStageId
      );
      setSuccessMessage(Messages.Stage.Deleted);
      reset({ stageId: '' });
      handleConfirmModal();
    } catch (err) {
      console.log(JSON.stringify(err));
      setErrorMessage(Messages.Stage.DeletedError);
    }
  };

  return (
    <DeleteConfirmationModal
      showModal={openModal}
      setShowModal={setOpenModal}
      handleCloseModal={() => reset({ stageId: '' })}
      event={handleDeleteStage}
      heading="Delete Stage"
      description={`Are you sure you want to remove this stage from the ${moduleMap.deal.singular}?`}
      extraBody={
        stagesExceptMe.length > 0 ? (
          <div className="mt-3">
            <p>
              Before deleting, we&apos;ll check if there are any deals
              associated with this stage. In that case we&apos;ll transfer those
              deals to another stage you choose from the below drop-down.
            </p>

            <FormGroup className="d-flex w-100 gap-2 align-items-center">
              <Label className="mb-0">Stage Name</Label>
              <div className="flex-fill">
                <DropdownValidation
                  name="stageId"
                  value={moveStageId}
                  validationConfig={{
                    required: 'Select a stage to transfer.',
                    onChange: (e) => {
                      handleStageChange(e);
                      // hook form method to set so that requirement meets
                      setValue('stageId', e.target.value);
                    },
                  }}
                  errors={errors}
                  customKeys={['id', 'name']}
                  register={register}
                  classNames="font-size-sm comfort"
                  options={stagesExceptMe || []}
                  emptyOption="None"
                  placeholder="Select stage"
                  errorDisplay="position-absolute mb-0 left-0 -bottom-26"
                />
              </div>
            </FormGroup>
          </div>
        ) : null
      }
    />
  );
};

const DeletePipelineModal = ({
  pipeline,
  pipelines,
  openModal,
  setOpenModal,
  handleConfirmModal,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const {
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { stageId: '', pipelineId: '' },
  });
  const [moveStageId, setMoveStageId] = useState(-1);
  const pipelinesExceptMe = pipelines.filter((p) => p.id !== pipeline.id);
  const [selectedPipeline, setSelectedPipeline] = useState(
    pipelinesExceptMe[0]
  );
  const [loaderStages, setLoaderStages] = useState(false);
  const [stages, setStages] = useState([]);
  const { moduleMap } = useModuleContext();

  useEffect(() => {
    setMoveStageId(-1);
    setValue('pipelineId', selectedPipeline?.id);
  }, [openModal]);

  const handleStageChange = (e) => {
    setMoveStageId(e.target.value);
  };

  const getStagesByPipeline = async (id) => {
    setLoaderStages(true);

    try {
      const data = await stageService.getStages(id);
      setStages(data);
      setMoveStageId(-1);
    } catch (error) {
      console.log(error);
    } finally {
      setLoaderStages(false);
    }
  };

  const handlePipelineChange = async (e) => {
    const { value } = e.target;
    setSelectedPipeline({ id: value });
    setValue('pipelineId', value);
    if (value) {
      getStagesByPipeline(value);
    }
  };

  const handleDeletePipeline = async () => {
    if (stages.length > 0 && moveStageId === -1) {
      throw new Error(
        JSON.stringify({
          type: 'no_selection',
          message: 'Select a stage to transfer',
        })
      );
    }
    try {
      await pipelineService.deletePipeline(
        pipeline.id,
        moveStageId === -1 ? null : moveStageId
      );
      setSuccessMessage(`${moduleMap.deal.singular} Deleted.`);
      reset({ stageId: '', pipelineId: '' });
      handleConfirmModal();
    } catch (err) {
      console.log(JSON.stringify(err));
      setErrorMessage(Messages.Pipeline.DeletedError);
    }
  };

  useEffect(() => {
    getStagesByPipeline(selectedPipeline?.id);
  }, [openModal]);

  return (
    <>
      <DeleteConfirmationModal
        showModal={openModal}
        setShowModal={setOpenModal}
        handleCloseModal={() => {
          reset({ stageId: '', pipelineId: '' });
          setOpenModal(!openModal);
        }}
        event={handleDeletePipeline}
        heading={`Delete ${moduleMap.deal.singular}`}
        description={`Are you sure you want to delete this ${moduleMap.deal.singular}?`}
        extraBody={
          <div className="mt-3">
            <p>
              This {moduleMap.deal.singular} has deals associated with it.
              Choose another {moduleMap.deal.singular} where you want these
              deals transferred.
            </p>
            <FormGroup className="d-flex w-100 gap-2 align-items-center">
              <Label className="mb-0">{moduleMap.deal.singular}</Label>
              <div className="flex-fill">
                <DropdownValidation
                  name="pipelineId"
                  value={selectedPipeline?.id}
                  validationConfig={{
                    required: `Select ${moduleMap.deal.singular} to load its stages.`,
                    onChange: (e) => {
                      handlePipelineChange(e);
                    },
                  }}
                  errors={errors}
                  customKeys={['id', 'name']}
                  register={register}
                  classNames="font-size-sm comfort"
                  options={pipelinesExceptMe || []}
                  emptyOption="None"
                  placeholder="Select pipeline"
                  errorDisplay="mb-0 mt-2"
                />
              </div>
            </FormGroup>
            <FormGroup className="d-flex w-100 gap-2 align-items-center">
              <Label className="mb-0">Stage name</Label>
              <div className="flex-fill position-relative">
                <DropdownValidation
                  name="stageId"
                  value={moveStageId}
                  validationConfig={{
                    required: 'Select a stage to transfer.',
                    onChange: (e) => {
                      handleStageChange(e);
                      // hook form method to set so that requirement meets
                      setValue('stageId', e.target.value);
                    },
                  }}
                  errors={errors}
                  customKeys={['id', 'name']}
                  register={register}
                  classNames="font-size-sm comfort"
                  options={stages || []}
                  emptyOption="None"
                  placeholder="Select stage"
                  errorDisplay="mb-0 position-absolute left-0 -bottom-26"
                />

                {loaderStages && (
                  <Spinner
                    className="spinner-grow-xs position-absolute"
                    style={{ right: 40, top: 14 }}
                  />
                )}
              </div>
            </FormGroup>
          </div>
        }
      />
    </>
  );
};

const AddEditPipelineStageModal = ({
  pipeline,
  stages,
  stage,
  openModal,
  setOpenModal,
  handleConfirmModal,
  mode = Actions.Add,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { name: '', probability: 0 },
  });

  const [editedStage, setEditedStage] = useState({ ...stage });
  const [loaderSaveStage, setLoaderSaveStage] = useState(false);

  useEffect(() => {
    setEditedStage(stage);
    setValue('name', stage?.name);
    setValue('probability', stage?.probability);
  }, [stage]);

  const handleProbabilityChange = (value) => {
    setEditedStage({ ...editedStage, probability: value });
  };

  const handleValueChange = (e) => {
    const { value } = e.target;
    setEditedStage({
      ...editedStage,
      name: value,
    });
    setValue('name', value);
  };

  const handleSave = async () => {
    setLoaderSaveStage(true);
    if (mode === Actions.Add) {
      const position =
        stages.length > 0 ? stages[stages.length - 1].position + 1 : 1;
      let newStage = [];
      try {
        const lastItemRank = stages.at(-1)?.rank;
        const newRank = generateRankAtEnd(lastItemRank);
        newStage = await stageService.createStage([
          {
            ...editedStage,
            pipelineId: pipeline.id,
            position,
            rank: newRank,
            probability: parseFloat(editedStage.probability),
          },
        ]);
        setSuccessMessage(Messages.Stage.Added);
        handleConfirmModal(Actions.Add, newStage[0]);
      } catch (err) {
        console.log(err);
        setErrorMessage('Error in saving. Please check console for details.');
      } finally {
        setLoaderSaveStage(false);
      }
    } else {
      try {
        await stageService.updateStage(
          {
            ...editedStage,
            pipelineId: pipeline.id,
            probability: parseFloat(editedStage.probability),
            description: '',
          },
          editedStage?.id
        );
        setSuccessMessage(Messages.Stage.Updated);
        handleConfirmModal(Actions.Edit, editedStage);
      } catch (err) {
        console.log(err);
        setErrorMessage('Error in saving. Please check console for details.');
      } finally {
        setLoaderSaveStage(false);
      }
    }
    reset({ name: '', probability: 0 });
  };

  const StageModalTitle = () => {
    return (
      <div className="d-flex align-items-center">
        <span>{mode === Actions.Edit ? 'Edit Stage' : 'Add Stage'}</span>
        <span
          className="ml-2 m-0 fs-7 badge-pill font-weight-medium tag-item"
          color="soft-secondary"
        >
          {pipeline.name}
        </span>
      </div>
    );
  };

  return (
    <SimpleModalCreation
      modalTitle={<StageModalTitle />}
      open={openModal}
      bankTeam={false}
      isLoading={loaderSaveStage}
      handleSubmit={handleSubmit((d) => handleSave(d))}
      onHandleCloseModal={() => setOpenModal(!openModal)}
    >
      <Form onSubmit={handleSubmit(handleSave)}>
        <Row className="align-items-center pb-3">
          <Col md={3}>
            <h5 className="mb-0">
              Stage Name <Asterick />{' '}
            </h5>
          </Col>
          <Col md={9}>
            <InputValidation
              name="name"
              type="input"
              autofocus
              placeholder="Stage Name"
              value={editedStage?.name || ''}
              errorDisplay="position-absolute error-show-right"
              validationConfig={{
                required: true,
                inline: true,
                onChange: handleValueChange,
              }}
              errors={errors}
              register={register}
            />
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col md={3}>
            <h5 className="mb-0">Probability</h5>
          </Col>
          <Col md={9}>
            <InputGroup className="align-items-center">
              <input
                type="number"
                min={0}
                max={100}
                className="form-control"
                placeholder="Probability"
                value={editedStage?.probability}
                onChange={(e) => handleProbabilityChange(e.target.value)}
              />
              <InputGroup.Append>
                <InputGroup.Text style={{ width: 40 }}>%</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
      </Form>
    </SimpleModalCreation>
  );
};

const StageSkeletonLoader = ({ circle = true, rows }) => {
  const [rowCount] = useState(Array(rows).fill(0));
  const Circle = ({ children }) => {
    return <div style={{ height: 20, width: 20 }}>{children}</div>;
  };
  return (
    <>
      {rowCount.map((r, idx) => (
        <div key={idx} className="d-flex col py-2 my-2 px-0 align-items-center">
          {circle && (
            <Circle>
              <Skeleton
                circle
                style={{ borderRadius: '50%', lineHeight: 1.3 }}
              />
            </Circle>
          )}
          <div className={`w-100 ${circle ? 'ml-2' : 'ml-0'}`}>
            <Skeleton height="10" />
          </div>
        </div>
      ))}
    </>
  );
};

const StageItem = ({
  stage,
  index,
  disabled,
  onHandleEdit,
  pipeline,
  onHandleRemove,
  isDraggable,
  closedStage = false,
  handleValueChange,
  handleAddNewLastStage,
  handleRemoveLastStage,
  totalStages,
}) => {
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues: { name: stage.name || '' },
  });

  const actionItems = [
    {
      id: 'remove',
      icon: 'delete',
      name: 'Delete',
    },
  ];

  const JustField = () => {
    return (
      <div className="position-relative ml-1 d-flex align-items-center flex-grow-1 border w-100 px-2 py-1 bg-white my-2 rounded">
        <div className="flex-grow-1">
          <p className="fs-7 mb-0 font-weight-medium">
            <TextOverflowTooltip maxLength={25} text={stage.name} />
          </p>
          {closedStage ? (
            <span className="fs-8 mb-0">Probability: 100%</span>
          ) : (
            <span className="fs-8 mb-0">
              {`Probability: ${stage.probability}%`}
            </span>
          )}
        </div>
        <div
          className={`d-flex align-items-center refresh-icon ${
            disabled ? 'hide' : ''
          }`}
        >
          {!pipeline?.cloned && (
            <>
              <TooltipComponent title="Edit stage">
                <a
                  onClick={() => onHandleEdit(stage)}
                  className={`icon-hover-bg mr-1 cursor-pointer`}
                >
                  <MaterialIcon
                    icon="edit"
                    clazz="text-gray-700 font-size-lg"
                  />{' '}
                </a>
              </TooltipComponent>

              <a className={`icon-hover-bg cursor-pointer`}>
                <MoreActions
                  icon="more_vert"
                  items={actionItems}
                  onHandleRemove={() => onHandleRemove(stage)}
                  onHandleEdit={() => onHandleEdit(stage)}
                  toggleClassName="w-auto p-0 h-auto"
                />
              </a>
            </>
          )}
        </div>
        {closedStage && (
          <div className={`closedStage ${stage.id}`}>
            <div className="d-flex align-items-center px-2">
              {stage.id === WON && (
                <MaterialIcon
                  icon="thumb_up"
                  clazz="text-success"
                  size="fs-6"
                />
              )}
              {stage.id === LOST && (
                <MaterialIcon icon="thumb_down" clazz="text-red" size="fs-6" />
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {isDraggable ? (
        <Draggable key={stage.id} draggableId={stage.id} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={`d-flex pl-2 pr-3 setting-item bg-hover-gray align-items-center ${
                snapshot.isDragging ? 'shadow-lg rounded-lg' : ''
              } `}
            >
              <MaterialIcon icon="drag_indicator" clazz="text-gray-600" />
              {pipeline?.id === PIPELINE_NEW_KEY && !pipeline?.cloned ? (
                <div className="d-flex flex-fill align-items-center gap-2 setting-item py-2">
                  <div className="flex-fill">
                    <InputValidation
                      name="name"
                      type="input"
                      autofocus
                      placeholder="Stage Name"
                      classNames="w-100"
                      value={stage?.name || ''}
                      errorDisplay="position-absolute error-show-right"
                      validationConfig={{
                        required: true,
                        inline: true,
                        onChange: handleValueChange,
                      }}
                      errors={errors}
                      register={register}
                    />
                  </div>
                  <div
                    className={`d-flex align-items-center refresh-icon gap-1`}
                  >
                    {totalStages > 1 &&
                      isPermissionAllowed('pipelines', 'delete') && (
                        <TooltipComponent title="Remove stage">
                          <a
                            onClick={() => handleRemoveLastStage(stage)}
                            className={`cursor-pointer bg-red d-flex rounded-circle align-items-center justify-content-center`}
                          >
                            <MaterialIcon
                              icon="remove"
                              size="fs-6"
                              clazz="text-white"
                            />
                          </a>
                        </TooltipComponent>
                      )}
                    {isPermissionAllowed('pipelines', 'create') && (
                      <TooltipComponent title="Add stage">
                        <a
                          onClick={handleAddNewLastStage}
                          className={`cursor-pointer bg-success d-flex rounded-circle align-items-center justify-content-center`}
                        >
                          <MaterialIcon
                            icon="add"
                            size="fs-6"
                            clazz="text-white"
                          />
                        </a>
                      </TooltipComponent>
                    )}
                  </div>
                </div>
              ) : (
                <JustField />
              )}
            </div>
          )}
        </Draggable>
      ) : (
        <div
          className={`d-flex px-2 setting-item bg-hover-gray align-items-center`}
        >
          <JustField />
        </div>
      )}
    </>
  );
};

const PipelineSection = ({ updatePipelines, pipelines, pipeline }) => {
  let actionItems = [
    {
      id: 'edit',
      icon: 'content_copy',
      permission: { collection: 'deals', action: 'create' },
      name: 'Clone',
    },
  ];

  // if there are more than one pipelines then show delete option, if there is one then dont allow deleting it, IDF-2429
  if (pipelines?.length > 1) {
    actionItems = [
      ...actionItems,
      {
        id: 'remove',
        icon: 'delete',
        permission: { collection: 'deals', action: 'delete' },
        name: 'Delete',
      },
    ];
  }

  const WonLostStages = [
    {
      id: WON,
      name: 'Closed Won',
      active: true,
      position: 1,
      probability: 0,
      pipelineId: pipeline?.id,
    },
    {
      id: LOST,
      name: 'Closed Lost',
      active: true,
      position: 2,
      probability: 0,
      pipelineId: pipeline?.id,
    },
  ];

  const { moduleMap } = useModuleContext();
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaderPipelineName, setLoaderPipelineName] = useState(false);
  const [stages, setStages] = useState([]);
  const [currentStage, setCurrentStage] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeletePipelineModal, setOpenDeletePipelineModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [mode, setMode] = useState(Actions.Add);
  const [pipe, setPipe] = useState(pipeline);
  const [showDeletePipelineModal, setShowDeletePipelineModal] = useState(false);
  const [pipelinesToDelete, setPipelinesToDelete] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [prevSelectedTeam, setPrevSelectedTeam] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [deletingPipeline, setDeletingPipeline] = useState(false);
  const [showTeams, setShowTeams] = useState(false);
  const latestSelectedData = useRef(selectedTeam);
  const [wonLostStages, setWonLostStages] = useState(WonLostStages);

  const sortByRank = (a, b) => {
    return a.rank.localeCompare(b.rank);
  };

  const onCancelPipelineName = async () => {
    setPipe(pipeline);
  };

  const onSavePipelineName = async () => {
    setLoaderPipelineName(true);
    // when cloning pipeline
    if (pipeline?.cloned) {
      // first need to create pipeline
      const newPipeline = await pipelineService.createPipeline({
        name: pipe.name,
      });
      // if cloned pipeline has stages then create those as well with new pipeline
      if (stages.length) {
        const newStages = stages.map((s, index) => ({
          name: s.name,
          position: index + 1,
          probability: s.probability,
          pipelineId: newPipeline.id,
          description: '',
        }));
        const createdStages = await stageService.createStage(newStages);
        setLoaderPipelineName(false);
        setStages(createdStages);
        updatePipelines(Actions.Save, newPipeline);
      }
    } else {
      if (pipeline.id !== PIPELINE_NEW_KEY) {
        await pipelineService.updatePipeline(pipeline.id, {
          name: pipe.name,
        });
        setLoaderPipelineName(false);
        updatePipelines(Actions.Update, pipe);
        setSuccessMessage(Messages.Pipeline.Updated);
      } else {
        const newPipeline = await pipelineService.createPipeline({
          name: pipe.name,
        });
        // if pipeline has stages then create those as well with new pipeline
        const newStages = stages
          ?.filter((s) => s.name) // Filter stages to include only those with a non-empty name
          ?.map((s) => ({
            name: s.name,
            position: Number(s.position),
            probability: s.probability,
            rank: s.rank,
            pipelineId: newPipeline.id,
          }));
        if (newStages?.length) {
          const createdStages = await stageService.createStage(newStages);
          setStages(createdStages);
          setLoaderPipelineName(false);
          updatePipelines(Actions.Save, newPipeline);
          setSuccessMessage(Messages.Pipeline.Created);
        } else {
          setStages([]);
          setLoaderPipelineName(false);
          updatePipelines(Actions.Save, newPipeline);
          setSuccessMessage(Messages.Pipeline.Created);
        }
      }
    }
  };

  const handleAddNewStage = () => {
    setIsEditingMode(false);
    setMode(Actions.Add);
    setCurrentStage({ name: '', probability: 0 });
    setOpenEditModal(true);
  };

  const onHandleRemovePipeline = async () => {
    setDeletingPipeline(true);
    const { counts } = await pipelineService.getPipelineSummary(pipeline.id);

    setDeletingPipeline(false);
    if (counts.deal > 0) {
      setOpenDeletePipelineModal(true);
    } else {
      // if no deals in any of the stages then just delete pipeline
      setPipelinesToDelete([{ ...pipeline, title: pipeline.name }]);
      setShowDeletePipelineModal(true);
    }
  };

  const onHandleClonePipeline = () => {
    updatePipelines(Actions.Clone, pipeline, stages);
  };

  const onHandleRemove = (stage) => {
    setIsEditingMode(false);
    setCurrentStage(stage);
    setOpenDeleteModal(true);
  };

  const onHandleEdit = (stage) => {
    setIsEditingMode(false);
    setCurrentStage(stage);
    setMode(Actions.Edit);
    setOpenEditModal(true);
  };

  const handleConfirmDeleteStage = () => {
    setOpenDeleteModal(false);
    setStages([...stages].filter((s) => s.id !== currentStage.id));
  };

  const handleConfirmDeletePipelineOrTransfer = () => {
    setOpenDeletePipelineModal(false);
    updatePipelines(Actions.Delete, pipeline);
  };

  const handleConfirmDeletePipeline = async () => {
    try {
      await pipelineService.deletePipeline(pipeline.id);
      setSuccessMessage(Messages.Pipeline.Deleted);
      setShowDeletePipelineModal(false);
      updatePipelines(Actions.Delete, pipeline);
    } catch (err) {
      console.log(JSON.stringify(err));
      setErrorMessage(Messages.Pipeline.DeletedError);
    }
  };

  const handleConfirmUpdateStage = (action, updatedStage) => {
    setIsEditingMode(false);
    setOpenEditModal(false);
    if (action === Actions.Edit) {
      setStages([
        ...stages
          .map((stage) =>
            stage.id === updatedStage.id ? { ...updatedStage } : stage
          )
          .sort(sortByRank),
      ]);
    } else {
      setStages([...stages, updatedStage].sort(sortByRank));
    }
  };

  const handleGlobalTeamChange = async (e) => {
    const { checked } = e.target;
    try {
      await pipelineService.updatePipeline(pipeline.id, {
        global: checked,
      });
      setSuccessMessage(Messages.TeamGlobal);
      updatePipelines(Actions.Update, { ...pipeline, global: checked });
    } catch (e) {
      console.log(e);
      setErrorMessage(Messages.TeamGlobalError);
    }
  };

  const handleTeamSelect = (event) => {
    const { value } = event.target;
    setSelectedTeam((prevState) => {
      const isSelected = prevState.includes(value);
      const updatedSelectedData = isSelected
        ? prevState.filter((item) => item !== value)
        : [...prevState, value];

      latestSelectedData.current = updatedSelectedData;
      return updatedSelectedData;
    });
  };

  const handleAllSelect = (all, allOrNone) => {
    setSelectedTeam(allOrNone);
    latestSelectedData.current = allOrNone;
  };

  const handleAPIUpdate = async (updatedSelectedData) => {
    // Compare prevSelectedTeam with updatedSelectedData
    const addedTeamsIds = updatedSelectedData.filter(
      (team) => !prevSelectedTeam?.includes(team)
    );
    const removedTeamsIds = prevSelectedTeam?.filter(
      (team) => !updatedSelectedData.includes(team)
    );

    // Call API functions to add and remove teams
    if (addedTeamsIds.length > 0) {
      await addTeamsToPipeline(addedTeamsIds);
    }
    if (removedTeamsIds.length > 0) {
      await removeTeamsFromPipeline(removedTeamsIds);
    }

    // Update prevSelectedTeam
    setPrevSelectedTeam(updatedSelectedData);
  };

  useEffect(() => {
    if (!showTeams) {
      handleAPIUpdate(latestSelectedData.current);
    }
  }, [showTeams]);

  const addTeamsToPipeline = async (teamsToAdd) => {
    try {
      await pipelineService.setPipelineTeam(
        teamsToAdd.map((id) => ({
          teamId: id,
          pipelineId: pipeline.id,
        }))
      );
      setSuccessMessage(Messages.TeamUpdated);
    } catch (err) {
      console.log(JSON.stringify(err));
      setErrorMessage(Messages.TeamError);
    }
  };

  const removeTeamsFromPipeline = async (teamsToRemove) => {
    try {
      for (const id of teamsToRemove) {
        await pipelineService.deletePipelineTeam(pipeline.id, id);
      }
      setSuccessMessage(Messages.TeamUpdated);
    } catch (err) {
      console.log(JSON.stringify(err));
      setErrorMessage(Messages.TeamError);
    }
  };

  const setAsDefault = async (e) => {
    e.preventDefault();
    if (!pipeline?.isDefault) {
      setDeletingPipeline(true);
      try {
        await pipelineService.setDefaultPipeline(pipeline.id);
        setSuccessMessage(Messages.Pipeline.Default);
        updatePipelines(Actions.Default, pipeline);
      } catch (err) {
        console.log(JSON.stringify(err));
        setErrorMessage(Messages.Pipeline.DefaultError);
      }
      setDeletingPipeline(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (pipeline?.id !== PIPELINE_NEW_KEY) {
        setLoading(true);
        const data = await stageService.getStages(pipeline?.id);
        setStages(data?.sort(sortByRank));
        setLoading(false);
      }
      const { data } = await TeamsService.getTeams({
        page: 1,
        limit: 5,
        filter: { isActive: true },
      });
      setTeams(data);
    })();
  }, []);

  useEffect(() => {
    setPipe(pipeline);
    if (pipeline?.stages) {
      setStages(pipeline?.stages);
    }
  }, [pipeline]);

  useEffect(() => {
    (async () => {
      if (pipeline?.id !== PIPELINE_NEW_KEY) {
        const pipelineTeams = await pipelineService.getPipelineTeam(
          pipeline?.id
        );
        const selectedTeams = pipelineTeams?.map((item) => item.id);
        if (teams) {
          setSelectedTeam(selectedTeams);
          setPrevSelectedTeam(selectedTeams);
        }
      }
    })();
  }, []);

  const handleIconSelect = async (item) => {
    try {
      setDeletingPipeline(true);
      const updatedPipe = { ...pipe, icon: item.name };
      setPipe(updatedPipe);
      // Update the icon in the backend
      await pipelineService.updatePipeline(pipe.id, { icon: item.name });
      setSuccessMessage(Messages.Pipeline.IconUpdated);
    } catch (e) {
      console.log(e);
    } finally {
      setDeletingPipeline(false);
    }
  };

  const teamOptions = teams?.map((item) => ({ key: item.id, name: item.name }));

  const updateStageRank = async (stage) => {
    try {
      setDeletingPipeline(true);
      await stageService.updateStage(
        {
          rank: stage.rank,
        },
        stage?.id
      );
      setSuccessMessage(Messages.Stage.Position);
    } catch (err) {
      console.log(err);
      setErrorMessage(
        'Error in updating stage position. Please check console for details.'
      );
    } finally {
      setDeletingPipeline(false);
    }
  };
  const updateStageOrder = (fromIndex, toIndex) => {
    const updatedItems = [...stages].filter(filterNotWonLostStages);
    const [movedItem] = updatedItems.splice(fromIndex, 1); // Remove item
    updatedItems.splice(toIndex, 0, movedItem); // Insert at new position

    const previousRank =
      toIndex === 0
        ? LexoRank.min()
        : LexoRank.parse(updatedItems[toIndex - 1].rank);
    const nextRank =
      toIndex === updatedItems.length - 1
        ? LexoRank.max()
        : LexoRank.parse(updatedItems[toIndex + 1].rank);
    const newRank = previousRank.between(nextRank).toString();

    movedItem.rank = newRank;
    updatedItems.sort(sortByRank);
    setStages(updatedItems);
    if (pipeline.id !== PIPELINE_NEW_KEY && !pipeline.cloned) {
      updateStageRank(movedItem);
    } else {
      setSuccessMessage(Messages.Stage.Position);
    }
  };

  // using useCallback is optional
  const reorder = (list, startIndex, endIndex) => {
    updateStageOrder(startIndex, endIndex);
  };

  const onHandleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    updateStageOrder(result.source.index, result.destination.index);
  };

  const onHandleDragEndClosed = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      [...wonLostStages],
      result.source.index,
      result.destination.index
    );
    setWonLostStages(items);
  };

  const handleAddNewLastStage = () => {
    const nextId = (stages.length + 1).toString();
    const lastItemRank = stages.at(-1)?.rank || LexoRank.min().toString();
    const newRank = generateRankAtEnd(lastItemRank);
    const newStage = {
      id: nextId,
      name: '',
      position: nextId,
      rank: newRank,
      probability: 0,
    };
    setStages([...stages, newStage]);
  };

  const handleRemoveLastStage = (stageToRemove) => {
    setStages((prevStages) => {
      const newStages = prevStages
        .filter((stage) => stage.id !== stageToRemove.id)
        .map((stage, index) => ({
          ...stage,
          id: (index + 1).toString(),
          position: (index + 1).toString(),
        }));
      return newStages;
    });
  };

  const handleValueChange = (e, stage) => {
    const newStages = stages?.map((s) =>
      s.id === stage.id ? { ...s, name: e.target.value } : s
    );
    setStages(newStages);
  };

  // filtering out those won/lost coming from API
  const filterNotWonLostStages = (st) => {
    return st.name !== CLOSED_LOST && st.name !== CLOSED_WON;
  };
  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>

      <DeleteConfirmationModal
        showModal={showDeletePipelineModal}
        setShowModal={setShowDeletePipelineModal}
        setSelectedCategories={setPipelinesToDelete}
        itemsConfirmation={pipelinesToDelete}
        event={handleConfirmDeletePipeline}
        heading={`Delete ${moduleMap.deal.singular}`}
        description={`Are you sure you want to delete this ${moduleMap.deal.singular}?`}
      />

      <DeletePipelineModal
        openModal={openDeletePipelineModal}
        setOpenModal={setOpenDeletePipelineModal}
        pipeline={pipeline}
        pipelines={pipelines}
        handleConfirmModal={handleConfirmDeletePipelineOrTransfer}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
      />
      <DeleteStageModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        stages={stages}
        stage={currentStage}
        handleConfirmModal={handleConfirmDeleteStage}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
      />
      <AddEditPipelineStageModal
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        mode={mode}
        stages={stages}
        stage={currentStage}
        pipeline={pipeline}
        handleConfirmModal={handleConfirmUpdateStage}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
      />
      <Card className="h-100 hover-actions shadow-none">
        <CardHeader className="px-3 py-2">
          {isEditingMode ? (
            <InlineInput
              show={isEditingMode}
              setShow={setIsEditingMode}
              value={pipe?.name}
              loading={loaderPipelineName}
              setInputValue={(name) => setPipe({ ...pipe, name })}
              onCancel={onCancelPipelineName}
              onSave={onSavePipelineName}
            />
          ) : (
            <div className="d-flex align-items-start w-100 justify-content-between">
              {pipeline.id !== PIPELINE_NEW_KEY && !pipeline.cloned ? (
                <>
                  <div>
                    {loaderPipelineName ? (
                      <Skeleton height="10" width={240} />
                    ) : (
                      <h4 className="mb-0 d-inline-flex align-items-center justify-content-center">
                        <div className="position-relative">
                          <MaterialIcon
                            icon={pipe?.icon}
                            onClick={(e) => e.stopPropagation()}
                            clazz="mt-1 bg-gray-hover"
                          />
                          <IconDropdownSearch
                            options={icons}
                            onSelect={handleIconSelect}
                          >
                            <TooltipComponent title="Change icon">
                              <a className="cursor-pointer position-absolute bottom-0 right-0">
                                <MaterialIcon
                                  icon="unfold_more"
                                  clazz="text-red opacity-0 d-inline"
                                />
                              </a>
                            </TooltipComponent>
                          </IconDropdownSearch>
                        </div>
                        <TextOverflowTooltip
                          text={pipe?.name}
                          maxLength={15}
                          textStyle="font-weight-medium text-truncate font-size-md2 mb-0 ml-1"
                        />
                        <a
                          href=""
                          onClick={(e) => setAsDefault(e)}
                          className={`ml-1 ${
                            pipe?.isDefault ? '' : 'action-items'
                          }`}
                        >
                          <TooltipComponent
                            title={
                              pipe?.isDefault
                                ? 'Default stage'
                                : 'Set as default'
                            }
                          >
                            <MaterialIcon
                              icon="star"
                              filled={pipe?.isDefault}
                              clazz={
                                pipe?.isDefault
                                  ? 'text-green ml-1'
                                  : 'text-gray-300 mt-1 ml-1'
                              }
                            />
                          </TooltipComponent>
                        </a>
                      </h4>
                    )}
                    <p className="text-muted fs-8 mb-0">
                      {loading ? (
                        <Skeleton height="10" width={120} />
                      ) : (
                        <> {stages?.length} Stages </>
                      )}
                    </p>
                  </div>
                  <div className={`d-flex align-items-center action-items`}>
                    {deletingPipeline && (
                      <Spinner className="spinner-grow-xs text-gray-700 mx-1" />
                    )}
                    <TooltipComponent title="Rename pipeline">
                      <a
                        className={`icon-hover-bg mr-1 cursor-pointer`}
                        onClick={() => setIsEditingMode(true)}
                      >
                        <MaterialIcon
                          icon="edit"
                          clazz="text-gray-700 font-size-lg"
                        />{' '}
                      </a>
                    </TooltipComponent>
                    {(isPermissionAllowed('deals', 'create') ||
                      isPermissionAllowed('deals', 'delete')) && (
                      <a className={`icon-hover-bg cursor-pointer`}>
                        <MoreActions
                          icon="more_vert"
                          items={actionItems}
                          onHandleRemove={() => onHandleRemovePipeline(null)}
                          onHandleEdit={() => onHandleClonePipeline(null)}
                          toggleClassName="w-auto p-0 h-auto"
                        />
                      </a>
                    )}
                  </div>
                </>
              ) : (
                <InlineInput
                  show={true}
                  setShow={() => {}}
                  value={pipe?.name}
                  setInputValue={(name) => setPipe({ ...pipe, name })}
                  onCancel={onCancelPipelineName}
                  onSave={onSavePipelineName}
                  showControls={false}
                />
              )}
            </div>
          )}
        </CardHeader>
        <CardBody className="py-2 px-0 overflow-y-auto">
          <h5 className="pt-2 pb-0 px-3 mb-0">Open Stages</h5>

          <>
            {loading ? (
              <div className="my-1 px-3">
                <StageSkeletonLoader rows={3} />
              </div>
            ) : (
              <DragDropContext onDragEnd={onHandleDragEnd}>
                <Droppable droppableId={`pipeline-${pipeline?.id}`}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`column-deal ${
                        snapshot.isDraggingOver ? 'bg-gray-300 vh-100' : ''
                      }`}
                    >
                      <TransitionGroup appear={true}>
                        {stages
                          .filter(filterNotWonLostStages)
                          ?.map((stage, index) => (
                            <Collapse key={stage.id}>
                              <StageItem
                                stage={stage}
                                index={index}
                                key={index}
                                pipeline={pipeline}
                                disabled={pipeline?.cloned}
                                totalStages={stages.length}
                                onHandleEdit={onHandleEdit}
                                onHandleRemove={onHandleRemove}
                                isDraggable={true}
                                handleAddNewLastStage={handleAddNewLastStage}
                                handleRemoveLastStage={handleRemoveLastStage}
                                handleValueChange={(e) =>
                                  handleValueChange(e, stage)
                                }
                              />
                            </Collapse>
                          ))}
                        {provided.placeholder}
                      </TransitionGroup>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </>

          {!loading && !pipeline?.cloned && (
            <ButtonIcon
              color="link"
              icon="add"
              disabled={isEditingMode === true}
              onclick={
                pipeline?.id === PIPELINE_NEW_KEY
                  ? handleAddNewLastStage
                  : handleAddNewStage
              }
              label="Stage"
              classnames="py-0 border-0 link-underline px-4 text-primary"
            />
          )}

          <h5 className="pt-4 px-3 mb-0">Closed Stages</h5>
          <>
            {loading ? (
              <div className="my-1 px-3">
                <StageSkeletonLoader rows={2} />
              </div>
            ) : (
              <DragDropContext onDragEnd={onHandleDragEndClosed}>
                <Droppable droppableId={`closed-pipeline-${pipeline?.id}`}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`column-deal ${
                        snapshot.isDraggingOver ? 'bg-gray-300 vh-100' : ''
                      }`}
                    >
                      <TransitionGroup appear={true}>
                        {wonLostStages?.map((stage, index) => (
                          <Collapse key={stage.id}>
                            <StageItem
                              stage={stage}
                              index={index}
                              key={index}
                              pipeline={pipeline}
                              disabled={true}
                              closedStage={true}
                              onHandleEdit={onHandleEdit}
                              onHandleRemove={onHandleRemove}
                              isDraggable={false}
                            />
                          </Collapse>
                        ))}
                        {provided.placeholder}
                      </TransitionGroup>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </>
        </CardBody>
        <CardFooter className="px-3">
          {pipeline?.id !== PIPELINE_NEW_KEY && !pipeline?.cloned ? (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="mb-0">Assigned Teams</h5>
                <CheckBoxInput
                  labelCheckBox="All Teams"
                  value={pipeline.global}
                  onChange={handleGlobalTeamChange}
                />
              </div>
              <SearchOptionDropdown
                scrollable="pr-3"
                label="Teams"
                dontClose
                search={true}
                show={showTeams}
                overflow={true}
                setShow={setShowTeams}
                data={teamOptions}
                selectedData={selectedTeam}
                setSelectedData={setSelectedTeam}
                handleOptionSelected={handleTeamSelect}
                handleAllSelect={handleAllSelect}
              />
            </>
          ) : (
            <>
              <ButtonIcon
                color="primary"
                classnames="btn-sm mr-1"
                label="Save"
                loading={loaderPipelineName}
                disabled={!pipe?.name}
                onclick={onSavePipelineName}
              />
              <ButtonIcon
                color="white"
                classnames="btn-sm"
                label="Cancel"
                onclick={() => updatePipelines(Actions.Remove, pipe)}
              />
            </>
          )}
        </CardFooter>
      </Card>
    </>
  );
};

const PipelineSectionLoader = () => {
  const { moduleMap } = useModuleContext();
  return (
    <Card className="h-100 hover-actions shadow-none">
      <CardHeader className="px-3">
        <div className="d-flex align-items-center w-100 justify-content-between">
          <div>
            <h4 className="mb-0">{moduleMap.deal.singular}</h4>
            <p className="text-muted fs-8 mb-0">
              <Skeleton height="10" width="120" />
            </p>
          </div>
        </div>
      </CardHeader>
      <CardBody className="py-2 px-0">
        <h5 className="pt-2 pb-0 px-3 mb-0">Open Stages</h5>
        <div className="my-1 px-3">
          <StageSkeletonLoader rows={3} />
        </div>
        <h5 className="pt-4 pb-0 px-3 mb-0">Closed Stages</h5>
        <div className="my-1 px-3">
          <StageSkeletonLoader rows={2} />
        </div>
      </CardBody>
      <CardFooter className="px-3">
        <h5 className="mb-0">Assigned Teams</h5>
        <div className="my-1">
          <StageSkeletonLoader circle={false} rows={1} />
        </div>
      </CardFooter>
    </Card>
  );
};

const PipelinesAndStages = () => {
  const { moduleMap } = useModuleContext();
  const [pipelines, setPipelines] = useState([]);
  const [loading, setLoading] = useState(false);
  const pipelineBoardRef = useRef(null);

  const getPipelines = async () => {
    setLoading(true);
    const { data } = await pipelineService.getPipelines();
    setLoading(false);
    setPipelines(data);
  };

  useEffect(() => {
    getPipelines();
  }, []);

  const handleCreateNewPipeline = () => {
    const lastRank = LexoRank.min();
    const rank1 = lastRank.between(LexoRank.max()).toString();
    const rank2 = LexoRank.parse(rank1).between(LexoRank.max()).toString();
    const newStages = [
      {
        id: '1',
        name: '',
        position: '1',
        rank: rank1,
        probability: 0,
      },
      {
        id: '2',
        name: '',
        position: '2',
        rank: rank2,
        probability: 0,
      },
    ];
    setPipelines([...pipelines, { id: PIPELINE_NEW_KEY, stages: newStages }]);
  };

  const updatePipelines = (action, pipe, stages) => {
    switch (action) {
      case Actions.Remove:
        // newly created static delete
        setPipelines(pipelines.slice(0, -1));
        break;
      case Actions.Delete:
        // api delete and refresh
        setPipelines([...pipelines].filter((p) => p.id !== pipe.id));
        break;
      case Actions.Save:
        // call to create new pipeline here
        setPipelines([
          ...pipelines.map((p) => (p.id === PIPELINE_NEW_KEY ? pipe : p)),
        ]);
        break;
      case Actions.Update:
        // just for update in ARRAY
        setPipelines([
          ...pipelines.map((pipeline) =>
            pipeline.id === pipe.id ? { ...pipe } : { ...pipeline }
          ),
        ]);
        break;
      case Actions.Clone:
        // clone the object and add in pipelines array
        setPipelines([
          ...pipelines,
          { ...pipe, id: PIPELINE_NEW_KEY, cloned: true, stages },
        ]);

        setTimeout(() => {
          pipelineBoardRef.current.scrollTo(
            pipelineBoardRef.current.scrollWidth,
            0
          );
        });
        break;
      case Actions.Default:
        // only set current pipeline default and update rest of them
        setPipelines(
          [...pipelines].map((p) => ({ ...p, isDefault: pipe.id === p.id }))
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div
        ref={pipelineBoardRef}
        className="row bg-setting-menu stages-board smooth-scroll overflow-x-auto flex-nowrap p-3"
      >
        {loading ? (
          <Col className="vertical-section">
            <PipelineSectionLoader />
          </Col>
        ) : (
          <>
            {pipelines?.map((pipeline, index) => (
              <Col
                key={index}
                className={`vertical-section ${index > 0 ? 'pl-0' : ''}`}
              >
                <PipelineSection
                  updatePipelines={updatePipelines}
                  pipeline={pipeline}
                  pipelines={pipelines}
                />
              </Col>
            ))}
          </>
        )}
        <Col
          className={`vertical-section ${pipelines.length > 0 ? 'pl-0' : ''}`}
        >
          <div
            className={`d-flex card flex-column justify-content-center setting-item align-items-center h-100`}
          >
            <div
              className="text-center d-flex flex-column justify-content-center cursor-pointer align-items-center"
              onClick={handleCreateNewPipeline}
            >
              <MaterialIcon
                icon="view_carousel"
                filled={false}
                clazz="text-gray-600= text-primary font-size-4xl"
              />
              <ButtonIcon
                color="link"
                icon="add"
                label={`New ${moduleMap?.deal?.singular}`}
                classnames="border-0 link-underline px-0 text-primary"
              />
            </div>
          </div>
        </Col>
      </div>
    </>
  );
};

export default PipelinesAndStages;
