import React, { useEffect, useState } from 'react';
import ButtonIcon from './commons/ButtonIcon';
import { CardBody } from 'reactstrap';
import {
  DATE_FORMAT,
  RIGHT_PANEL_WIDTH,
  checkDueDate,
  isModuleAllowed,
  isPermissionAllowed,
} from '../utils/Utils';
import { useTenantContext } from '../contexts/TenantContext';
import checklistService from '../services/checklist.service';
import RightPanelModal from './modal/RightPanelModal';
import AlertWrapper from './Alert/AlertWrapper';
import Alert from './Alert/Alert';
import { AddChecklistOrg } from './checklist/AddChecklistOrg';
import { Card } from 'react-bootstrap';
import PillFilters from './commons/PillFilters';
import { useProfileContext } from '../contexts/profileContext';
import TableSkeleton from './commons/TableSkeleton';
import Table from './GenericTable';
import { sortingTable } from '../utils/sortingTable';
import Skeleton from 'react-loading-skeleton';
import OwnerAvatar from './ActivitiesTable/OwnerAvatar';
import ChecklistStatus from './checklist/ChecklistStatus';
import { ChecklistStatuses } from '../utils/checklist.constants';
import moment from 'moment';
import TableRowHover from './commons/TableRowHover';
import MaterialIcon from './commons/MaterialIcon';
import Pagination from './Pagination';
import ViewChecklist from './fields/modals/ViewChecklist';
import MoreActions from './MoreActions';
import DeleteConfirmationModal from '../components/modal/DeleteConfirmationModal';

const checklistFiltersList = [
  { id: 'all', label: 'All Checklists', status: 'all' },
  { id: 'my', label: 'My Checklists', status: 'my' },
];

export const ChecklistsOrganization = ({
  organization,
  step,
  organizationId,
  setRefreshTabCounts,
}) => {
  const { tenant } = useTenantContext();
  const { profileInfo } = useProfileContext();
  const [openView, setOpenView] = useState(false);
  const [row, setRow] = useState({});
  const [checklists, setCheckLists] = useState([]);
  const [isChecklistShow, setChecklistShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [activityFilters, setActivityFilters] = useState(checklistFiltersList);
  const [activityFilter, setActivityFilter] = useState(checklistFiltersList[0]);
  const [showLoading, setShowLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataInDB, setDataInDB] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [order, setOrder] = useState([['initialDueDate', 'DESC']]);
  const [componentsToDelete, setComponentsToDelete] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const getChecklistData = async () => {
    setLoading(true);

    const filters =
      activityFilter?.status === 'my'
        ? { self: true, done: false, ownerId: profileInfo?.id }
        : {};

    try {
      const { data } = await checklistService.getOrgCheckList(organizationId, {
        page: pagination?.page,
        limit: pagination?.limit,
        ...filters,
        order,
      });
      const checklistData = {
        data: data?.data,
        pagination: data?.pagination,
      };
      setPagination(checklistData?.pagination);
      setDataInDB(Boolean(data?.pagination?.totalPages));
      setCheckLists(checklistData?.data);
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoading(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (organizationId && profileInfo?.id) {
      getChecklistData();
    }
  }, [
    order,
    activityFilter,
    pagination?.page,
    pagination?.limit,
    organizationId,
    profileInfo,
  ]);

  const handleFilterSelect = (item) => {
    setActivityFilter(item);
    // const { status } = item;
    // if (status === 'all') {
    //   setFilterBy({ type: ['task', 'event', 'call'] });
    // } else {
    //   setFilterBy({
    //     type: ['task', 'event', 'call'],
    //     self: true,
    //   });
    // }
  };

  const getCountsForTabs = async () => {
    const paginationData = {
      page: 1,
      limit: 5,
    };

    try {
      const [allCheckListsData, myCheckListsData] = await Promise.all([
        checklistService.getOrgCheckList(organizationId, {
          ...paginationData,
        }),
        checklistService.getOrgCheckList(organizationId, {
          ...paginationData,
          self: true,
          done: false,
          ownerId: profileInfo?.id,
        }),
      ]);

      const allActivitiesCount = allCheckListsData?.data?.pagination?.count;
      const myActivitiesCount = myCheckListsData?.data?.pagination?.count;
      updateFilterLabel('all', allActivitiesCount);
      updateFilterLabel('my', myActivitiesCount);
    } catch (err) {
      console.error(err);
    }
  };

  const updateFilterLabel = (id, count) => {
    setActivityFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.id === id ? { ...filter, count } : filter
      )
    );
  };

  useEffect(() => {
    if (organizationId && profileInfo?.id) {
      getCountsForTabs();
    }
  }, [profileInfo?.id, organizationId]);

  const columns = [
    {
      key: 'checklist_name',
      orderBy: 'checklist.title',
      component: 'Checklist Name',
      classes: 'pl-3',
      width: '245px',
    },
    {
      key: 'owner',
      orderBy: '',
      component: 'Owner',
      width: '170px',
    },
    {
      key: 'status',
      component: 'Status',
      orderBy: 'organizationChecklistProgresses.status',
      width: '170px',
    },
    {
      key: 'completed_at',
      component: 'Completed At',
      orderBy: 'organizationChecklistProgresses.completedAt',
      width: '170px',
    },
    {
      key: 'due_date',
      orderBy: 'initialDueDate',
      component: 'Due Date',
      width: '170px',
    },
    {
      key: 'action',
      component: '',
      width: '100px',
    },
  ];

  const data = checklists?.map((item) => ({
    ...item,
    dataRow: [
      {
        key: 'checklist_name',
        component: (
          <span className="text-black pl-2">{item?.checklist?.title}</span>
        ),
      },
      {
        key: 'owner',
        component: (
          <div className="position-relative index-0-on-scroll">
            <ChecklistOwner checklist={item} />
          </div>
        ),
      },
      {
        key: 'status',
        component: (
          <ChecklistStatus
            item={{
              status: item?.organizationChecklistProgresses?.every(
                (progress) => progress?.progress === 100
              )
                ? ChecklistStatuses.Completed.text
                : item?.organizationChecklistProgresses?.every(
                    (progress) =>
                      progress?.progress > 0 &&
                      item?.organizationChecklistProgresses?.every(
                        (progress) => progress?.progress < 100
                      )
                  )
                ? ChecklistStatuses.InProgress.text
                : item?.organizationChecklistProgresses?.every(
                    (progress) => progress?.progress === 0
                  )
                ? ChecklistStatuses.NotStarted.text
                : '',
            }}
          />
        ),
      },
      {
        key: 'completed_at',
        component: (
          <span className="text-black">
            {item?.organizationChecklistProgresses?.every(
              (progress) => progress?.progress === 100
            )
              ? moment(
                  item?.organizationChecklistProgresses[0]?.completedAt
                ).format(DATE_FORMAT)
              : '--'}
          </span>
        ),
      },
      {
        key: 'due_date',
        bgColor:
          !item?.organizationChecklistProgresses[0]?.completedAt &&
          checkDueDate(item?.initialDueDate)
            ? 'bg-red-soft'
            : '',
        component: (
          <>
            <TableRowHover />
            <span
              className={`${
                !item?.organizationChecklistProgresses[0]?.completedAt &&
                checkDueDate(item?.initialDueDate) === 'text-red'
                  ? 'text-red'
                  : ''
              }`}
            >
              {!item?.organizationChecklistProgresses[0]?.completedAt &&
              checkDueDate(item?.initialDueDate) ? (
                <span className="position-relative" style={{ top: 5 }}>
                  <MaterialIcon
                    icon="flag"
                    rounded
                    filled
                    size="fs-flag-icon"
                    clazz="text-red"
                  />
                </span>
              ) : null}{' '}
              {moment(item?.initialDueDate).format(DATE_FORMAT)}
            </span>
          </>
        ),
      },
      {
        key: 'action',
        component: (
          <>
            <span className="position-relative">
              {item?.organizationChecklistId &&
                isPermissionAllowed('checklists', 'delete') && (
                  <MoreActions
                    stopPropagation={false}
                    items={[
                      {
                        id: 'remove',
                        icon: 'delete',
                        name: 'Delete',
                        permission: {
                          collection: 'checklists',
                          action: 'delete',
                        },
                      },
                    ]}
                    onHandleRemove={(e) => {
                      removeItem(item);
                    }}
                    toggleClassName="add-more-btn icon-hover-bg z-index-100"
                    menuClassName="z-index-250"
                  />
                )}
            </span>
          </>
        ),
      },
    ],
  }));

  const onPaginationChange = (page) => {
    setPagination((prev) => ({ ...prev, page }));
  };

  const sortTable = ({ name }) => {
    if (name === 'action') return null;
    sortingTable({ name, order, setOrder: (val) => setOrder([val]) }, true);
  };

  const handleActivityRowClick = (item) => {
    setRow(item);
    setOpenView(true);
  };

  const removeItem = (item) => {
    setComponentsToDelete([item]);
    setOpenDeleteModal(true);
  };

  const handleOrgChecklistDelete = async () => {
    try {
      await checklistService.deleteOrganizationCheckList(
        componentsToDelete[0]?.organizationChecklistId
      );

      getChecklistData();
      getCountsForTabs();
      setSuccessMessage('Checklist Deleted');
      setOpenDeleteModal(false);
      setRefreshTabCounts((prevState) => prevState + 1);
    } catch (e) {
      console.log(e);
    } finally {
      setOpenDeleteModal(false);
    }
  };

  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      <Card className="border-0">
        <Card.Header className="border-top-0 pt-0 px-0">
          <div className="px-3 d-flex align-items-center justify-content-between text-right w-100">
            <PillFilters
              items={activityFilters}
              currentFilter={activityFilter}
              onFilterChange={handleFilterSelect}
            />
            {isModuleAllowed(tenant?.modules, 'checklist') &&
            isPermissionAllowed('checklists', 'create') ? (
              <ButtonIcon
                color="outline-primary"
                classnames="btn-sm rounded-pill pr-3 font-size-sm font-weight-medium"
                icon="add"
                onclick={() => setChecklistShow(true)}
                label={'Checklist'}
              />
            ) : (
              ''
            )}
          </div>
        </Card.Header>
        {!step && (
          <CardBody className={`p-0 ${step ? 'shadow-none' : ''}`}>
            {isModuleAllowed(tenant?.modules, 'checklist') &&
            organization?.id ? (
              <>
                {showLoading ? (
                  <TableSkeleton cols={6} rows={10} />
                ) : (
                  <Table
                    columns={columns}
                    actionPadding="h-100 min-h-150"
                    data={data}
                    paginationInfo={{}}
                    emptyDataText="This record doesn't have any checklists"
                    emptyDataIcon="checklist"
                    title="Checklist"
                    headClass="bg-gray-table-head"
                    tableLoading={loading}
                    usePagination={false}
                    dataInDB={dataInDB}
                    onClick={handleActivityRowClick}
                    sortingTable={sortTable}
                    sortingOrder={order}
                    permission={{ collection: 'checklists' }}
                  />
                )}
                <div className="px-3 pb-3">
                  <Pagination
                    paginationInfo={pagination}
                    onPageChange={onPaginationChange}
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </CardBody>
        )}

        {isChecklistShow && (
          <RightPanelModal
            showModal={isChecklistShow}
            setShowModal={() => setChecklistShow()}
            showOverlay={true}
            containerBgColor={'pb-0'}
            containerWidth={RIGHT_PANEL_WIDTH}
            containerPosition={'position-fixed'}
            headerBgColor="bg-gray-5"
            Title={
              <div className="d-flex py-2 align-items-center text-capitalize">
                <h3 className="mb-0">Add Checklist</h3>
              </div>
            }
          >
            <AddChecklistOrg
              organizationId={organizationId}
              isChecklistShow={isChecklistShow}
              getChecklistsData={getChecklistData}
              getCountsForTabs={getCountsForTabs}
              checklistData={checklists}
              organization={organization}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
              setChecklistShow={setChecklistShow}
            />
          </RightPanelModal>
        )}

        {isPermissionAllowed('checklists', 'view') && (
          <ViewChecklist
            openModal={openView}
            setOpenModal={setOpenView}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
            checklist={row}
            activityChecklist={true}
            getChecklistData={getChecklistData}
            organization={{ name: row?.name }}
            readonly={true}
          />
        )}
      </Card>

      <DeleteConfirmationModal
        showModal={openDeleteModal}
        setShowModal={setOpenDeleteModal}
        setSelectedCategories={setComponentsToDelete}
        event={handleOrgChecklistDelete}
        itemsConfirmation={componentsToDelete}
        heading={
          componentsToDelete?.length > 1
            ? 'Delete Checklists'
            : 'Delete Checklist'
        }
        description={
          componentsToDelete?.length > 1
            ? 'Are you sure you want to delete all the selected checklists?'
            : 'Are you sure you want to delete this checklist?'
        }
      />
    </>
  );
};

const ChecklistOwner = ({ checklist }) => {
  const [loader, setLoader] = useState(false);
  const [checklistOwner, setChecklistOwner] = useState([]);
  const getChecklistOwner = async () => {
    try {
      setLoader(true);
      const { data } = await checklistService.getChecklistOwners({
        organizationChecklistId: checklist?.organizationChecklistId,
        limit: 1,
        page: 1,
      });
      setChecklistOwner(data?.data || []);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getChecklistOwner();
  }, []);

  return (
    <>
      {loader ? (
        <Skeleton width={100} height={10} />
      ) : (
        <div>
          {checklistOwner ? (
            checklistOwner.map((owner, index) => (
              <div key={index}>
                <OwnerAvatar key={owner} item={owner?.user} isMultiple={true} />
              </div>
            ))
          ) : (
            <h5 className="ml-1 mb-0"> -- </h5>
          )}
        </div>
      )}
    </>
  );
};
