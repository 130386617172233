import React, { useState } from 'react';
import { Badge } from 'reactstrap';
import ProfileCardItem from '../../peopleProfile/ProfileCardItem';
import { capitalize, CompanyRelated } from '../../../utils/Utils';
import DeleteConfirmationModal from '../../modal/DeleteConfirmationModal';

const OrgRelatedList = ({
  item,
  handleRemove,
  isPrincipalOwner,
  mainOwner,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const removeItem = () => {
    setOpenModal(true);
  };

  const handleConfirm = async () => {
    try {
      await handleRemove(item.id);
      setOpenModal(false);
    } catch (error) {
      console.log('error while delete', error);
    }
  };

  const name = item?.related_organization_name;
  // lol @ bigin naming conventions....
  const friendlyCalculatedType =
    item?.calculated_type === 'sibling'
      ? 'sister'
      : item?.calculated_type === 'child'
      ? 'daughter'
      : item?.calculated_type;

  return (
    <div>
      <DeleteConfirmationModal
        showModal={openModal}
        setShowModal={setOpenModal}
        event={handleConfirm}
        heading={`Delete ${name}`}
        description={`Are you sure you want to remove ${name} as ${
          CompanyRelated[capitalize(item?.calculated_type)]?.label
        }?`}
      />

      <div className="media align-items-center justify-content-between">
        <div className="mr-auto">
          <ProfileCardItem
            user={item}
            mainOwner={mainOwner}
            size="xs"
            org={true}
          />
        </div>
        <div className="ml-auto">
          {item?.calculated_type && (
            <Badge
              id={item.id}
              style={{
                fontSize: '11px',
                borderRadius: 40,
                backgroundColor: '#e6e6e6',
                color: 'black',
              }}
              className="text-uppercase"
            >
              {CompanyRelated[capitalize(item?.calculated_type)]?.label}
            </Badge>
          )}
        </div>
        {handleRemove && (
          <div
            style={{ width: '10%' }}
            className={'d-flex justify-content-center'}
          >
            {friendlyCalculatedType !== 'sister' &&
              handleRemove &&
              isPrincipalOwner && (
                <a
                  className="cursor-pointer btn-icon btn btn-icon-sm icon-hover-bg"
                  onClick={removeItem}
                >
                  <i className="material-symbols-rounded fs-5">delete</i>
                </a>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrgRelatedList;
