import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './style.css';
import './responsive.css';
import Avatar from '../Avatar';
import BrandLogoIcon from '../sidebar/BrandLogoIcon';
import { useTenantContext } from '../../contexts/TenantContext';
import authService from '../../services/auth.service';
import { Col, Row } from 'react-bootstrap';
import useContactModal from './useContactModal';
import { SIGN_OUT } from '../../utils/constants';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';
import { useViewport } from '../../contexts/viewportContext';
import { useClientPortalContext } from '../../contexts/clientPortalContext';

const MenuItems = {
  Home: 'Home',
  Files: 'Files',
  Videos: 'Videos',
  Contact: 'Contact',
};

const MENU_ITEMS = [
  {
    name: MenuItems.Home,
    route: '/clientportal/dashboard',
    key: 'dashboard',
    icon: 'dashboard',
  },
  {
    name: MenuItems.Files,
    route: '/clientportal/dashboard/files',
    key: 'files',
    icon: 'attachment',
  },
];

const MenuItem = ({ menu, active, setActive, toggle }) => {
  const history = useHistory();
  const isMenuActive = active === menu.key;

  return (
    <>
      <div
        id={`menu-${menu.key}`}
        className={`js-nav-tooltip-link portal-menu-item nav-link cursor-pointer fw-bold ${isMenuActive}`}
        onClick={(e) => {
          setActive(menu.key);
          history.push(`${menu.route}`);
          toggle();
        }}
      >
        <i
          id={`menu-${menu.key}`}
          className={`material-symbols-rounded portal-menu-item-icon rounded p-1 ${
            isMenuActive ? 'primary-selected-item filled' : ''
          }`}
        >
          {menu.icon}
        </i>

        <span
          style={{ maxWidth: 100 }}
          className={`fs-8 text-truncate fw-bold ${
            isMenuActive ? 'text-primary' : ''
          }`}
        >
          {menu.name}
        </span>
      </div>
    </>
  );
};

const ContactMenuItem = ({ owner, toggle }) => {
  const { ContactYourRepresentative, setShowModal, showModal } =
    useContactModal(owner);

  const menu = {
    name: MenuItems.Contact,
    key: 'conversations',
    route: null,
    icon: 'contact_page',
  };

  const handleClick = (e) => {
    e.preventDefault();
    setShowModal(true);
    toggle();
  };

  return (
    <>
      <ContactYourRepresentative />
      <li key={menu.name} className="nav-item">
        <div
          id={`menu-${menu.name}`}
          className={`js-nav-tooltip-link portal-menu-item nav-link cursor-pointer fw-bold`}
          onClick={handleClick}
        >
          <i
            id={`menu-${menu.name}`}
            className={`material-symbols-rounded portal-menu-item-icon rounded p-1 ${
              showModal ? 'primary-selected-item filled' : ''
            }`}
          >
            {menu.icon}
          </i>

          <span
            style={{ maxWidth: 100 }}
            className={`fs-8 text-truncate fw-bold ${
              showModal ? 'text-primary' : ''
            }`}
          >
            {menu.name}
          </span>
        </div>
      </li>
    </>
  );
};

const ClientPortalMenu = ({
  active,
  setActive,
  owner,
  organization,
  toggle,
}) => {
  return (
    <div className="navbar-vertical-content bg-light">
      <div className="col d-md-none d-flex justify-content-center align-items-center px-0 mb-2">
        <OrganizationLabel organization={organization} />
      </div>
      <ul className="navbar-nav navbar-nav-lg nav-tabs font-weight-medium font-size-md">
        {MENU_ITEMS.map((menu) => (
          <li key={menu.name} className="nav-item">
            <MenuItem
              menu={menu}
              active={active}
              setActive={setActive}
              owner={owner}
              toggle={toggle}
            />
          </li>
        ))}
        <ContactMenuItem owner={owner} toggle={toggle} />
      </ul>
    </div>
  );
};

const OrganizationLabel = ({ organization }) => {
  return (
    <>
      {organization ? (
        <div className="d-flex gap-1 align-items-center flex-wrap">
          <label
            className="avatar avatar-sm avatar-circle avatar-uploader"
            htmlFor="avatarUploader"
          >
            <Avatar
              classModifiers="max-wh bg-primary-soft text-primary"
              sizeIcon="fs-5"
              user={organization}
              type="organization"
              defaultSize=""
            />
          </label>
          <TextOverflowTooltip
            maxLength={40}
            capitalizeText={false}
            text={organization.name || ''}
            textStyle="page-header-title fs-6 text-truncate font-weight-medium mb-0"
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

const LeftSidebar = () => {
  const activeUrl = document.URL.split('/').at(-1);
  const history = useHistory();
  const { tenant } = useTenantContext();
  const { organization, contact, owner } = useClientPortalContext();

  const [active, setActive] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleSideBar = () => setShowSidebar((prevState) => !prevState);

  useEffect(() => {
    setActive(activeUrl);
  }, []);

  const logout = () => {
    authService.logout();
    history.push('/clientportal/login');
  };

  const { width } = useViewport();
  const navbarDirection =
    width < 768
      ? 'navbar-horizontal-fixed sidebar-collapse sidebar-mobile h-100 pt-5'
      : 'navbar-vertical-fixed';

  return (
    <Fragment>
      <header
        id="headerDesktop"
        className="navbar navbar-expand-lg z-index-100 navbar-fixed navbar-height navbar-flush border-bottom pr-0 navbar-container"
      >
        <div className="navbar-nav-wrap py-1 justify-content-between">
          <div className="ml-2 d-md-none" onClick={toggleSideBar}>
            <span className="material-symbols-rounded text-primary font-size-2xl">
              menu
            </span>
          </div>

          <Row>
            <Col>
              <div className="pl-2 pr-4">
                <BrandLogoIcon tenant={tenant} width="100%" />
              </div>
            </Col>
          </Row>

          <div className="col d-flex-md justify-content-center align-items-center px-0">
            <OrganizationLabel organization={organization} />
          </div>

          <div className="d-flex align-items-center col-auto px-1 gap-1">
            <div className="d-flex align-items-center">
              <div className="navbar-nav-wrap-content-right avatar-mobile">
                <ul className="navbar-nav align-items-center flex-row">
                  <li className="nav-item">
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={toggle}
                      direction="down"
                    >
                      <div className="hs-unfold">
                        <DropdownToggle
                          className="bg-transparent border-0 p-2"
                          data-toggle="dropdown"
                          aria-expanded={dropdownOpen}
                        >
                          <div>
                            <Avatar
                              user={contact}
                              active={false}
                              sizeIcon="avatar-dark"
                            />
                          </div>
                        </DropdownToggle>
                        <DropdownMenu className="navfix py-1">
                          <div id="accountNavbarDropdown">
                            <DropdownItem className="px-3 bg-hover-white cursor-default">
                              <div className="media align-items-center">
                                <Avatar
                                  user={contact}
                                  classModifiers="mr-2"
                                  sizeIcon="avatar-dark text-white"
                                />
                                <div className="media-body">
                                  {contact?.first_name && (
                                    <span className="card-title h5">
                                      {contact?.id
                                        ? `${contact.first_name} ${contact.last_name}`
                                        : 'Loading...'}
                                    </span>
                                  )}

                                  <span className="card-text text-muted font-size-xs">
                                    {contact?.id
                                      ? `${contact?.email_work}`
                                      : 'Loading...'}
                                  </span>
                                </div>
                              </div>
                            </DropdownItem>
                            <div className="dropdown-divider"></div>

                            <DropdownItem className="px-3">
                              <div onClick={logout}>
                                <span
                                  className="text-truncate text-black pr-2"
                                  title={SIGN_OUT}
                                >
                                  {SIGN_OUT}
                                </span>
                              </div>
                            </DropdownItem>
                          </div>
                        </DropdownMenu>
                      </div>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        className={`${navbarDirection} js-navbar-vertical-aside navbar navbar-vertical-aside fw-bold navbar-vertical navbar-expand-xl navbar-bordered navbar-light  ${
          width < 768 && !showSidebar && 'collapse'
        }`}
      >
        <div id="mainMenu" className="navbar-vertical-container">
          <div className="navbar-vertical-footer-offset pt-2">
            <ClientPortalMenu
              active={active}
              setActive={setActive}
              owner={owner}
              organization={organization}
              toggle={toggleSideBar}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LeftSidebar;
