import ConfettiExplosion from 'react-confetti-explosion';
import React, { useCallback, useMemo, useState } from 'react';

const ConfettiEffect = ({ isVisible, duration, particleCount }) => {
  if (!isVisible) return null;
  return (
    <div className="position-fixed abs-center-xy z-index-100">
      <ConfettiExplosion
        force={0.8}
        duration={duration}
        particleCount={particleCount}
        width={1500}
      />
    </div>
  );
};

const useConfettiEffect = (duration = 3000, particleCount = 50) => {
  const [showConfetti, setShowConfetti] = useState(false);

  const toggleConfetti = () => {
    setShowConfetti(true);
    // automatically turn off after a delay
    setTimeout(() => setShowConfetti(false), duration);
  };

  const ConfettiEffectCallback = useCallback(() => {
    return (
      <ConfettiEffect
        isVisible={showConfetti}
        duration={duration}
        particleCount={particleCount}
      />
    );
  }, [showConfetti, duration, particleCount]);

  return useMemo(
    () => ({
      showConfetti,
      setShowConfetti,
      toggleConfetti,
      ConfettiEffect: ConfettiEffectCallback,
    }),
    [toggleConfetti, ConfettiEffectCallback]
  );
};

export default useConfettiEffect;
