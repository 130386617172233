import React, { useState, useEffect } from 'react';
import { Card, FormGroup } from 'react-bootstrap';

import RoleTable from '../../../components/role/RoleTable';
import CreateRoleModal from '../../../components/role/CreateRoleModal';
import DeleteRoleModal from '../../../components/role/DeleteRoleModal';
import EditRoleModal from '../../../components/role/EditRoleModal';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import Toast from '../../../components/Alert/Alert';
import roleService from '../../../services/role.service';
import stringConstants from '../../../utils/stringConstants.json';
import LayoutHead from '../../../components/commons/LayoutHead';
import { sortingTable } from '../../../utils/sortingTable';
import { DataFilters } from '../../../components/DataFilters';
import { useProfileContext } from '../../../contexts/profileContext';
import { useTenantContext } from '../../../contexts/TenantContext';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import { Label } from 'reactstrap';
import NewKindOfSearchDropdown from '../../../components/commons/NewKindOfSearchDropdown';
import TableSkeleton from '../../../components/commons/TableSkeleton';

const constants = stringConstants.settings.roles;

const Roles = ({ activeTab, paginationPage, setRolesPaginationPage }) => {
  const defaultPagination = { page: 1, limit: 10 };
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
  const [showEditRoleModal, setShowEditRoleModal] = useState(false);
  const [showUsersViewModal, setShowUsersViewModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedRoleData, setSelectedRoleData] = useState();
  const [pagination, setPagination] = useState(defaultPagination);
  const [roles, setRoles] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  const [filter, setFilter] = useState();
  const [toast, setToast] = useState({ msg: '', color: '' });
  const [selectedData, setSelectedData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [modified, setModified] = useState(false);
  const [deleteResults, setDeleteResults] = useState([]);
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [dataInDB, setDataInDB] = useState(false);
  const [order, setOrder] = useState(['created_at', 'DESC']);
  const [selectedItem, setSelectedItem] = useState({});
  const { profileInfo } = useProfileContext();
  const [hasAdminAccess, setHasAdminAccess] = useState(false);
  const [countToggle, setCountToggle] = useState(true);
  const { tenant } = useTenantContext();
  const [searchProfile, setSearchProfile] = useState({ search: '' });
  const [selectedProfile, setSelectedProfile] = useState({});
  const [profileData, setProfileData] = useState([]);

  const getRoles = async () => {
    setShowLoading(true);
    try {
      const result = await roleService.GetRoles({
        ...pagination,
        order,
        filter,
      });
      setRoles(result.data);
      setPagination(result.pagination);
      if (countToggle) {
        setDataInDB(Boolean(result?.pagination?.totalPages));
        setCountToggle(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setShowLoading(false);
    }
  };

  const changePaginationPage = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const deleteRoles = async (selection) => {
    const deleteReportResults = [];
    const deletedRoles = selection.map(async (roleId) => {
      return roleService.deleteRole(roleId);
    });

    // TODO: will improve it.
    await Promise.allSettled(deletedRoles).then((results) => {
      results.forEach((result, index) => {
        const { status } = result;
        if (status === 'rejected') {
          const { response } = result.reason || {};
          const { config, data } = response;
          const roleId = config.url.split('/').pop();
          deleteReportResults[index] = {
            id: roleId,
            result: constants.delete.failed,
            msg: data.error,
          };
        } else {
          deleteReportResults[index] = {
            id: selection[index],
            result: constants.delete.success,
            msg: '',
          };
        }
      });
      setDeleteResults(deleteReportResults);
    });
  };

  const handleDelete = async () => {
    await deleteRoles(selectedData);
    setSelectedData([]);
    setShowDeleteReport(true);
  };

  useEffect(() => {
    if (profileInfo && profileInfo.role?.admin_access) {
      setHasAdminAccess(true);
    }
  }, [profileInfo]);

  useEffect(() => {
    if (activeTab === '2') {
      getRoles();
    }
  }, [pagination.page, modified, order, filter, activeTab]);

  useEffect(() => {
    paginationPage.page === 1 && changePaginationPage(1);
  }, [paginationPage]);

  const sortTable = ({ name }) => sortingTable({ name, order, setOrder });

  const handleDeleteRole = (item) => {
    setSelectedRoleData(item);
    setShowRemoveModal(true);
  };

  const handleDeleteProfile = async () => {
    try {
      await roleService.deleteRole(selectedRoleData.id, selectedProfile?.id);
      setShowRemoveModal(false);
      setSelectedRoleData();
      setShowEditRoleModal(false);
      getRoles();
      setSuccessMessage('Profile Deleted');
    } catch (error) {
      console.log(error);
      setErrorMessage(
        'Unable to delete profile. Please check console for details.'
      );
    }
  };

  useEffect(() => {
    if (!showEditRoleModal) {
      setShowUsersViewModal(false);
    }
  }, [showEditRoleModal]);

  const handleViewUsers = (item) => {
    setSelectedRoleData(item);
    setShowUsersViewModal(true);
    setShowEditRoleModal(true);
  };

  const handleEditRole = (item) => {
    setSelectedRoleData(item);
    setShowEditRoleModal(true);
  };

  const toggleLoadRoles = (reset) => {
    if (reset) {
      setCountToggle(true);
      changePaginationPage(1);
    } else {
      getRoles();
    }
  };
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onInputProfileSearch = (e) => {
    const { value } = e.target || {};
    setSearchProfile({
      ...searchProfile,
      search: value,
    });
  };

  const handleProfileSelected = (item) => {
    setSelectedProfile(item);
  };
  const loadProfiles = async () => {
    const { data } = await roleService.GetRoles({
      limit: 5,
      page: 1,
      self: true,
      order,
      filter: searchProfile,
    });
    setProfileData(data);
  };

  useEffect(() => {
    if (showRemoveModal) {
      loadProfiles();
    }
  }, [searchProfile, showRemoveModal]);

  const loader = () => {
    if (showLoading) return <TableSkeleton cols={4} rows={10} />;
  };

  return (
    <>
      <Toast message={toast.msg} setMessage={setToast} color={toast.color} />

      <AlertWrapper>
        <Toast
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
        <Toast
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>

      <CreateRoleModal
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        showModal={showCreateRoleModal}
        setShowModal={setShowCreateRoleModal}
        handleEditRole={handleEditRole}
        toggleLoadRoles={toggleLoadRoles}
      />

      {showEditRoleModal && (
        <EditRoleModal
          selectedRoleData={selectedRoleData}
          showModal={showEditRoleModal}
          showViewUsers={showUsersViewModal}
          setShowModal={setShowEditRoleModal}
          toggleLoadRoles={toggleLoadRoles}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
        />
      )}

      {showDeleteRoleModal && (
        <DeleteRoleModal
          showModal={showDeleteRoleModal}
          setShowModal={setShowDeleteRoleModal}
          setSelectedRoles={setSelectedData}
          event={handleDelete}
          selectedData={selectedData}
          data={roles}
          results={deleteResults}
          setResults={setDeleteResults}
          showReport={showDeleteReport}
          setShowReport={setShowDeleteReport}
          modified={modified}
          setModified={setModified}
        />
      )}

      <DeleteConfirmationModal
        showModal={showRemoveModal}
        setShowModal={setShowRemoveModal}
        event={handleDeleteProfile}
        heading={constants.delete.deleteProfile}
        description={constants.delete.deleteProfileConfirm}
        extraBody={
          <div className="mt-3 mb-0">
            <FormGroup className="d-flex w-100 gap-2 align-items-center mb-0">
              <Label className="mb-0">Transfer to</Label>
              <div className="flex-fill">
                <NewKindOfSearchDropdown
                  readOnly={true}
                  preData={profileData}
                  placeholder="Select Profile"
                  onItemSelect={handleProfileSelected}
                  onInputSearch={onInputProfileSearch}
                />
              </div>
            </FormGroup>
          </div>
        }
      />

      <Card className="mb-5 shadow-none border-0 rounded-0">
        <Card.Header className="border-0 px-4 py-2 w-100">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <p className="mb-0" style={{ maxWidth: 700 }}>
              Profiles define user permissions and actions in {tenant?.name},
              assigned upon invitation.
            </p>
            <LayoutHead
              onHandleCreate={() => setShowCreateRoleModal(true)}
              buttonLabel={constants.edit.add}
              selectedData={selectedData}
              onDelete={() => setShowDeleteRoleModal(true)}
              dataInDB={!hasAdminAccess ? dataInDB : false}
            >
              <div className="flex-fill">
                <DataFilters
                  filterSelected={filter}
                  searchClasses="col-md-12"
                  setFilterSelected={setFilter}
                  searchPlaceholder="Search Profiles"
                  paginationPage={paginationPage}
                  setPaginationPage={setRolesPaginationPage}
                />
              </div>
            </LayoutHead>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {showLoading ? (
            loader()
          ) : (
            <RoleTable
              dataSource={roles}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              selectAll={selectAll}
              setShowModal={setShowCreateRoleModal}
              setSelectedItem={setSelectedItem}
              setSelectAll={setSelectAll}
              paginationInfo={pagination}
              onPageChange={changePaginationPage}
              dataInDB={dataInDB}
              setShowCreateRoleModal={setShowCreateRoleModal}
              sortingTable={sortTable}
              sortingOrder={order}
              hasAdminAccess={hasAdminAccess}
              handleEditRole={handleEditRole}
              handleDeleteRole={handleDeleteRole}
              handleViewUsers={handleViewUsers}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Roles;
