import axios from 'axios';

import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + '/api/courses';
class CourseService {
  getCourses(query = {}) {
    const { page = 1, limit = 10, ...rest } = query;

    return axios
      .get(`${API_URL}`, {
        headers: authHeader(),
        params: {
          page,
          limit,
          ...rest,
        },
      })
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  deleteCourses(courseId) {
    return axios
      .delete(`${API_URL}/${courseId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  updateCourse(id, data) {
    return axios
      .put(`${API_URL}/${id}`, data, {
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  saveCourse(course) {
    return axios
      .post(API_URL, course, {
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  getCourseLessonsById(id) {
    return axios
      .get(`${API_URL}/${id}/lessons`, { headers: authHeader() })
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  getCourseById(id, params = {}) {
    return axios
      .get(`${API_URL}/${id}`, { headers: authHeader(), params })
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  getCourseProgress(id, params = {}) {
    return axios
      .get(`${API_URL}/${id}/progress`, { headers: authHeader(), params })
      .then((response) => response.data);
  }

  updateCourseLessons(id, courseLesson) {
    return axios
      .put(
        `${API_URL}/${id}/lessons`,
        {
          ...courseLesson,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  putFavoriteCourseById(id) {
    return axios
      .put(
        `${API_URL}/${id}/favorite`,
        { courseId: id },
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }
}

export default new CourseService();
