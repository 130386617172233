import React, { useState, useEffect } from 'react';
import { Card, FormCheck } from 'react-bootstrap';

import {
  paginationDefault,
  SEARCH_COURSES,
  SERVER_ERROR,
} from '../../../utils/constants';
import stringConstants from '../../../utils/stringConstants.json';
import CoursesTable from '../../../components/courses/CoursesTable';
import courseService from '../../../services/course.service';
import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import LayoutHead from '../../../components/commons/LayoutHead';
import { sortingTable } from '../../../utils/sortingTable';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import TableSkeleton from '../../../components/commons/TableSkeleton';
import { DataFilters } from '../../../components/DataFilters';
import { formatText, TrainingFilterOptions } from '../../../utils/Utils';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import ModalCreateCourse from '../../../components/modal/ModalCreateCourse';
import { useProfileContext } from '../../../contexts/profileContext';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../Deals/contacts/utils';
const constants = stringConstants.settings.resources.courses;

const ListCourses = ({ setCreate, setId, setModified }) => {
  const [courses, setCourses] = useState([]);
  const [coursesSelect, setCoursesSelect] = useState([]);
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);
  const [showLoading, setShowLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [filterSelected, setFilterSelected] = useState({});
  const [dataInDB, setDataInDB] = useState(false);
  const [order, setOrder] = useState([['updated_at', 'DESC']]);
  const [selectAll, setSelectAll] = useState(false);
  const [coursesToDelete, setCoursesToDelete] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [orgCheck, setOrgCheck] = useState(true);

  const [filterOptionSelected, setFilterOptionSelected] = useState({
    id: 1,
    key: 'updated_at',
    name: 'Last Modified',
  });
  const { profileInfo } = useProfileContext();
  const roleInfo = profileInfo?.role;
  const isAdmin = roleInfo?.admin_access;
  const restrictByCondition =
    !isAdmin && orgCheck ? 'private' : isAdmin ? 'externalPublic' : '';

  const getCourses = async (count) => {
    setLoading(true);
    try {
      const response = await courseService.getCourses({
        page: paginationPage.page,
        limit: paginationPage.limit,
        ...(restrictByCondition && { restrictBy: restrictByCondition }),
        deleted: false,
        order,
        ...filterSelected,
      });

      const { data: courses, pagination } = response || {};

      setPagination(pagination);
      setCourses(courses);
      setDataInDB(Boolean(pagination?.totalPages));
      setShowLoading(false);
      setLoading(false);
    } catch (err) {
      console.log('err', err);
      setErrorMessage(SERVER_ERROR);
    }
  };

  const onHandleFilterCourse = (e, option) => {
    e.preventDefault();
    setFilterOptionSelected(option);
    setPaginationPage((prev) => ({ ...prev, page: 1 }));
    if (option.key === 'updated_at') {
      setFilterSelected({
        order: [['updated_at', 'DESC']],
      });
    } else {
      setFilterSelected({
        status: option.key,
      });
    }
  };

  const onHandleRedirectCourses = (item) => {
    if (isAdmin || (!isAdmin && !item.isPublic)) {
      setId(item.id);
      setCreate(true);
    }
  };

  useEffect(() => {
    if (profileInfo?.id) {
      getCourses(true);
    }
  }, [filterSelected, paginationPage, order, orgCheck, profileInfo]);

  const sortTable = ({ name }) =>
    sortingTable({ name, order, setOrder: (val) => setOrder([val]) }, true);

  const clearSelection = () => {
    setSelectAll(false);
    setCoursesSelect([]);
  };

  const handleDelete = (course) => {
    setCoursesToDelete([course]);
    setShowDeleteModal(true);
  };

  const handleDeleteCourse = async () => {
    const course = coursesToDelete[0];
    try {
      await courseService.deleteCourses([course?.id]);
      getCourses(true);
      setSuccessMessage('Course Deleted');
    } catch (error) {
      if (error.response.status === 409) {
        setErrorMessage(formatText(error.response.data.errors[0].message));
      }
    } finally {
      setCoursesToDelete([]);
      setShowDeleteModal(false);
      setModified((prevState) => prevState + 1);
    }
  };

  const permissions = {
    collection: 'courses',
    action: 'create',
  };

  return (
    <div>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>

      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        setSelectedCategories={setCoursesToDelete}
        event={handleDeleteCourse}
        itemsConfirmation={coursesToDelete}
        heading="Delete Course"
        description="Are you sure you want to delete this course?"
      />

      <ModalCreateCourse
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        onCreate={onHandleRedirectCourses}
        setSuccessMessage={setSuccessMessage}
        successMessage={successMessage}
      />

      <Card className="mb-0 card-0 rounded-0">
        <Card.Header className="flex-column align-items-unset px-0">
          <div className="d-flex align-items-center px-3">
            <DataFilters
              filterSelected={filterSelected}
              setFilterSelected={setFilterSelected}
              searchPlaceholder={SEARCH_COURSES}
              paginationPage={paginationPage}
              setPaginationPage={setPaginationPage}
            />

            <div className="d-flex align-items-center gap-2">
              <>
                {!isAdmin && (
                  <FormCheck
                    id={'orgCheck'}
                    name={'orgCheck'}
                    type="switch"
                    custom={true}
                    label="My Organization"
                    checked={orgCheck}
                    onChange={() => setOrgCheck(!orgCheck)}
                  />
                )}
              </>

              <ButtonFilterDropdown
                options={TrainingFilterOptions}
                filterOptionSelected={filterOptionSelected}
                handleFilterSelect={onHandleFilterCourse}
              />

              <LayoutHead
                onHandleCreate={() => {
                  setShowCreateModal(true);
                }}
                onDelete={() => setShowDeleteModal(true)}
                buttonLabel={constants.addCourse}
                selectedData={coursesSelect}
                onClear={clearSelection}
                dataInDB={dataInDB}
                permission={permissions}
                alignTop="mb-0"
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {showLoading && <TableSkeleton cols={5} rows={12} />}

          {!showLoading && (
            <CoursesTable
              data={courses}
              tableLoading={loading}
              paginationInfo={pagination}
              onPageChange={(newPage) => {
                changePaginationPage(newPage, setPaginationPage);
                setCoursesSelect([]);
              }}
              onLimitChange={(perPage) => {
                changePaginationLimit(perPage, setPaginationPage);
                setCoursesSelect([]);
              }}
              selectedCourses={coursesSelect}
              setSelectedCourses={setCoursesSelect}
              dataInDB={dataInDB}
              sortingTable={sortTable}
              sortingOrder={order}
              orgCheck={orgCheck}
              setCreate={() => {
                setShowCreateModal(true);
              }}
              permission={permissions}
              onClickRow={(item) => {
                onHandleRedirectCourses(item);
              }}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              handleDelete={handleDelete}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ListCourses;
