import React, { useEffect, useState } from 'react';
import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import filesService from '../../../services/files.service';
import feedService from '../../../services/feed.service';
import { createBlobObject } from '../../../utils/Utils';
import stringConstants from '../../../utils/stringConstants.json';
import { MAX_WEIGHT, MAX_WEIGHT_ERROR_MESSAGE } from '../../../utils/constants';
import IdfUploadFiles from './IdfUploadFiles';
import Skeleton from 'react-loading-skeleton';
import ActivityFileMobile from './ActivityFileMobile';
import ActivityFile from './ActivityFile';
import Pagination from '../../Pagination';
import { Table } from 'react-bootstrap';
import NoDataFound from '../../commons/NoDataFound';
const constants = stringConstants.deals.contacts;

const paginationDefault = { page: 1, limit: 10 };

const AddFile = ({ contactId, organizationId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);
  const [fileInput, setFileInput] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const isMobile = window.innerWidth <= 768;

  const handleSubmit = async (file) => {
    const form = new FormData();

    const formBlob = await createBlobObject(file);

    const { size } = formBlob || {};

    if (size > MAX_WEIGHT) {
      setIsLoading(false);
      return setErrorMessage(MAX_WEIGHT_ERROR_MESSAGE);
    }

    if (contactId) {
      form.append('contact_id', contactId);
    }
    if (organizationId) {
      form.append('organization_id', organizationId);
    }

    form.append('isPublic', true);
    form.append('file', formBlob, file.name);

    try {
      await filesService.uploadFile(form);
      setSuccessMessage(constants.profile.fileUploaded);
      setFileInput(undefined);
      getFiles();
    } catch (error) {
      setErrorMessage(constants.profile.fileUploadError);
    } finally {
      setIsLoading(false);
    }
  };

  const getFiles = async () => {
    try {
      const res = await feedService.getFiles(
        { contactId, organizationId },
        paginationPage
      );
      setFiles(res?.files);
      setPagination(res?.pagination);
    } catch (error) {
      console.log('error while getting files', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contactId && organizationId) {
      getFiles();
    }
  }, [organizationId, contactId, paginationPage]);

  return (
    <>
      <AlertWrapper>
        <Alert message={successMessage} setMessage={setSuccessMessage} />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>

      <div className={`pt-3 pb-4`}>
        <div className="mx-3 public-page-setting">
          <IdfUploadFiles
            fileInput={fileInput}
            setFileInput={setFileInput}
            handleSubmit={handleSubmit}
            loading={isLoading}
            setIsLoading={setIsLoading}
            setErrorMessage={setErrorMessage}
            organizationId={organizationId}
            contactId={contactId}
          />

          <div>
            {loading ? (
              <div className="p-3">
                <Skeleton count={5} height={10} className={'mb-2'} />
              </div>
            ) : (
              <>
                {files?.length ? (
                  <>
                    <div className="d-flex align-items-center pt-3 pb-1 media">
                      <h5 className="mb-0">{`${
                        pagination?.count || 0
                      } files uploaded`}</h5>
                    </div>

                    {isMobile ? (
                      <>
                        {files?.map((file) => (
                          <div key={file.id} className="mt-2">
                            <ActivityFileMobile
                              key={file.file_id}
                              file={file}
                              publicPage={true}
                              getFiles={getFiles}
                              contactId={contactId}
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="card generic mb-4 card-0">
                        {files?.length ? (
                          <Table responsive className="mb-0">
                            <thead>
                              <tr>
                                <th className="text-muted fs-8 text-transform-none font-weight-medium border-0">
                                  File Name
                                </th>
                                <th className="align-middle text-muted text-transform-none fs-8 font-weight-medium border-0">
                                  Date Uploaded
                                </th>
                                <th className="align-middle text-muted text-transform-none fs-8 font-weight-normal border-0">
                                  Uploaded By
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {files?.map((file) => (
                                <tr key={file.id}>
                                  <ActivityFile
                                    key={file.file_id}
                                    file={file}
                                    publicPage={true}
                                    getFiles={getFiles}
                                    contactId={contactId}
                                  />
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : null}
                      </div>
                    )}

                    {pagination.totalPages > 1 && (
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mb-3 d-flex justify-content-center">
                        <Pagination
                          className="m-auto"
                          paginationInfo={pagination}
                          onPageChange={(page) => {
                            setPaginationPage((prevState) => ({
                              ...prevState,
                              page,
                            }));
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <NoDataFound
                    icon="note_stack"
                    iconSymbol={true}
                    title={
                      <div className="font-normal font-size-sm2 text-gray-search">
                        {`This record doesn’t have any files`}
                      </div>
                    }
                    containerStyle="text-gray-search py-6"
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFile;
