export const ChecklistFieldsTabs = {
  Internal: 'internal',
  Client: 'client',
};

export const ActionIcons = {
  upload: 'description',
  signature: 'draw',
  acknowledge: 'visibility',
  video: 'play_circle',
  watch: 'play_circle',
};

export const CHECKLIST_ACTIONS = [
  {
    name: 'acknowledge',
    title: 'Acknowledge',
    value: 'acknowledge',
    icon: ActionIcons.acknowledge,
  },
  {
    name: 'upload',
    title: 'Upload',
    value: 'upload',
    icon: ActionIcons.upload,
  },
  {
    name: 'signature',
    title: 'Signature',
    value: 'signature',
    icon: ActionIcons.signature,
  },
  { name: 'watch', title: 'Video', value: 'watch', icon: ActionIcons.video },
];

export const ChecklistStatuses = {
  Pending: {
    value: 'pending',
    text: 'Pending',
    color: 'status-gray',
  },
  NotStarted: {
    value: 'not-started',
    text: 'Not Started',
    color: 'status-gray',
  },
  InProgress: {
    value: 'in-progress',
    text: 'In Progress',
    color: 'status-yellow',
  },
  NotViewed: {
    value: 'not-viewed',
    text: 'Not Viewed',
    color: 'status-gray',
  },
  Completed: {
    value: 'completed',
    text: 'Completed',
    color: 'status-green',
  },
  Deleted: {
    value: 'delete',
    text: 'Deleted',
    color: 'status-red',
  },
};

export const statusText = {
  completed: ChecklistStatuses.Completed.text,
  inProgress: ChecklistStatuses.InProgress.text,
  pending: ChecklistStatuses.NotStarted.text,
  overdue: 'Overdue',
};

export const checklistsReportColumnsToExport = [
  {
    key: 'companyName',
    component: 'Company Name',
  },
  {
    key: 'checklistTitle',
    component: 'Checklist Name',
  },
  {
    key: 'ownerName',
    component: 'Owner',
  },
  {
    key: 'status',
    component: 'Status',
  },
  {
    key: 'lastUpdate',
    component: 'Last Update',
  },
  {
    key: 'initialDueDate',
    component: 'Due Date',
  },
  {
    key: 'completedAt',
    component: 'Completed At',
  },
];

export const checklistsReportColumns = [
  {
    key: 'companyName',
    component: 'Company Name',
    orderBy: 'organization.name',
  },
  {
    key: 'checklistTitle',
    component: 'Checklist Name',
    orderBy: 'checklist.title',
  },
  {
    key: 'owner',
    component: 'Owner',
  },
  {
    key: 'status',
    component: 'Status',
    orderBy: 'organizationChecklistProgresses.status',
  },
  {
    key: 'updatedAt',
    component: 'Last Update',
    orderBy: 'organizationChecklistProgresses.updatedAt',
  },
  {
    key: 'initialDueDate',
    component: 'Due Date',
    orderBy: 'initialDueDate',
  },
  {
    key: 'completedAt',
    component: 'Completed At',
  },
];

export const checklistsOverviewColumns = [
  {
    key: 'checklist',
    component: <span>Checklist Name</span>,
    width: '30%',
    orderBy: 'title',
  },
  {
    key: 'assignedCompanies',
    component: <span>Assigned</span>,
    orderBy: 'assignedCompaniesCount',
  },
  {
    key: 'status',
    component: <span>Status</span>,
  },
  {
    key: 'lastUpdate',
    component: <span>Last Update</span>,
    orderBy: 'lastUpdate',
  },
  {
    key: 'progress',
    component: <span>Progress</span>,
    orderBy: 'avgProgress',
  },
];

export const checklistsReportsPages = [
  {
    key: '30',
    name: '30',
    filter: '',
  },
  {
    key: '50',
    name: '50',
    filter: '',
  },
  {
    key: '100',
    name: '100',
    filter: '',
  },
  {
    key: '300',
    name: '300',
    filter: '',
  },
  {
    key: '500',
    name: '500',
    filter: '',
  },
];

export const statsStyles = {
  completed: {
    icon: 'check',
    color: 'bg-rpt-green',
    iconColor: 'text-rpt-green',
  },
  inProgress: {
    icon: 'schedule',
    color: 'bg-rpt-orange',
    iconColor: 'rpt-orange-box-heading',
  },
  pending: {
    icon: 'Info',
    color: 'bg-rpt-gray',
    iconColor: 'text-rpt-gray',
  },
  overdue: {
    icon: 'calendar_today',
    color: 'bg-rpt-red',
    iconColor: 'rpt-red-box-heading',
  },
};
