import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useContext } from 'react';
import Home from './views/Overview/Home';
import MyLessons from './views/Resources/MyLessons';
import Category from './views/Resources/category/Category';
import Lesson from './views/Resources/Lesson';
import Login from './pages/Login';
import Reset from './pages/Reset';
import Request from './pages/Request';
import SignUp from './pages/SignUp';
import PrivateRoute from './routes/private';
import Deals from './pages/Deals';
import PipelineDetail from './pages/PipelineDetail';
import Insights from './pages/Insights';
import Settings from './views/settings/Settings';
import ResendInvite from './views/settings/Resources/ResendInvite';
import Security from './pages/Security';
import Notification from './pages/Notification';
import SiteSettings from './pages/SiteSettings';
import Users from './pages/Users';
import Resources from './pages/Resources';
import Products from './pages/Products';
import Roles from './pages/Roles';
import PeopleProfile from './pages/PeopleProfile';
import Contacts from './pages/Contacts';
import OrganizationProfile from './pages/OrganizationProfile';
import routes from './utils/routes.json';
import Resrcs from './pages/Resrcs';
import AIAssist from './pages/AIAssist';
import CompanyDetail from './components/prospecting/v2/Profile/CompanyDetail';
import UserProfile from './views/settings/ManageUsers/Profile';
import Groups from './pages/Groups';
import ManageRoles from './pages/ManageRoles';
import Unauthorized from './views/Errors/403';
import PeopleDetail from './components/prospecting/v2/Profile/PeopleDetail';
import BulkImportPage from './pages/BulkImport';
import BulkImport from './components/BulkImport';
import Integrations from './pages/Integrations';
import { TenantContext } from './contexts/TenantContext';
import {
  checkStringsInInput,
  getAccountsChildPermissions,
  isDisplayWelcomeScreen,
  isModuleAllowed,
} from './utils/Utils';
import Tenants from './pages/Tenant';
import PipelinesAndStages from './pages/PipelinesAndStages';
import Fields from './pages/Fields';
import ChecklistFields from './pages/ChecklistFields';
import WorkFlow from './pages/WorkFlow';
import { WorkFlowDetail } from './components/workFlow/workFlowDetail';
import { useProfileContext } from './contexts/profileContext';
import ClientLogin from './pages/ClientLogin';
import ClientDashboard from './pages/ClientDashboard';
import ClientDashboardConv from './components/ClientPortal/Conversations';
import ClientDashboardVideos from './components/ClientPortal/Videos';
import ClientDashboardFiles from './components/ClientPortal/Files';
import NotificationsAll from './pages/NotificationsAll';
import Activities from './pages/Activities';
import OrganizationsPage from './pages/Organizations';
import ProfilePage from './pages/ProfilePage';
import SelfAssessment from './views/Resources/selfAssessment/SelfAssessment';
import Maintenance from './pages/Maintenance';
import SelfAssessmentResults from './views/Resources/selfAssessment/SelfAssessmentResults';
import ScrollToTop from './hooks/useScrollToTop';
import Learn from './views/Learn/Learn';
import Welcome from './pages/ComericaWelcome';
import VideoPlayer from './pages/VideoPlayer';
import KeycloakCallback from './pages/KeycloakCallback';
import CourseDetail from './views/Resources/CourseDetail';
import KnowledgeAssessment from './views/Resources/knowledgeAssessment/KnowledgeAssessment';
import { PermissionsConstants } from './utils/permissions.constants';
import CAccounts from './pages/CAccounts';
import Checklists from './pages/Checklist';
import Changelog from './pages/Changelog';
import ClientRoute from './routes/Client';

const LearnModuleStrings = Object.values(PermissionsConstants.Learn);

export const AppRouter = () => {
  const { tenant } = useContext(TenantContext);

  const getComponent = (name, component) => {
    if (isDisplayWelcomeScreen(tenant.modules)) {
      return Welcome;
    }
    if (tenant.modules === '*') return component;
    let updatedName = name;
    // IDF-5623, IDF-5622
    if (name === 'companies' || name === 'contacts') {
      updatedName = getAccountsChildPermissions(tenant?.modules, name);
    }
    if (
      updatedName === PermissionsConstants.Learn.Learn &&
      isModuleAllowed(tenant.modules, LearnModuleStrings)
    ) {
      return component;
    }

    if (name === PermissionsConstants.CAccounts.Accounts) {
      if (
        isModuleAllowed(tenant.modules, [
          PermissionsConstants.CAccounts.Companies,
          PermissionsConstants.CAccounts.Contacts,
        ])
      ) {
        return component;
      }
      return Home;
    }
    if (isModuleAllowed(tenant.modules, updatedName)) {
      return component;
    }
    return Home;
  };

  const getRootComponent = () => {
    if (isDisplayWelcomeScreen(tenant.modules)) {
      return Welcome;
    }
    if (tenant.modules === '*') return Home;

    if (
      checkStringsInInput(tenant.modules, ['dashboards', 'home', 'new_home'])
    ) {
      return Home;
    }
    if (isModuleAllowed(tenant.modules, 'companies')) {
      return OrganizationsPage;
    }
    if (
      isModuleAllowed(tenant.modules, [
        PermissionsConstants.CAccounts.Companies,
        PermissionsConstants.CAccounts.Contacts,
      ])
    ) {
      return CAccounts;
    }
    if (isModuleAllowed(tenant.modules, 'contacts')) {
      return Contacts;
    }
    if (isModuleAllowed(tenant.modules, 'pipelines')) {
      return Deals;
    }
    if (isModuleAllowed(tenant.modules, 'activities')) {
      return Activities;
    }
    if (
      isModuleAllowed(tenant.modules, PermissionsConstants.Checklist.checklist)
    ) {
      return Checklists;
    }
    if (isModuleAllowed(tenant.modules, 'prospecting')) {
      return Resrcs;
    }
    if (isModuleAllowed(tenant.modules, 'reporting')) {
      return Insights;
    }
    if (isModuleAllowed(tenant.modules, LearnModuleStrings)) {
      return MyLessons;
    }
    return Unauthorized;
  };
  const { profileInfo } = useProfileContext();
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/login" component={Login} />
        {!tenant?.modules && <Route exact path="/" component={Login} />}
        <Route
          exact
          path="/login?id:id&access_token=:access_token&expires:expires&refresh_token=:refresh_token"
          component={Login}
        />
        <Route exact path="/request-password" component={Request} />
        <Route exact path="/reset-password" component={Reset} />

        <Route exact path="/clientportal/login" component={ClientLogin} />
        <ClientRoute
          exact
          path="/clientportal/dashboard"
          component={ClientDashboard}
        />
        <ClientRoute
          exact
          path="/clientportal/dashboard/files"
          component={ClientDashboardFiles}
        />
        <ClientRoute
          exact
          path="/clientportal/dashboard/conversations"
          component={ClientDashboardConv}
        />
        <ClientRoute
          exact
          path="/clientportal/dashboard/videos"
          component={ClientDashboardVideos}
        />

        <Route exact path="/callback" component={KeycloakCallback} />

        <PrivateRoute
          exact
          path={routes.pipeline}
          component={getComponent('pipelines', Deals)}
        />
        <PrivateRoute
          exact
          path={routes.pipeline2}
          component={getComponent('pipelines', Deals)}
        />
        <PrivateRoute
          exact
          path={`${routes.pipeline}/:id/activity/:activityId`}
          component={getComponent('activities', PipelineDetail)}
        />
        <PrivateRoute
          exact
          path={`${routes.pipeline}/:id`}
          component={PipelineDetail}
        />
        <PrivateRoute
          exact
          path={`${routes.pipeline2}/:id`}
          component={PipelineDetail}
        />
        <PrivateRoute
          exact
          path={routes.caccounts}
          isSplitView={true}
          component={getComponent(
            PermissionsConstants.CAccounts.Accounts,
            CAccounts
          )}
        />
        <PrivateRoute
          isSplitView={true}
          path={`${routes.contacts}/:contactId/profile/activity/:activityId`}
          component={getComponent('contacts', PeopleProfile)}
        />
        <PrivateRoute
          isSplitView={true}
          path={`${routes.contacts}/:contactId/profile`}
          component={getComponent('contacts', PeopleProfile)}
        />
        <PrivateRoute
          isSplitView={true}
          path={routes.Activities}
          component={getComponent('activities', Activities)}
        />
        <PrivateRoute
          isSplitView={true}
          path={routes.Checklist}
          component={getComponent(
            PermissionsConstants.Checklist.checklist,
            Checklists
          )}
        />
        <PrivateRoute
          isSplitView={true}
          path={`${routes.contacts}/:organizationId/organization/profile/activity/:activityId`}
          component={getComponent('companies', OrganizationProfile)}
        />
        <PrivateRoute
          isSplitView={true}
          path={`${routes.companies}/:organizationId/organization/profile`}
          component={getComponent('companies', OrganizationProfile)}
        />

        <PrivateRoute
          path={routes.insights}
          component={getComponent('reporting', Insights)}
        />
        <PrivateRoute
          isSplitView={true}
          path={`${routes.insightsCompanies}/:organizationId/organization/profile`}
          component={getComponent('companies', OrganizationProfile)}
        />
        <PrivateRoute
          path={routes.insightsCompanies}
          component={getComponent('companies', OrganizationsPage)}
        />
        <PrivateRoute
          path={routes.reporting}
          component={getComponent('reporting', Insights)}
        />

        <PrivateRoute
          path={routes.favorites}
          component={getComponent('learns', MyLessons)}
        />
        <Route
          exact
          path={`${routes.selfAssessmentPublic}/:id/results`}
          component={SelfAssessmentResults}
        />
        <Route
          exact
          path={`${routes.videoPlayer}/:videoId`}
          component={VideoPlayer}
        />
        <Route
          exact
          path={routes.selfAssessmentPublic}
          component={SelfAssessment}
        />
        <Route
          exact
          path={`${routes.selfAssessmentAdaption}/:id/results`}
          component={SelfAssessmentResults}
        />
        <Route
          exact
          path={routes.selfAssessmentAdaption}
          component={SelfAssessment}
        />
        <Route exact path={routes.maintenance} component={Maintenance} />
        <PrivateRoute exact path={routes.welcome} component={Welcome} />

        <PrivateRoute
          path={`${routes.selfAssessment}/:id/results`}
          component={getComponent(
            'learns_self_assessment',
            SelfAssessmentResults
          )}
        />
        <PrivateRoute
          path={routes.selfAssessment}
          component={getComponent('learns_self_assessment', SelfAssessment)}
        />
        <PrivateRoute
          path={routes.knowledgeAssessment}
          component={getComponent(
            PermissionsConstants.Learn.KnowledgeAssessment,
            KnowledgeAssessment
          )}
        />
        <PrivateRoute
          path="/learn/categories/:slug"
          component={getComponent('learns_lesson_catalog', Category)}
        />
        <PrivateRoute
          path="/learn/lessons/:id/page/:page_id/course/:course_id"
          component={getComponent('learns', CourseDetail)}
        />
        <PrivateRoute
          path="/learn/lessons/:id/page/:page_id"
          component={getComponent('learns', Lesson)}
        />
        <PrivateRoute
          path={routes.lesson}
          component={getComponent('learns', Lesson)}
        />
        <PrivateRoute
          exact
          path={routes.learnMain}
          component={getComponent('learns', Learn)}
        />
        <PrivateRoute
          path="/learn/courses/:id"
          exact
          component={getComponent('learns', CourseDetail)}
        />
        <PrivateRoute
          exact
          path="/settings"
          component={getComponent('settings', Settings)}
        />
        <PrivateRoute
          exact
          path="/settings/profile"
          component={getComponent('settings_profile', ProfilePage)}
        />
        <PrivateRoute path="/settings/security" component={Security} />
        <PrivateRoute
          path="/settings/notifications"
          component={getComponent('settings_notifications', Notification)}
        />
        <PrivateRoute
          path={routes.notificationsAll}
          component={NotificationsAll}
        />
        <PrivateRoute
          path={routes.branding}
          component={getComponent('settings_branding', SiteSettings)}
        />
        <PrivateRoute
          path={routes.integrations}
          component={getComponent('settings_integrations', Integrations)}
        />
        <PrivateRoute
          path={routes.pipelinesAndStages}
          component={getComponent(
            'settings_pipeline_and_stages',
            PipelinesAndStages
          )}
        />
        <PrivateRoute
          path={routes.fields}
          component={getComponent('settings_fields', Fields)}
        />
        <PrivateRoute
          path={routes.checklistfields}
          component={getComponent('settings_checklist', ChecklistFields)}
        />
        <PrivateRoute
          path="/settings/bulk-import"
          component={getComponent('settings_data_mapper', BulkImportPage)}
          exact
        />
        <PrivateRoute
          path="/settings/bulk-import/:type"
          component={getComponent('settings_data_mapper', BulkImport)}
          exact
        />
        <PrivateRoute
          exact
          path={routes.training}
          component={getComponent('learns', Resources)}
        />
        <PrivateRoute
          exact
          path={routes.learn}
          component={getComponent('settings_learn', Resources)}
        />
        <PrivateRoute
          requireAdminAccess
          exact
          path="/settings/resources/:userId"
          component={getComponent('resources', ResendInvite)}
        />
        <PrivateRoute
          requireAdminAccess
          exact
          path="/settings/workflow"
          component={WorkFlow}
        />
        <PrivateRoute
          requireAdminAccess
          exact
          path={`${routes.workflow}/view`}
          component={WorkFlowDetail}
        />
        <PrivateRoute
          requireAdminAccess
          exact
          path={routes.users}
          component={getComponent('settings_users_and_controls', Users)}
        />
        <PrivateRoute
          requireAdminAccess
          exact
          path="/settings/products"
          component={getComponent('settings_products', Products)}
        />

        <PrivateRoute
          requireAdminAccess
          path={`${routes.roles}/:id`}
          component={getComponent('settings_profile', Roles)}
        />
        <PrivateRoute
          isSplitView={true}
          path={`${routes.contacts}/:organizationId/organization/profile/activity/:activityId`}
          component={getComponent('companies', OrganizationProfile)}
        />

        <PrivateRoute
          exact
          path={routes.resources}
          component={getComponent('prospecting', Resrcs)}
        />
        <PrivateRoute
          exact
          path={routes.prospecting}
          component={getComponent('prospecting', Resrcs)}
        />
        <PrivateRoute
          exact
          path={routes.aiAssist}
          component={getComponent('ai_assist', AIAssist)}
        />
        <PrivateRoute
          exact
          path="/prospects/people/:id"
          component={getComponent('contacts', PeopleDetail)}
        />
        <PrivateRoute
          exact
          path="/prospects/company/:id"
          component={getComponent('companies', CompanyDetail)}
        />
        <PrivateRoute
          requireAdminAccess
          exact
          path={`${routes.users}/:id`}
          component={ResendInvite}
        />
        <PrivateRoute
          exact
          path={`${routes.usersProfile}/:id`}
          component={UserProfile}
        />
        <PrivateRoute
          requireAdminAccess
          path={`${routes.groups}/:id`}
          component={Groups}
        />
        <PrivateRoute
          requireAdminAccess
          path={`${routes.ManageRoles}/:id`}
          component={ManageRoles}
        />
        <PrivateRoute
          requireAdminAccess
          exact
          path={routes.tenant}
          component={Tenants}
        />
        <PrivateRoute
          requireAdminAccess
          exact
          path="/training"
          component={Resources}
        />
        <PrivateRoute
          exact
          requireAdminAccess
          path="/changelog"
          component={Changelog}
        />
        {/* Errors views */}

        <PrivateRoute
          exact
          path={routes.errors.Unauthorized}
          component={Unauthorized}
        />
        {!profileInfo?.role?.admin_access && tenant?.modules && (
          <PrivateRoute path={'/'} component={getRootComponent()} />
        )}
        {profileInfo?.role?.admin_access && (
          <>
            {window.location.pathname === '/tenants' ||
            window.location.pathname === '/settings' ? (
              <>
                <PrivateRoute path={routes.tenant} component={Tenants} />
                <PrivateRoute path="/settings" component={Settings} />
                <PrivateRoute
                  exact
                  requireAdminAccess
                  path="/changelog"
                  component={Changelog}
                />
                <PrivateRoute
                  path="/settings/profile"
                  component={ProfilePage}
                />
                <PrivateRoute path="/settings/security" component={Security} />
                <PrivateRoute
                  path="/settings/notifications"
                  component={Notification}
                />
                <PrivateRoute path={routes.branding} component={SiteSettings} />
              </>
            ) : (
              <Redirect to="/tenants" />
            )}
            <Redirect to="/tenants" />
          </>
        )}
      </Switch>
    </Router>
  );
};
