import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import RightPanelModal from '../../../components/modal/RightPanelModal';
import ProspectLookupTabs from './ProspectLookupTabs';
import { PROSPECT_RIGHT_PANEL_WIDTH } from '../../../utils/Utils';
import TopicIcon from '../../commons/TopicIcon';

const ProspectLookupPanel = ({
  prospectModal,
  setProspectModal,
  profileInfo,
}) => {
  return (
    <RightPanelModal
      showModal={prospectModal}
      setShowModal={setProspectModal}
      profileInfo={profileInfo}
      containerWidth={PROSPECT_RIGHT_PANEL_WIDTH}
      Title={
        <div className="d-flex py-2 gap-2 align-items-center">
          <div className="nav-item scroll-item">
            <div className={`nav-icon treasury cursor-pointer`}>
              <TopicIcon
                icon="person"
                iconBg="treasury"
                filled={true}
                iconClasses="fs-5"
              />
            </div>
          </div>
          <h4 className="mb-0">Prospect Lookup</h4>
        </div>
      }
    >
      <ProspectLookupTabs profileInfo={profileInfo} />
    </RightPanelModal>
  );
};

const ProspectLookup = ({ profileInfo }) => {
  const [prospectModal, setProspectModal] = useState(false);

  const onSearchProspect = () => {
    setProspectModal(true);
  };

  return (
    <>
      <OverlayTrigger
        key="prospectLookup"
        placement="bottom"
        overlay={
          <Tooltip
            id={`tooltip-niacs}`}
            className={`tooltip-profile font-weight-bold`}
          >
            <p>Prospect Lookup</p>
          </Tooltip>
        }
      >
        <div className="nav-item scroll-item mb-2" onClick={onSearchProspect}>
          <div className={`nav-icon treasury forced cursor-pointer`}>
            <TopicIcon
              icon="person"
              iconBg="treasury forced"
              filled={true}
              iconClasses="fs-5"
            />
          </div>
        </div>
      </OverlayTrigger>
      {prospectModal && (
        <ProspectLookupPanel
          prospectModal={prospectModal}
          setProspectModal={setProspectModal}
          profileInfo={profileInfo}
        />
      )}
    </>
  );
};

export default ProspectLookup;
