import React, { forwardRef, useEffect, useState } from 'react';
import {
  Button,
  Card,
  FormControl,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';

import Table from '../../../GenericTable';
import { columnsTablePeople } from '../../constants';
import MaterialIcon from '../../../commons/MaterialIcon';
import RocketReachPeopleCard from '../../../organizationProfile/overview/RocketReachPeopleCard';
import RocketReachViewInfoCard from '../../../organizationProfile/overview/RocketReachViewInfoCard';
import LookupPeopleLoader from '../../../loaders/LookupPeople';
import Skeleton from 'react-loading-skeleton';
import { useFilterProspectContext } from '../../../../contexts/filterProspectContext';
import TableSelectedCount from './TableSelectedCount';
import {
  getKeysWithData,
  isModuleAllowed,
  isPermissionAllowed,
  overflowing,
} from '../../../../utils/Utils';
import ButtonIcon from '../../../commons/ButtonIcon';
import ProspectResults from '../ProspectResults';
import { ProspectTypes } from '../constants';

import TableStartSearchPlaceholder from './TableStartSearchPlaceholder';
import { usePagesContext } from '../../../../contexts/pagesContext';
import RocketReachLocation from './RocketReachLocation';
import RocketReachCompanyProfile from './RocketReachCompanyProfile';

import { PermissionsConstants } from '../../../../utils/permissions.constants';
import { useTenantContext } from '../../../../contexts/TenantContext';
import Pagination from '../../../Pagination';
import prospectService from '../../../../services/prospect.service';
import { Spinner } from 'reactstrap';
import WriteAIPanel from '../../../organizationProfile/overview/WriteAIPanel';

const SearchButton = ({ onClick }) => (
  <InputGroup.Text
    role="button"
    onClick={onClick}
    className="position-absolute border-0 p-0 z-10"
    style={{ top: 13, left: 10 }}
  >
    <MaterialIcon icon="search" />
  </InputGroup.Text>
);

const SearchInput = forwardRef(
  ({ value, onChange, onClear, onSearch }, ref) => (
    <div className="p-3">
      <FormGroup className="position-relative" size="sm">
        <SearchButton onClick={onSearch} />
        <FormControl
          id="search-input"
          ref={ref}
          aria-label="Search"
          className={`form-control w-100 rounded px-5`}
          placeholder="Search"
          value={value}
          onChange={onChange}
          onKeyDown={onSearch}
        />
        {value && <ResetButton onClick={onClear} show={true} />}
      </FormGroup>
    </div>
  )
);

SearchInput.displayName = 'SearchInput';

const ResetButton = ({ onClick, show = true }) =>
  show && (
    <Button
      variant="link"
      className="border-0 pl-0 p-0 position-absolute"
      style={{ top: 13, right: 10 }}
      size="sm"
      onClick={onClick}
    >
      <span className="material-symbols-rounded fs-5 search-close">close</span>
    </Button>
  );

const NameColumn = ({ prospect }) => {
  return (
    <RocketReachPeopleCard
      prospect={prospect}
      showSocialLinks
      withCompany={false}
      withLocation={false}
      avatarStyle={{ width: 56, height: 56 }}
      containerStyle="pt-1 pb-3"
    />
  );
};

const CompanyColumn = ({ prospect, setCompanyDetailPage, type }) => {
  const [loading, setLoading] = useState(false);
  const loadCompany = async () => {
    setLoading(true);
    try {
      const { data } = await prospectService.getContact({ id: prospect.id });
      const companyData = await prospectService.query(
        {
          name: [prospect.employer || prospect.current_employer],
          id: [data?.current_employer_id],
        },
        {
          page: 1,
          limit: 1,
          type: ProspectTypes.company,
        }
      );

      if (companyData?.data?.data?.length) {
        const company = companyData?.data?.data[0];
        setCompanyDetailPage((prevState) => ({
          ...prevState,
          id: company.id,
          show: true,
          swot: false,
          tab: ProspectTypes.people,
          name: company.name,
          ticker: company.ticker?.trim(),
        }));
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {prospect.employer && prospect.employer.toLowerCase() !== 'undefined' ? (
        <div onClick={loadCompany}>
          <span className="hoverLink text-wrap font-weight-semi-bold">
            {loading && (
              <Spinner className="text-primary spinner-grow-xs mr-1" />
            )}
            {prospect.employer}
          </span>
        </div>
      ) : (
        ''
      )}
      <RocketReachLocation prospect={prospect} />
    </>
  );
};

const PeopleWithCompanyColumn = ({
  prospect,
  inline,
  setCompanyDetailPage = () => {},
}) => {
  return (
    <>
      {prospect.employer && prospect.employer.toLowerCase() !== 'undefined' ? (
        <RocketReachCompanyProfile
          prospect={prospect}
          inline={inline}
          type={ProspectTypes.people}
          setCompanyDetailPage={setCompanyDetailPage}
        />
      ) : (
        ''
      )}
    </>
  );
};

const ContactInfoColumn = ({ prospect, setProspect }) => {
  return (
    <RocketReachViewInfoCard
      prospect={prospect}
      setProspect={setProspect}
      layout="column"
    />
  );
};

const TablePeopleProspect = ({
  data = [],
  setData,
  checkbox = false,
  pagination,
  onPageChange,
  onLimitChange,
  selectedProspects,
  setSelectedProspects,
  onHandleEdit,
  domain,
  selectAll,
  setSelectAll,
  switchToWriteTab,
  showLoading,
  loading,
  chargeFilter,
  importProspects,
  exportProspects,
  clearSelection,
  filter,
  setErrorMessage,
  setSuccessMessage,
  permissionExportImport,
  setCompanyDetailPage,
}) => {
  const { globalFilters } = useFilterProspectContext();
  const [localFilter, setLocalFilter] = useState(globalFilters);
  const [searchClicked, setSearchClicked] = useState(false);
  const { tenant } = useTenantContext();

  const [showWriteAIPanel, setShowWriteAIPanel] = useState(false);
  const [profileInfo, setProfileInfo] = useState(false);

  useEffect(() => {
    return () => {
      setShowWriteAIPanel(false);
      overflowing();
    };
  }, []);

  const updateProspects = (prospect) => {
    const newProspects = [...selectedProspects];
    newProspects.forEach((pros) => {
      if (pros.id === prospect.id) {
        pros.emails_list = prospect.emails_list;
        pros.phones_list = prospect.phones_list;
      }
    });
    setSelectedProspects(newProspects);
  };

  const updateData = (item) => {
    const newProspects = [...data].map((p) => ({
      ...p,
      isExpanded: p.id === item.id ? !p.isExpanded : false,
      detailType: ProspectTypes.people,
    }));
    setData(newProspects);
  };

  const ComponentAction = ({ item }) => {
    const onEmailClick = () => {
      const { first_name, last_name, employer, title } = item;
      const prospect = { first_name, last_name, employer, title };
      setProfileInfo(prospect);
      setShowWriteAIPanel(true);
    };

    return (
      <>
        <div className="d-flex flex-column gap-1">
          {isModuleAllowed(tenant?.modules, 'prospecting_peoples_import') &&
          isPermissionAllowed('contacts', 'create') ? (
            <ButtonIcon
              icon="add"
              label="Import Profile"
              className="btn-xs btn-primary border-success"
              onclick={(e) => {
                e.stopPropagation();
                handleOnEdit(item);
              }}
              style={{ border: '1px solid', width: 142 }}
            />
          ) : (
            <></>
          )}

          {isModuleAllowed(
            tenant?.modules,
            PermissionsConstants.AIAssist.Assist
          ) &&
            isModuleAllowed(
              tenant?.modules,
              PermissionsConstants.AIAssist.Write
            ) && (
              <ButtonIcon
                icon="auto_fix_high"
                label="Write Email"
                className="btn-xs btn-outline-primary mt-1 bg-white"
                onclick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onEmailClick();
                }}
                style={{ borderWidth: '1px', width: 142 }}
              />
            )}
        </div>
        <div className="text-primary d-flex align-items-center position-absolute expand-collapse bottom-0 mb-1 abs-center z-index-99">
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              updateData(item);
            }}
            className="d-flex align-items-center"
          >
            <span className="font-weight-medium fs-8">
              {item.isExpanded ? 'View less' : 'View more'}
            </span>
            <MaterialIcon
              icon={`${item.isExpanded ? 'expand_less' : 'expand_more'}`}
              clazz={`font-size-lg text-primary`}
            />
          </a>
        </div>
      </>
    );
  };

  const rows = data?.map((item) => {
    const response = {
      ...item,
      dataRow: [
        {
          key: 'Name',
          component: <NameColumn prospect={item} />,
        },
        {
          key: 'Company',
          component: (
            <CompanyColumn
              prospect={item}
              setCompanyDetailPage={setCompanyDetailPage}
            />
          ),
        },
        {
          key: 'Available Details',
          component: (
            <ContactInfoColumn
              prospect={item}
              setProspect={(item) => updateProspects(item)}
            />
          ),
        },
        {
          key: '',
          component: <ComponentAction item={item} />,
        },
        {
          key: '',
          component: '',
        },
      ],
    };

    return response;
  });

  const handleOnEdit = (row) => {
    onHandleEdit(row);
  };
  const { pageContext, setPageContext } = usePagesContext();

  useEffect(() => {
    setLocalFilter(globalFilters);
    const filters = getKeysWithData(globalFilters);
    delete filters.news_time_range;
    delete filters.news;
    setSearchClicked(!!Object.keys(filters).length);
    setPageContext({
      ...pageContext,
      PeopleSearch: { global: globalFilters, local: filters },
    });
  }, [globalFilters]);

  return (
    <>
      <Card className="card-0 position-unset">
        <Card.Header className="border-bottom d-flex prospect-detail-heading align-items-center justify-between">
          <h4 className="mb-0">People</h4>
          {data && data.length > 0 && (
            <div>
              {domain && <span className="fw-bold">Contacts at {domain}</span>}
              {pagination?.total && (
                <ProspectResults
                  saveSearchOnly={true}
                  pagination={pagination}
                  filter={localFilter}
                  type={ProspectTypes.people}
                  setErrorMessage={setErrorMessage}
                  setSuccessMessage={setSuccessMessage}
                />
              )}
            </div>
          )}
        </Card.Header>
        <Card.Body className="p-0">
          {selectedProspects.length > 0 && (
            <TableSelectedCount
              list={selectedProspects}
              containerPadding="py-3 pr-3 pl-2_1"
              btnClick={importProspects}
              btnClass="btn-sm text-white"
              btnIcon="add"
              btnLabel="Create"
              btnColor="success"
              onClear={clearSelection}
              align="justify-content-start"
              customButton={
                <div className="d-flex align-items-center gap-2 flex-grow-1 justify-content-end">
                  {isModuleAllowed(
                    tenant?.modules,
                    'prospecting_peoples_import'
                  ) &&
                    isPermissionAllowed('contacts', 'create') && (
                      <ButtonIcon
                        onclick={importProspects}
                        icon="add"
                        label="Import"
                        classnames={`btn-sm px-3 text-white d-flex align-items-center justify-content-center`}
                        color="success"
                      />
                    )}
                  {isModuleAllowed(
                    tenant?.modules,
                    'prospecting_peoples_export'
                  ) &&
                    isPermissionAllowed('prospects', 'view') && (
                      <ButtonIcon
                        onclick={exportProspects}
                        icon="file_download"
                        label="Export"
                        classnames="btn-sm px-3"
                        color="outline-primary"
                      />
                    )}
                </div>
              }
            />
          )}

          <div className="datatable-custom">
            {showLoading || loading ? (
              <LookupPeopleLoader
                count={6}
                circle={<Skeleton height={60} width={60} circle />}
                container
              />
            ) : (
              <>
                {data?.length > 0 || rows.length > 0 ? (
                  <Table
                    className={`prospect-table`}
                    checkbox={
                      isPermissionAllowed('prospects', 'view') ||
                      isPermissionAllowed('contacts', 'create')
                    }
                    selectedData={selectedProspects}
                    setSelectedData={setSelectedProspects}
                    columns={columnsTablePeople}
                    data={rows}
                    checkboxZIndex=""
                    showLoading={loading}
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    paginationInfo={pagination}
                    title="prospects"
                    usePagination={false}
                    dataInDB={pagination?.total > 9}
                    noDataInDbValidation
                    expandComponent={PeopleWithCompanyColumn}
                    setCompanyDetailPage={setCompanyDetailPage}
                  />
                ) : (
                  <>
                    {searchClicked ? (
                      <TableStartSearchPlaceholder
                        title="No Results Found."
                        description="Results matching this query could not be displayed. Please try refining your search or clearing some of your filters."
                      />
                    ) : (
                      <TableStartSearchPlaceholder />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Card.Body>
      </Card>
      {data?.length > 0 || rows.length > 0 ? (
        <div className="align-items-center border-top bg-gray-table-head row px-3 py-1 prospect-stats">
          <ProspectResults
            resultsOnly={true}
            loading={showLoading}
            pagination={pagination}
            filter={localFilter}
            type={ProspectTypes.people}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
          />

          <div className="d-flex align-items-center ml-auto">
            <Pagination
              showPerPage={false}
              paginationInfo={pagination}
              onPageChange={onPageChange}
              onLimitChange={onLimitChange}
            />
          </div>
        </div>
      ) : null}

      {showWriteAIPanel && (
        <WriteAIPanel
          newsstandModal={showWriteAIPanel}
          setNewsstandModal={setShowWriteAIPanel}
          profileInfo={profileInfo}
        />
      )}
    </>
  );
};

export default TablePeopleProspect;
