import React, { useState } from 'react';
import ProfileCardItem from '../../peopleProfile/ProfileCardItem';
import DeleteConfirmationModal from '../../modal/DeleteConfirmationModal';
import { useModuleContext } from '../../../contexts/moduleContext';

const OrgContactsList = ({
  item,
  handleRemove,
  isPrincipalOwner,
  mainOwner,
}) => {
  const { moduleMap } = useModuleContext();
  const [openModal, setOpenModal] = useState(false);

  const removeItem = () => {
    setOpenModal(true);
  };

  const handleConfirm = async () => {
    try {
      await handleRemove(item.id);
      setOpenModal(false);
    } catch (error) {
      console.log('error while delete contact', error);
    }
  };

  const heading = `Delete ${moduleMap.contact.singular}`;
  const description = `Are you sure you want to delete this ${moduleMap.contact.singular}?`;

  return (
    <div className="my-0">
      <DeleteConfirmationModal
        showModal={openModal}
        setShowModal={setOpenModal}
        event={handleConfirm}
        heading={heading}
        description={description}
      />

      <div className="media">
        <ProfileCardItem
          user={item}
          mainOwner={mainOwner}
          size={'xs'}
          contact={true}
        />
        <div className="media-body my-0" />
        {handleRemove && (
          <div
            style={{ width: '10%' }}
            className={'d-flex justify-content-center'}
          >
            {isPrincipalOwner && (
              <button
                className="btn btn-icon btn-sm btn-ghost-danger rounded-circle mt-2"
                onClick={removeItem}
              >
                <i className="material-symbols-rounded fs-5">delete</i>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrgContactsList;
