import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react';
import tenantService from '../services/tenant.service';
import { useTenantContext } from './TenantContext';
import { useAppContext } from './appContext';

const CONFIG_NAMES = {
  DISABLE_REPORT_FILE_PARSING: 'DISABLE_REPORT_FILE_PARSING',
  DISABLE_REPORT_TREASURY_FEES: 'DISABLE_REPORT_TREASURY_FEES',
};

const TenantConfigsContext = createContext();

export const TenantConfigsContextProvider = ({ children }) => {
  const [configs, setConfigs] = useState({});
  const { tenant } = useTenantContext();
  const { isAuthenticated } = useAppContext();

  useEffect(() => {
    const getTenantConfig = async () => {
      const tenantConfigs = await tenantService.getTenantConfig(tenant.id);
      const parsedConfigs = tenantConfigs
        .filter((cf) => !cf.config.isEditable) // currently isEditable: true are SMTP ones so only picking false ones for creating a map
        .reduce((acc, tenantConfig) => {
          acc[tenantConfig.config.name] = {
            name: tenantConfig.config.name,
            value: tenantConfig.value,
          };
          return acc;
        }, configs);
      setConfigs(parsedConfigs);
    };
    // when we refresh page default root id is set to mount react app so avoiding that call
    // make sure we are logged in to get config
    tenant?.id !== 'root' && isAuthenticated && getTenantConfig();
  }, [tenant, isAuthenticated]);

  const value = useMemo(
    () => ({ configs, setConfigs, CONFIG_NAMES }),
    [configs, CONFIG_NAMES]
  );

  return (
    <TenantConfigsContext.Provider value={value}>
      {children}
    </TenantConfigsContext.Provider>
  );
};

export const useTenantConfigs = () => useContext(TenantConfigsContext);
