import axios from 'axios';
import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';
const API_URL = process.env.REACT_APP_API_URL;

class CubeService extends BaseRequestService {
  loadCustomAnalytics({
    type,
    users,
    startDate,
    endDate,
    limit,
    teams,
    orderBy,
    assignments,
  }) {
    return axios
      .post(
        `${API_URL}/api/providers/analytics/load`,
        { type, users, startDate, endDate, limit, teams, assignments, orderBy },
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data);
  }
}

export const cubeService = new CubeService();
