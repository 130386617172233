import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Card,
  CardForm,
  CardHeader,
  CardBlock,
  CardContentCustom,
  CardSection,
  CardSideCustom,
  CardTitle,
  CardSubtitle,
  CardSubContent,
  CardButton,
  List,
  Item,
  ItemAvatar,
  ItemUser,
  ItemActions,
} from '../../../components/layouts/CardLayout';
import Alert from '../../../components/Alert/Alert';
import roleService from '../../../services/role.service';
import userService from '../../../services/user.service';
import stringConstants from '../../../utils/stringConstants.json';
import permissions from '../../../utils/permissions';
import { isModuleAllowed } from '../../../utils/Utils';
import Avatar from '../../../components/Avatar';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import { PermissionsContext } from '../../../contexts/permissionContext';
import { Col, Row } from 'reactstrap';
import { Form, FormCheck } from 'react-bootstrap';
import {
  CANCEL_LABEL,
  NAME_INVITED_USER,
  STATUS_ACTIVE,
} from '../../../utils/constants';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import TooltipComponent from '../../../components/lesson/Tooltip';
import _, { capitalize } from 'lodash';
import { useTenantContext } from '../../../contexts/TenantContext';
import AutoComplete from '../../../components/AutoComplete';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';

const constants = stringConstants.settings.roles.edit;

const permissionList = permissions.permissionList;

const role = {
  name: '',
  description: '',
  id: '',
  isAdmin: false,
};

const buttons = {
  save: {
    title: constants.saveRole,
    variant: 'primary',
  },
  remove: {
    title: constants.remove,
    variant: 'outline-danger',
  },
  add: {
    title: constants.add,
    variant: 'outline-primary',
  },
};

const EditRoles = () => {
  const history = useHistory();
  const [removeModal, setRemoveModal] = useState(false);
  const [roleData, setRoleData] = useState(role);
  const [searchUser, setSearchUser] = useState();
  const [usersData, setUsersData] = useState([]);
  const [userSelection, setUserSelection] = useState([]);
  const [initialRoleUsers, setInitialRoleUsers] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState(null);
  const [ownerAccessSwitch, setOwnerAccessSwitch] = useState(false);
  const [tenantAccessSwitch, seTenantAccessSwitch] = useState(false);
  const [permissionLevel, setPermissionLevel] = useState({});
  const [permissionSwitches, setpermissionSwitches] = useState([]);
  const { permissionChanges, setPermissionChanges } =
    useContext(PermissionsContext);
  const [permissions, setPermissions] = useState([...permissionList]);
  const [permissionsDropdown, setPermissionsDropdown] = useState('');
  const { tenant } = useTenantContext();
  const onInputChange = (e) => {
    const { name, value } = e.target || {};
    setRoleData({
      ...roleData,
      [name]: value,
    });
  };
  const onInputSearch = (e) => {
    const { value } = e.target || {};
    setSearchUser({
      ...searchUser,
      search: value,
    });
  };

  const roleId = useParams();
  const deleteUserItem = async (itemIndex) => {
    const updatedUsers = userSelection.filter(
      (item, index) => index !== itemIndex
    );
    setUserSelection(updatedUsers);
  };
  // Update role service
  const updateRole = () => {
    return new Promise((resolve, reject) => {
      roleData.id = roleId.id;
      roleData.isOwner = ownerAccessSwitch || false;

      userSelection.length > 0 &&
        userSelection.forEach(async (user) => {
          const checkIfExist = initialRoleUsers.some(
            (item) => item.id === user.id
          );
          if (!checkIfExist) {
            const userUpdate = {
              roleId: roleId.id,
              first_name: user?.first_name,
              last_name: user?.last_name,
              avatar: user?.avatar,
              status: user?.status,
            };

            userService
              .updateUserInfoById(user.id, userUpdate)
              .catch((err) => console.log(err));
          }
        });
      initialRoleUsers.length > 0 &&
        initialRoleUsers.forEach(async (user) => {
          const checkIfExist = userSelection.some(
            (item) => item.id === user.id
          );
          if (!checkIfExist) {
            const userUpdate = {
              roleId: null,
              first_name: null,
              last_name: null,
              avatar: null,
            };

            userService
              .updateUserInfoById(user.id, userUpdate)
              .catch((err) => console.log(err));
          }
        });

      setInitialRoleUsers(userSelection);

      return roleService.updateRole(roleData).then(resolve).catch(reject);
    });
  };

  const getRoleById = () => {
    const roleById = roleService.getRoleById(roleId.id);
    roleById &&
      roleById.then((roleResult) => {
        setRoleData({
          name: roleResult.name || '',
          description: roleResult.description || '',
          id: roleResult.id || '',
        });
        setOwnerAccessSwitch(roleResult.owner_access || '');
      });
  };

  const getRoleUsers = async () => {
    const response = await userService
      .getUsers({ roleId: roleId.id }, { limit: 20 })
      .catch((err) => console.log(err));

    const roleUsers = response.data.users;

    const roleUsersList = roleUsers.map((user) => {
      const roleUsersItem = {
        name: `${
          user.first_name !== null ? user.first_name : NAME_INVITED_USER
        } ${user.last_name !== null ? user.last_name : ''}`,
        email: user.email,
        avatar: user.avatar,
        id: user.id,
        status: user?.status,
        roleId: user.roleId,
        first_name: user.first_name,
        last_name: user.last_name,
      };
      return roleUsersItem;
    });
    setUserSelection(roleUsersList);
    setInitialRoleUsers(roleUsersList);
  };

  const getRolePermissions = async () => {
    const rolePermissions = await roleService
      .getPermissionsByRole(roleId.id)
      .catch((err) => console.log(err));

    const permissionsCollection = rolePermissions.filter((item) => {
      return item.collection;
    });

    if (permissionsCollection && permissionsCollection[0].level === 'tenant') {
      seTenantAccessSwitch(true);
    }
    setPermissionLevel(permissionsCollection[0]);
    const groupedPermission = _.groupBy(permissionsCollection, 'collection');

    const loadedPermissions = Object.keys(groupedPermission).map((key) => {
      const inner = groupedPermission[key]; // array
      const innerPermssion = {};
      innerPermssion.name = key;
      innerPermssion.label = capitalize(key);
      innerPermssion.group = inner.map((perm) => {
        return {
          name: perm.action,
          label: capitalize(perm.action),
          isChecked: true,
          permissions: [
            {
              label: capitalize(perm.action),
              collection: perm.collection,
              action: perm.action,
            },
          ],
        };
      });
      return innerPermssion;
    });

    const permissionJson = [...permissions];
    permissionJson.forEach((permission) => {
      permission.inner_permissions.forEach((permCat) => {
        const permFound = loadedPermissions.find(
          (f) => f.name === permCat.name
        );
        if (permFound) {
          const groupItems = permFound.group.map((m) => m.name);
          permCat.group.forEach((m2) => {
            m2.isChecked = groupItems.indexOf(m2.name) > -1;
          });
        }
      });
    });
    setPermissions(permissionJson);
    setpermissionSwitches(permissionsCollection);
  };

  useEffect(() => {
    getRoleById();
    getRoleUsers();
    getRolePermissions();
  }, []);
  const getUsers = async () => {
    const searchResults = await userService
      .getUsers(
        { ...{ search: searchUser?.search, status: STATUS_ACTIVE } },
        {
          page: 1,
          limit: 10,
        }
      )
      .catch((err) => console.log(err));
    const { data } = searchResults || {};

    const filteredUsersData = data?.users?.filter((user) => {
      return !userSelection?.some((item) => item.id === user.id);
    });
    setUsersData(filteredUsersData);
  };
  useEffect(() => {
    getUsers();
  }, [searchUser?.search]);
  useEffect(() => {
    if (ownerAccessSwitch) setOwnerAccessSwitch(true);
  }, [ownerAccessSwitch]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const updatedData = permissionSwitches?.map((item) => {
        return {
          ...item,
          level: permissionSwitches[0]?.level === 'tenant' ? 'tenant' : 'user',
        };
      });
      await updateRole();
      await roleService.updatePermissions(roleId.id, [...updatedData]);
      await getRolePermissions();
      setToast(constants.roleUpdateSuccess);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
      setPermissionChanges(!permissionChanges);
      handleDropdownPermission('');
    }
  };

  const handleDeleteProfile = async () => {
    try {
      await roleService.deleteRole(roleId.id);
      history.goBack();
    } catch (error) {
      setToast(constants.roleRemoveFailed);
    }
  };

  const handleSwitchEvent = async (e, permissionGroup, permissionsCategory) => {
    if (
      permissionSwitches[0]?.level === 'user' &&
      permissionGroup?.name === 'delete'
    ) {
      return null;
    } else {
      const isChecked = e.target.checked;
      const newPermissionGroup = {
        collection: permissionGroup.permissions[0].collection,
        action: permissionGroup.name,
      };

      // update permissions category group
      const updatedGroup = permissionsCategory.group.map((group) => ({
        ...group,
        isChecked:
          group.name === permissionGroup.name
            ? !group.isChecked
            : group.isChecked,
      }));

      const updatedPermissions = [...permissions].map((perm) => ({
        ...perm,
        inner_permissions: [...perm.inner_permissions].map((permCat) => ({
          ...permCat,
          group:
            permCat.name === permissionsCategory.name
              ? updatedGroup
              : permCat.group,
        })),
      }));
      setPermissions(updatedPermissions);
      if (isChecked) {
        let newPermissionsData = [...permissionSwitches, newPermissionGroup];
        if (newPermissionGroup?.collection === 'lessons') {
          const course = {
            collection: 'courses',
            action: newPermissionGroup?.action,
          };
          const categories = {
            collection: 'categories',
            action: newPermissionGroup?.action,
          };
          newPermissionsData = [
            ...permissionSwitches,
            newPermissionGroup,
            course,
            categories,
          ];
        }
        setpermissionSwitches(newPermissionsData);
      } else {
        if (
          permissionGroup?.name === 'view' &&
          newPermissionGroup?.collection !== 'prospects'
        ) {
          const updatedCheckedPermissions = permissionSwitches?.filter(
            (permissionCheck) => {
              return permissionCheck.collection !== permissionsCategory?.name;
            }
          );
          const updatedGroup = permissionsCategory.group.map((group) => ({
            ...group,
            isChecked: false,
          }));
          const updatedPermissions = [...permissions].map((perm) => ({
            ...perm,
            inner_permissions: [...perm.inner_permissions].map((permCat) => ({
              ...permCat,
              group:
                permCat.name === permissionsCategory.name
                  ? updatedGroup
                  : permCat.group,
            })),
          }));
          setPermissions(updatedPermissions);
          setpermissionSwitches(updatedCheckedPermissions);
          setPermissionsDropdown('');
        } else {
          let updatedSwitches = permissionSwitches.filter(
            (f) =>
              f.collection !== newPermissionGroup.collection ||
              f.action !== newPermissionGroup.action
          );
          if (newPermissionGroup.collection === 'lessons') {
            permissionSwitches.forEach((switchItem) => {
              if (
                switchItem.collection === 'courses' ||
                switchItem.collection === 'categories'
              ) {
                updatedSwitches = permissionSwitches.filter(
                  (item) =>
                    (item.collection !== 'courses' ||
                      item.action !== newPermissionGroup.action) &&
                    (item.collection !== 'lessons' ||
                      item.action !== newPermissionGroup.action) &&
                    (item.collection !== 'categories' ||
                      item.action !== newPermissionGroup.action)
                );
              }
            });
          }
          setpermissionSwitches(updatedSwitches);
        }
      }
      if (updatedGroup.every((item) => !item.isChecked)) {
        handleDropdownPermission('');
      }
    }
  };

  const handlePermissionCategoryChange = async (
    permission,
    permissionCategory
  ) => {
    // now each permission object has inner_permissions array which is being rendered in UI, so we have to update that
    // in order to reflect in state/UI
    // instead of using .isChecked, you've started using permissionSwitches in value of toggle
    const updatedGroup = permissionCategory?.group.map((group) => ({
      ...group,
      isChecked: !permissionCategory.isChecked,
    }));
    const updatedInnerPermissions = [...permission.inner_permissions].map(
      (permCat) => ({
        ...permCat,
        isChecked:
          permissionCategory.name === permCat.name
            ? !permissionCategory.isChecked
            : permCat.isChecked,
        group:
          permissionCategory.name === permCat.name
            ? updatedGroup
            : permCat.group,
      })
    );

    // once we have inner_permissions updated we need to updated its parent too.
    const updatedPermissions = [...permissions].map((perm) => ({
      ...perm,
      inner_permissions:
        perm.name === permission.name
          ? updatedInnerPermissions
          : perm.inner_permissions,
    }));
    setPermissions(updatedPermissions);
    const collection = permission.inner_permissions.find((item) => {
      return item.name === permissionCategory.name;
    });
    const action = collection?.group?.filter((item) => {
      return item.name;
    });
    const permissionCheckUnCheck = permissionSwitches?.find((item) => {
      return item.collection === permissionCategory.name;
    });
    let updatedCheckedPermissions = [];
    if (permissionCategory.isChecked) {
      updatedCheckedPermissions = permissionSwitches?.filter(
        (permissionCheck) => {
          return (
            permissionCheck.collection !== permissionCheckUnCheck.collection
          );
        }
      );
      setpermissionSwitches(updatedCheckedPermissions);
      handleTraining(updatedCheckedPermissions);
      handleDropdownPermission('');
    } else {
      const arr = [];
      const permissionArray = action
        .map((group) => ({
          collection: group.permissions[0]?.collection,
          action: group.permissions[0]?.action,
        }))
        .filter((permissionChecked) => {
          return !(
            (permissionLevel?.level === 'user' || !ownerAccessSwitch) &&
            (permissionChecked?.action === 'delete' ||
              isInvalidPermission(permissionChecked))
          );
        });
      const getContactCollection = permission.inner_permissions.find(
        (item) => item.name === 'contacts'
      );
      const getCompaniesCollection = permission.inner_permissions.find(
        (item) => item.name === 'organizations'
      );
      const getDealCollection = permission.inner_permissions.find(
        (item) => item.name === 'deals'
      );
      const getNoteCollection = permission.inner_permissions.find(
        (item) => item.name === 'notes'
      );
      const getProductCollection = permission.inner_permissions.find(
        (item) => item.name === 'products'
      );
      if (permissionCategory.name === 'deals') {
        if (
          !permissionArray.some(
            (item) =>
              item.collection === getCompaniesCollection?.name &&
              item.action === getCompaniesCollection?.group[0].name
          ) &&
          !permissionArray.some(
            (item) =>
              item.collection === getContactCollection?.name &&
              item.action === getContactCollection?.group[0].name
          ) &&
          !permissionArray.some(
            (item) =>
              item.collection === getProductCollection?.name &&
              item.action === getProductCollection?.group[0].name
          ) &&
          !permissionArray.some(
            (item) =>
              item.collection === getNoteCollection?.name &&
              item.action === getNoteCollection?.group[0].name
          )
        ) {
          arr.push(
            {
              collection: getCompaniesCollection?.name,
              action: getCompaniesCollection?.group[0].name,
            },
            {
              collection: getContactCollection?.name,
              action: getContactCollection?.group[0].name,
            },
            {
              collection: getProductCollection?.name,
              action: getProductCollection?.group[0].name,
            },
            {
              collection: getNoteCollection?.name,
              action: getNoteCollection?.group[0].name,
            }
          );
        }
      } else if (permissionCategory.name === 'activities') {
        // Check if the objects are not already in the array before pushing
        if (
          !permissionArray.some(
            (item) =>
              item.collection === getCompaniesCollection?.name &&
              item.action === getCompaniesCollection?.group[0].name
          ) &&
          !permissionArray.some(
            (item) =>
              item.collection === getContactCollection?.name &&
              item.action === getContactCollection?.group[0].name
          ) &&
          !permissionArray.some(
            (item) =>
              item.collection === getDealCollection?.name &&
              item.action === getDealCollection?.group[0].name
          ) &&
          !permissionArray.some(
            (item) =>
              item.collection === getNoteCollection?.name &&
              item.action === getNoteCollection?.group[0].name
          )
        ) {
          arr.push(
            {
              collection: getCompaniesCollection?.name,
              action: getCompaniesCollection?.group[0].name,
            },
            {
              collection: getContactCollection?.name,
              action: getContactCollection?.group[0].name,
            },
            {
              collection: getDealCollection?.name,
              action: getDealCollection?.group[0].name,
            },
            {
              collection: getNoteCollection?.name,
              action: getNoteCollection?.group[0].name,
            }
          );
        }
      }
      const allPermissionsData = [
        ...permissionSwitches,
        ...permissionArray,
        ...arr,
      ];
      if (permissionCategory?.name === 'lessons') {
        handleTraining(allPermissionsData);
      }
      setpermissionSwitches(allPermissionsData);
    }
  };
  const isInvalidPermission = (permission) => {
    const { collection, action } = permission;
    return (
      (collection === 'dashboard' && action === 'manage') ||
      (collection === 'lessons' && action === 'create') ||
      (collection === 'lessons' && action === 'edit') ||
      (collection === 'prospects' && action === 'edit') ||
      (collection === 'prospects' && action === 'create')
    );
  };
  const handleTraining = (allPermissionsData) => {
    if (
      allPermissionsData.every((item) => item.collection !== 'courses') &&
      allPermissionsData.every((item) => item.collection !== 'categories')
    ) {
      const allowedActions = ['edit', 'delete', 'view', 'create'];

      const isAllowedActionExists = allPermissionsData.some(
        (permission) =>
          permission.collection === 'lessons' &&
          allowedActions.includes(permission.action)
      );

      if (!isAllowedActionExists) {
        allPermissionsData = allPermissionsData.filter(
          (permission) =>
            permission.collection !== 'courses' &&
            permission.collection !== 'categories'
        );
      }
      const newCourse = allowedActions
        .map((action) => ({
          collection: 'courses',
          action: isAllowedActionExists ? action : '',
        }))
        .filter((obj) => obj.collection !== '' && obj.action !== '');

      const newCategory = allowedActions
        .map((action) => ({
          collection: 'categories',
          action: isAllowedActionExists ? action : '',
        }))
        .filter((obj) => obj.collection !== '' && obj.action !== '');

      setpermissionSwitches([
        ...allPermissionsData,
        ...newCategory,
        ...newCourse,
      ]);
    }
  };
  const handleDropdownPermission = (item) => {
    if (permissionsDropdown) {
      setPermissionsDropdown('');
    } else if (!permissionsDropdown) {
      setPermissionsDropdown(item);
    }
  };

  const handleSelected = (item) => {
    // Check if item with the same id already exists in userSelection
    const isItemAlreadyAdded = userSelection?.some(
      (existingItem) => existingItem.id === item.id
    );

    if (!isItemAlreadyAdded) {
      // If not present, add the item to userSelection
      const updatedUserSelection = [...userSelection, item];
      setUserSelection(updatedUserSelection);
      setSearchUser({ search: '' });
    } else {
      // Log or handle the case where the item is already in userSelection
      console.log(
        'Item with the same id already exists in userSelection:',
        item
      );
    }
  };

  useEffect(() => {
    if (!tenantAccessSwitch) {
      const filteredPermissions = permissionSwitches?.filter(
        (switchPermission) => {
          return !permissionList[1]?.inner_permissions?.some((listGroup) =>
            listGroup?.group?.some(
              (listPermission) =>
                listGroup.name === switchPermission.collection &&
                listPermission.name === switchPermission.action
            )
          );
        }
      );
      setpermissionSwitches(filteredPermissions);
    }
  }, [tenantAccessSwitch]);

  const moduleNoteCheck = (modules, moduleName) => {
    const isCRMModule = moduleName === 'crm';

    if (isCRMModule) {
      const notesModule = modules?.includes('pipelines_notes')
        ? 'pipelines_notes'
        : '' || modules?.includes('contacts_notes')
        ? 'contacts_notes'
        : '' || modules?.includes('companies_notes')
        ? 'companies_notes'
        : '';
      return isModuleAllowed(modules, notesModule);
    } else {
      return isModuleAllowed(modules, moduleName);
    }
  };

  return (
    <>
      <AlertWrapper>
        <Alert message={toast} setMessage={setToast} color="success" />
      </AlertWrapper>
      <DeleteConfirmationModal
        showModal={removeModal}
        setShowModal={setRemoveModal}
        itemsConfirmation={[roleData]}
        event={handleDeleteProfile}
        heading="Delete Profile"
        description="Are you sure you want to delete this Profile?"
        extraBody={
          <p className="mt-2 mb-0">
            {stringConstants.deleteConfirmationModal.deleteProfileMessage}
          </p>
        }
      />
      <Card>
        <CardHeader>
          <CardTitle>{constants.title}</CardTitle>
          <div>
            {initialRoleUsers?.length < 1 && (
              <CardButton
                title={buttons.remove.title}
                variant={'danger'}
                onClick={() => setRemoveModal(true)}
                className="btn-sm mr-2"
              />
            )}
            <CardButton
              title={CANCEL_LABEL}
              variant={'light'}
              onClick={() => history.goBack()}
              className="btn-sm mr-2"
            />
            <CardButton
              title={buttons.save.title}
              variant={buttons.save.variant}
              onClick={handleSubmit}
              isLoading={isLoading}
              className="btn-sm ms-2"
            />
          </div>
        </CardHeader>
        <CardForm wrapInContainer={false}>
          <CardSection endLine>
            <CardBlock>
              <Form.Group as={Row} className="my-2">
                <Col xs={2}>
                  <Form.Label>
                    <h5>{constants.name}</h5>
                  </Form.Label>
                </Col>
                <Col xs={10}>
                  <Form.Control
                    type="text"
                    name={`name`}
                    value={roleData.name}
                    onChange={onInputChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="my-2">
                <Col xs={2}>
                  <Form.Label>
                    <h5>{constants.description}</h5>
                  </Form.Label>
                </Col>
                <Col xs={10}>
                  <textarea
                    name={`description`}
                    placeholder="description"
                    rows={4}
                    className="form-control"
                    value={roleData.description}
                    onChange={onInputChange}
                  ></textarea>
                </Col>
              </Form.Group>
            </CardBlock>
          </CardSection>
          <CardSection>
            <CardContentCustom>
              <CardSubtitle endLine>{constants.users}</CardSubtitle>
              <CardSubContent>
                <AutoComplete
                  placeholder="Search for users"
                  data={usersData}
                  customKey="name"
                  id={'clone'}
                  onHandleSelect={handleSelected}
                  onChange={onInputSearch}
                  showIcon
                />
                <List>
                  {userSelection
                    .filter((value) => Object.keys(value).length !== 0)
                    .map((user, index) => (
                      <Item id={`user-${index}`} key={index}>
                        <ItemAvatar>
                          <Avatar user={user} classModifiers="mr-2" />
                        </ItemAvatar>

                        <ItemUser name={user.name} email={user.email} />
                        <ItemActions>
                          <a
                            onClick={(e) => {
                              deleteUserItem(index);
                            }}
                            className="cursor-pointer d-none"
                          >
                            <TooltipComponent title={'Remove'}>
                              <MaterialIcon
                                icon={'delete'}
                                clazz="text-danger"
                              />
                            </TooltipComponent>
                          </a>
                        </ItemActions>
                      </Item>
                    ))}
                  {userSelection.filter(
                    (value) => Object.keys(value).length !== 0
                  ).length < 1 && (
                    <p className="text-muted text-center">{`No users`}</p>
                  )}
                </List>
              </CardSubContent>
            </CardContentCustom>
            <CardSideCustom>
              <CardSubtitle endLine>{constants.adminPermissions}</CardSubtitle>
              {permissions.length > 0 &&
                permissions.map((permission, i) => (
                  <div className="mt-3" key={`permissions${i}`}>
                    <h5 className={`${i === 1 ? 'mt-4' : ''}`}>
                      {permission.name}
                    </h5>
                    {permission.inner_permissions.length > 0 &&
                      permission.inner_permissions.map(
                        (permissionsCategory) => {
                          if (
                            moduleNoteCheck(
                              tenant?.modules,
                              permissionsCategory.tenantModule
                            )
                          ) {
                            const isContactsAndCreateExist =
                              permissionSwitches?.some(
                                (item) =>
                                  item.collection === 'contacts' &&
                                  item.action === 'create'
                              );
                            return (
                              <>
                                <Row
                                  className="switch-setting-main align-items-center mx-0 pl-0"
                                  key={permissionsCategory.name}
                                >
                                  <Col md={5} className="pl-0">
                                    <h6>{permissionsCategory.label}</h6>
                                  </Col>

                                  <Col md={7}>
                                    <div
                                      className={
                                        ownerAccessSwitch ||
                                        (permissionsCategory.label ===
                                          'Insights' &&
                                          !ownerAccessSwitch)
                                          ? 'd-flex align-items-center pt-0 pb-2 pointer-event'
                                          : 'd-flex align-items-center pt-0 pb-2'
                                      }
                                    >
                                      <FormCheck
                                        id={permissionsCategory.label}
                                        type="switch"
                                        custom={true}
                                        className={`${
                                          ownerAccessSwitch ||
                                          (!tenantAccessSwitch &&
                                            !ownerAccessSwitch &&
                                            [
                                              'Branding',
                                              'Fields',
                                              'Pipeline & Stages',
                                              'Users & Controls',
                                              'Reports',
                                              'Checklists',
                                              'Bulk Import',
                                            ].includes(
                                              permissionsCategory.label
                                            )) ||
                                          (permissionSwitches?.some(
                                            (item) =>
                                              item.collection === 'deals'
                                          ) &&
                                            [
                                              'Contacts',
                                              'Products',
                                              'Notes',
                                              'Companies',
                                            ].includes(
                                              permissionsCategory.label
                                            )) ||
                                          (permissionSwitches?.some(
                                            (item) =>
                                              item.collection === 'activities'
                                          ) &&
                                            [
                                              'Deals',
                                              'Contacts',
                                              'Notes',
                                              'Companies',
                                            ].includes(
                                              permissionsCategory.label
                                            )) ||
                                          (permissionsCategory.label ===
                                            'Bulk Import' &&
                                            permissionLevel?.level ===
                                              'tenant' &&
                                            permissionSwitches?.some(
                                              (item) =>
                                                item.collection !== 'contacts'
                                            ))
                                            ? 'disabled'
                                            : ''
                                        }`}
                                        name={permissionsCategory.label}
                                        disabled={
                                          ownerAccessSwitch ||
                                          (!tenantAccessSwitch &&
                                            !ownerAccessSwitch &&
                                            [
                                              'Branding',
                                              'Fields',
                                              'Pipeline & Stages',
                                              'Users & Controls',
                                              'Reports',
                                              'Bulk Import',
                                              'Checklists',
                                            ].includes(
                                              permissionsCategory.label
                                            )) ||
                                          (permissionSwitches?.some(
                                            (item) =>
                                              item.collection === 'deals'
                                          ) &&
                                            [
                                              'Contacts',
                                              'Products',
                                              'Notes',
                                              'Companies',
                                            ].includes(
                                              permissionsCategory.label
                                            )) ||
                                          (permissionSwitches?.some(
                                            (item) =>
                                              item.collection === 'activities'
                                          ) &&
                                            [
                                              'Deals',
                                              'Contacts',
                                              'Notes',
                                              'Companies',
                                            ].includes(
                                              permissionsCategory.label
                                            )) ||
                                          (permissionsCategory.label ===
                                            'Bulk Import' &&
                                            permissionLevel?.level ===
                                              'tenant' &&
                                            permissionSwitches?.some(
                                              (item) =>
                                                item.collection !== 'contacts'
                                            ))
                                        }
                                        checked={
                                          (isContactsAndCreateExist &&
                                            permissionsCategory.label ===
                                              'Bulk Import' &&
                                            permissionLevel?.level ===
                                              'tenant') ||
                                          permissionSwitches?.find((item) => {
                                            return item.collection ===
                                              permissionsCategory.name
                                              ? (permissionsCategory.isChecked = true)
                                              : (permissionsCategory.isChecked = false);
                                          })
                                        }
                                        onChange={() =>
                                          handlePermissionCategoryChange(
                                            permission,
                                            permissionsCategory
                                          )
                                        }
                                      />
                                      <div>
                                        {permissionsCategory.group.length > 0 &&
                                          permissionsCategory.isChecked && (
                                            <div
                                              className="switch-setting-1"
                                              onClick={() =>
                                                handleDropdownPermission(
                                                  permissionsCategory.name
                                                )
                                              }
                                            >
                                              {permissionsCategory.isChecked &&
                                                permissionsCategory.group.map(
                                                  (permissionGroup, index) => {
                                                    permissionSwitches?.find(
                                                      (item) => {
                                                        return permissionsCategory.name ===
                                                          item.collection &&
                                                          item.action ===
                                                            permissionGroup.name
                                                          ? (permissionGroup.isChecked = true)
                                                          : (permissionGroup.isChecked = false);
                                                      }
                                                    );
                                                    return (
                                                      <>
                                                        {permissionGroup.isChecked && (
                                                          <div className="abc">
                                                            <span className="text-capitalize">
                                                              {
                                                                permissionGroup.label
                                                              }
                                                            </span>
                                                          </div>
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )}
                                            </div>
                                          )}
                                        {permissionsCategory.group.length > 0 &&
                                          permissionsCategory.name ===
                                            permissionsDropdown && (
                                            <div className="switch-setting shadow">
                                              {permissionsCategory.group.map(
                                                (permissionGroup) => {
                                                  const allowedPermissionGroups =
                                                    [
                                                      'Edit',
                                                      'Create',
                                                      'Delete',
                                                      'Manage',
                                                    ];
                                                  const allowedPermissionsCategories =
                                                    [
                                                      'Dashboards',
                                                      'Reports',
                                                      'Learn',
                                                      'Products',
                                                    ];

                                                  return (
                                                    <>
                                                      <label
                                                        className="d-block text-capitalize"
                                                        htmlFor={
                                                          permissionGroup.name
                                                        }
                                                      >
                                                        <input
                                                          id={
                                                            permissionGroup.name
                                                          }
                                                          type="checkbox"
                                                          disabled={
                                                            ((permissionLevel?.level ===
                                                              'user' ||
                                                              !ownerAccessSwitch) &&
                                                              (permissionGroup.name ===
                                                                'delete' ||
                                                                permissionGroup.name ===
                                                                  'manage')) ||
                                                            (permissionsCategory?.name ===
                                                              'prospects' &&
                                                              permissionGroup.name ===
                                                                'create') ||
                                                            (!tenantAccessSwitch &&
                                                              allowedPermissionGroups.includes(
                                                                permissionGroup.label
                                                              ) &&
                                                              allowedPermissionsCategories.includes(
                                                                permissionsCategory.label
                                                              )) ||
                                                            (permissionSwitches?.some(
                                                              (item) =>
                                                                item.collection ===
                                                                'deals'
                                                            ) &&
                                                              [
                                                                'Contacts',
                                                                'Products',
                                                                'Notes',
                                                                'Companies',
                                                              ].includes(
                                                                permissionsCategory.label
                                                              ) &&
                                                              permissionGroup.label ===
                                                                'View') ||
                                                            (permissionSwitches?.some(
                                                              (item) =>
                                                                item.collection ===
                                                                'activities'
                                                            ) &&
                                                              [
                                                                'Deals',
                                                                'Contacts',
                                                                'Notes',
                                                                'Companies',
                                                              ].includes(
                                                                permissionsCategory.label
                                                              ) &&
                                                              permissionGroup.label ===
                                                                'View')
                                                          }
                                                          checked={permissionSwitches?.find(
                                                            (item) => {
                                                              return permissionsCategory.name ===
                                                                item.collection &&
                                                                item.action ===
                                                                  permissionGroup.name
                                                                ? (permissionGroup.isChecked = true)
                                                                : (permissionGroup.isChecked = false);
                                                            }
                                                          )}
                                                          onChange={(e) =>
                                                            handleSwitchEvent(
                                                              e,
                                                              permissionGroup,
                                                              permissionsCategory
                                                            )
                                                          }
                                                        />
                                                        <span>
                                                          {
                                                            permissionGroup.label
                                                          }
                                                        </span>
                                                      </label>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            );
                          } else {
                            return '';
                          }
                        }
                      )}
                  </div>
                ))}
            </CardSideCustom>
          </CardSection>
        </CardForm>
      </Card>
    </>
  );
};

export default EditRoles;
