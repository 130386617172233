import { Label } from 'reactstrap';
import React, { useCallback, useMemo, useState } from 'react';

const RiskColorKeys = {
  Red: 'red',
  Yellow: 'yellow',
  Green: 'green',
};

const RiskColorKeysWithClasses = {
  Red: 'rpt-red-box',
  Yellow: 'rpt-yellow-box',
  Green: 'rpt-green-box',
};

const RiskIntensity = {
  [RiskColorKeysWithClasses.Red]:
    'high risk given your deposit volume and lack of fraud solutions.',
  [RiskColorKeysWithClasses.Yellow]:
    'medium risk given your deposit volume and active fraud solutions.',
  [RiskColorKeysWithClasses.Green]:
    'low risk given your deposit volume and active fraud solutions.',
};

const DefaultRiskColor = {
  id: 1,
  key: RiskColorKeys.Red,
  isSelected: true,
  widgetColor: RiskColorKeysWithClasses.Red,
};

const Colors = [
  { ...DefaultRiskColor },
  {
    id: 2,
    key: RiskColorKeys.Yellow,
    widgetColor: RiskColorKeysWithClasses.Yellow,
  },
  {
    id: 3,
    key: RiskColorKeys.Green,
    widgetColor: RiskColorKeysWithClasses.Green,
  },
];

const RiskColorsBlock = ({ riskColors, onChangeRiskColor }) => {
  return (
    <div className="d-flex align-items-center">
      <Label className="mb-0">Colors:</Label>
      <div className="d-flex ml-2 align-items-center gap-1">
        {riskColors.map((clr) => (
          <div
            key={clr.id}
            onClick={() => onChangeRiskColor(clr)}
            className={clr.isSelected ? 'risk-selected rounded-circle' : ''}
          >
            <p
              style={{ height: 15, width: 15 }}
              className={`mb-0 risk-colors rounded-circle risk-${clr.key}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const useRiskColors = (onChangeRiskColor) => {
  const [riskColors, setRiskColors] = useState(Colors);
  const GenerateRiskColorsBlockCallback = useCallback(() => {
    return (
      <RiskColorsBlock
        riskColors={riskColors}
        onChangeRiskColor={onChangeRiskColor}
      />
    );
  }, [riskColors, setRiskColors, onChangeRiskColor]);

  return useMemo(
    () => ({
      riskColors,
      setRiskColors,
      RiskColorsBlock: GenerateRiskColorsBlockCallback,
    }),
    [riskColors, setRiskColors, GenerateRiskColorsBlockCallback]
  );
};

export {
  useRiskColors,
  RiskColorKeys,
  RiskIntensity,
  DefaultRiskColor,
  RiskColorKeysWithClasses,
};
