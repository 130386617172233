import { CardFooter, Row, Col } from 'reactstrap';
import Pagination from './Pagination';
import { NO_SEARCH_RESULTS } from '../utils/constants';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import NoDataFound from './commons/NoDataFound';
import ButtonIcon from './commons/ButtonIcon';
import { setDateFormat } from '../utils/Utils';
import MaterialIcon from './commons/MaterialIcon';
import useOutsideClickDropDown from '../hooks/useOutsideClickDropDown';
import { Dropdown } from 'react-bootstrap';
import TableSkeleton from './commons/TableSkeleton';
import TableFooterStats from './TableFooterStats';
import {
  AllCommunityModule,
  ModuleRegistry,
  themeQuartz,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useProfileContext } from '../contexts/profileContext';

ModuleRegistry.registerModules([AllCommunityModule]);

const IconDesc = ({ active }) => {
  return (
    <span
      data-ref="eSortDesc"
      className="ag-header-icon action-items ag-header-label-icon ag-sort-descending-icon"
      aria-hidden="true"
    >
      <span
        className={`${
          active ? 'ag-icon-active action-items' : ''
        } ag-icon ag-icon-desc`}
        style={{ userSelect: 'none' }}
        role="presentation"
      ></span>
    </span>
  );
};
const IconAsc = ({ active }) => {
  return (
    <span
      data-ref="eSortAsc"
      className="ag-header-icon action-items ag-header-label-icon ag-sort-ascending-icon"
      aria-hidden="true"
    >
      <span
        className={`${
          active ? 'ag-icon-active action-items' : ''
        } ag-icon ag-icon-asc`}
        style={{ userSelect: 'none' }}
        role="presentation"
      ></span>
    </span>
  );
};

const TableSorter = ({
  props,
  data,
  hovered,
  menuOpen,
  sortingOrder,
  sortingTable,
}) => {
  const [show, setShow] = useState(false);
  const columnInfo = props.column.userProvidedColDef;
  const orderBy = columnInfo?.orderBy;
  const isSortApplied = sortingOrder?.length && sortingOrder[0] === orderBy;

  const menuRef = useRef(null);
  const toggleRef = useRef(null);
  useOutsideClickDropDown(menuRef, show, setShow);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const rect = toggleRef.current.getBoundingClientRect();
        menuRef.current.style.position = 'fixed';
        menuRef.current.style.zIndex = 1000;
        menuRef.current.style.transform = `translate3d(${rect.left + 10}px, ${
          rect.top + 20
        }px, 0px)`;
      }, 1);
    }
  }, [show]);

  const handleSort = (ascDesc) => {
    if (!data?.length) return;
    sortingTable({
      name: {
        key: columnInfo?.field,
        orderBy: columnInfo.orderBy,
        clicked: ascDesc,
      },
    });
  };

  return (
    <Dropdown show={show} onToggle={setShow}>
      <Dropdown.Toggle
        as="a"
        ref={toggleRef}
        style={{ height: 20 }}
        className="cursor-pointer no-caret text-center d-inline-flex text-gray-700 justify-content-center align-items-center"
      >
        <span data-ref="eText" className="ag-header-cell-text text-black">
          {props.displayName}
        </span>
        {(hovered || menuOpen || show || isSortApplied) && orderBy ? (
          <>
            {isSortApplied ? (
              <>
                {sortingOrder[1] === 'DESC' && <IconDesc active />}
                {sortingOrder[1] === 'ASC' && <IconAsc active />}
              </>
            ) : (
              <IconDesc />
            )}
          </>
        ) : null}
      </Dropdown.Toggle>
      <Dropdown.Menu ref={menuRef} className="p-1 z-index-250">
        <div
          onClick={() => handleSort('ASC')}
          className={`${
            isSortApplied && sortingOrder[1] === 'ASC'
              ? 'text-primary bg-primary-soft'
              : 'text-black'
          } p-2 d-flex bg-hover-gray rounded no-underline btn-link hover-link hoverLink cursor-pointer align-items-center gap-1`}
        >
          <MaterialIcon
            icon="keyboard_arrow_up"
            clazz="font-weight-normal"
            size="fs-4"
          />
          <span className="fs-7 font-weight-medium">ASC</span>
        </div>
        <div
          onClick={() => handleSort('DESC')}
          className={`${
            isSortApplied && sortingOrder[1] === 'DESC'
              ? 'text-primary bg-primary-soft'
              : 'text-black'
          } p-2 d-flex bg-hover-gray btn-link no-underline hover-link rounded hoverLink cursor-pointer align-items-center gap-1`}
        >
          <MaterialIcon
            icon="keyboard_arrow_down"
            clazz="font-weight-normal"
            size="fs-4"
          />
          <span className="fs-7 font-weight-medium">DESC</span>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const DropdownItem = ({
  eventKey,
  icon,
  sortIcon,
  label,
  onClick = () => {},
  iconSize = 'fs-18',
  iconClass = '',
}) => {
  return (
    <Dropdown.Item
      as="a"
      eventKey={eventKey}
      onClick={onClick}
      className="text-black fs-7 font-weight-medium cursor-pointer bg-hover-gray rounded no-underline btn-link hover-link hoverLink"
    >
      <div className="d-flex gap-2 align-items-center">
        {sortIcon ? (
          <span className="ag-menu-sort-icon">
            <MaterialIcon icon="arrow_left_alt" clazz="rotate-90" />
            <MaterialIcon icon="arrow_right_alt" clazz="rotate-90" />
          </span>
        ) : (
          <MaterialIcon icon={icon} size={iconSize} clazz={iconClass} />
        )}
        {label}
      </div>
    </Dropdown.Item>
  );
};

export default function Table({
  className = '',
  actionPadding,
  checkbox = false,
  tableId,
  checkboxType = 'multiRow',
  columns = [],
  columnsToExport = [],
  exportToCSV,
  data,
  setSelectedData,
  usePagination = true,
  paginationInfo,
  showPerPage,
  onLimitChange,
  onPageChange,
  handleRowClick,
  showLoading,
  emptyDataText,
  emptyDataIcon = 'manage_search',
  tableData = [],
  fileName = 'report',
  dataInDB,
  noDataInDbValidation,
  sortingTable,
  sortingOrder = ['name', 'ASC'],
  externalValues = [],
  stats = false,
  showTotalStats = false,
  customStyle = {
    position: 'absolute',
    top: '0px',
    right: '0px',
    height: '41px',
  },
  stickyColumn,
  stickyFooter = false,
  stickyClass,
  tableLoading,
  paginationLoading = false,
}) {
  const { profileInfo } = useProfileContext();
  const userId = profileInfo?.id;

  const onHandleSelect = (params) => {
    const selectedRows = params.api.getSelectedRows();
    const selectedRowIds = selectedRows?.map((row) => row.id);
    setSelectedData(selectedRowIds);
  };

  const buildCSVContent = (columns, exportData, externalValues = []) => {
    let csvContent = '';

    // Function to escape special characters for CSV
    const escapeForCSV = (value) => {
      if (value == null) return ''; // Handle null or undefined values
      value = value.toString().replace(/"/g, '""'); // Escape double quotes
      if (/[",\n]/.test(value)) {
        // Enclose in double quotes if needed
        value = `"${value}"`;
      }
      return value;
    };

    // Add external values if any
    if (externalValues?.length > 0) {
      externalValues.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          const extraRowData = [escapeForCSV(key), escapeForCSV(obj[key])];
          csvContent += extraRowData.join(',') + '\n';
        });
      });
    }

    // Add column headers
    const headers = columns?.map((column) => escapeForCSV(column?.component));
    csvContent += headers?.join(',') + '\n';

    // Add rows
    exportData?.forEach((result, index) => {
      // Define the function to format row data
      const formatRowData = (rowResult) => {
        return columns?.map((column) => {
          const key = column?.key;
          let value;
          if (key === 'LessonProgress.countOfCompleted') {
            value =
              rowResult['LessonProgress.countOfCompleted'] > 0 ? 'Yes' : 'No';
          } else if (key === 'rank') {
            value = index + 1;
          } else if (key === 'ChecklistProgress.checklistCompletedDate') {
            value =
              rowResult['ChecklistProgress.progress'] === 'Completed'
                ? setDateFormat(
                    rowResult['ChecklistProgress.checklistCompletedDate'],
                    'MM/DD/YYYY'
                  )
                : '';
          } else if (key === 'ChecklistProgress.initialDueDate') {
            value = setDateFormat(
              rowResult['ChecklistProgress.initialDueDate'],
              'MM/DD/YYYY'
            );
          } else if (key === 'Assignment.dueAt') {
            value = rowResult['Assignment.dueAt']
              ? setDateFormat(rowResult['Assignment.dueAt'], 'MM/DD/YYYY')
              : '--';
          } else if (key === 'LessonProgress.maxOfLastAttempt') {
            value = setDateFormat(
              rowResult['LessonProgress.maxOfLastAttempt'],
              'MM/DD/YYYY'
            );
          } else if (key === 'lastAttempted') {
            value = setDateFormat(rowResult.lastAttempted, 'MM/DD/YYYY');
          } else if (key === 'CourseProgress.maxOfLastAttempt') {
            value = setDateFormat(
              rowResult['CourseProgress.maxOfLastAttempt'],
              'MM/DD/YYYY'
            );
          } else if (
            key === 'AssignmentUser.assignmentId' ||
            key === 'AssignmentTeam.assignmentId'
          ) {
            value = rowResult['AssignmentUser.assignmentId']
              ? 'Yes'
              : rowResult['AssignmentTeam.assignmentId']
              ? 'Yes'
              : 'No';
          } else if (key === 'sumOfDurationCourse') {
            value = rowResult.sumOfDurationCourse / rowResult.count;
          } else if (column?.render) {
            value = column?.render;
          } else {
            value = rowResult[key];
          }
          return escapeForCSV(value);
        });
      };

      // Add the primary row
      const rowData = formatRowData(result);
      csvContent += rowData?.join(',') + '\n';

      // Add a second row if a specific condition is met
    });

    return encodeURI('data:text/csv;charset=utf-8,' + csvContent);
  };

  const exportCSV = () => {
    const encodedUri = buildCSVContent(
      columnsToExport.length > 0 ? columnsToExport : columns,
      tableData,
      externalValues
    );
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const Title = () => {
    return (
      <div className="font-normal font-size-sm2 text-gray-search">
        {emptyDataText || NO_SEARCH_RESULTS}
      </div>
    );
  };

  const noData = () => {
    if (!dataInDB && !noDataInDbValidation) {
      return (
        <>
          <NoDataFound
            title={<Title />}
            icon={emptyDataIcon}
            iconStyle="text-gray-search font-size-4em"
            description=""
            containerStyle="text-gray-900 my-6 py-6"
          />
        </>
      );
    } else if (!data?.length && !showLoading) {
      return (
        <NoDataFound
          icon="manage_search"
          containerStyle="text-gray-search my-6 py-6"
          title={<Title />}
        />
      );
    }
  };

  const tableStats = () => {
    return (
      <TableFooterStats
        stats={[{ id: '1', label: 'Total', count: paginationInfo?.count || 0 }]}
        loading={tableLoading}
      />
    );
  };

  const myTheme = themeQuartz.withParams({
    fontFamily: 'Inter, sans-serif',
    wrapperBorder: false,
    wrapperBorderRadius: 0,
    headerHeight: '33px',
    headerFontSize: 11.2,
    headerFontWeight: 600,
    headerTextColor: '#000',
    headerBackgroundColor: '#FCFDFD',
    headerRowBorder: { color: '#f6f7fb' },
    headerColumnResizeHandleHeight: '50%',
    rowHeight: '50px',
    cellTextColor: '#000',
    cellHorizontalPadding: '12px',
    rowBorder: { color: '#f6f7fb' },
    rowHoverColor: 'rgba(140, 152, 164, 0.05)',
    selectedRowBackgroundColor: 'rgba(140, 152, 164, 0.05)',
  });
  const theme = useMemo(() => {
    return myTheme;
  }, []);

  const rowStyle = { cursor: 'pointer' };

  // Define column Def
  const defaultColDef = {
    filter: false, // Disable filtering on all columns
    editable: false, // Disable editing on all cells
  };

  const selectionColumnDef = {
    pinned: 'left', // always pin selection column
  };

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState(columns);

  useEffect(() => {
    setRowData(data);
    refreshHeader(); // this is required for update custom header sortingOrder state
  }, [data]);

  const refreshHeader = () => {
    setColDefs((prevColDefs) =>
      prevColDefs.map((item) => ({
        ...item,
        headerComponent: CustomHeader, // Add the custom header
      }))
    );
  };

  const autoSizeAll = useCallback(
    (skipHeader = false) => {
      const allColumnIds = [];
      gridRef.current.api.getColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      gridRef.current.api.autoSizeColumns(allColumnIds, skipHeader);
    },
    [data]
  );

  const autoSizeColumn = useCallback((colId, skipHeader = false) => {
    gridRef.current.api.autoSizeColumns([colId], skipHeader);
  }, []);

  const moveColumn = useCallback((colId, direction) => {
    const columnState = gridRef.current.api.getColumnState();
    const columnCount = gridRef.current.api.getColumns().length;

    // Find the current index of the column
    const currentIndex = columnState.findIndex(
      (column) => column.colId === colId
    );

    if (currentIndex === -1) {
      console.warn(`Column with colId ${colId} not found.`);
      return;
    }

    // Ensure `ag-Grid-SelectionColumn` always stays on the left
    const leftMostIndex =
      columnState.findIndex(
        (column) => column.colId === 'ag-Grid-SelectionColumn'
      ) + 1;

    let newIndex;
    if (direction === 'left') {
      // Move left but don't surpass the `ag-Grid-SelectionColumn`
      newIndex = Math.max(currentIndex - 1, leftMostIndex);
    } else if (direction === 'right') {
      // Move right but stay within bounds
      newIndex = Math.min(currentIndex + 1, columnCount);
    }

    // Only move if there's a change in position
    if (newIndex !== currentIndex) {
      gridRef.current.api.moveColumnByIndex(currentIndex, newIndex);
    }
  }, []);

  const rowSelection = useMemo(() => {
    return {
      mode: checkboxType, // "singleRow" or "multiRow"
      checkboxes: checkbox,
      headerCheckbox: checkbox,
      enableClickSelection: false,
    };
  }, []);

  const SortMenuItem = ({ props }) => {
    const columnInfo = props?.column?.userProvidedColDef;
    const orderBy = columnInfo?.orderBy;
    const isSortApplied = sortingOrder?.length && sortingOrder[0] === orderBy;

    const handleSort = (ascDesc) => {
      if (!data?.length) return;
      sortingTable({
        name: {
          key: columnInfo?.field,
          orderBy: columnInfo.orderBy,
          clicked: ascDesc,
        },
      });
    };

    const menuRef = useRef(null);
    const toggleRef = useRef(null);
    const [show, setShow] = useState(false);
    const [dropdownPosition, setDropdownPosition] =
      useState('ag-menu-sort-right');

    const handleMouseEnter = () => {
      setShow(true);
    };

    const handleMouseLeave = () => {
      setShow(false);
    };

    useEffect(() => {
      if (toggleRef.current && menuRef.current && show) {
        const menuRect = menuRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        if (menuRect.x + 50 > viewportWidth) {
          setDropdownPosition('ag-menu-sort-left');
        } else {
          setDropdownPosition('ag-menu-sort-right');
        }
      } else {
        setDropdownPosition('ag-menu-sort-right');
      }
    }, [show]);

    if (!orderBy) return null; // dont't show menu item when no orderBy present in column

    return (
      <>
        {orderBy ? (
          <>
            <Dropdown.Item
              as="a"
              eventKey="001"
              className="cursor-pointer position-relative bg-hover-gray rounded no-underline"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Dropdown
                show={show}
                onToggle={setShow}
                className={dropdownPosition}
              >
                <Dropdown.Toggle
                  as="a"
                  ref={toggleRef}
                  style={{ height: 20 }}
                  className="d-flex gap-2 align-items-center text-black fs-7 font-weight-medium btn-link hover-link hoverLink"
                >
                  <span className="ag-menu-sort-icon">
                    <MaterialIcon icon="arrow_left_alt" clazz="rotate-90" />
                    <MaterialIcon icon="arrow_right_alt" clazz="rotate-90" />
                  </span>
                  <span className="flex-grow-1">Sort</span>
                  <MaterialIcon icon="chevron_right" size="fs-18" />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  ref={menuRef}
                  className="p-1 position-absolute top-0 bg-white"
                >
                  <div
                    onClick={() => handleSort('ASC')}
                    className={`${
                      isSortApplied && sortingOrder[1] === 'ASC'
                        ? 'text-primary bg-primary-soft'
                        : 'text-black'
                    } p-2 d-flex bg-hover-gray rounded no-underline btn-link hover-link hoverLink cursor-pointer align-items-center gap-1`}
                  >
                    <MaterialIcon
                      icon="keyboard_arrow_up"
                      clazz="font-weight-normal"
                      size="fs-4"
                    />
                    <span className="fs-7 font-weight-medium">ASC</span>
                  </div>
                  <div
                    onClick={() => handleSort('DESC')}
                    className={`${
                      isSortApplied && sortingOrder[1] === 'DESC'
                        ? 'text-primary bg-primary-soft'
                        : 'text-black'
                    } p-2 d-flex bg-hover-gray btn-link no-underline hover-link rounded hoverLink cursor-pointer align-items-center gap-1`}
                  >
                    <MaterialIcon
                      icon="keyboard_arrow_down"
                      clazz="font-weight-normal"
                      size="fs-4"
                    />
                    <span className="fs-7 font-weight-medium">DESC</span>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Item>
            <Dropdown.Divider />
          </>
        ) : null}
      </>
    );
  };

  const CustomHeader = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [hovered] = useState(true);

    const handleMenuAction = (action) => {
      if (!data?.length) return;
      setMenuOpen(false);
      if (action === 'autoSizeColumn') {
        autoSizeColumn(props.column.colId);
      } else if (action === 'autoSizeAllColumns') {
        autoSizeAll();
      }
    };

    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };

    return (
      <Fragment>
        <div
          className="ag-cell-label-container hover-actions"
          role="presentation"
        >
          <Fragment>
            <Dropdown show={menuOpen} onToggle={toggleMenu}>
              <Dropdown.Toggle
                variant="outline-link"
                id="dropdown"
                className="p-0"
              >
                <span
                  data-ref="eMenu"
                  className="ag-grid-header-toggle action-items ag-header-icon ag-header-cell-menu-button ag-header-menu-icon ag-header-menu-always-show"
                  aria-hidden="true"
                >
                  <MaterialIcon
                    icon="arrow_drop_down"
                    clazz="rounded-circle bg-gray-5-"
                  />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-1 ag-grid-header-menu">
                <SortMenuItem props={props} />
                <DropdownItem
                  eventKey="4"
                  icon="keyboard_tab"
                  label="Move Right"
                  onClick={() => moveColumn(props.column?.colId, 'right')}
                />
                <DropdownItem
                  eventKey="3"
                  icon="keyboard_tab_rtl"
                  label="Move Left"
                  onClick={() => moveColumn(props.column?.colId, 'left')}
                />
                <DropdownItem
                  eventKey="1"
                  icon="expand"
                  iconClass="rotate-90"
                  label="AutoFit Column"
                  onClick={() => handleMenuAction('autoSizeColumn')}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Fragment>
          <div
            data-ref="eLabel"
            className="ag-header-cell-label"
            role="presentation"
          >
            {props.column.userProvidedColDef?.orderBy ? (
              <TableSorter
                props={props}
                data={data}
                hovered={hovered}
                menuOpen={menuOpen}
                sortingOrder={sortingOrder}
                sortingTable={sortingTable}
              />
            ) : (
              <span data-ref="eText" className="ag-header-cell-text text-black">
                {props.displayName}
              </span>
            )}
          </div>
        </div>
      </Fragment>
    );
  };

  const onGridReady = (params) => {
    const savedState = localStorage.getItem(`gridState_${tableId}_${userId}`);

    if (savedState) {
      const { state, order } = JSON.parse(savedState);

      if (state) {
        params.api.applyColumnState({ state, applyOrder: true });
      }

      if (order && order.length > 0) {
        const allColumns = params.api.getColumns();
        const columnsToMove = order.filter((id) =>
          allColumns.some((col) => col.getColId() === id)
        );
        params.api.moveColumns(columnsToMove, 0);

        // update colDef state for order to persist
        setColDefs((prevColDefs) => {
          const colDefsMap = new Map(
            prevColDefs.map((colDef) => [colDef.field || colDef.colId, colDef])
          );

          const reorderedColDefs = order
            .map((colId) => colDefsMap.get(colId))
            .filter(Boolean); // Remove undefined columns if any colId doesn't exist in colDefsMap

          const enhancedreorderedColDefs = reorderedColDefs.map((col) => {
            // Find the matching column state for this colId
            const savedState = state.find(
              (savedCol) => savedCol.colId === col.field
            );
            return {
              ...col,
              pinned: savedState?.pinned || undefined, // Include pinned state in saved data
            };
          });

          // Preserve other columns not in the order
          const remainingColDefs = prevColDefs.filter(
            (colDef) => !order.includes(colDef.field || colDef.colId)
          );

          // Combine reordered and remaining columns
          return [...enhancedreorderedColDefs, ...remainingColDefs];
        });
      }
    } else {
      onColumnUpdate();
    }
  };

  const onColumnUpdate = () => {
    const columnApi = gridRef.current.api;

    const columnState = columnApi.getColumnState();
    const displayedColumns = columnApi.getAllDisplayedColumns();
    const columnOrder = displayedColumns.map((col) => col.getColId());

    localStorage.setItem(
      `gridState_${tableId}_${userId}`,
      JSON.stringify({ state: columnState, order: columnOrder })
    );
  };

  const onColumnMoved = (event) => {
    onColumnUpdate(); // for persist state

    const movedColumn = event.column;
    const headerElement = document.querySelector(
      `[col-id="${movedColumn?.colId}"]`
    );

    if (headerElement && event.finished && event.source === 'uiColumnMoved') {
      headerElement.classList.add('ag-moved-column');

      setTimeout(() => {
        headerElement.classList.remove('ag-moved-column');
      }, 2000);
    }
  };

  const showHideHeaderControls = (params, fn) => {
    const colId = params.column.getColId();
    const colIndex = columns.findIndex((f) => f.field === colId);
    const cellId = checkbox ? colIndex + 1 : colIndex;
    const headerCell = document.querySelectorAll('.ag-header-cell')[cellId];
    if (headerCell) {
      const labelContainer = headerCell.querySelector(
        '.ag-cell-label-container'
      );
      if (labelContainer) {
        labelContainer.classList[fn]('hover-actions');
      }
    }
  };

  return (
    <>
      <div
        className={`table-container ${className} ${actionPadding} ${
          stickyColumn ? 'ag-sticky-column' : ''
        } ${
          stickyFooter &&
          (stats ||
            paginationInfo?.totalPages > 1 ||
            showPerPage ||
            showTotalStats)
            ? 'sticky-table-footer'
            : ''
        } ${stickyFooter && stickyClass}`}
      >
        {exportToCSV && tableData?.length > 0 && (
          <div className="text-right">
            <ButtonIcon
              icon="ios_share"
              label="Export"
              onclick={exportCSV}
              classnames="m-3"
              style={customStyle}
              color="outline-primary"
            />
          </div>
        )}

        {tableLoading ? (
          <TableSkeleton cols={6} rows={12} />
        ) : (
          <AgGridReact
            ref={gridRef}
            theme={theme}
            rowStyle={rowStyle}
            rowData={rowData}
            columnDefs={colDefs}
            onCellMouseOut={(params) => {
              showHideHeaderControls(params, 'add');
            }}
            onCellMouseOver={(params) => {
              showHideHeaderControls(params, 'remove');
            }}
            defaultColDef={defaultColDef}
            selectionColumnDef={selectionColumnDef}
            tooltipShowDelay={500}
            colResizeDefault={undefined}
            pagination={false}
            suppressDragLeaveHidesColumns={true}
            suppressMoveWhenColumnDragging={true}
            rowSelection={rowSelection}
            onSelectionChanged={onHandleSelect}
            onRowClicked={handleRowClick}
            noRowsOverlayComponent={noData}
            onGridReady={onGridReady}
            onColumnMoved={onColumnMoved}
            onColumnResized={onColumnUpdate}
            onColumnPinned={onColumnUpdate}
            onColumnVisible={onColumnUpdate}
            onFirstDataRendered={onColumnUpdate}
          />
        )}
      </div>

      {usePagination &&
        dataInDB &&
        (stats ||
          paginationInfo?.totalPages > 1 ||
          showPerPage ||
          showTotalStats) && (
          <CardFooter>
            <Row className="align-items-center">
              <Col className="mb-2 mb-sm-0">
                {showTotalStats ? tableStats() : stats ? stats() : ''}
              </Col>
              {(paginationInfo?.totalPages > 1 || showPerPage) && (
                <Col className="sticky-table-pagination">
                  <Pagination
                    showPerPage={showPerPage}
                    paginationInfo={paginationInfo}
                    onPageChange={onPageChange}
                    onLimitChange={onLimitChange}
                    loading={paginationLoading}
                  />
                </Col>
              )}
            </Row>
          </CardFooter>
        )}

      {usePagination && !dataInDB && (
        <div className="bg-white" style={{ height: '56px' }} />
      )}
    </>
  );
}
