import { Route } from 'react-router-dom';
import useCheckTokenValidity from '../hooks/useCheckTokenValidity';
import useSessionExpiryModal from '../components/modal/SessionExpiryModal';
import { ClientPortalProvider } from '../contexts/clientPortalContext';
import ClientDashboardLayout from '../layouts/ClientDashboardLayout';

const ClientRoute = ({
  requireAdminAccess,
  isSplitView,
  component: Component,
  ...rest
}) => {
  const { SessionExpiryModal, setShowModal } = useSessionExpiryModal(true);

  useCheckTokenValidity(setShowModal);

  const clientRenderer = (props) => {
    return (
      <ClientPortalProvider>
        <ClientDashboardLayout>
          <SessionExpiryModal />
          <Component {...props} {...rest} />
        </ClientDashboardLayout>
      </ClientPortalProvider>
    );
  };

  return <Route {...rest} render={clientRenderer} />;
};

export default ClientRoute;
