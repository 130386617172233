import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import groupService from '../../services/groups.service';
import userService from '../../services/user.service';
import {
  paginationDefault,
  STATUS_ACTIVE,
  STATUS_INVITED,
  STATUS_SUSPENDED,
} from '../../utils/constants';
import { setDateFormat } from '../../utils/Utils';
import Avatar from '../Avatar';
import Table from '../GenericTable';
import { DataFilters } from '../DataFilters';
import { changePaginationPage } from '../../views/Deals/contacts/utils';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import InvitationModal from '../modal/InvitationModal.component';
import { usersColumns } from './ManageUsers.constants';
import LayoutHead from '../commons/LayoutHead';
import { sortingTable } from '../../utils/sortingTable';
import authService from '../../services/auth.service';
import MoreActions from '../MoreActions';
import TableSkeleton from '../commons/TableSkeleton';
import useUserProfileModal from './modals/useUserProfileModal';
import PillFilters from '../commons/PillFilters';
import stringConstants from '../../utils/stringConstants.json';
import DeleteConfirmationModal from '../../components/modal/DeleteConfirmationModal';

const FILTER_STATUSES = {
  active: 'active',
  inactive: 'deactivated',
  invited: 'invited',
  deleted: 'deleted',
};
const UsersTable = ({ activeTab, paginationPage, setPaginationPage }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [filterStatuses, setFilterStatuses] = useState([
    {
      id: FILTER_STATUSES.active,
      label: 'Active',
      status: FILTER_STATUSES.active,
      index: 0,
    },
    {
      id: FILTER_STATUSES.inactive,
      label: 'Inactive',
      status: FILTER_STATUSES.inactive,
      index: 1,
    },
    {
      id: FILTER_STATUSES.invited,
      label: 'Invited',
      status: FILTER_STATUSES.invited,
      index: 2,
    },
    {
      id: FILTER_STATUSES.deleted,
      label: 'Deleted',
      status: FILTER_STATUSES.deleted,
      index: 3,
    },
  ]);

  const loadStatusesCounts = async () => {
    const params = {
      page: 1,
      limit: 1,
    };
    const fetchUsersByStatus = (status) =>
      userService.getUsers({ status, order }, params);

    const responses = await Promise.all([
      fetchUsersByStatus(FILTER_STATUSES.active),
      fetchUsersByStatus(FILTER_STATUSES.inactive),
      fetchUsersByStatus(FILTER_STATUSES.invited),
      fetchUsersByStatus(FILTER_STATUSES.deleted),
    ]);

    setFilterStatuses((prevStatuses) =>
      prevStatuses.map((status, index) => ({
        ...status,
        count: responses[index]?.data?.pagination?.count || 0,
      }))
    );
  };

  const [filter, setFilter] = useState({ status: FILTER_STATUSES.active });
  const [pagination, setPagination] = useState(paginationDefault);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [dataInDB, setDataInDB] = useState(false);
  const [order, setOrder] = useState([]);
  const [, setShowModal] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [isShowTreeView, setIsShowTreeView] = useState('');
  const history = useHistory();
  const [, setOpenFilter] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [userFilter, setUserFilter] = useState(filterStatuses[0]);
  const onImpersonateClick = async (user) => {
    try {
      await authService.impersonate(user?.id, true);
      history.push('/');
      window.location.reload(false);
    } catch (err) {
      setErrorMessage('Server error!! try again or contact to support');
    }
  };

  const onHandleDelete = async () => {
    try {
      await Promise.all(
        selectedData?.map(async (item) => {
          await userService.deleteById(item?.id);
        })
      );

      setSuccessMessage('User Deleted Successfully.');
      getUsers();
      setShowDeleteUserModal(false);
    } catch (err) {
      setErrorMessage('Server error!! try again or contact to support');
    } finally {
      setSelectedData([]);
    }
  };

  const onHandleChangeStatus = async (user) => {
    const userStatus = user.status;
    const status =
      userStatus === 'active'
        ? 'deactivated'
        : userStatus === 'deactivated'
        ? 'active'
        : userStatus === 'invited'
        ? 'invite_cancelled'
        : 'invited';
    const MESSAGE_ALERT =
      userStatus === 'active'
        ? 'User Deactivated'
        : userStatus === 'deactivated'
        ? 'User Active'
        : userStatus === 'invited'
        ? 'Cancelled Invite'
        : 'Invite Sent';
    try {
      await userService.updateUserInfoById(user?.id, { status });
      if (user?.status === 'deleted') {
        await userService.resendInvite(user?.id);
      }
      setSuccessMessage(MESSAGE_ALERT);
      getUsers();
    } catch (err) {
      setErrorMessage('Server error!! try again or contact to support');
    }

    if (userStatus === 'invite_cancelled') {
      try {
        await userService.resendInvite(user?.id);
        setSuccessMessage('Email was sent successfully');
      } catch (err) {
        setErrorMessage('Server error!! try again or contact to support');
      }
    }
  };
  const onHandleReInvite = async (user) => {
    const userStatus = user.status;
    // const status = 'invited';
    const MESSAGE_ALERT = 'Invite Sent';
    try {
      await userService.resendInvite(user?.id);
      setSuccessMessage(MESSAGE_ALERT);
      getUsers();
    } catch (err) {
      setErrorMessage('Server error!! try again or contact to support');
    }

    if (userStatus === 'invite_cancelled') {
      try {
        await userService.resendInvite(user?.id);
        setSuccessMessage('Email was sent successfully');
      } catch (err) {
        setErrorMessage('Server error!! try again or contact to support');
      }
    }
  };

  const getListGroups = async () => {
    try {
      const result = await groupService.getRoles();
      setAllGroups(result);
    } catch (error) {
      setErrorMessage();
    }
  };

  const getUsers = async () => {
    setShowLoading(true);

    if (filter.status === 'all') {
      delete filter.status;
    }

    if (!filter.role) {
      delete filter.role;
    }
    if (!filter.roleId) {
      delete filter.roleId;
    }

    const response = await userService
      .getUsers(
        { ...filter, order },
        {
          page: paginationPage.page,
          limit: 10,
        }
      )
      .catch((err) => console.log(err));

    const { data } = response || {};

    setAllUsers(data?.users);
    setPagination(data?.pagination);
    setDataInDB(Boolean(data?.pagination?.totalPages));

    setShowLoading(false);
  };

  const loader = () => {
    if (showLoading) return <TableSkeleton cols={5} rows={10} />;
  };

  const getActionItemsByUserStatus = (user) => {
    const actions = [];
    if (user?.status === STATUS_INVITED) {
      actions.push({
        id: 'reInvite',
        name: 'Resend Invite',
        icon: 'refresh',
      });
      actions.push({
        type: 'divider',
      });
      actions.push({
        id: 'remove',
        name: 'Delete',
        icon: 'delete',
      });
    } else {
      actions.push({
        id: 'edit',
        name:
          user?.status === STATUS_SUSPENDED
            ? 'Activate'
            : user?.status === STATUS_ACTIVE
            ? 'Deactivate'
            : 'Resend Invite',
        icon: 'block',
      });

      if (user?.status === STATUS_ACTIVE) {
        actions.push({
          id: 'add',
          name: 'Ghost Login',
          icon: 'tv_signin',
        });
      }
      actions.push({
        type: 'divider',
      });

      actions.push({
        id: 'remove',
        name: 'Delete',
        icon: 'delete',
      });
    }

    return actions;
  };
  const handleShowDeleteModal = (user) => {
    setShowDeleteUserModal(true);
    const arr = [];
    arr.push(user);
    setSelectedData(arr);
  };
  const [refresh, setRefresh] = useState(0);
  const {
    UserProfileModal,
    setShowModal: setShowUserProfileModal,
    setData,
  } = useUserProfileModal({
    setSuccessMessage,
    setErrorMessage,
    refreshUsers: () => {
      setRefresh((prevState) => prevState + 1);
    },
  });

  const data = allUsers?.map((user) => ({
    ...user,
    dataRow: [
      {
        key: 'user',
        component: (
          <Link
            className="text-black fw-bold"
            onClick={(e) => {
              e.preventDefault();
              setData(user);
              setShowUserProfileModal(true);
            }}
            to={`/settings/users/${user.id}`}
          >
            <div className="media">
              <div className="avatar avatar-sm avatar-circle mr-3">
                <Avatar user={user} />
              </div>
              <div className="media-body">
                <div>
                  <h5 className="mb-0">
                    {!user.first_name && !user.last_name
                      ? 'Invited'
                      : `${user.first_name || ''} ${user.last_name || ''}`}
                  </h5>
                  <span className="d-block text-muted font-weight-medium font-size-sm">
                    {user.email}
                  </span>
                </div>
              </div>
            </div>
          </Link>
        ),
      },
      {
        key: 'role',
        component: <span>{user?.role?.name || '--'}</span>,
      },
      {
        key: 'group',
        component: <span>{user?.group?.name || '--'}</span>,
      },
      {
        key: 'last_login',
        component: (
          <span>
            {user.last_access ? setDateFormat(user.last_access) : '--'}
          </span>
        ),
      },
      {
        component: (
          <span>
            {
              <MoreActions
                icon="more_horiz"
                stopPropagation={false}
                items={getActionItemsByUserStatus(user)}
                onHandleRemove={() => handleShowDeleteModal(user)}
                onHandleEdit={() => onHandleChangeStatus(user)}
                onHandleReinvite={() => onHandleReInvite(user)}
                onHandleAdd={() => onImpersonateClick(user)}
              />
            }
          </span>
        ),
      },
    ],
  }));

  const sortTable = ({ name }) => sortingTable({ name, order, setOrder });

  useEffect(() => {
    getListGroups();
  }, [showInvitationModal]);

  useEffect(() => {
    if (activeTab === '1') {
      getUsers();
    }
  }, [filter, paginationPage, order, refresh, activeTab]);

  useEffect(() => {
    if (activeTab === '1') {
      loadStatusesCounts();
    }
  }, [refresh, activeTab]);

  const constants = stringConstants.settings.users;

  return (
    <>
      <UserProfileModal />

      <DeleteConfirmationModal
        showModal={showDeleteUserModal}
        setShowModal={setShowDeleteUserModal}
        setSelectedCategories={setSelectedData}
        event={onHandleDelete}
        heading={constants.delete.heading}
        description={constants.delete.confirm}
      />

      <Card className="mb-5 shadow-none border-0 rounded-0">
        <Card.Header className="border-0 py-2 w-100">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <PillFilters
              items={filterStatuses}
              moduleName="users"
              currentFilter={userFilter}
              onFilterChange={(newFilter) => {
                setFilter({ ...filter, status: newFilter.status });
                setOpenFilter(false);
                setPagination(paginationDefault);
                setPaginationPage(paginationDefault);
                setUserFilter(newFilter);
              }}
            />
            <LayoutHead
              onHandleCreate={() => setShowInvitationModal(true)}
              buttonLabel={'Add New User'}
              selectedData={selectedData}
              onDelete={setShowModal.bind(true)}
              labelButtonDelete="Cancel Invites"
              toggle={() => setShowInvitationModal(true)}
              dataInDB={dataInDB}
            >
              <div className="flex-fill">
                <DataFilters
                  filterSelected={filter}
                  searchClasses="col-md-12"
                  setFilterSelected={(searchFilter) => {
                    setPagination(paginationDefault);
                    setPaginationPage(paginationDefault);
                    setFilter(searchFilter);
                  }}
                  searchPlaceholder="Search Users"
                  paginationPage={paginationPage}
                  setPaginationPage={setPaginationPage}
                />
              </div>
            </LayoutHead>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <div className="table-responsive-md datatable-custom">
            <div
              id="datatable_wrapper"
              className="dataTables_wrapper no-footer"
            >
              {showLoading ? (
                loader()
              ) : (
                <Table
                  columns={usersColumns}
                  data={data}
                  selectAll={selectAll}
                  tableSize="table-xs"
                  headClass="bg-gray-table-head border-top"
                  setSelectAll={setSelectAll}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  onPageChange={(newPage) =>
                    changePaginationPage(newPage, setPaginationPage)
                  }
                  paginationInfo={pagination}
                  usePagination
                  toggle={() => setShowInvitationModal(true)}
                  emptyDataText="No users available yet."
                  title="User"
                  dataInDB={dataInDB}
                  sortingTable={sortTable}
                  sortingOrder={order}
                  isShowTreeView={isShowTreeView}
                  setIsShowTreeView={setIsShowTreeView}
                />
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      {showInvitationModal && (
        <InvitationModal
          showModal={showInvitationModal}
          getUsers={getUsers}
          setShowModal={setShowInvitationModal}
          data={allGroups}
          isShowTreeView={isShowTreeView}
          setIsShowTreeView={setIsShowTreeView}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
        />
      )}
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
    </>
  );
};

export default UsersTable;
