import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import groupService from '../../../../services/groups.service';
import stringConstants from '../../../../utils/stringConstants.json';
import CreateGroupModal from '../../../../components/groups/CreateGroupModal';
import Alert from '../../../../components/Alert/Alert';
import AlertWrapper from '../../../../components/Alert/AlertWrapper';
import LayoutHead from '../../../../components/commons/LayoutHead';
import DeleteConfirmationModal from '../../../../components/modal/DeleteConfirmationModal';
import { TreeViewTable } from '../../../../components/prospecting/v2/common/TreeViewTable';
import { useForm } from 'react-hook-form';
import TableSkeleton from '../../../../components/commons/TableSkeleton';
import { FormGroup, Label } from 'reactstrap';
import { DropdownTreeView } from '../../../../components/prospecting/v2/common/DropdownTreeView';
const constants = stringConstants.settings.groups;

const Groups = ({ activeTab }) => {
  const groupObj = {
    name: '',
    perant_id: '',
    description: '',
  };
  const [groupForm, setGroupForm] = useState(groupObj);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: groupForm,
  });
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [filterGroup, setFilterGroups] = useState(allGroups);

  const [siblingAccess, setSblingAccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [deleteRoleData, setDeleteRoleData] = useState('');
  const [showUpdateGroupModal, setShowUpdateGroupModal] = useState(false);
  const [dataGet, setDataGet] = useState('');
  const [isShow, setIsShow] = useState('');
  const [isAddSingleRole, setIsAddSingleRole] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getListGroups = async () => {
    setShowLoading(true);
    try {
      const result = await groupService.getRoles();
      setAllGroups(result);
      setShowLoading(false);
    } catch (error) {
      setErrorMessage();
    }
  };

  const handleModelShow = async (item) => {
    setDeleteRoleData({ ...item, title: item.name });
    setOpenModal(true);
  };

  const handleDelete = async () => {
    if (!isShow?.id) {
      throw new Error(
        JSON.stringify({
          type: 'no_selection',
          message: constants.edit?.selectRoleTransfer,
        })
      );
    }
    try {
      await groupService.deleteGroup(deleteRoleData.id, isShow?.id);
      setOpenModal(false);
      setDeleteRoleData([]);
      setIsShow('');
      getListGroups();
      setSuccessMessage(constants.delete.transfer);
    } catch (error) {
      setErrorMessage(constants.edit?.groupUpdateFailed);
    }
  };

  const handleEditModelShow = async (item) => {
    setDataGet(item);
    const roleById = await groupService.getGroupById(item?.id);
    roleById &&
      setGroupForm({
        name: roleById?.name || '',
        description: roleById?.description || '',
        id: roleById?.id || '',
        has_sibling_access: roleById.has_sibling_access || false,
        parent_id: isShow?.id || '',
        parent: roleById.parent || '',
      });
    setOpenEditModal(true);
    setValue('name', roleById?.name);
    setValue('description', roleById?.description);
    setValue('parent_id', isShow?.id);
  };

  const handleGetRoleById = async () => {
    setIsLoading(true);
    try {
      await groupService.updateGroup({
        ...groupForm,
        parent_id: isShow?.id,
      });
      setOpenEditModal(false);
      reset(
        setGroupForm({
          name: '',
          description: '',
          parent_id: '',
          has_sibling_access: '',
        })
      );
      setIsShow('');
      getListGroups();
      setDataGet('');
      setSuccessMessage(constants.edit.groupUpdateSuccess);
    } catch (error) {
      setErrorMessage(constants.edit?.groupUpdateFailed);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === '3' && !openModal) {
      getListGroups();
    }
  }, [activeTab, openModal]);

  const createGroup = async (group) => {
    setIsLoading(true);
    const data = {
      name: group?.group_name,
      parent_id: isShow?.id || isAddSingleRole?.id,
      has_sibling_access: siblingAccess,
      description: group?.description,
    };
    try {
      await groupService.CreateGroup(data);
      getListGroups();
      setIsAddSingleRole('');

      setSuccessMessage(constants.create.groupCreatedSuccess);
    } catch (error) {
      setErrorMessage(constants.create?.groupCreatedFailed);
    }
    closeModal();
    setIsLoading(false);
  };

  const closeModal = async () => {
    setShowCreateGroupModal(false);
    setIsShow('');
    setDataGet('');
    setIsAddSingleRole('');
    reset(
      setGroupForm({
        name: '',
        description: '',
        parent_id: '',
        has_sibling_access: '',
      })
    );
  };

  const closeEditModal = async () => {
    setOpenEditModal(false);
    setIsShow('');
    setDataGet('');
    setIsAddSingleRole('');
    reset(
      setGroupForm({
        name: '',
        description: '',
        parent_id: '',
        has_sibling_access: '',
      })
    );
  };

  const handleCreateModalShow = () => {
    setIsAddSingleRole('');
    setShowCreateGroupModal(true);
  };

  const removeNodeWithChildren = (data, targetId) => {
    if (!data || !data.children) return setFilterGroups(data);

    // Filter out the node with the target ID from the children
    data.children = data?.children?.filter((child) => child.id !== targetId);

    // Recursively apply this function to the remaining children
    data.children.forEach((child) => removeNodeWithChildren(child, targetId));
    setFilterGroups(data);
  };

  useEffect(() => {
    if (Object.keys(deleteRoleData)?.length > 0) {
      removeNodeWithChildren(allGroups, deleteRoleData?.id);
    }
  }, [deleteRoleData, openModal]);

  const loader = () => {
    if (showLoading) return <TableSkeleton cols={3} rows={10} />;
  };

  return (
    <>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>

      <DeleteConfirmationModal
        showModal={openModal}
        setShowModal={setOpenModal}
        event={handleDelete}
        heading={constants.delete.RoleDelete}
        description={constants.delete.RoleDeleteConfirm}
        extraBody={
          <div className="mt-3 mb-0">
            <FormGroup className={`d-flex w-100 gap-2 align-items-start mb-0`}>
              <Label className="mb-0 mt-2">Transfer to</Label>
              <div className="flex-fill">
                <DropdownTreeView
                  data={filterGroup}
                  setIsDropdownId={setIsShow}
                  isDropdownId={isShow}
                />
              </div>
            </FormGroup>
          </div>
        }
      />

      <CreateGroupModal
        showModal={showCreateGroupModal}
        setShowModal={closeModal}
        data={allGroups}
        createGroup={createGroup}
        setErrorMessage={setErrorMessage}
        setIsDropdownId={setIsShow}
        setSblingAccess={setSblingAccess}
        isLoading={isLoading}
        isAddSingleRole={isAddSingleRole}
        perantId={isShow?.id || isAddSingleRole?.id}
        siblingAccess={siblingAccess}
        isDropdownId={isShow}
        onChangeDrop={(e) => (e?.target ? setAllGroups(e.target.value) : null)}
      />

      <Card className="mb-5 shadow-none border-0">
        <Card.Header className="px-4 py-2 justify-content-between align-items-center">
          <p className="mb-0" style={{ maxWidth: 700 }}>
            Organizational roles restrict user access to records based on
            hierarchical levels.
          </p>
          <div>
            <LayoutHead
              onHandleCreate={() => handleCreateModalShow()}
              buttonLabel={constants.edit.add}
              headingTitle=""
              dataInDB={allGroups}
              headingText=""
              onDelete={setOpenModal.bind(true)}
            />
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {showLoading ? (
            loader()
          ) : (
            <TreeViewTable
              setUpdateGroupModal={setShowUpdateGroupModal}
              updateGroupModal={showUpdateGroupModal}
              data={allGroups}
              handleEditModelShow={handleEditModelShow}
              setShowReport={handleModelShow}
              handleGetRoleById={handleGetRoleById}
              editRoleData={groupForm}
              closeEditModal={closeEditModal}
              openEditModal={openEditModal}
              getListGroups={getListGroups}
              setOpenEditModal={setOpenEditModal}
              dataGet={dataGet}
              isLoading={isLoading}
              setIsDropdownId={setIsShow}
              isDropdownId={isShow}
              setShowCreateGroupModal={setShowCreateGroupModal}
              setIsAddSingleRole={setIsAddSingleRole}
              showLoading={showLoading}
              setEditRoleData={setGroupForm}
              register={register}
              handleSubmit={handleSubmit}
              reset={reset}
              setValue={setValue}
              errors={errors}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Groups;
