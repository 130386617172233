import React, { useState, useEffect } from 'react';
import { Spinner, Modal, ModalBody, ModalFooter } from 'reactstrap';

import stringConstants from '../../utils/stringConstants.json';
import { Button } from 'react-bootstrap';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';

const constants = stringConstants.deleteConfirmationModal;

const ConfirmationModal = ({
  showModal,
  setShowModal,
  handleCloseModal = () => {},
  setSelectedCategories = () => {},
  event,
  itemsConfirmation = [],
  itemsReport = [],
  clearSelection = () => {},
  customBody,
  heading = constants.deleteHeading,
  description = constants.deleteConfirm,
  positiveBtnText = constants.deleteButton,
  positiveBtnIcon = 'delete',
  showModalFooter = true,
  className = 'delete-confirmation-modal',
  customFooter,
  FooterClassName,
  customEvent,
  loader,
  newModal = true,
  extraBody,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [failedReport, setFailedReport] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const onHandleCloseModal = () => {
    setShowModal(false);
    handleCloseModal();
    try {
      setSelectedCategories([]);
    } catch (e) {
      console.log(e);
    }
    clearSelection();
  };

  const handleSubmit = async () => {
    if (customEvent) {
      customEvent();
    } else {
      try {
        setIsLoading(true);
        await event();
        setIsLoading(false);
        setShowModal(false);
        handleCloseModal();
        setSelectedCategories([]);
        clearSelection();
      } catch (err) {
        try {
          const parsedError = JSON.parse(err?.message || '{}');
          if (parsedError?.message) {
            setErrorMessage(parsedError?.message);
          }
        } catch (e) {
          setErrorMessage(
            'Something went wrong. Please check console for details.'
          );
        }
        console.log('delete error: ', err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderConfirmationIcon = (icon, classModifier) => (
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <i
            className={`material-symbols-rounded confirmation-icon ${classModifier}`}
          >
            {icon}
          </i>
        </div>
      </div>
    </div>
  );

  const validateReport = () => {
    setFailedReport(itemsReport.filter((item) => !item.isDeleted));
  };

  useEffect(() => {
    if (itemsReport.length > 0) {
      validateReport();
    }
  }, [itemsReport]);

  return (
    <Modal
      isOpen={showModal}
      fade={false}
      zIndex={1202}
      className={`delete-role-modal ${className}`}
    >
      <AlertWrapper>
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>

      <ModalBody>
        {customBody || (
          <>
            {newModal ? (
              <>
                <h2 className="mb-2_1 fs-19">{heading}</h2>
                <p className="m-0">{description}</p>
                {extraBody}
              </>
            ) : (
              <>
                {itemsConfirmation?.length > 0 && itemsReport?.length === 0 && (
                  <>
                    {renderConfirmationIcon('report_problem')}
                    <h4>{description}</h4>
                    <hr />
                    <p className="mb-2">{constants.aboutToDelete}</p>
                    <ul className="list-disc">
                      {itemsConfirmation?.map((item) => (
                        <li className="font-weight-medium ml-4" key={item?.id}>
                          <p className="mb-1">{item?.title || item?.name}</p>
                        </li>
                      ))}
                    </ul>
                  </>
                )}

                {itemsReport?.length > 0 && (
                  <>
                    {renderConfirmationIcon('check_circle', 'text-success')}
                    <h4>
                      {failedReport?.length > 0
                        ? constants.confirmationResultFailed
                        : constants.confirmationResultSuccess}
                    </h4>
                    <p>{constants.details}</p>
                    <ul>
                      {itemsReport?.map((item) => (
                        <li key={item.id}>
                          {item.title || item?.name}
                          <i
                            className={`material-symbols-rounded fs-5 ${
                              item.isDeleted ? 'text-success' : 'text-error'
                            }`}
                          >
                            {item.isDeleted ? 'done' : 'close'}
                          </i>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </>
            )}
          </>
        )}
      </ModalBody>

      {showModalFooter && (
        <ModalFooter className={`flex-nowrap gap-1 ${FooterClassName}`}>
          {customFooter || (
            <>
              {itemsReport.length === 0 && (
                <>
                  {newModal ? (
                    <>
                      <Button
                        variant="white"
                        size="sm"
                        onClick={onHandleCloseModal}
                      >
                        {constants.cancelButton}
                      </Button>
                      <Button variant="danger" size="sm" onClick={handleSubmit}>
                        {isLoading || loader ? (
                          <Spinner className="spinner-grow-xs" />
                        ) : (
                          <span>{positiveBtnText}</span>
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-sm w-50 btn-outline-danger"
                        onClick={handleSubmit}
                      >
                        {isLoading || loader ? (
                          <Spinner className="spinner-grow-xs" />
                        ) : (
                          <span className="d-flex align-items-center justify-content-center">
                            <i className="material-symbols-rounded fs-5">
                              {positiveBtnIcon}
                            </i>
                            {positiveBtnText}
                          </span>
                        )}
                      </button>
                      <button
                        className="btn btn-sm w-50 btn-primary"
                        data-dismiss="modal"
                        onClick={onHandleCloseModal}
                      >
                        {constants.cancelButton}
                      </button>
                    </>
                  )}
                </>
              )}

              {itemsReport.length > 0 && (
                <button
                  className="btn btn-sm btn-block btn-primary"
                  data-dismiss="modal"
                  onClick={onHandleCloseModal}
                >
                  {constants.okButton}
                </button>
              )}
            </>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
