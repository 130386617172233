import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, CardHeader } from 'reactstrap';

import {
  DATE_FORMAT,
  checkDueDate,
  setDateFormat,
  getTomorrowDate,
  DATE_FORMAT_TIME,
} from '../../../../utils/Utils';
import Stats from './ChecklistsStats';
import GenericTable from '../../../../components/GenericTable';
import { ReportSkeletonLoader } from '../../ReportSkeletonLoader';
import checklistService from '../../../../services/checklist.service';
import MaterialIcon from '../../../../components/commons/MaterialIcon';
import {
  statusText,
  checklistsReportsPages,
  checklistsReportColumns,
  checklistsReportColumnsToExport,
} from '../../../../utils/checklist.constants';
import OwnerAvatar from '../../../../components/ActivitiesTable/OwnerAvatar';
import ChecklistStatus from '../../../../components/checklist/ChecklistStatus';
import ButtonFilterDropdown from '../../../../components/commons/ButtonFilterDropdown';
import DatePickerTraining from '../../../../components/dealsBreakdown/DatePickerTraining';
import ReportSearchOptionDropdown from '../../../../components/commons/ReportSearchOptionDropdown';

const ChecklistsReport = () => {
  const [order, setOrder] = useState();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checklists, setChecklists] = useState([]);
  const [limit, setLimit] = useState(checklistsReportsPages[2].key);
  const [checklistIds, setChecklistIds] = useState([]);
  const [orgChecklists, setOrgChecklists] = useState([]);
  const [selectedChecklistsKeys, setSelectedChecklistsKeys] = useState([]);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(1970, 0, 1),
    endDate: getTomorrowDate(),
  });

  const mapOrganizationChecklist = (item, i) => {
    return {
      id: i,
      dataRow: [
        {
          key: 'COMPANY NAME',
          component: <span className="fw-bold">{item?.companyName}</span>,
        },
        {
          key: 'CHECKLIST NAME',
          component: item?.checklistTitle,
        },
        {
          key: 'OWNER',
          component: item?.ownerFirstName ? (
            <OwnerAvatar
              isMultiple={true}
              item={{
                external: false,
                first_name: item?.ownerFirstName,
                last_name: item?.ownerLastName,
              }}
            />
          ) : (
            '--'
          ),
        },
        {
          key: 'STATUS',
          component: (
            <ChecklistStatus
              item={{
                status: statusText[item?.status] || 'Pending',
                statusText: statusText[item?.status] || 'Not Started',
              }}
            />
          ),
        },
        {
          key: 'LAST UPDATE',
          component: moment(item?.updatedAt).format(DATE_FORMAT),
        },
        {
          key: 'DUE DATE',
          bgColor:
            item?.status !== 'completed' &&
            checkDueDate(item?.initialDueDate)?.length
              ? 'bg-red-soft'
              : '',
          component: (
            <span
              className={`${
                item?.status !== 'completed'
                  ? checkDueDate(item?.initialDueDate)
                  : ''
              }`}
            >
              {item?.status !== 'completed' &&
                checkDueDate(item?.initialDueDate)?.length && (
                  <span className="position-relative" style={{ top: 5 }}>
                    <MaterialIcon icon="flag" rounded filled clazz="text-red" />
                  </span>
                )}
              {item?.initialDueDate
                ? setDateFormat(item?.initialDueDate, 'MM/DD/YYYY')
                : '--'}
            </span>
          ),
        },
        {
          key: 'COMPLETED AT',
          component:
            item?.status === 'completed'
              ? setDateFormat(item?.updatedAt, 'MM/DD/YYYY')
              : '--',
        },
      ],
    };
  };

  const getOrganizationChecklists = async () => {
    setLoading(true);
    const payload = {
      page: 1,
      limit,
      order,
      'initialDueDateRange[start]': moment(dateRange?.startDate).format(
        DATE_FORMAT_TIME
      ),
      'initialDueDateRange[end]': moment(dateRange?.endDate).format(
        DATE_FORMAT_TIME
      ),
    };

    if (checklistIds?.length) {
      payload.checklistId = checklistIds;
    }

    const { data } = await checklistService.getOrgCheckLists(payload);

    const mappedData = data?.data?.map((orgChk) => {
      const owner = orgChk?.organizationChecklistOwners?.[0]?.user;
      const lastProgress =
        orgChk?.organizationChecklistProgresses?.[
          orgChk?.organizationChecklistProgresses?.length - 1 || 0
        ];

      return {
        companyName: orgChk?.organization?.name,
        checklistTitle: orgChk?.checklist?.title,
        ownerFirstName: owner?.first_name,
        ownerLastName: owner?.last_name,
        ownerName: owner?.name,
        status: lastProgress?.status,
        lastUpdate: lastProgress?.updatedAt,
        initialDueDate: orgChk?.initialDueDate,
        completedAt:
          lastProgress?.status === 'completed' ? lastProgress?.updatedAt : '--',
      };
    });

    setOrgChecklists(mappedData);
    setLoading(false);
  };

  const handleSort = (key) => {
    setOrder([[key?.name?.orderBy, key?.name?.clicked.toLowerCase()]]);
  };

  const getChecklists = async () => {
    const { data } = await checklistService.getCheckLists({
      page: 1,
      limit: 100,
      status: 'active',
    });
    setChecklists(data.data);
  };

  const getStatsProps = () => {
    const stats = orgChecklists.reduce(
      (prev, curr) => {
        if (!curr) return prev;

        const { initialDueDate: idd, status = null } = curr;
        if (!status) return prev;

        const overdueDate = moment(idd).isBefore(moment());
        const currStatus = status;

        if (overdueDate && currStatus !== 'completed') {
          prev.overdue = (prev.overdue ?? 0) + 1;
        }
        prev[currStatus] = (prev[currStatus] ?? 0) + 1;
        return prev;
      },
      { completed: 0, inProgress: 0, pending: 0, overdue: 0 }
    );

    return { ...stats, totalCount: orgChecklists.length };
  };

  useEffect(() => {
    getOrganizationChecklists();
  }, [order, limit, checklistIds, dateRange]);

  useEffect(() => {
    getChecklists();
  }, []);

  return (
    <Card className={`overflow-x-hidden overflow-y-auto`}>
      <CardHeader>
        <div className="d-flex justify-content-between align items center w-100">
          <h4 className="card-title text-hover-primary mb-0 pt-2 pb-2">
            Checklists Report
          </h4>
          <div className={`d-flex align-items-center mr-5 pr-5`}>
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              disablePastDate
              extraClass="mx-1"
            />
            <ReportSearchOptionDropdown
              show={show}
              data={checklists}
              setShow={setShow}
              showCheckAll={false}
              label={`Checklists`}
              setQueryFilters={() => {}}
              scrollable="pr-3 text-left"
              handleOptionSelected={() => {}}
              customStyle={{ width: '340px' }}
              customKey={['title', 'checklistId']}
              selectedData={selectedChecklistsKeys}
              handleAllSelect={() => setChecklistIds('')}
              setSelectedData={setSelectedChecklistsKeys}
              handleOnDone={(items) => setChecklistIds(items)}
              controlledTitle={
                checklists
                  ?.filter((i) => checklistIds?.includes(i.id))
                  .map((i) => i.title)
                  .join(',') || ''
              }
            />
            <ButtonFilterDropdown
              buttonText="Timeline"
              options={checklistsReportsPages}
              filterOptionSelected={{ key: limit, name: limit }}
              handleFilterSelect={(_, item) => setLimit(item.key)}
              btnToggleStyle={`btn-md mr-5 ml-2`}
            />
          </div>
        </div>
      </CardHeader>
      <div className="p-3">
        <Stats {...{ ...getStatsProps(), isLoading: loading }} />
        {loading ? (
          <div className="w-100 d-block p-1">
            <ReportSkeletonLoader rows={15} height={15} />
          </div>
        ) : (
          <GenericTable
            checkbox={false}
            exportToCSV={true}
            usePagination={false}
            sortingTable={handleSort}
            tableData={orgChecklists}
            fileName="Checklist Report"
            noDataInDbValidation={true}
            columns={checklistsReportColumns}
            columnsToExport={checklistsReportColumnsToExport}
            data={orgChecklists.map(mapOrganizationChecklist)}
          />
        )}
      </div>
    </Card>
  );
};

export default ChecklistsReport;
