import React from 'react';
import AddFile from './addfile/AddFile';
import PageTitle from '../commons/PageTitle';
import './style.css';
import './responsive.css';
import { useClientPortalContext } from '../../contexts/clientPortalContext';

const Files = () => {
  const { organizationId, contactId } = useClientPortalContext();

  return (
    <>
      <PageTitle page="Files" pageModule="" />

      <div className="custom-layout">
        <div className="custom-layout-content bg-white">
          <div className="page-title pt-3 px-3 pb-0 d-flex justify-content-between align-items-center">
            <h3 className="mb-0">
              <span className="font-weight-bold">Files</span>
            </h3>
          </div>
          <AddFile contactId={contactId} organizationId={organizationId} />
        </div>
      </div>
    </>
  );
};

export default Files;
